import React, {useEffect, useState, useRef} from "react";
import imageUrlBuilder from '@sanity/image-url'
import client from "../client";
import { BiX } from 'react-icons/bi';
import './bigpicture.styles.scss'

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

const BigPicture=({img,caption, setBigPicture})=>{

// console.log(img)

return(
    <div className="bigpicture-wrapper">
        <div className="bigpicture-close" onClick={()=>setBigPicture(false)}>
            <BiX/>
        </div>
        <div className="bigpicture-image-wrapper" onClick={()=>setBigPicture(false)}>
            <div className="bigpicture-image">
                <img src={urlFor(img).width(1200).url()}/>
            </div>
            {caption && 
            <div className="bigpicture-caption">
                {caption}
            </div>
            }
        </div>
        
        <div className="bigpicture-background" onClick={()=>setBigPicture(false)}></div>
    </div>
    )
}
export default BigPicture