import React, {useEffect, useState, useRef} from "react";
import styled from 'styled-components'

import './kirkekart.styles.scss'

const KirkeKart =({zoomLevel})=>{

let zoomLevelFactor = 0.4;
if (zoomLevel > 4){
	zoomLevelFactor = 0.1;
}else if (zoomLevel <= 4 && zoomLevel > 3){
	zoomLevelFactor = 0.2;
}else if (zoomLevel <= 3 && zoomLevel > 2){
	zoomLevelFactor = 0.25;
}else if (zoomLevel <= 2){
	zoomLevelFactor = 0.3;
}

return(
    <div>
<svg version="1.1" x="0px" y="0px" viewBox="0 0 1190.08 1200.73">
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="447.08,794.37 447.41,790.16 444.82,788.87 443.85,772.04 447.73,763.3 462.62,752.62 472,750.03 
	481.06,751.01 491.42,753.59 495.63,754.24 498.86,758.12 501.77,762.01 504.04,766.54 503.07,768.48 503.72,770.1 505.66,777.54 
	506.95,775.28 507.92,775.6 507.28,790.16 505.33,790.49 504.36,792.43 504.69,802.14 509.54,804.08 515.37,803.43 518.93,800.19 
	517.95,792.75 516.01,791.13 516.01,789.52 516.01,775.6 516.66,773.01 518.28,773.33 517.95,774.31 519.25,774.63 520.54,773.98 
	520.54,772.04 520.87,769.13 520.54,765.57 520.87,763.63 526.05,757.8 530.9,750.36 540.61,747.45 549.02,747.12 555.49,747.45 
	560.35,749.71 564.88,752.95 569.41,754.89 573.94,758.77 574.26,761.68 574.59,764.92 574.26,764.92 573.62,766.86 575.88,767.19 
	578.47,790.49 576.53,790.49 576.53,797.93 588.83,798.58 590.12,796.63 589.47,789.84 588.83,787.57 587.53,787.25 588.83,767.83 
	589.8,767.51 591.42,767.19 591.42,765.57 590.12,765.24 589.8,763.63 590.77,759.74 592.39,757.8 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="517.95,756.51 529.93,745.5 540.28,742.59 548.7,741.62 552.91,741.94 558.73,742.59 561.97,744.53 
	567.79,747.45 572,750.68 574.26,753.27 577.18,757.8 577.82,751.01 577.18,740.65 517.95,741.94 517.63,756.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="505.98,756.51 505.98,741.94 442.23,743.56 441.58,759.1 452.26,751.01 460.68,747.12 467.47,745.18 
	473.3,744.86 478.15,745.18 486.89,747.45 493.36,749.39 499.19,752.62 506.95,757.15 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="439.32,800.84 445.79,795.34 447.08,794.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="517.31,741.3 518.28,740.97 518.6,739.68 519.9,740 520.22,739.36 518.93,738.38 517.95,738.06 
	517.95,733.53 518.6,726.09 518.93,725.44 518.93,725.12 518.28,724.47 517.95,723.82 517.95,720.91 518.28,719.94 516.66,718.97 
	516.98,671.4 517.95,668.81 519.25,668.81 519.25,669.78 520.22,669.13 520.54,668.16 520.54,667.51 521.84,666.87 521.84,664.28 
	518.93,662.98 512.78,662.01 508.57,662.01 505.66,663.95 504.36,664.28 504.04,665.9 504.04,667.19 505.33,668.48 506.3,668.81 
	507.28,668.81 507.92,669.78 508.25,671.4 507.6,672.04 508.57,672.37 507.6,718.32 506.95,718.64 506.3,719.61 505.98,720.91 
	506.63,723.82 506.3,724.47 505.98,724.79 506.3,726.73 506.3,737.41 505.66,738.38 504.69,739.03 504.69,739.68 505.66,740.97 
	505.98,740.97 507.28,741.3 517.31,741.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="508.89,663.63 506.95,641.62 505.98,632.89 503.72,628.68 498.86,620.91 492.71,615.41 485.59,611.2 
	476.86,607.32 469.41,610.23 457.76,615.41 453.88,619.62 450.32,625.77 446.44,633.21 444.17,640.98 443.85,662.98 445.47,663.31 
	446.76,664.6 446.44,666.22 445.14,665.9 445.14,670.43 442.55,724.47 442.23,731.26 442.55,738.06 442.23,739.36 443.52,740.33 
	444.17,742.91 442.23,742.91 439.96,742.91 438.99,742.91 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="506.95" y1="724.15" x2="440.61" y2="725.12"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="505.98" y1="725.44" x2="440.61" y2="726.73"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="504.04,733.85 504.36,731.59 504.04,730.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="504.04,729.97 504.04,729.65 503.07,729.32 503.07,729 503.07,728.03 501.77,728.03 500.8,728.68 
	500.8,729 500.48,729.32 499.83,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="499.83,730.94 499.83,731.59 499.83,733.85 504.36,733.85 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="504.04" y1="729.97" x2="499.83" y2="729.97"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="504.04" y1="730.94" x2="499.83" y2="730.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="498.54,734.18 498.54,731.59 498.54,730.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="498.21,730.29 498.21,729.65 497.57,729.32 497.57,729 497.24,728.35 495.95,728.03 495.3,728.68 
	495.3,729.32 494.98,729.32 494.33,729.97 494.33,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="494.33,730.94 494.33,731.59 494.01,734.18 498.54,733.85 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="498.21" y1="730.29" x2="494.33" y2="730.29"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="498.54" y1="730.94" x2="494.33" y2="730.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="492.39,734.18 492.39,731.59 492.39,730.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="492.39,730.29 492.39,729.65 491.42,729.32 491.42,729 491.1,728.35 489.8,728.03 489.15,728.68 
	489.15,729.32 488.83,729.32 488.18,729.97 488.18,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="488.18,730.94 488.18,731.59 488.18,734.18 492.39,733.85 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="492.39" y1="730.29" x2="488.18" y2="730.29"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="492.39" y1="730.94" x2="488.18" y2="730.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="486.56,734.18 486.56,731.91 486.24,731.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="486.24,730.29 486.24,729.97 485.27,729.65 485.27,729.32 485.27,728.35 483.98,728.35 483,729 
	483,729.32 482.68,729.65 482.03,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="482.03,731.26 482.03,731.91 482.03,734.18 486.56,734.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="486.24" y1="730.29" x2="482.03" y2="730.29"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="486.24" y1="731.26" x2="482.03" y2="731.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="480.09,734.18 480.09,731.59 480.09,730.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="479.77,730.29 479.77,729.65 479.12,729.32 479.12,729 478.8,728.35 477.5,728.03 476.86,728.68 
	476.86,729.32 476.53,729.32 475.89,729.97 475.89,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="475.89,730.94 475.89,731.59 475.89,733.85 480.09,733.85 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="480.09" y1="730.29" x2="475.89" y2="730.29"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="480.09" y1="730.94" x2="475.89" y2="730.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="473.94,734.18 473.94,731.91 473.94,731.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="473.62,730.29 473.62,729.97 472.97,729.65 472.97,729.32 472.65,728.35 471.35,728.35 470.71,729 
	470.71,729.32 470.38,729.65 469.74,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="469.74,731.26 469.74,731.91 469.41,734.18 473.94,734.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="473.94" y1="730.29" x2="469.74" y2="730.29"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="473.94" y1="731.26" x2="469.74" y2="731.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="467.47,734.18 467.79,731.59 467.47,731.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="467.47,730.29 467.47,729.97 466.5,729.32 466.5,729 466.18,728.35 464.88,728.03 464.24,728.68 
	464.24,729.32 463.91,729.32 463.26,729.97 463.26,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="463.26,731.26 463.26,731.59 463.26,734.18 467.79,733.85 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="467.47" y1="730.29" x2="463.26" y2="730.29"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="467.47" y1="731.26" x2="463.26" y2="731.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="461,734.18 461,731.91 461,731.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="461,730.29 461,729.97 460.03,729.65 460.03,729.32 459.7,728.35 458.41,728.35 457.76,729 
	457.76,729.32 457.44,729.65 456.79,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="456.79,731.26 456.79,731.91 456.79,734.18 461,734.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="461" y1="730.29" x2="456.79" y2="730.29"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="461" y1="731.26" x2="456.79" y2="731.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="454.85,734.5 454.85,731.91 454.85,731.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="454.85,730.29 454.53,729.97 453.88,729.65 453.88,729.32 453.56,728.35 452.26,728.35 451.61,729 
	451.61,729.65 451.29,729.65 450.64,730.29 450.64,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="450.64,731.26 450.64,731.91 450.64,734.18 454.85,734.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="454.85" y1="730.29" x2="450.64" y2="730.62"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="454.85" y1="731.26" x2="450.64" y2="731.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="448.38,734.82 448.38,732.24 448.38,731.59 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="448.05,730.94 448.05,730.29 447.41,729.97 447.41,729.65 447.08,729 445.79,728.68 445.14,729.32 
	445.14,729.97 444.82,729.97 444.17,730.62 444.17,730.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="444.17,731.59 444.17,732.24 443.85,734.82 448.38,734.5 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="448.38" y1="730.94" x2="444.17" y2="730.94"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="448.38" y1="731.59" x2="444.17" y2="731.59"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="501.13,726.73 499.19,729.32 497.57,726.73 501.13,726.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="495.3,726.73 493.36,729.32 491.74,726.73 495.3,726.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="489.15,727.06 487.21,729.32 485.27,727.06 489.15,727.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="483,727.06 481.06,729.32 479.44,727.06 483,727.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="476.86,727.06 474.91,729.65 472.97,727.06 476.86,727.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="470.71,727.06 468.77,729.65 467.15,727.06 470.71,727.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="464.24,727.38 461.97,729.65 460.35,727.38 464.24,727.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="457.76,727.38 455.82,729.97 454.2,727.38 457.76,727.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="451.29,727.38 449.35,729.97 447.41,727.38 451.29,727.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="506.3,729.32 505.01,729.32 503.39,727.06 506.63,727.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="441.91,727.38 445.14,727.38 443.2,729.97 441.91,729.97 441.91,727.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="578.47,729.32 578.47,729 577.82,728.68 577.5,728.35 577.5,727.38 576.21,727.38 575.23,728.03 
	575.23,728.68 574.59,729.32 574.59,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="574.26,730.29 574.26,730.94 574.26,733.21 578.79,733.21 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="578.47" y1="729.32" x2="574.59" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="578.47" y1="730.29" x2="574.26" y2="730.29"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="572.97,733.53 572.97,731.26 572.97,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="572.65,729.65 572.65,729.32 572,728.68 571.67,727.71 570.38,727.71 569.73,728.35 569.73,728.68 
	569.41,729 568.76,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="568.76,730.62 568.76,730.94 568.76,733.53 572.97,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="572.97" y1="729.65" x2="568.76" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="572.97" y1="730.62" x2="568.76" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="566.82,733.53 567.14,730.94 566.82,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="566.82,729.65 566.82,729.32 565.85,728.68 565.53,727.71 564.23,727.71 563.58,728.35 563.58,728.68 
	563.26,729 562.61,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="562.61,730.62 562.61,730.94 562.61,733.53 567.14,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="566.82" y1="729.65" x2="562.61" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="566.82" y1="730.62" x2="562.61" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="561,733.53 561,731.26 561,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="560.67,729.65 560.67,729.32 560.02,729 559.7,728.68 559.7,727.71 558.41,727.71 557.76,728.35 
	557.76,728.68 557.44,729 556.79,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="556.46,730.62 556.46,731.26 556.46,733.53 561,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="560.67" y1="729.65" x2="556.79" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="561" y1="730.62" x2="556.46" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="554.52,733.53 554.52,730.94 554.52,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="554.52,729.65 554.52,729.32 553.55,728.68 553.55,728.35 553.23,727.71 551.93,727.71 551.29,728.35 
	551.29,728.68 550.96,729 550.32,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="550.32,730.62 550.32,730.94 550.32,733.53 554.52,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="554.52" y1="729.65" x2="550.32" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="554.52" y1="730.62" x2="550.32" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="548.37,733.53 548.37,731.26 548.37,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="548.37,729.65 548.05,729.32 547.4,729 547.4,728.68 547.08,727.71 545.79,727.71 545.14,728.35 
	545.14,728.68 544.81,729 544.17,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="544.17,730.62 544.17,731.26 544.17,733.53 548.37,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="548.37" y1="729.65" x2="544.17" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="548.37" y1="730.62" x2="544.17" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="542.23,733.53 542.23,731.26 541.9,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="541.9,729.65 541.9,729.32 540.93,728.68 540.93,727.71 539.64,727.71 538.67,728.35 538.67,728.68 
	538.34,729 538.02,729.65 537.7,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="537.7,730.62 537.7,731.26 537.7,733.53 542.23,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="541.9" y1="729.65" x2="537.7" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="541.9" y1="730.62" x2="537.7" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="535.43,733.53 535.75,731.26 535.43,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="535.43,729.65 535.43,729.32 534.46,729 534.46,728.68 534.14,727.71 532.84,727.71 532.19,728.35 
	532.19,728.68 531.87,729 531.22,729.65 531.22,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="531.22,730.62 531.22,731.26 531.22,733.53 535.75,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="535.43" y1="729.65" x2="531.22" y2="729.97"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="535.43" y1="730.62" x2="531.22" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="529.28,733.85 529.28,731.26 529.28,730.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="529.28,729.97 529.28,729.65 528.31,729 528.31,728.68 527.99,728.03 526.69,728.03 526.05,728.68 
	526.05,729 525.72,729.32 525.07,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="525.07,730.94 525.07,731.26 525.07,733.85 529.28,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="529.28" y1="729.97" x2="525.07" y2="729.97"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="529.28" y1="730.94" x2="525.07" y2="730.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="522.81,734.18 522.81,731.91 522.81,731.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="522.81,730.29 522.81,729.97 521.84,729.32 521.51,728.35 520.22,728.35 519.57,729 519.57,729.32 
	519.25,729.65 518.6,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="518.6,731.26 518.6,731.59 518.6,734.18 522.81,734.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="522.81" y1="730.29" x2="518.6" y2="730.29"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="522.81" y1="731.26" x2="518.6" y2="731.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="575.56,726.41 573.62,728.68 572,726.41 575.56,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="569.73,726.41 567.79,728.68 566.17,726.41 569.73,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="563.58,726.41 561.64,728.68 560.02,726.41 563.58,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="557.44,726.41 555.49,729 553.88,726.41 557.44,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="551.29,726.41 549.35,729 547.4,726.41 551.29,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="545.14,726.73 543.2,729 541.58,726.73 545.14,726.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="538.67,726.73 536.72,729 534.78,726.73 538.67,726.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="532.52,727.06 530.25,729.32 528.63,727.06 532.52,727.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="525.72,727.06 523.78,729.32 521.84,727.06 525.72,727.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="580.74,728.68 579.76,728.68 577.82,726.41 581.06,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="518.6,726.73 520.54,727.06 518.93,729.32 518.28,729.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="516.66,718.32 513.1,718.97 511.48,718.64 509.22,718.32 507.28,718.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="517.31,718.97 515.04,719.61 512.13,719.29 509.54,719.29 508.25,719.29 506.95,718.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="518.28,724.79 524.75,724.79 560.67,723.82 577.82,723.82 577.82,724.79 518.93,725.76 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="577.18,723.82 576.53,723.17 576.53,721.88 576.21,721.56 575.88,721.23 518.28,720.59 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="506.3,720.59 442.88,721.88 441.91,722.53 440.94,723.5 440.94,725.12 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="566.82,720.59 566.17,719.94 566.5,718.97 566.5,718.64 566.82,718.64 566.82,718 566.17,717.67 
	565.53,717.67 565.2,718.32 565.53,718.64 565.2,718.97 565.2,719.61 517.95,719.61 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="506.3,719.29 461,720.26 460.68,720.26 460.35,719.94 461,719.29 461,718.97 460.35,718.64 
	460.03,718.64 459.38,719.29 459.38,719.94 459.06,720.26 458.73,721.56 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="498.21,708.29 497.89,666.54 495.3,657.8 489.15,652.63 481.39,649.07 470.06,653.92 466.5,662.66 
	464.88,685.31 466.5,706.02 465.85,708.61 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M487.54,657c0-2.95-2.39-5.34-5.34-5.34c-2.95,0-5.34,2.39-5.34,5.34s2.39,5.34,5.34,5.34
	C485.14,662.34,487.54,659.94,487.54,657"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M479.77,669.94c0-2.95-2.39-5.34-5.34-5.34c-2.95,0-5.34,2.39-5.34,5.34s2.39,5.34,5.34,5.34
	C477.38,675.28,479.77,672.89,479.77,669.94"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M494.65,670.59c0-2.95-2.39-5.34-5.34-5.34c-2.95,0-5.34,2.39-5.34,5.34s2.39,5.34,5.34,5.34
	C492.26,675.93,494.65,673.54,494.65,670.59"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="495.95,708.29 496.27,683.69 495.63,681.75 494.98,680.78 493.36,680.13 492.39,679.49 489.48,681.1 
	488.83,684.02 488.18,687.9 487.86,696.96 488.83,708.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="485.92,708.29 486.56,680.78 486.24,679.49 485.27,678.19 484.62,676.9 483.65,676.25 482.68,675.6 
	480.09,677.22 478.47,680.46 478.47,687.25 478.47,701.82 478.15,708.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="477.18,708.29 476.53,682.72 475.89,680.13 475.24,679.16 473.3,678.19 470.71,679.81 469.09,684.02 
	468.12,693.73 467.79,703.11 468.12,708.61 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="518.93,663.31 522.49,632.89 534.46,619.62 541.9,613.47 551.61,611.53 567.14,620.27 574.91,634.5 
	578.15,651.98 576.53,695.99 576.85,722.53 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="578.15,660.39 571.67,643.89 567.14,638.39 562.29,634.5 552.58,624.15 542.87,626.09 538.02,627.39 
	531.22,632.56 528.63,635.48 526.05,639.04 521.51,664.28 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="552.26,624.8 559.7,644.54 563.91,657.16 566.5,668.81 565.53,684.99 565.2,706.02 565.2,706.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="550.64,624.8 541.26,634.83 537.05,638.71 534.14,642.59 530.25,661.36 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="481.39,635.8 491.1,642.59 497.24,648.74 502.1,655.86 505.66,664.6 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="483.65,636.77 473.62,639.36 466.82,645.51 463.91,649.71 461,653.92 458.73,657.48 456.79,665.9 
	456.79,709.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="444.82,661.36 452.26,635.48 472.97,622.85 481.39,624.47 489.15,624.8 494.33,627.39 498.54,632.24 
	505.33,643.57 508.57,654.24 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="479.77,623.83 492.07,637.74 496.92,647.13 497.57,649.07 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="480.09,624.15 470.06,633.21 464.56,642.59 460.03,655.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="544.17,639.04 552.91,642.92 558.08,650.04 564.56,660.72 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="543.2,639.36 534.46,642.27 533.81,643.57 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="563.58,706.67 563.26,664.92 560.67,656.19 554.52,651.01 546.76,647.45 535.43,652.3 530.9,661.04 
	530.25,683.69 531.87,704.73 531.22,707.32 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M552.91,655.38c0-2.95-2.39-5.34-5.34-5.34c-2.95,0-5.34,2.39-5.34,5.34s2.39,5.34,5.34,5.34
	C550.51,660.72,552.91,658.33,552.91,655.38"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M545.14,668.32c0-2.95-2.39-5.34-5.34-5.34s-5.34,2.39-5.34,5.34s2.39,5.34,5.34,5.34
	S545.14,671.27,545.14,668.32"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M560.02,668.97c0-2.95-2.39-5.34-5.34-5.34c-2.95,0-5.34,2.39-5.34,5.34s2.39,5.34,5.34,5.34
	C557.63,674.31,560.02,671.92,560.02,668.97"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="561.32,706.67 561.64,682.08 561,680.13 560.35,679.16 558.73,678.52 557.76,677.87 554.85,679.49 
	554.2,682.4 553.55,686.28 553.23,695.34 553.88,706.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="551.29,706.67 551.93,679.16 551.29,677.87 550.64,676.57 549.99,675.28 549.02,674.63 548.05,673.99 
	545.46,675.6 543.84,679.16 543.52,685.64 543.84,700.2 543.52,706.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="542.55,706.67 541.9,681.1 541.26,678.52 540.61,677.55 538.67,676.57 536.08,678.19 534.14,682.4 
	533.49,692.43 533.16,701.49 533.49,706.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="723.45,868.48 723.77,867.83 724.1,867.18 724.42,866.53 724.74,866.21 724.42,865.56 724.1,864.92 
	723.77,864.92 723.45,863.95 723.45,862.98 723.45,862.33 723.13,862.33 723.13,861.36 723.45,861.03 724.1,861.03 724.42,861.36 
	724.42,861.68 724.1,862 724.1,862.33 724.42,862.65 725.71,863.62 726.36,863.62 727.01,863.3 727.33,862.65 727.33,861.36 
	727.33,860.39 727.33,859.74 727.01,859.09 727.01,858.77 727.01,858.44 726.69,859.09 726.36,858.44 726.36,856.5 726.36,856.18 
	726.69,855.86 727.01,856.18 727.01,856.5 727.66,855.86 728.3,855.21 727.98,855.21 727.66,854.88 727.66,854.24 728.3,853.91 
	728.95,854.24 729.27,854.56 729.27,854.88 729.27,855.21 729.27,855.53 729.6,856.18 729.92,856.18 729.92,855.86 730.24,855.86 
	730.24,856.83 730.24,857.8 730.24,858.77 729.92,858.44 730.24,859.09 730.57,859.42 730.57,860.06 730.57,860.39 730.57,861.36 
	730.57,862.33 730.57,862.98 730.89,864.92 730.89,865.24 730.89,865.56 730.89,866.21 730.57,866.86 730.24,866.86 730.24,866.21 
	730.57,865.89 730.24,865.89 729.6,866.21 729.6,866.53 729.27,867.18 729.6,867.83 730.24,868.8 730.57,869.12 730.57,869.45 
	730.57,870.09 730.57,870.74 730.57,871.39 731.22,871.39 731.22,872.36 722.15,872.04 722.48,871.07 723.13,871.07 723.45,870.74 
	723.45,870.09 723.45,869.45 723.45,868.8 723.77,868.48 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="725.07,870.74 725.07,870.09 724.74,870.09 725.07,869.45 725.39,868.8 725.71,868.48 726.36,868.15 
	727.33,867.51 727.98,868.15 728.63,868.8 728.95,869.45 729.27,870.09 729.27,870.74 728.95,870.42 728.95,870.74 725.07,870.74 
	"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="723.45" y1="861.03" x2="723.77" y2="805.7"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="728.95" y1="854.24" x2="729.27" y2="805.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="693.68,868.8 694,867.83 694.32,867.18 694.65,866.86 694.97,866.53 694.65,865.56 694.32,865.24 
	694,864.92 693.68,863.95 693.68,863.3 693.68,862.33 693.35,862.33 693.35,861.68 693.68,861.03 694.32,861.03 694.65,861.36 
	694.65,861.68 694.32,862 694.32,862.33 694.65,862.98 695.94,863.95 696.59,863.62 697.24,863.62 697.56,862.65 697.56,861.68 
	697.56,860.71 697.56,860.06 697.24,859.42 697.24,858.77 696.91,859.09 696.59,858.44 696.59,856.5 696.59,856.18 696.91,855.86 
	697.24,856.5 697.88,855.86 698.53,855.53 698.21,855.21 697.88,854.88 697.88,854.56 697.88,854.24 698.53,854.24 699.18,854.24 
	699.5,854.56 699.5,854.88 699.5,855.21 699.5,855.53 699.5,855.86 699.83,856.5 700.15,856.5 700.15,856.18 700.47,856.18 
	700.47,857.15 700.47,858.12 700.47,858.77 700.15,858.44 700.47,859.09 700.8,859.42 700.8,860.06 700.8,860.71 700.8,861.36 
	700.8,862.33 700.8,862.98 701.12,864.92 701.12,865.24 701.12,865.89 701.12,866.53 700.8,866.86 700.47,866.86 700.47,866.53 
	700.8,866.21 700.47,866.21 699.83,866.53 699.5,867.18 699.83,868.15 700.47,868.8 700.8,869.12 700.8,869.45 700.8,870.42 
	700.8,871.07 700.8,871.39 701.44,871.39 701.44,872.36 692.38,872.36 692.71,871.07 693.35,871.07 693.68,870.74 693.68,870.09 
	693.68,869.77 693.68,869.12 694,868.8 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="695.29,870.74 695.29,870.42 694.97,870.09 695.29,869.45 695.62,868.8 695.94,868.48 696.59,868.15 
	697.56,867.83 698.21,868.15 698.85,869.12 699.18,869.77 699.5,870.42 699.5,870.74 699.18,870.74 699.18,870.42 699.18,870.74 
	699.18,871.07 695.29,870.74 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="699.18" y1="854.56" x2="699.18" y2="782.72"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="693.68,790.81 693.68,789.84 693.68,789.19 693.35,788.87 693.03,788.22 693.35,787.9 693.68,787.9 
	694,787.9 694.65,787.9 694.32,788.54 694,788.87 694.32,789.19 694.32,789.52 695.94,790.49 696.59,790.49 696.91,790.16 
	697.56,789.52 697.56,788.22 697.56,787.25 697.24,786.6 697.24,785.96 697.24,785.63 697.24,785.31 696.91,785.96 696.59,785.63 
	696.27,784.98 696.59,783.37 696.27,782.72 696.91,782.72 697.24,783.04 697.56,782.72 698.21,782.07 697.88,781.75 697.88,781.42 
	697.88,781.1 697.88,780.78 698.21,780.78 698.85,780.78 699.5,781.1 699.5,781.42 699.5,782.07 699.18,782.07 699.5,782.4 
	699.83,783.04 700.15,782.72 700.47,782.72 700.47,783.69 700.47,784.66 700.15,785.63 700.15,785.31 699.83,785.31 700.15,785.96 
	700.47,785.96 700.47,786.93 700.47,787.25 700.8,787.9 700.8,788.87 700.8,789.84 701.12,791.46 701.12,791.78 701.12,792.43 
	700.8,793.07 700.8,793.72 700.47,793.72 700.47,793.07 700.47,792.75 699.83,793.07 699.5,793.4 699.5,794.05 699.83,794.69 
	700.15,795.34 700.47,795.66 700.8,796.31 700.8,796.96 700.8,797.61 700.8,797.93 701.12,798.25 701.12,799.22 699.18,799.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="699.18,791.13 693.68,790.81 693.68,861.03 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="637.04,868.15 637.04,867.51 637.69,866.86 638.02,866.21 638.02,865.89 638.02,865.24 637.37,864.92 
	637.04,864.59 637.04,863.62 637.04,862.98 637.04,862 636.72,862 636.4,861.03 636.72,860.71 637.04,860.71 637.37,860.71 
	638.02,861.03 637.69,861.36 637.37,861.68 637.69,862 637.69,862.65 639.31,863.3 639.96,863.3 640.28,862.98 640.93,862.33 
	640.93,861.36 640.93,860.06 640.6,859.74 640.6,858.77 640.6,858.44 640.6,858.12 640.28,858.77 639.96,858.77 639.63,858.12 
	639.96,856.18 639.63,855.86 640.28,855.53 640.28,855.86 640.6,856.18 640.93,855.53 641.57,854.88 641.25,854.88 641.25,854.56 
	641.25,853.91 641.57,853.59 642.22,853.91 642.87,854.24 642.87,854.56 642.55,854.88 642.55,855.21 643.19,855.86 643.52,855.86 
	643.52,855.53 643.84,855.53 643.84,856.5 643.84,857.8 643.52,858.44 643.52,858.12 643.19,858.12 643.52,858.77 643.84,859.09 
	643.84,859.74 643.84,860.06 643.84,861.03 643.84,862 644.16,862.65 644.49,864.59 644.49,864.92 644.49,865.24 644.16,865.89 
	644.16,866.53 643.84,866.53 643.84,865.89 643.84,865.56 643.19,865.89 642.87,866.21 642.87,866.86 643.19,867.51 643.52,868.48 
	643.84,868.8 643.84,869.12 644.16,869.77 644.16,870.42 644.16,871.07 644.49,871.07 644.49,872.04 635.75,871.71 635.75,870.74 
	636.4,870.74 636.72,870.42 636.72,869.77 636.72,869.12 637.04,868.48 637.04,868.15 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="638.34,870.42 638.66,869.77 638.34,869.77 638.02,869.77 638.34,869.12 638.99,868.48 639.31,868.15 
	639.63,867.83 640.6,867.18 641.25,867.83 641.9,868.48 642.55,869.45 642.55,869.77 642.55,870.42 642.55,870.09 642.22,870.09 
	642.22,870.42 638.34,870.42 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="642.55" y1="853.91" x2="642.55" y2="782.4"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="636.72,790.49 636.72,790.16 636.72,789.52 636.72,788.87 636.4,788.54 636.4,787.9 636.72,787.57 
	636.72,787.25 637.37,787.25 637.69,787.57 637.37,787.9 637.37,788.22 637.37,788.87 637.37,789.19 638.99,790.16 639.63,789.84 
	640.28,789.84 640.6,789.19 640.6,787.9 640.6,786.93 640.6,786.28 640.28,785.63 640.28,785.31 640.28,784.98 639.96,785.31 
	639.63,784.66 639.63,782.72 639.63,782.4 639.96,782.07 640.28,782.72 640.93,782.07 641.25,781.75 641.25,781.42 640.93,781.1 
	640.93,780.78 640.93,780.45 641.57,780.45 642.22,780.45 642.55,780.78 642.55,781.1 642.55,781.75 642.55,782.07 642.87,782.72 
	643.19,782.72 643.19,782.4 643.52,782.4 643.52,783.37 643.52,784.34 643.52,784.98 643.19,784.66 643.52,785.31 643.84,785.63 
	643.84,786.28 643.84,786.93 643.84,787.57 643.84,788.54 643.84,789.19 644.16,791.13 644.16,791.46 644.16,792.1 644.16,792.75 
	643.84,793.07 643.52,793.07 643.52,792.75 643.84,792.43 643.52,792.43 642.87,792.75 642.55,793.4 642.87,794.37 643.52,795.02 
	643.84,795.34 643.84,795.66 643.84,796.63 643.84,797.28 643.84,797.61 644.49,797.61 644.49,798.58 642.55,798.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="642.55,790.81 636.72,790.49 636.72,860.71 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="607.92,868.15 608.24,867.51 608.57,866.53 608.89,866.21 609.21,865.89 608.89,865.24 608.57,864.59 
	607.92,864.59 607.92,863.62 607.92,862.65 607.92,862 607.6,861.68 607.6,861.03 607.92,860.71 608.57,860.71 608.89,860.71 
	608.57,861.36 608.57,861.68 608.57,862 608.57,862.33 610.18,863.3 610.83,863.3 611.48,862.98 611.8,862.33 611.8,861.03 
	611.8,860.06 611.8,859.42 611.48,858.77 611.48,858.44 611.48,858.12 611.16,858.77 611.16,858.44 610.83,857.8 610.83,856.18 
	610.83,855.53 611.16,855.53 611.48,855.86 612.13,855.53 612.45,854.88 612.45,854.56 612.13,854.24 612.13,853.91 612.13,853.59 
	612.77,853.59 613.42,853.59 613.74,853.91 613.74,854.24 613.74,854.88 613.74,855.21 614.07,855.86 614.39,855.86 614.39,855.53 
	614.72,855.53 614.72,856.5 614.72,857.47 614.72,858.44 614.39,858.12 614.72,858.77 615.04,858.77 615.04,859.74 615.04,860.06 
	615.04,860.71 615.04,862 615.04,862.65 615.36,864.27 615.36,864.59 615.36,865.24 615.36,865.89 615.04,866.53 614.72,866.53 
	614.72,865.89 615.04,865.56 614.72,865.56 614.07,865.89 614.07,866.21 613.74,866.86 614.07,867.51 614.72,868.15 615.04,868.48 
	615.04,869.12 615.04,869.77 615.04,870.42 615.04,870.74 615.69,871.07 615.69,872.04 606.62,871.71 606.95,870.74 607.6,870.42 
	607.6,870.09 607.92,869.45 607.92,869.12 607.92,868.48 607.92,868.15 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="609.54,870.42 609.54,869.77 609.21,869.45 609.54,869.12 609.86,868.48 610.18,868.15 610.83,867.83 
	611.8,867.18 612.45,867.83 613.1,868.48 613.42,869.12 613.74,869.77 613.74,870.09 613.42,870.09 613.1,870.09 613.1,870.42 
	613.42,870.42 609.54,870.42 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="613.42" y1="853.91" x2="613.42" y2="782.07"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="607.92,790.49 607.92,790.16 607.92,789.52 607.92,788.54 607.6,788.54 607.27,787.57 607.6,787.25 
	607.92,787.25 608.24,787.25 608.89,787.57 608.57,787.9 608.24,788.22 608.57,788.54 608.57,789.19 610.18,789.84 610.83,789.84 
	611.16,789.84 611.8,788.87 611.8,787.9 611.8,786.93 611.48,786.28 611.48,785.63 611.48,784.98 611.16,785.31 610.83,785.31 
	610.51,784.66 610.83,782.72 610.51,782.4 611.16,782.07 611.48,782.72 611.8,782.07 612.45,781.75 612.13,781.42 612.13,781.1 
	612.13,780.78 612.13,780.45 612.45,780.45 613.1,780.45 613.74,780.78 613.74,781.1 613.42,781.42 613.42,781.75 614.07,782.72 
	614.39,782.4 614.39,782.07 614.72,782.4 614.72,783.37 614.72,784.34 614.39,784.98 614.39,784.66 614.07,784.66 614.39,785.31 
	614.72,785.63 614.72,786.28 614.72,786.93 615.04,787.57 614.72,788.54 615.04,789.19 615.36,791.13 615.36,791.46 615.36,792.1 
	615.04,792.43 615.04,793.07 614.72,793.07 614.72,792.75 614.72,792.1 614.07,792.43 613.74,792.75 613.74,793.4 614.07,794.37 
	614.39,795.02 614.72,795.34 615.04,795.66 615.04,796.63 615.04,796.96 615.04,797.61 615.36,797.61 615.36,798.58 613.42,798.58 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="613.42,790.81 607.92,790.49 607.92,860.71 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="549.02,869.45 549.35,868.48 549.67,867.83 549.99,867.51 550.32,867.18 549.99,866.21 549.67,865.89 
	549.35,865.56 549.02,864.59 549.02,863.95 549.02,862.98 548.7,862.98 548.7,862.33 549.02,861.68 549.35,861.68 549.67,861.68 
	549.99,862 549.99,862.33 549.67,862.65 549.67,862.98 549.99,863.62 551.29,864.27 552.26,864.27 552.58,864.27 552.91,863.3 
	553.23,862.33 552.91,861.36 552.91,860.71 552.58,860.06 552.58,859.42 552.26,859.74 551.93,859.09 551.93,857.15 551.93,856.83 
	552.58,856.5 552.58,857.15 552.91,857.15 553.23,856.5 553.88,856.18 553.55,855.86 553.23,855.53 553.23,855.21 553.55,854.88 
	553.88,854.88 554.52,854.88 555.17,855.21 555.17,855.53 554.85,855.86 554.85,856.18 554.85,856.5 555.17,857.15 555.49,857.15 
	555.49,856.83 555.82,856.83 555.82,857.8 556.14,858.77 555.82,859.42 555.82,859.09 555.49,859.09 555.82,859.74 556.14,860.06 
	556.14,860.71 556.14,861.36 556.14,862 556.14,862.98 556.46,863.62 556.79,865.56 556.79,865.89 556.46,866.53 556.46,867.18 
	556.14,867.51 555.82,867.51 555.82,867.18 556.14,866.86 555.82,866.86 555.49,867.18 555.17,867.18 554.85,867.83 555.17,868.8 
	555.82,869.45 556.14,869.77 556.14,870.09 556.14,871.07 556.46,871.71 556.46,872.04 556.79,872.04 556.79,873.01 548.05,873.01 
	548.05,871.71 548.7,871.71 549.02,871.39 549.02,870.74 549.02,870.42 549.02,869.77 549.35,869.45 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="550.64,871.39 550.64,871.07 550.64,870.74 550.32,870.74 550.64,870.09 550.96,869.45 551.29,869.12 
	551.93,868.8 552.91,868.48 553.55,868.8 554.2,869.45 554.52,870.42 554.85,871.07 554.85,871.39 554.52,871.39 554.52,871.07 
	554.52,871.39 554.52,871.71 550.64,871.39 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="553.55" y1="854.88" x2="553.88" y2="783.04"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="549.02,791.46 549.02,791.13 549.02,790.49 549.02,789.84 548.7,789.52 548.7,788.87 548.7,788.54 
	549.02,788.22 549.67,788.22 549.99,788.54 549.67,789.19 549.35,789.52 549.67,789.84 549.67,790.16 551.29,791.13 551.93,791.13 
	552.26,790.81 552.91,790.16 552.91,788.87 552.91,787.9 552.58,787.25 552.58,786.6 552.58,786.28 552.58,785.96 552.26,786.28 
	551.93,786.28 551.93,785.63 551.93,784.01 551.93,783.37 552.26,783.04 552.58,783.69 552.91,783.04 553.55,782.72 553.55,782.4 
	553.23,782.07 553.23,781.75 553.23,781.42 553.55,781.42 554.2,781.42 554.85,781.75 554.85,782.07 554.85,782.72 554.52,782.72 
	554.85,783.04 555.17,783.69 555.49,783.37 555.82,783.37 555.82,784.34 555.82,785.31 555.82,786.28 555.49,785.96 555.82,786.6 
	556.14,787.57 556.14,787.9 556.14,788.54 556.14,789.52 556.14,790.49 556.46,792.1 556.46,792.43 556.46,793.07 556.14,793.72 
	556.14,794.37 555.82,794.37 555.82,793.72 556.14,793.4 555.82,793.4 555.17,793.72 554.85,794.05 554.85,794.69 555.17,795.34 
	555.49,795.99 555.82,796.31 556.14,796.96 556.14,797.61 556.14,798.25 556.14,798.58 556.46,798.9 556.46,799.87 554.52,799.87 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="553.88,791.78 549.02,791.46 549.02,861.68 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="488.83,870.09 489.15,869.12 489.48,868.48 489.8,868.15 490.12,867.83 489.8,866.86 489.15,866.53 
	488.83,866.21 488.83,865.24 488.83,864.59 488.83,863.95 488.51,863.62 488.51,862.98 488.51,862.65 488.83,862.33 489.48,862.33 
	489.8,862.65 489.48,862.98 489.48,863.3 489.48,863.95 489.48,864.27 491.1,865.24 491.74,864.92 492.07,864.92 492.71,864.27 
	492.71,862.98 492.71,862 492.39,861.36 492.39,860.71 492.39,860.39 492.39,860.06 492.07,860.39 491.74,860.39 491.74,859.74 
	491.74,857.8 491.74,857.47 492.07,857.15 492.39,857.8 492.71,857.15 493.36,856.83 493.36,856.5 493.04,856.18 493.04,855.86 
	493.04,855.53 493.36,855.53 494.01,855.53 494.65,855.86 494.65,856.18 494.65,856.83 494.33,856.83 494.65,857.15 494.98,857.8 
	495.3,857.47 495.63,857.47 495.63,858.44 495.63,859.42 495.63,860.06 495.3,859.74 495.63,860.39 495.63,860.71 495.95,861.36 
	495.95,862 495.95,862.65 495.95,863.62 495.95,864.27 496.27,866.21 496.27,866.53 496.27,867.18 496.27,867.83 495.95,868.15 
	495.63,868.15 495.63,867.83 495.95,867.51 495.63,867.51 494.98,867.83 494.65,867.83 494.65,868.48 494.98,869.45 495.3,870.09 
	495.63,870.42 495.95,871.07 495.95,871.71 495.95,872.36 495.95,872.68 496.27,872.68 496.27,873.65 487.54,873.65 487.86,872.36 
	488.18,872.36 488.51,872.04 488.83,871.39 488.83,871.07 488.83,870.42 488.83,870.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="490.45,872.04 490.45,871.71 490.12,871.71 490.12,871.39 490.12,870.74 490.77,870.09 491.1,869.77 
	491.74,869.45 492.39,869.12 493.36,869.77 494.01,870.42 494.33,871.07 494.65,871.71 494.65,872.04 494.33,872.04 494.01,871.71 
	494.01,872.04 494.01,872.36 490.45,872.04 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="494.33" y1="855.86" x2="494.33" y2="784.01"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="488.51,792.1 488.51,791.13 488.51,790.49 488.18,790.16 488.18,789.52 488.51,789.19 488.83,789.19 
	489.15,789.19 489.48,789.52 489.48,789.84 489.15,790.16 489.15,790.49 489.48,790.81 490.77,791.78 491.74,791.78 492.07,791.46 
	492.39,790.81 492.71,789.52 492.71,788.54 492.39,787.9 492.39,787.25 492.39,786.93 492.07,786.6 491.74,787.25 491.42,786.6 
	491.42,784.66 491.42,784.01 492.07,784.01 492.07,784.34 492.39,784.66 492.71,784.01 493.36,783.37 493.04,783.37 493.04,783.04 
	492.71,782.4 493.04,782.07 493.36,782.07 494.01,782.07 494.65,782.4 494.65,783.04 494.33,783.37 494.33,783.69 494.65,784.34 
	494.98,784.34 495.3,784.01 495.63,784.98 495.63,785.96 495.3,786.93 495.3,786.6 494.98,786.6 495.3,787.25 495.63,787.57 
	495.63,788.22 495.63,788.54 495.63,789.52 495.63,790.49 495.95,791.13 496.27,793.07 495.95,793.72 495.95,794.37 495.95,795.02 
	495.63,795.02 495.63,794.37 495.63,794.05 495.3,794.05 494.98,794.37 494.65,794.69 494.33,795.34 494.98,795.99 495.3,796.63 
	495.63,797.28 495.63,797.61 495.63,798.25 495.95,798.9 495.95,799.55 496.27,799.55 496.27,800.52 494.33,800.52 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="493.36" y1="784.01" x2="494.33" y2="784.01"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="494.33,792.43 488.51,792.1 488.83,862.33 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="692.71,976.56 693.03,975.92 693.35,974.95 693.68,974.62 694,974.3 693.68,973.65 693.35,973 
	693.03,973 692.71,971.71 692.71,971.06 692.71,970.41 692.38,970.09 692.38,969.44 692.71,969.12 693.03,968.8 693.35,968.8 
	693.68,969.12 693.68,969.77 693.35,969.77 693.35,970.41 693.68,970.74 694.97,971.71 695.62,971.71 696.27,971.39 696.59,970.74 
	696.91,969.44 696.59,968.47 696.59,967.83 696.27,967.18 696.27,966.85 696.27,966.53 695.94,966.85 695.62,966.21 695.62,964.59 
	695.62,963.94 696.27,963.62 696.27,964.27 696.91,963.62 697.56,963.3 697.24,962.97 696.91,962.65 696.91,962.32 697.24,962 
	697.56,962 698.21,962 698.53,962.32 698.53,962.65 698.53,963.3 698.53,963.62 698.85,964.27 699.18,964.27 699.18,963.94 
	699.5,963.94 699.5,964.91 699.83,965.88 699.5,966.53 699.18,966.21 699.5,967.18 699.83,967.18 699.83,967.83 699.83,968.47 
	699.83,969.12 699.83,970.09 700.15,971.06 700.47,972.68 700.47,973 700.15,973.65 700.15,974.3 699.83,974.62 699.5,974.62 
	699.5,974.3 699.83,973.97 699.5,973.97 698.85,974.3 698.53,975.27 698.85,975.92 699.5,976.56 699.83,976.89 699.83,977.53 
	699.83,978.18 699.83,978.83 699.83,979.15 700.47,979.15 700.47,980.45 691.73,980.12 691.73,978.83 692.38,978.83 692.71,978.51 
	692.71,977.86 692.71,977.53 692.71,976.89 693.03,976.56 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="694.32,978.83 694.32,978.18 694,977.86 694.32,977.53 694.65,976.56 694.97,976.56 695.62,976.24 
	696.59,975.59 697.24,976.24 697.88,976.89 698.21,977.53 698.53,978.18 698.53,978.51 698.21,978.51 698.21,978.83 694.32,978.83 
	"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="697.24" y1="962" x2="697.56" y2="890.48"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="692.71,898.9 692.71,898.57 692.71,897.93 692.71,896.95 692.38,896.95 692.06,895.98 692.38,895.66 
	692.71,895.66 693.35,895.66 693.68,895.98 693.35,896.31 693.03,896.63 693.35,896.95 693.35,897.6 694.97,898.25 695.62,898.25 
	695.94,898.25 696.59,897.28 696.59,896.31 696.59,895.34 696.27,894.69 696.27,894.04 696.27,893.39 696.27,893.07 695.94,893.72 
	695.62,893.72 695.62,893.07 695.62,891.13 695.62,890.81 695.94,890.48 696.27,890.81 696.27,891.13 696.59,890.48 697.24,889.84 
	696.91,889.51 696.91,889.19 696.91,888.86 697.24,888.86 697.88,888.86 698.53,889.19 698.53,889.51 698.53,889.84 698.21,890.16 
	698.53,890.16 698.85,891.13 699.18,890.81 699.18,890.48 699.5,890.48 699.5,891.45 699.5,892.75 699.5,893.39 699.18,893.07 
	698.85,893.07 699.5,893.72 699.5,894.04 699.83,894.69 699.83,895.01 699.83,895.98 699.83,896.95 699.83,897.6 700.15,899.54 
	700.15,899.87 700.15,900.19 699.83,900.84 699.83,901.49 699.5,901.49 699.5,901.16 699.5,900.51 698.85,900.84 698.53,901.16 
	698.53,901.81 698.85,902.78 699.18,903.43 699.5,903.75 699.83,904.07 699.83,904.72 699.83,905.37 699.83,906.02 700.15,906.02 
	700.15,906.99 698.21,906.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="697.56,898.9 692.71,898.9 692.71,968.8 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="666.49,976.56 666.49,975.59 667.14,974.95 667.14,974.62 667.46,974.3 667.46,973.33 666.82,973 
	666.49,972.68 666.49,971.71 666.49,971.06 666.49,970.41 666.17,970.09 665.85,969.44 666.17,969.12 666.49,968.8 666.82,968.8 
	667.46,969.12 667.14,969.44 666.82,969.77 667.14,970.41 667.14,970.74 668.76,971.71 669.41,971.39 669.73,971.39 670.38,970.74 
	670.38,969.44 670.38,968.47 670.05,967.83 670.05,967.18 670.05,966.53 669.73,966.85 669.41,966.85 669.08,966.21 669.41,964.27 
	669.08,963.94 669.73,963.62 669.73,964.27 670.05,964.27 670.38,963.62 671.02,963.3 670.7,962.97 670.7,962.65 670.7,962.32 
	670.7,962 671.02,962 671.67,962 672.32,962.32 672.32,962.65 671.99,962.97 671.99,963.3 671.99,963.62 672.64,964.27 
	672.97,963.94 673.29,963.94 673.29,964.91 673.29,965.88 672.97,966.53 672.97,966.21 672.64,966.21 672.97,966.85 673.29,967.18 
	673.29,967.83 673.29,968.47 673.29,969.12 673.29,970.09 673.61,970.74 673.94,972.68 673.94,973 673.94,973.65 673.61,974.3 
	673.61,974.62 673.29,974.62 673.29,974.3 673.29,973.97 672.64,974.3 672.32,974.3 672.32,974.95 672.64,975.92 672.97,976.56 
	673.29,976.89 673.29,977.21 673.61,978.18 673.61,978.83 673.61,979.15 673.94,979.15 673.94,980.12 665.2,980.12 665.2,978.83 
	665.85,978.83 666.17,978.51 666.17,977.86 666.17,977.53 666.49,976.89 666.49,976.56 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="667.79,978.51 668.11,978.18 667.79,978.18 667.46,977.86 667.79,977.21 668.43,976.56 668.76,976.24 
	669.08,975.92 670.05,975.59 670.7,975.92 671.35,976.89 671.99,977.53 671.99,978.18 671.99,978.51 671.67,978.18 671.67,978.51 
	671.67,978.83 667.79,978.51 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="670.7" y1="962" x2="671.02" y2="890.16"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="666.17,898.57 666.17,897.6 666.17,896.95 665.85,896.63 665.85,895.98 666.17,895.66 666.82,895.66 
	667.14,895.98 666.82,896.31 666.82,896.63 666.82,896.95 666.82,897.28 668.43,898.25 669.08,898.25 669.41,897.93 670.05,897.28 
	670.05,895.98 670.05,895.01 669.73,894.37 669.73,893.72 669.73,893.39 669.73,893.07 669.41,893.72 669.08,892.75 669.08,891.13 
	669.08,890.48 669.41,890.48 669.73,890.81 670.38,890.48 670.7,889.84 670.38,889.19 670.38,888.86 670.38,888.54 670.7,888.54 
	671.67,888.54 671.99,888.86 671.99,889.19 671.99,889.84 671.99,890.16 672.32,890.81 672.64,890.81 672.64,890.48 672.97,890.48 
	672.97,891.45 672.97,892.42 672.97,893.39 672.64,893.07 672.97,893.72 673.29,894.04 673.29,894.69 673.29,895.01 673.29,895.98 
	673.29,896.95 673.29,897.6 673.61,899.22 673.61,899.54 673.61,900.19 673.61,900.84 673.29,901.49 672.97,901.49 672.97,900.84 
	673.29,900.51 672.97,900.51 672.32,900.84 672.32,901.16 671.99,901.81 672.32,902.46 672.97,903.1 673.29,903.75 673.29,904.07 
	673.29,904.72 673.29,905.37 673.29,905.69 673.94,906.02 673.94,906.99 671.99,906.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="671.02,898.9 666.17,898.57 666.17,968.8 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="637.37,977.86 637.69,976.89 638.02,976.24 638.34,975.92 638.66,975.59 638.34,974.62 638.02,974.3 
	637.37,973.97 637.37,973 637.37,972.36 637.37,971.71 637.04,971.39 637.04,970.74 637.37,970.41 637.37,970.09 638.02,970.09 
	638.34,970.41 638.02,970.74 638.02,971.06 638.02,971.71 638.02,972.03 639.63,973 640.28,973 640.6,972.68 641.25,972.03 
	641.25,970.74 641.25,969.77 640.93,969.12 640.93,968.47 640.93,968.15 640.93,967.83 640.6,968.15 640.28,968.15 640.28,967.5 
	640.28,965.88 640.28,965.24 640.6,964.91 640.93,965.56 641.57,964.91 641.9,964.59 641.9,964.27 641.57,963.94 641.57,963.62 
	641.57,963.3 641.9,963.3 642.87,963.3 643.19,963.62 643.19,963.94 643.19,964.59 643.19,964.91 643.52,965.56 643.84,965.56 
	643.84,965.24 644.16,965.24 644.16,966.21 644.16,967.18 644.16,967.83 643.84,967.5 644.16,968.15 644.49,968.47 644.49,969.12 
	644.49,969.77 644.49,970.41 644.49,971.39 644.49,972.36 644.81,973.97 644.81,974.3 644.81,974.95 644.81,975.59 644.49,975.92 
	644.16,975.92 644.16,975.59 644.49,975.27 644.16,975.27 643.52,975.59 643.19,976.24 643.52,977.21 644.16,977.86 644.49,978.18 
	644.49,978.83 644.49,979.48 644.49,980.12 644.49,980.45 645.13,980.45 645.13,981.42 636.07,981.42 636.4,980.12 637.04,980.12 
	637.04,979.8 637.37,979.15 637.37,978.83 637.37,978.18 637.37,977.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="638.99,979.8 638.99,979.48 638.66,979.48 638.66,979.15 638.99,978.51 639.31,977.86 639.63,977.86 
	640.28,977.21 641.25,976.89 641.9,977.53 642.55,978.18 642.87,978.83 643.19,979.48 643.19,979.8 642.87,979.8 642.55,979.8 
	642.87,980.12 638.99,979.8 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="641.9" y1="963.3" x2="642.22" y2="891.78"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="637.37,899.87 637.37,899.22 637.37,898.25 637.04,898.25 636.72,897.28 637.04,896.95 637.37,896.95 
	637.69,896.95 638.34,897.28 638.02,897.6 637.69,897.93 638.02,898.25 638.02,898.57 639.63,899.54 640.28,899.54 640.6,899.22 
	641.25,898.57 641.25,897.28 641.25,896.31 640.93,895.98 640.93,895.01 640.93,894.69 640.93,894.37 640.6,895.01 640.28,895.01 
	639.96,894.37 640.28,892.42 639.96,892.1 640.6,891.78 640.6,892.1 640.93,892.42 641.25,891.78 641.9,891.13 641.57,891.13 
	641.57,890.81 641.57,890.16 641.9,889.84 642.55,890.16 643.19,890.48 643.19,890.81 642.87,891.13 642.87,891.45 643.52,892.1 
	643.84,891.78 644.16,891.78 644.16,892.75 644.16,893.72 643.84,894.69 643.84,894.37 643.52,894.37 643.84,895.01 644.16,895.34 
	644.16,895.98 644.16,896.31 644.16,897.28 644.16,898.25 644.49,898.9 644.81,900.84 644.81,901.16 644.81,901.49 644.49,902.13 
	644.49,902.78 644.16,902.78 644.16,902.13 644.16,901.81 643.52,902.13 643.19,902.46 643.19,903.1 643.52,903.75 643.84,904.72 
	644.16,905.04 644.16,905.37 644.49,906.02 644.49,906.66 644.49,907.31 644.81,907.31 644.81,908.28 642.87,908.28 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="641.9,900.19 637.37,899.87 637.37,970.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="608.89,978.51 608.89,977.53 609.54,976.89 609.86,976.56 610.18,976.24 609.86,975.27 609.21,974.95 
	608.89,974.62 608.89,973.65 608.89,973 608.89,972.36 608.57,972.03 608.24,971.39 608.57,971.06 608.89,970.74 609.54,970.74 
	609.86,971.06 609.54,971.39 609.21,971.71 609.54,972.36 609.54,972.68 611.16,973.65 611.8,973.33 612.13,973.33 612.77,972.68 
	612.77,971.39 612.77,970.41 612.45,969.77 612.45,969.12 612.45,968.8 612.45,968.47 612.13,968.8 611.8,968.8 611.8,968.15 
	611.8,966.21 611.8,965.88 612.13,965.56 612.45,966.21 612.77,965.56 613.42,965.24 613.1,964.91 613.1,964.59 613.1,964.27 
	613.1,963.94 613.42,963.94 614.07,963.94 614.72,964.27 614.72,964.59 614.72,965.24 614.39,965.24 614.72,965.56 615.04,966.21 
	615.36,965.88 615.69,965.88 615.69,966.85 615.69,967.83 615.69,968.47 615.36,968.15 615.04,968.15 615.69,968.8 615.69,969.12 
	615.69,969.77 615.69,970.41 616.01,971.06 616.01,972.03 616.01,972.68 616.33,974.62 616.33,974.95 616.33,975.59 616.01,976.24 
	616.01,976.56 615.69,976.56 615.69,976.24 615.69,975.92 615.04,976.24 614.72,976.24 614.72,976.89 615.04,977.86 615.36,978.51 
	615.69,978.83 616.01,979.48 616.01,980.12 616.01,980.77 616.01,981.09 616.33,981.09 616.33,982.06 607.6,982.06 607.92,980.77 
	608.24,980.77 608.57,980.45 608.57,979.8 608.57,979.48 608.89,978.83 608.89,978.51 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="610.51,980.45 610.51,980.12 610.18,980.12 610.18,979.8 610.18,979.15 610.83,978.51 611.16,978.18 
	611.48,977.86 612.45,977.53 613.42,978.18 614.07,978.83 614.39,979.48 614.39,980.12 614.39,980.45 614.07,980.12 614.07,980.45 
	614.07,980.77 610.51,980.45 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="613.42" y1="963.94" x2="613.42" y2="892.42"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="608.57,900.51 608.57,899.54 608.57,898.9 608.24,898.57 608.24,897.93 608.57,897.6 608.89,897.6 
	609.21,897.6 609.54,897.93 609.54,898.25 609.21,898.57 609.21,898.9 609.54,899.22 610.83,900.19 611.48,900.19 612.13,899.87 
	612.45,899.22 612.77,897.93 612.45,896.95 612.45,896.31 612.13,895.66 612.13,895.34 612.13,895.01 611.8,895.66 611.48,895.01 
	611.48,893.07 611.48,892.42 612.13,892.42 612.13,892.75 612.13,893.07 612.77,892.42 613.42,891.78 613.1,891.78 612.77,891.45 
	612.77,890.81 613.1,890.48 613.42,890.48 614.07,890.48 614.39,890.81 614.39,891.45 614.39,891.78 614.39,892.1 614.72,892.75 
	615.04,892.75 615.04,892.42 615.36,892.42 615.36,893.39 615.69,894.37 615.36,895.34 615.36,895.01 615.04,895.01 615.36,895.66 
	615.69,895.98 615.69,896.63 615.69,896.95 615.69,897.93 615.69,898.9 616.01,899.54 616.33,901.49 616.01,902.13 616.01,902.78 
	615.69,903.43 615.36,903.43 615.36,902.78 615.69,902.46 615.36,902.46 614.72,902.78 614.72,903.1 614.39,903.75 614.72,904.4 
	615.36,905.04 615.69,905.69 615.69,906.02 615.69,906.66 615.69,907.31 615.69,907.96 616.33,907.96 616.33,908.93 614.39,908.93 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="613.42,900.84 608.57,900.51 608.57,970.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="549.02,975.92 549.35,975.27 549.67,974.62 549.99,973.97 550.32,973.65 549.99,973 549.67,972.68 
	549.35,972.36 549.02,971.39 549.02,970.74 549.02,969.77 548.7,969.77 548.7,968.8 549.02,968.47 549.67,968.47 549.99,968.8 
	549.99,969.12 549.67,969.44 549.67,969.77 549.99,970.41 551.29,971.06 551.93,971.06 552.58,971.06 552.91,970.09 553.23,969.12 
	552.91,968.15 552.91,967.5 552.58,966.85 552.58,966.21 552.58,965.88 552.26,966.53 551.93,965.88 551.93,963.94 551.93,963.62 
	552.26,963.3 552.58,963.62 552.58,963.94 553.23,963.3 553.88,962.65 553.55,962.65 553.23,962.32 553.23,962 553.55,961.68 
	553.88,961.68 554.52,961.68 554.85,962 554.85,962.32 554.85,962.65 554.85,962.97 555.17,963.94 555.49,963.94 555.49,963.62 
	555.82,963.3 555.82,964.27 556.14,965.56 555.82,966.21 555.82,965.88 555.49,965.88 555.82,966.53 556.14,966.85 556.14,967.5 
	556.14,967.83 556.14,968.8 556.14,969.77 556.46,970.41 556.46,972.36 556.46,972.68 556.46,973 556.46,973.65 556.14,974.3 
	555.82,974.3 555.82,973.97 556.14,973.33 555.82,973.33 555.17,973.65 555.17,973.97 554.85,974.62 555.17,975.59 555.82,976.24 
	556.14,976.56 556.14,976.89 556.14,977.53 556.14,978.18 556.14,978.83 556.79,978.83 556.79,979.8 547.73,979.8 548.05,978.51 
	548.7,978.51 549.02,978.18 549.02,977.53 549.02,976.89 549.02,976.56 549.35,975.92 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="550.64,978.18 550.64,977.53 550.32,977.53 550.64,976.89 550.96,976.24 551.29,975.92 551.93,975.59 
	552.91,974.95 553.55,975.59 554.2,976.24 554.52,977.21 554.85,977.53 554.85,978.18 554.52,978.18 554.52,977.86 554.52,978.18 
	550.64,978.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="553.55" y1="961.68" x2="553.88" y2="889.84"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="549.02,898.25 549.02,897.93 549.02,897.28 549.02,896.63 548.7,896.31 548.37,895.66 548.7,895.34 
	549.02,895.01 549.67,895.01 549.99,895.34 549.67,895.66 549.35,895.98 549.67,896.63 549.67,896.95 551.29,897.93 551.93,897.93 
	552.26,897.6 552.91,896.95 552.91,895.66 552.91,894.69 552.58,894.04 552.58,893.39 552.58,893.07 552.58,892.75 552.26,893.07 
	551.93,893.07 551.93,892.42 551.93,890.81 551.93,890.16 552.26,889.84 552.58,890.48 552.91,889.84 553.55,889.51 553.23,889.19 
	553.23,888.86 553.23,888.54 553.23,888.22 553.55,888.22 554.2,888.22 554.85,888.54 554.85,888.86 554.85,889.51 554.52,889.51 
	554.85,889.84 555.17,890.48 555.49,890.16 555.82,890.16 555.82,891.13 555.82,892.1 555.82,892.75 555.49,892.42 555.17,892.42 
	555.82,893.07 555.82,893.39 555.82,894.04 555.82,894.69 556.14,895.34 556.14,896.31 556.14,897.28 556.46,898.9 556.46,899.22 
	556.46,899.87 556.14,900.51 556.14,900.84 555.82,900.84 555.82,900.51 555.82,900.19 555.17,900.51 554.85,900.51 554.85,901.16 
	555.17,902.13 555.49,902.78 555.82,903.1 556.14,903.75 556.14,904.4 556.14,905.04 556.14,905.37 556.46,905.37 556.46,906.34 
	554.52,906.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="553.88,898.57 549.02,898.25 549.02,968.47 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="490.45,976.89 490.77,976.24 491.1,975.59 491.42,974.95 491.74,974.62 491.42,973.97 491.1,973.33 
	490.45,973.33 490.45,972.36 490.45,971.39 490.45,970.74 490.12,970.41 490.12,969.77 490.45,969.44 491.1,969.44 491.42,969.77 
	491.1,970.09 491.1,970.41 491.1,970.74 491.1,971.06 492.71,972.03 493.36,972.03 493.68,971.71 494.33,971.06 494.33,969.77 
	494.33,968.8 494.01,968.15 494.01,967.5 494.01,967.18 494.01,966.85 493.68,967.5 493.36,967.5 493.36,966.53 493.36,964.91 
	493.36,964.27 493.68,964.27 494.01,964.59 494.33,964.27 494.98,963.62 494.65,962.97 494.65,962.65 494.65,962.32 494.98,962.32 
	495.95,962.32 496.27,962.65 496.27,962.97 496.27,963.62 495.95,963.62 496.27,963.94 496.6,964.59 496.92,964.27 497.24,964.27 
	497.24,965.24 497.24,966.21 497.24,967.18 496.92,966.85 497.24,967.5 497.24,967.83 497.57,968.47 497.57,968.8 497.57,969.77 
	497.57,970.74 497.57,971.39 497.89,973 497.89,973.33 497.89,973.97 497.89,974.62 497.57,975.27 497.24,975.27 497.24,974.62 
	497.57,974.3 497.24,974.3 496.6,974.62 496.27,974.95 496.27,975.59 496.6,976.24 497.24,976.89 497.57,977.53 497.57,977.86 
	497.57,978.51 497.57,979.15 497.57,979.48 498.21,979.8 498.21,980.77 489.15,980.45 489.48,979.48 490.12,979.15 490.12,978.83 
	490.45,978.51 490.45,977.86 490.45,977.21 490.45,976.89 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="492.07,979.15 492.07,978.51 491.74,978.51 491.74,978.18 492.07,977.86 492.39,977.21 492.71,976.89 
	493.36,976.56 494.33,975.92 494.98,976.56 495.63,977.21 495.95,977.86 496.27,978.51 496.27,978.83 495.95,978.83 495.63,978.83 
	495.63,979.15 495.95,979.15 492.07,979.15 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="494.98" y1="962.32" x2="495.3" y2="890.81"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="490.45,899.22 490.12,898.9 490.45,898.25 490.45,897.28 489.8,897.28 489.8,896.63 490.12,895.98 
	490.45,895.98 490.77,895.98 491.1,896.31 491.1,896.63 490.77,896.95 490.77,897.28 491.1,897.93 492.39,898.57 493.36,898.57 
	493.68,898.57 494.01,897.6 494.33,896.63 494.33,895.66 494.01,895.01 494.01,894.37 494.01,893.72 493.68,894.04 493.36,894.04 
	493.04,893.39 493.36,891.45 493.04,891.13 493.68,890.81 493.68,891.45 494.01,891.45 494.33,890.81 494.98,890.48 494.65,890.16 
	494.65,889.84 494.33,889.51 494.65,889.19 494.98,889.19 495.63,889.19 496.27,889.51 496.27,889.84 495.95,890.16 495.95,890.48 
	495.95,890.81 496.27,891.45 496.6,891.45 496.92,891.13 497.24,892.1 497.24,893.07 496.92,893.72 496.92,893.39 496.6,893.39 
	496.92,894.04 497.24,894.37 497.24,895.01 497.24,895.66 497.24,896.31 497.24,897.28 497.57,897.93 497.89,899.87 497.89,900.19 
	497.89,900.84 497.57,901.49 497.57,901.81 497.24,901.81 497.24,901.49 497.24,901.16 496.92,901.16 496.6,901.16 496.27,901.49 
	496.27,902.13 496.6,903.1 496.92,903.75 497.24,904.07 497.24,904.4 497.57,905.37 497.57,906.02 497.57,906.34 497.89,906.34 
	497.89,907.31 495.95,907.31 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="494.98,899.22 490.45,899.22 490.45,969.44 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="579.76,867.83 579.76,866.86 580.41,866.21 580.74,865.89 580.74,865.24 580.74,864.59 580.09,864.27 
	579.76,863.95 579.76,862.98 579.76,862.33 579.76,861.36 579.44,861.36 579.12,860.71 579.44,860.06 579.76,860.06 580.09,860.06 
	580.74,860.39 580.41,860.71 580.09,861.03 580.41,861.36 580.41,862 582.03,862.65 582.68,862.65 583,862.65 583.65,861.68 
	583.65,860.71 583.65,859.74 583.32,859.09 583.32,858.44 583.32,857.8 583,858.12 582.68,858.12 582.35,857.47 582.68,855.53 
	582.35,855.21 583,854.88 583,855.53 583.32,855.53 583.65,854.88 584.3,854.56 583.97,854.24 583.97,853.91 583.97,853.59 
	583.97,853.27 584.3,853.27 584.94,853.27 585.59,853.59 585.59,853.91 585.27,854.24 585.27,854.56 585.27,854.88 585.91,855.53 
	586.24,855.21 586.56,855.21 586.56,856.18 586.56,857.15 586.24,857.8 586.24,857.47 585.91,857.47 586.24,858.12 586.56,858.44 
	586.56,859.09 586.56,859.74 586.88,860.39 586.56,861.36 586.88,862 587.21,863.95 587.21,864.27 587.21,864.92 586.88,865.56 
	586.88,865.89 586.56,865.89 586.56,865.56 586.56,865.24 585.91,865.56 585.59,865.56 585.59,866.21 585.91,867.18 586.24,867.83 
	586.56,868.15 586.88,868.48 586.88,869.45 586.88,870.09 586.88,870.42 587.21,870.42 587.21,871.39 578.47,871.39 578.47,870.09 
	579.12,870.09 579.44,869.77 579.44,869.12 579.44,868.8 579.76,868.15 579.76,867.83 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="579.76" y1="860.06" x2="579.76" y2="797.93"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="585.27" y1="853.27" x2="585.27" y2="798.58"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="519.25,868.48 519.57,867.83 519.9,867.18 520.22,866.53 520.54,866.21 520.22,865.56 519.9,864.92 
	519.25,864.92 519.25,863.95 519.25,863.3 519.25,862.33 518.93,862.33 518.93,861.36 519.25,861.03 519.9,861.03 520.22,861.36 
	519.9,861.68 519.9,862 519.9,862.33 519.9,862.65 521.51,863.62 522.16,863.62 522.49,863.3 523.13,862.65 523.13,861.68 
	523.13,860.39 522.81,860.06 522.81,859.09 522.81,858.77 522.81,858.44 522.49,859.09 522.16,859.09 522.16,858.44 522.16,856.5 
	522.16,856.18 522.49,855.86 522.81,856.18 522.81,856.5 523.13,855.86 523.78,855.21 523.46,854.88 523.46,854.24 523.78,853.91 
	524.75,854.24 525.07,854.56 525.07,854.88 525.07,855.21 524.75,855.53 525.07,855.53 525.4,856.18 525.72,856.18 525.72,855.86 
	526.05,855.86 526.05,856.83 526.05,858.12 526.05,858.77 525.72,858.44 526.05,859.09 526.05,859.42 526.37,860.06 526.37,860.39 
	526.37,861.36 526.37,862.33 526.37,862.98 526.69,864.92 526.69,865.24 526.69,865.56 526.69,866.21 526.37,866.86 526.05,866.86 
	526.05,866.21 526.37,865.89 526.05,865.89 525.4,866.21 525.07,866.53 525.07,867.18 525.4,867.83 526.05,868.8 526.37,869.12 
	526.37,869.45 526.37,870.09 526.37,870.74 526.37,871.39 527.02,871.39 527.02,872.36 517.95,872.04 518.28,871.07 518.93,871.07 
	518.93,870.74 519.25,870.09 519.25,869.45 519.25,868.8 519.25,868.48 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="520.87,870.74 520.87,870.09 520.54,870.09 520.87,869.45 521.19,868.8 521.51,868.48 522.16,868.15 
	523.13,867.51 523.78,868.15 524.43,868.8 524.75,869.77 525.07,870.09 525.07,870.74 524.75,870.42 524.43,870.42 524.43,870.74 
	524.75,870.74 520.87,870.74 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="519.25" y1="861.03" x2="519.57" y2="805.7"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="524.75" y1="854.24" x2="525.07" y2="796.96"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="667.46,867.51 667.79,866.86 668.11,866.21 668.43,865.56 668.76,865.24 668.43,864.59 668.11,864.27 
	667.46,863.95 667.46,862.98 667.46,862.33 667.46,861.36 667.14,861.36 667.14,860.39 667.46,860.06 668.11,860.06 668.43,860.39 
	668.11,860.71 668.11,861.03 668.11,861.36 668.11,862 669.73,862.65 670.38,862.65 670.7,862.65 671.35,861.68 671.35,860.71 
	671.35,859.74 671.02,859.09 671.02,858.44 671.02,857.8 671.02,857.47 670.7,858.12 670.38,858.12 670.38,857.47 670.38,855.53 
	670.38,855.21 670.7,854.88 671.02,855.21 671.02,855.53 671.67,854.88 671.99,854.24 671.67,853.91 671.67,853.59 671.67,853.27 
	671.99,853.27 672.97,853.27 673.29,853.59 673.29,853.91 673.29,854.24 673.29,854.56 673.61,855.53 673.94,855.21 674.26,854.88 
	674.26,855.86 674.26,857.15 674.26,857.8 673.94,857.47 674.26,858.12 674.26,858.44 674.58,859.09 674.58,859.42 674.58,860.39 
	674.58,861.36 674.58,862 674.91,863.95 674.91,864.27 674.91,864.59 674.91,865.24 674.58,865.89 674.26,865.89 674.26,865.56 
	674.58,864.92 674.26,864.92 673.61,865.24 673.61,865.56 673.29,866.21 673.61,867.18 674.26,867.83 674.58,868.15 674.58,868.48 
	674.58,869.12 674.58,869.77 674.58,870.42 675.23,870.42 675.23,871.39 666.17,871.39 666.49,870.09 667.14,870.09 667.14,869.77 
	667.46,869.12 667.46,868.48 667.46,868.15 667.46,867.51 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="667.46" y1="860.06" x2="667.79" y2="804.72"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="672.97" y1="853.27" x2="673.29" y2="795.02"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="582.68" y1="962.65" x2="582.68" y2="890.16"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="577.82,898.57 577.82,898.25 577.82,897.6 577.82,896.63 577.5,896.63 577.5,895.66 577.82,895.34 
	578.15,895.34 578.47,895.34 578.79,895.66 578.79,895.98 578.47,896.31 578.47,896.63 578.79,897.28 580.09,897.93 581.06,897.93 
	581.38,897.93 581.71,896.95 582.03,895.98 582.03,895.01 581.71,894.37 581.71,893.72 581.71,893.07 581.38,892.75 581.06,893.39 
	580.74,892.75 580.74,890.81 580.74,890.48 581.38,890.16 581.38,890.48 581.71,890.81 582.03,890.16 582.68,889.51 582.35,889.51 
	582.35,889.19 582.03,888.86 582.35,888.54 582.68,888.54 583.32,888.54 583.97,888.86 583.97,889.19 583.65,889.51 583.65,889.84 
	583.97,890.81 584.3,890.81 584.3,890.48 584.62,890.16 584.94,891.13 584.94,892.42 584.62,893.07 584.62,892.75 584.3,892.75 
	584.62,893.39 584.94,893.72 584.94,894.37 584.94,894.69 584.94,895.66 584.94,896.63 585.27,897.28 585.59,899.22 585.59,899.54 
	585.27,899.87 585.27,900.51 584.94,901.16 584.94,900.84 584.94,900.19 584.62,900.19 584.3,900.51 583.97,900.84 583.65,901.49 
	584.3,902.46 584.62,903.1 584.94,903.43 584.94,903.75 584.94,904.4 585.27,905.04 585.27,905.69 585.59,905.69 585.59,906.66 
	583.65,906.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="582.68,898.57 577.82,898.57 577.82,964.59 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="524.75" y1="960.71" x2="524.75" y2="888.86"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="519.9,897.28 519.9,896.95 519.9,896.31 519.9,895.66 519.57,895.34 519.57,894.69 519.9,894.37 
	520.22,894.04 520.54,894.04 520.87,894.37 520.87,894.69 520.54,895.01 520.54,895.66 520.87,895.98 522.16,896.95 523.13,896.95 
	523.46,896.63 523.78,895.98 524.1,894.69 523.78,893.72 523.78,893.07 523.78,892.42 523.78,892.1 523.46,891.78 523.13,892.1 
	522.81,891.45 522.81,889.84 522.81,889.19 523.46,888.86 523.46,889.51 523.78,889.51 524.1,888.86 524.75,888.54 524.43,888.22 
	524.43,887.89 524.1,887.57 524.43,887.25 524.75,887.25 525.4,887.25 526.05,887.57 526.05,887.89 525.72,888.54 525.72,888.86 
	526.05,889.51 526.37,889.51 526.37,889.19 526.69,889.19 527.02,890.16 527.02,891.13 526.69,891.78 526.37,891.45 526.69,892.1 
	527.02,892.42 527.02,893.07 527.02,893.72 527.02,894.37 527.02,895.34 527.34,896.31 527.66,897.93 527.66,898.25 527.34,898.9 
	527.34,899.54 527.02,899.87 526.69,899.87 526.69,899.54 527.02,899.22 526.69,899.22 526.37,899.54 526.05,899.54 525.72,900.51 
	526.37,901.16 526.69,901.81 527.02,902.13 527.02,902.78 527.02,903.43 527.34,904.07 527.34,904.4 527.66,904.4 527.66,905.69 
	525.72,905.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="524.75,897.6 519.9,897.28 519.9,967.5 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="728.3" y1="963.62" x2="728.63" y2="891.13"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="723.77,899.54 723.77,899.22 723.77,898.57 723.77,897.6 723.45,897.6 723.45,896.95 723.77,896.31 
	724.42,896.31 724.74,896.63 724.74,896.95 724.42,897.28 724.42,897.6 724.74,898.25 726.04,899.22 726.69,898.9 727.33,898.9 
	727.66,897.93 727.98,896.95 727.66,895.98 727.66,895.34 727.33,894.69 727.33,894.04 727.01,894.37 726.69,893.72 726.69,891.78 
	726.69,891.45 727.01,891.13 727.33,891.78 727.98,891.13 728.63,890.81 728.3,890.48 727.98,890.16 727.98,889.84 728.3,889.51 
	728.63,889.51 729.27,889.51 729.6,889.84 729.6,890.16 729.6,890.48 729.6,890.81 729.6,891.13 729.92,891.78 730.24,891.78 
	730.24,891.45 730.57,891.45 730.57,892.42 730.89,893.39 730.57,894.04 730.57,893.72 730.24,893.72 730.57,894.37 730.89,894.69 
	730.89,895.34 730.89,895.98 730.89,896.63 730.89,897.6 731.22,898.25 731.22,900.19 731.22,900.51 731.22,901.16 731.22,901.81 
	730.89,902.13 730.57,902.13 730.57,901.81 730.89,901.49 730.57,901.49 729.92,901.81 729.6,902.46 729.92,903.43 730.57,904.07 
	730.89,904.4 730.89,904.72 730.89,905.69 730.89,906.34 730.89,906.66 731.54,906.66 731.54,907.63 729.6,907.63 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="728.63,899.87 723.77,899.54 723.77,965.24 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="519.9,974.62 520.22,973.97 520.54,973.33 520.87,972.68 521.19,972.36 520.87,971.71 520.54,971.39 
	520.22,971.06 519.9,970.09 519.9,969.44 519.9,968.47 519.57,968.47 519.57,967.5 519.9,967.18 520.54,967.18 520.87,967.5 
	520.87,967.83 520.54,968.15 520.54,968.47 520.87,969.12 522.16,969.77 522.81,969.77 523.46,969.77 523.78,968.8 524.1,967.83 
	523.78,966.85 523.78,966.21 523.46,965.56 523.46,964.91 523.46,964.59 523.13,965.24 522.81,964.59 522.81,962.65 522.81,962.32 
	523.13,962 523.46,962.32 523.46,962.65 524.1,962 524.75,961.35 524.43,961.35 524.1,961.03 524.1,960.71 524.43,960.38 
	524.75,960.38 525.4,960.38 525.72,960.71 525.72,961.03 525.72,961.35 525.72,961.68 526.05,962.65 526.37,962.65 526.37,962.32 
	526.69,962 526.69,962.32 526.69,963.3 527.02,964.27 526.69,964.91 526.69,964.59 526.37,964.59 526.69,965.24 527.02,965.56 
	527.02,966.21 527.02,966.53 527.02,967.5 527.02,968.47 527.34,969.12 527.34,971.06 527.34,971.39 527.34,971.71 527.34,972.36 
	527.02,973 526.69,973 526.69,972.68 527.02,972.03 526.69,972.03 526.05,972.36 526.05,972.68 525.72,973.33 526.05,974.3 
	526.69,974.95 527.02,975.27 527.02,975.59 527.02,976.24 527.02,976.89 527.02,977.53 527.66,977.53 527.66,978.51 518.6,978.51 
	518.93,977.21 519.57,977.21 519.9,976.89 519.9,976.24 519.9,975.59 519.9,975.27 520.22,974.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="592.71,759.1 601.12,754.24 606.95,751.33 613.42,750.36 622.16,750.03 629.6,750.68 634.13,750.36 
	638.66,752.3 642.55,754.56 645.46,756.83 647.72,762.66 647.72,766.54 645.78,766.22 644.49,768.16 646.11,768.8 647.08,768.8 
	647.4,770.75 648.37,777.54 648.69,783.69 648.05,786.93 649.34,791.13 648.69,794.69 648.69,795.66 647.72,796.31 647.4,797.93 
	647.72,805.05 663.26,805.37 664.23,795.99 661.32,795.66 661.32,794.05 663.26,793.07 663.26,768.8 663.58,767.83 664.55,767.83 
	663.9,766.22 662.61,765.89 666.17,755.21 669.73,749.06 673.29,746.8 682.67,744.53 695.62,744.53 701.12,745.18 709.86,748.42 
	713.74,754.56 719.24,760.39 720.54,762.98 719.89,765.57 718.59,765.57 718.92,766.54 721.51,767.19 722.15,783.37 721.83,786.28 
	723.45,793.72 722.8,797.28 720.21,797.61 720.21,800.52 720.86,805.7 737.69,805.37 737.69,797.28 734.78,795.99 733.8,796.31 
	733.48,778.19 733.8,768.48 735.42,766.54 736.39,765.89 736.07,763.63 734.78,762.66 734.13,762.66 735.1,758.12 746.75,748.74 
	754.84,745.5 770.05,743.89 782.67,750.36 788.5,755.54 793.67,761.36 797.56,768.8 796.26,781.75 794.64,787.25 794.32,789.84 
	792.05,790.81 792.38,793.72 792.7,798.9 792.38,803.11 792.38,806.02 797.56,807.31 803.06,807.31 806.94,806.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="588.18,755.86 600.48,745.18 610.83,741.94 618.92,741.3 623.45,741.62 628.95,742.27 632.19,744.21 
	638.02,746.8 642.22,750.03 644.81,752.95 647.4,757.48 648.05,750.68 647.72,740.33 588.18,741.3 587.86,755.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="660.67,756.83 672.97,745.83 683.32,742.91 691.73,742.27 695.94,742.27 701.77,742.91 705,745.18 
	710.83,747.77 714.71,751.01 717.3,753.59 719.89,758.12 720.86,751.65 720.21,740.97 660.67,742.27 660.67,756.83 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="733.48,756.18 745.78,745.18 756.13,742.27 764.22,741.62 768.75,741.62 774.58,742.27 777.82,744.21 
	783.32,747.12 787.52,750.36 790.11,752.95 792.7,757.48 793.67,751.01 793.03,740.33 733.48,741.62 733.48,756.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="648.37,729.65 648.37,729 647.4,728.68 647.4,728.35 647.4,727.71 646.11,727.38 645.13,728.03 
	645.13,728.68 644.81,728.68 644.16,729.32 644.16,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="644.16,730.29 644.16,730.94 644.16,733.53 648.69,733.21 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="648.37" y1="729.65" x2="644.16" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="648.37" y1="730.29" x2="644.16" y2="730.29"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="642.87,733.53 642.87,731.26 642.87,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="642.55,729.65 642.55,729.32 641.9,729 641.9,728.68 641.57,727.71 640.28,727.71 639.63,728.35 
	639.63,728.68 639.31,729 638.66,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="638.66,730.62 638.66,731.26 638.34,733.53 642.87,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="642.55" y1="729.65" x2="638.66" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="642.87" y1="730.62" x2="638.66" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="636.72,733.53 637.04,731.26 636.72,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="636.72,729.65 636.72,729.32 635.75,729 635.75,728.68 635.43,727.71 634.13,727.71 633.48,728.35 
	633.48,728.68 633.16,729 632.51,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="632.51,730.62 632.51,731.26 632.51,733.53 637.04,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="636.72" y1="729.65" x2="632.51" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="636.72" y1="730.62" x2="632.51" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="630.9,733.85 630.9,731.26 630.57,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="630.57,729.65 630.57,729.32 629.6,729 629.6,728.68 629.6,727.71 628.31,727.71 627.34,728.35 
	627.34,728.68 627.01,729 626.37,729.65 626.37,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="626.37,730.62 626.37,731.26 626.37,733.53 630.9,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="630.57" y1="729.65" x2="626.37" y2="729.97"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="630.57" y1="730.62" x2="626.37" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="624.42,733.53 624.42,731.26 624.42,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="624.1,729.65 624.1,729.32 623.45,728.68 623.13,727.71 621.83,727.71 621.19,728.35 621.19,728.68 
	620.86,729 620.22,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="620.22,730.62 620.22,731.26 620.22,733.53 624.42,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="624.42" y1="729.65" x2="620.22" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="624.42" y1="730.62" x2="620.22" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.27,733.85 618.27,731.26 618.27,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="617.95,729.65 617.95,729.32 617.3,729 617.3,728.68 616.98,727.71 615.69,727.71 615.04,728.35 
	615.04,728.68 614.72,729 614.07,729.65 614.07,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="614.07,730.62 614.07,731.26 613.74,733.53 618.27,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="618.27" y1="729.65" x2="614.07" y2="729.97"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="618.27" y1="730.62" x2="614.07" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="611.8,733.53 612.13,731.26 611.8,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="611.8,729.65 611.8,729.32 610.83,729 610.83,728.68 610.51,727.71 609.21,727.71 608.57,728.35 
	608.57,728.68 608.24,729 607.6,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="607.6,730.62 607.6,731.26 607.6,733.53 612.13,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="611.8" y1="729.65" x2="607.6" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="611.8" y1="730.62" x2="607.6" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="605.33,733.85 605.33,731.26 605.33,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="605.33,729.65 605.33,729.32 604.36,729 604.36,728.68 604.04,727.71 602.74,727.71 602.09,728.35 
	602.09,729 601.77,729 601.12,729.65 601.12,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="601.12,730.62 601.12,731.26 601.12,733.53 605.33,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="605.33" y1="729.65" x2="601.12" y2="729.97"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="605.33" y1="730.62" x2="601.12" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="599.18,733.85 599.18,731.59 599.18,730.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="599.18,729.97 598.86,729.65 598.21,729 597.89,728.03 596.59,728.03 595.95,728.68 595.95,729 
	595.62,729.32 594.97,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="594.97,730.94 594.97,731.59 594.97,733.85 599.18,733.85 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="599.18" y1="729.97" x2="594.97" y2="729.97"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="599.18" y1="730.94" x2="594.97" y2="730.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="592.71,734.18 592.71,731.91 592.71,731.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="592.39,730.29 592.39,729.97 591.74,729.65 591.74,729.32 591.42,728.35 590.12,728.35 589.47,729 
	589.47,729.32 589.15,729.65 588.5,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="588.5,731.26 588.5,731.91 588.5,734.18 592.71,734.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="592.71" y1="730.29" x2="588.5" y2="730.29"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="592.71" y1="731.26" x2="588.5" y2="731.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="645.46,726.41 643.52,728.68 641.9,726.41 645.46,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="639.63,726.41 637.69,728.68 636.07,726.41 639.63,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="633.48,726.41 631.54,729 629.6,726.41 633.48,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="627.34,726.41 625.39,729 623.78,726.41 627.34,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="621.19,726.73 619.25,729 617.3,726.73 621.19,726.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="615.04,726.73 613.1,729 611.48,726.73 615.04,726.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="608.57,726.73 606.3,729.32 604.68,726.73 608.57,726.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="602.09,727.06 600.15,729.32 598.53,727.06 602.09,727.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="595.62,727.06 593.68,729.32 591.74,727.06 595.62,727.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="586.24,727.06 589.47,727.06 587.53,729.65 586.24,729.65 586.24,726.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="721.18,729.32 721.18,729 720.21,728.68 720.21,728.35 719.89,727.38 718.59,727.38 717.95,728.03 
	717.95,728.35 717.62,728.68 716.98,729.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="716.98,730.29 716.98,730.94 716.98,733.21 721.18,733.21 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="721.18" y1="729.32" x2="716.98" y2="729.32"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="721.18" y1="730.29" x2="716.98" y2="730.29"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="715.68,733.53 715.68,730.94 715.36,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="715.36,729.32 715.36,729 714.39,728.68 714.39,728.35 714.39,727.38 713.09,727.38 712.12,728.03 
	712.12,728.68 711.48,729.32 711.15,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="711.15,730.29 711.15,730.94 711.15,733.21 715.68,733.21 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="715.36" y1="729.32" x2="711.15" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="715.36" y1="730.29" x2="711.15" y2="730.29"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="709.53,733.53 709.53,730.94 709.53,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="709.21,729.32 709.21,729 708.56,728.68 708.56,728.35 708.24,727.38 706.94,727.38 706.3,728.03 
	706.3,728.68 705.97,728.68 705.33,729.32 705.33,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="705.33,730.29 705.33,730.94 705,733.21 709.53,733.21 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="709.53" y1="729.32" x2="705.33" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="709.53" y1="730.29" x2="705.33" y2="730.29"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="703.39,733.53 703.39,730.94 703.39,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="703.39,729.65 703.39,729.32 702.41,728.68 702.09,727.71 700.8,727.71 700.15,728.35 700.15,728.68 
	699.83,729 699.18,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="699.18,730.62 699.18,730.94 699.18,733.53 703.39,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="703.39" y1="729.65" x2="699.18" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="703.39" y1="730.62" x2="699.18" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="697.24,733.53 697.24,730.94 697.24,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="696.91,729.32 696.91,729 696.27,728.68 695.94,728.35 695.94,727.38 694.65,727.38 694,728.03 
	694,728.68 693.68,728.68 693.03,729.32 693.03,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="693.03,730.29 692.71,730.94 692.71,733.21 697.24,733.21 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="696.91" y1="729.32" x2="693.03" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="697.24" y1="730.29" x2="693.03" y2="730.29"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="691.09,733.53 691.09,730.94 691.09,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="690.76,729.65 690.76,729.32 690.12,728.68 689.79,728.35 689.79,727.71 688.5,727.71 687.85,728.35 
	687.85,728.68 687.53,729 686.88,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="686.56,730.62 686.56,730.94 686.56,733.53 691.09,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="690.76" y1="729.65" x2="686.88" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="691.09" y1="730.62" x2="686.56" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="684.62,733.53 684.62,730.94 684.62,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="684.62,729.65 684.29,729 683.64,728.68 683.64,728.35 683.32,727.71 682.03,727.38 681.38,728.03 
	681.38,728.68 681.06,728.68 680.41,729.32 680.41,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="680.41,730.29 680.41,730.94 680.41,733.21 684.62,733.21 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="684.62" y1="729.32" x2="680.41" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="684.62" y1="730.29" x2="680.41" y2="730.29"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="678.14,733.53 678.14,731.26 678.14,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="677.82,729.65 677.82,729.32 677.17,728.68 676.85,727.71 675.55,727.71 674.91,728.35 674.91,728.68 
	674.58,729 673.94,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="673.94,730.62 673.94,731.26 673.61,733.53 678.14,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="678.14" y1="729.65" x2="673.94" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="678.14" y1="730.62" x2="673.94" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="671.99,733.85 671.99,731.26 671.99,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="671.67,729.65 671.67,729.32 671.02,729 670.7,728.68 670.7,727.71 669.41,727.71 668.76,728.35 
	668.76,728.68 668.43,729 667.79,729.65 667.79,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="667.79,730.62 667.46,731.26 667.46,733.53 671.99,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="671.67" y1="729.65" x2="667.79" y2="729.97"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="671.99" y1="730.62" x2="667.79" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.52,734.18 665.52,731.59 665.52,730.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.2,729.97 665.2,729.65 664.55,729.32 664.23,729 664.23,728.03 662.93,728.03 662.29,728.68 
	662.29,729.32 661.96,729.32 661.32,729.97 661.32,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="661.32,730.94 660.99,731.59 660.99,733.85 665.52,733.85 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="665.2" y1="729.97" x2="661.32" y2="730.29"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="665.52" y1="730.94" x2="661.32" y2="730.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="718.27,726.09 716.33,728.68 714.39,726.09 718.27,726.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="712.45,726.09 710.5,728.68 708.56,726.09 712.45,726.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="706.3,726.09 704.36,728.68 702.41,726.09 706.3,726.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="700.15,726.09 698.21,728.68 696.27,726.41 700.15,726.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="694,726.41 691.73,729 690.12,726.41 694,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="687.85,726.41 685.91,729 683.97,726.41 687.85,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="681.06,726.41 679.11,729 677.5,726.41 681.06,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="674.91,726.73 672.97,729.32 671.02,726.73 674.91,726.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="668.43,726.73 666.49,729.32 664.55,726.73 668.43,726.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="794.32,729 794.32,728.35 793.35,728.03 793.35,727.71 793.03,727.06 791.73,726.73 791.08,727.38 
	791.08,728.03 790.76,728.03 790.11,728.68 790.11,729 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="790.11,729.65 790.11,730.29 790.11,732.56 794.32,732.56 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="794.32" y1="729" x2="790.11" y2="729"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="794.32" y1="729.65" x2="790.11" y2="729.65"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="788.82,732.88 788.82,730.62 788.5,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="788.5,729 788.5,728.68 787.52,728.03 787.52,727.06 786.23,727.06 785.26,727.71 785.26,728.03 
	784.94,728.35 784.29,729 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="784.29,729.97 784.29,730.62 784.29,732.88 788.82,732.88 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="788.5" y1="729" x2="784.29" y2="729"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="788.5" y1="729.97" x2="784.29" y2="729.97"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="782.67,732.88 782.67,730.62 782.67,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="782.35,729 782.35,728.68 781.7,728.03 781.38,727.06 780.08,727.06 779.43,727.71 779.43,728.03 
	779.11,728.35 778.46,729 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="778.46,729.97 778.46,730.62 778.14,732.88 782.67,732.88 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="782.67" y1="729" x2="778.46" y2="729"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="782.67" y1="729.97" x2="778.46" y2="729.97"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="776.52,733.21 776.52,730.62 776.52,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="776.52,729 776.52,728.68 775.55,728.35 775.55,728.03 775.23,727.06 773.93,727.06 773.29,727.71 
	773.29,728.03 772.96,728.35 772.31,729 772.31,729.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="772.31,729.97 772.31,730.62 772.31,732.88 776.52,732.88 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="776.52" y1="729" x2="772.31" y2="729.32"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="776.52" y1="729.97" x2="772.31" y2="729.97"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="770.37,732.88 770.37,730.62 770.37,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="770.05,729 770.05,728.68 769.4,728.03 769.08,728.03 769.08,727.06 767.78,727.06 767.14,727.71 
	767.14,728.03 766.81,728.35 766.17,729 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="765.84,729.97 765.84,730.62 765.84,732.88 770.37,732.88 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="770.05" y1="729" x2="766.17" y2="729"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="770.37" y1="729.97" x2="765.84" y2="729.97"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="764.22,732.88 764.22,730.62 764.22,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="763.9,729 763.9,728.68 763.25,728.35 762.93,728.03 762.93,727.06 761.64,727.06 760.99,727.71 
	760.66,728.03 760.66,728.35 760.02,729 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="759.69,729.97 759.69,730.62 759.69,732.88 764.22,732.88 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="763.9" y1="729" x2="760.02" y2="729"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="764.22" y1="729.97" x2="759.69" y2="729.97"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="757.75,732.88 757.75,730.62 757.75,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="757.75,729 757.43,728.68 756.78,728.35 756.78,728.03 756.46,727.06 755.16,727.06 754.52,727.71 
	754.52,728.03 754.19,728.35 753.55,729 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="753.55,729.97 753.55,730.62 753.55,732.88 757.75,732.88 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="757.75" y1="729" x2="753.55" y2="729"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="757.75" y1="729.97" x2="753.55" y2="729.97"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="751.28,733.21 751.28,730.62 751.28,729.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="750.96,729 750.96,728.68 750.31,728.35 750.31,728.03 749.99,727.06 748.69,727.06 748.04,727.71 
	748.04,728.35 747.72,728.35 747.07,729 747.07,729.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="747.07,729.97 747.07,730.62 746.75,732.88 751.28,732.88 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="750.96" y1="729" x2="747.07" y2="729.32"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="751.28" y1="729.97" x2="747.07" y2="729.97"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="745.13,733.21 745.13,730.62 745.13,730.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="744.81,729.32 744.81,729 744.16,728.35 743.84,728.35 743.84,727.38 742.54,727.38 741.89,728.03 
	741.89,728.35 741.57,728.68 740.92,729.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="740.92,730.29 740.6,730.62 740.6,733.21 745.13,733.21 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="744.81" y1="729.32" x2="740.92" y2="729.32"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="745.13" y1="730.29" x2="740.92" y2="730.29"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="738.66,733.53 738.66,731.26 738.66,730.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="738.34,729.65 738.34,729.32 737.69,728.68 737.36,728.68 737.36,727.71 736.07,727.71 735.42,728.35 
	735.42,728.68 735.1,729 734.45,729.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="734.13,730.62 734.13,731.26 734.13,733.53 738.66,733.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="738.34" y1="729.65" x2="734.45" y2="729.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="738.66" y1="730.62" x2="734.13" y2="730.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="791.41,725.76 789.47,728.03 787.52,725.76 791.41,725.76 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="785.58,725.76 783.64,728.03 781.7,725.76 785.58,725.76 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="779.43,725.76 777.49,728.35 775.55,725.76 779.43,725.76 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="773.29,725.76 771.34,728.35 769.4,725.76 773.29,725.76 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="767.14,725.76 764.87,728.35 763.25,726.09 767.14,725.76 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="760.99,726.09 759.05,728.35 757.1,726.09 760.99,726.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="754.19,726.09 752.25,728.68 750.63,726.09 754.19,726.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="748.04,726.41 746.1,728.68 744.16,726.41 748.04,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="741.57,726.41 739.63,728.68 737.69,726.41 741.57,726.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="732.19,726.41 735.42,726.41 733.16,729 732.19,728.68 732.19,726.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="588.18,741.3 586.24,727.71 586.56,695.67 587.21,655.22 588.83,649.39 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="638.34,642.59 640.93,658.45 643.84,668.48 645.46,683.37 646.43,692.43 647.72,705.05 647.72,709.91 
	647.4,726.41 647.08,740.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="660.99,742.91 660.99,709.58 661.64,687.58 662.93,668.81 662.93,658.45 663.26,649.07 666.17,639.68 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="713.42,651.33 715.04,659.42 717.3,676.25 717.3,697.61 718.92,718.32 722.48,731.91 720.54,741.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="733.8,742.27 731.54,726.09 731.54,663.95 732.51,649.71 732.51,643.89 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="783.96,639.36 788.82,651.66 796.26,722.2 794.97,740.33 795.61,760.07 797.56,768.8 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="587.86,725.44 587.53,722.85 597.56,722.53 599.51,719.29 634.13,718.97 637.69,721.56 646.43,722.2 
	646.11,725.12 587.86,725.44 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="599.83,719.61 599.18,689.52 600.8,666.22 601.12,660.07 603.39,651.98 606.3,647.77 614.72,645.51 
	619.57,645.18 628.31,648.74 631.22,651.33 632.84,662.66 634.46,672.37 635.75,685.31 634.46,719.29 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="599.18" y1="704.4" x2="634.78" y2="704.08"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="602.42,704.73 603.39,674.96 603.71,668.81 605.33,663.63 608.57,662.34 610.18,663.63 611.16,667.51 
	611.48,674.31 612.13,682.72 612.45,691.78 612.77,698.9 613.1,704.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="620.22,704.08 619.57,680.13 619.89,673.01 620.54,667.19 621.51,662.98 623.13,662.34 626.37,664.92 
	628.31,669.45 629.28,678.19 629.92,689.84 629.92,698.26 629.6,704.08 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M620.54,655.7c0-3.31-2.68-5.99-5.99-5.99c-3.31,0-5.99,2.68-5.99,5.99c0,3.31,2.68,5.99,5.99,5.99
	C617.86,661.69,620.54,659.01,620.54,655.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="601.77,718 601.45,706.67 633.48,706.02 632.84,716.7 601.77,718 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="660.99,723.17 660.67,720.59 671.02,719.94 672.64,716.7 707.27,716.38 710.83,719.29 719.57,719.94 
	719.57,722.85 660.99,723.17 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="672.97,717.35 672.32,687.25 673.94,663.95 674.26,657.8 676.53,649.71 679.44,645.51 687.85,643.24 
	692.71,642.92 701.77,646.48 704.36,649.07 706.3,660.39 707.59,670.1 708.89,683.05 707.59,717.03 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="672.64" y1="702.14" x2="707.92" y2="701.82"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="675.55,702.46 676.53,672.69 676.85,666.54 678.47,661.04 681.7,660.07 683.64,661.36 684.29,665.25 
	684.62,672.04 685.26,680.46 685.59,689.52 685.91,696.64 686.56,702.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="693.68,701.82 692.71,677.87 693.03,670.43 693.68,664.92 694.65,660.72 696.27,660.07 699.5,662.66 
	701.44,667.19 702.74,675.93 703.39,687.25 703.06,695.67 702.74,701.82 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M693.68,653.44c0-3.31-2.68-5.99-5.99-5.99c-3.31,0-5.99,2.68-5.99,5.99c0,3.31,2.68,5.99,5.99,5.99
	C691,659.42,693.68,656.74,693.68,653.44"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="674.91,715.73 674.91,704.4 706.62,703.43 706.3,714.44 674.91,715.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="733.48,721.56 733.48,718.97 743.51,718.32 745.45,715.08 779.76,714.76 783.64,717.67 792.05,718 
	792.05,721.23 733.48,721.56 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="745.45,715.73 745.13,685.64 746.43,662.34 746.75,655.86 749.34,647.77 751.93,643.57 760.34,641.62 
	765.2,641.3 774.26,644.54 776.85,647.45 778.79,658.78 780.08,668.16 781.38,681.43 780.08,715.41 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="745.13" y1="700.52" x2="780.73" y2="700.2"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="748.37,700.52 749.01,671.07 749.66,664.6 750.96,659.42 754.19,658.45 756.13,659.75 756.78,663.31 
	757.1,670.1 757.75,678.52 758.08,687.9 758.4,695.02 759.05,700.85 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="766.17,699.87 765.2,676.25 765.52,668.81 766.17,663.31 767.14,659.1 768.75,658.13 772.31,660.72 
	773.93,665.25 775.23,673.99 775.87,685.64 775.55,694.05 775.23,700.2 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M766.49,651.49c0-3.31-2.68-5.99-5.99-5.99c-3.31,0-5.99,2.68-5.99,5.99s2.68,5.99,5.99,5.99
	C763.81,657.48,766.49,654.8,766.49,651.49"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="747.4,714.11 747.4,702.46 779.43,701.82 778.79,712.82 747.4,713.79 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="439.32" y1="800.84" x2="439.64" y2="965.24"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="434.79" y1="803.43" x2="435.43" y2="965.24"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="429.29" y1="800.19" x2="431.55" y2="965.56"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="406.96,825.76 315.7,823.82 262.62,828.67 238.35,847.44 228.32,865.89 223.79,895.34 242.24,921.87 
	258.09,936.11 282.36,941.29 312.78,945.82 352.59,946.14 376.21,943.23 381.71,942.91 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="439.64,965.24 352.59,969.77 307.61,971.39 271.36,969.44 244.83,964.59 212.14,948.09 196.61,933.85 
	187.22,919.61 182.69,896.63 187.55,868.48 192.4,856.18 199.52,843.88 211.49,832.23 238.03,815.73 252.27,806.34 281.39,800.19 
	351.29,799.55 369.74,798.58 401.45,799.22 439.32,800.84 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="415.37" y1="966.53" x2="409.54" y2="949.06"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="408.25,936.11 409.87,928.67 407.28,924.79 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="402.75" y1="914.75" x2="401.45" y2="908.28"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="398.54,901.49 397.25,899.54 394.01,892.42 394.01,892.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="392.72,851.97 392.72,851.65 393.36,846.15 397.89,840 398.22,839.35 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="399.51,837.41 402.43,832.56 405.01,826.73 405.98,825.44 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="365.86,889.84 361,889.84 365.86,933.85 367.47,947.11 373.62,968.47 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="361" y1="889.84" x2="360.68" y2="876.89"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="360.68,866.53 360.36,846.15 366.5,825.11 368.77,799.55 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="361,889.84 300.81,889.84 280.1,887.89 268.77,883.04 261.33,880.45 251.95,872.36 259.71,867.83 
	268.45,858.12 275.57,854.88 287.22,850.35 300.49,849.06 313.43,848.41 327.99,848.09 352.91,849.06 367.15,848.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="296.93,889.51 288.51,893.39 270.07,898.9 262.62,902.13 260.36,904.07 264.89,907.31 272.98,913.14 
	278.48,916.05 285.6,918.96 297.9,924.46 307.93,927.37 320.87,928.67 333.17,929.32 348.71,929.64 362.94,928.99 365.53,928.99 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="304.37" y1="849.06" x2="304.37" y2="849.06"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="302.11,848.74 287.54,847.12 263.6,844.21 258.09,840 263.6,835.47 267.48,831.91 292.72,829 
	314.4,827.05 346.76,825.76 355.5,825.76 370.06,825.44 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="351.29,799.55 351.94,825.11 347.41,843.88 348.06,868.48 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="348.06,874.3 348.71,892.75 354.53,930.61 354.85,946.47 355.82,969.44 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="291.1,970.74 294.01,934.82 297.25,924.14 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="298.22" y1="907.96" x2="298.22" y2="907.96"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="298.22" y1="866.86" x2="298.22" y2="866.21"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="291.1,829 290.46,827.38 281.39,800.19 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="231.56,876.89 233.5,878.19 235.76,873.65 234.47,871.07 230.91,871.71 230.59,875.6 231.56,876.89 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="234.15,877.21 239.97,887.25 248.39,896.63 250.97,897.28 263.27,910.55 273.63,916.05 283.01,919.61 
	297.25,924.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="296.93,889.51 280.42,891.13 261.98,894.69 250.65,897.28 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="235.76,873.33 245.8,873.01 252.27,872.36 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="231.88,877.21 233.18,896.31 238.68,904.72 246.12,910.55 254.53,916.37 259.06,921.87 266.51,928.02 
	274.27,931.58 286.9,936.44 293.69,937.08 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="234.47,871.07 237.38,863.62 239.97,854.88 243.21,849.71 248.06,844.21 257.45,835.79 264.89,830.29 
	279.13,828.35 284.31,828.03 290.13,827.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="298.87,849.06 280.75,848.74 262.95,847.44 252.92,847.77 243.21,849.71 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="272.66,862.65 271.69,868.8 273.95,872.36 280.42,873.98 283.34,871.39 285.6,863.62 283.01,860.06 
	275.25,859.74 273.63,861.36 272.33,863.95 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="333.82" y1="876.24" x2="333.5" y2="871.07"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="333.5,869.45 332.52,855.21 291.75,856.18 286.9,859.74 286.57,863.62 289.48,865.89 293.69,865.56 
	300.49,866.53 307.61,865.89 319.26,865.89 326.38,865.89 333.17,866.21 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="300.49,866.53 289.81,868.15 287.22,870.42 288.84,873.98 292.07,875.92 297.9,877.54 310.52,877.21 
	317.64,876.89 325.08,876.24 330.26,876.24 333.82,876.24 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="299.52,907.96 292.72,908.6 289.81,911.52 290.13,915.72 311.81,916.69 317.31,917.02 328.32,917.02 
	333.5,916.69 339.32,916.37 339,912.81 338.67,898.9 305.34,897.28 301.13,897.6 295.31,898.57 291.1,900.51 290.78,902.78 
	292.4,906.34 296.6,907.96 301.46,908.28 312.46,907.96 322.82,906.34 334.47,906.02 338.67,906.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="272.98,906.34 272.01,907.63 276.54,912.81 280.1,913.78 286.25,911.19 286.9,906.34 287.54,901.81 
	285.6,899.87 283.01,898.57 278.81,898.57 276.22,899.22 273.95,901.81 273.3,904.4 272.98,906.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="272.66,836.12 271.36,840 273.63,843.23 276.54,844.21 281.07,844.21 283.98,841.94 284.31,839.03 
	283.01,837.09 281.07,835.14 277.83,834.17 274.27,834.17 272.66,836.12 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="332.2,844.85 322.17,845.18 312.78,845.18 304.69,845.18 297.9,845.5 291.1,845.18 287.54,841.62 
	286.9,840.32 289.16,838.06 293.04,836.76 298.55,837.41 307.61,837.41 317.96,837.41 325.73,837.41 330.91,837.41 333.82,837.41 
	332.2,844.85 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="333.82,837.41 336.08,829.32 293.37,830.29 287.22,829.97 286.9,831.58 287.22,834.17 289.16,836.44 
	293.04,836.76 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="390.45,889.19 387.54,889.19 386.89,891.13 386.89,892.75 383.33,892.1 378.15,892.1 372.65,891.45 
	368.45,891.78 366.18,891.45 365.86,891.13 365.53,884.98 365.53,880.45 365.53,877.86 365.53,876.89 361.97,876.57 359.06,877.21 
	358.74,876.57 350.65,871.71 350,874.3 344.82,874.3 343.2,876.24 341.26,876.24 339.97,876.24 338.35,875.92 336.73,874.95 
	334.47,871.07 312.46,871.07 335.11,869.45 336.41,867.18 338.03,866.53 339.64,866.21 341.91,866.53 343.53,867.83 344.5,868.8 
	346.76,868.8 351.29,867.83 350.97,871.07 361.33,866.21 366.5,865.24 366.5,850.68 366.83,848.09 387.86,848.41 387.54,851 
	389.16,852.3 390.78,853.27 391.1,853.27 390.45,889.84 392.72,892.42 394.33,850.35 391.1,853.59 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="392.72" y1="892.1" x2="399.84" y2="891.78"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="394.33" y1="850.35" x2="400.16" y2="850.35"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="396.6,907.63 399.84,897.6 400.48,893.07 400.81,886.92 401.13,881.1 401.45,874.3 401.78,868.15 
	401.78,862.98 401.78,857.15 400.48,849.06 399.51,844.85 398.54,841.29 398.22,839.68 398.22,837.41 399.19,837.41 399.84,839.68 
	402.43,855.86 402.75,873.33 402.43,889.84 399.84,901.81 397.89,908.6 397.25,908.6 396.92,908.93 396.6,908.28 396.92,907.31 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="398.22,908.6 403.4,907.96 404.37,913.46 409.87,892.75 410.19,876.24 410.19,862.98 409.87,851.65 
	408.57,844.53 408.25,837.41 407.93,833.53 405.34,838.06 407.6,856.18 407.93,869.12 407.28,877.86 405.66,892.1 405.34,898.25 
	404.04,904.72 403.4,907.96 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="384.63,954.23 386.57,951.32 387.22,948.41 386.89,944.2 386.89,940.32 386.89,935.46 385.6,933.52 
	385.27,933.2 384.95,933.2 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="384.3,954.56 400.81,954.23 401.45,951 401.13,944.85 401.78,939.02 401.78,935.14 400.48,931.26 
	398.22,930.61 397.89,932.23 384.63,932.88 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="386.89" y1="936.44" x2="401.78" y2="936.11"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="386.89" y1="944.85" x2="401.13" y2="944.2"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="386.89" y1="950.03" x2="401.45" y2="950.35"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="387.54,932.88 388.51,930.61 390.45,927.7 392.07,925.76 392.72,923.49 394.01,921.55 396.28,920.9 
	397.89,919.28 399.19,917.34 400.48,916.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="401.78,936.11 403.4,933.85 405.98,929.32 406.96,926.08 407.6,923.49 408.9,922.2 410.84,920.58 
	412.13,920.58 413.75,919.28 413.75,916.05 412.13,914.11 409.87,912.81 406.31,912.81 404.04,914.43 402.1,915.08 400.48,916.69 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="400.81,931.9 400.48,927.7 403.72,927.7 403.07,923.49 406.63,923.49 406.31,920.25 407.93,919.93 
	407.6,917.99 409.22,917.99 408.9,916.69 410.19,916.69 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="410.19,916.37 409.87,915.72 411.81,915.72 411.81,913.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="401.78,936.11 403.72,935.79 404.69,945.5 403.72,949.7 403.4,950.35 402.43,950.67 401.45,951 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="403.72,949.7 405.98,949.7 406.31,949.06 410.52,949.06 412.46,948.73 412.13,950.03 416.99,949.38 
	417.96,945.82 416.34,934.49 411.81,934.49 411.16,936.11 410.52,936.44 407.93,936.11 406.63,936.44 405.98,935.79 405.34,935.46 
	404.69,935.46 404.37,936.11 403.72,936.11 403.72,935.79 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="364.89,873.65 363.59,873.01 361.97,873.01 361,872.68 361,871.07 361.97,870.42 362.94,870.42 
	363.59,869.45 366.18,868.15 367.8,868.48 369.42,867.83 372.01,867.83 372.65,868.8 376.86,869.12 380.1,868.8 381.39,868.8 
	382.04,868.15 382.36,868.15 382.36,869.12 381.71,869.77 380.74,870.09 380.74,871.39 381.71,871.71 382.36,872.36 382.36,873.65 
	382.04,874.3 381.71,873.98 381.07,873.33 380.42,873.65 380.1,874.63 379.77,875.6 378.48,875.27 376.21,875.27 373.95,874.95 
	372.33,874.3 369.09,873.98 369.42,874.63 371.36,874.63 372.01,874.63 373.62,875.6 373.62,875.92 372.33,876.57 371.36,876.24 
	370.06,875.92 368.12,875.6 366.18,874.95 364.56,873.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="413.75,834.5 411.81,834.17 409.87,833.53 407.93,833.2 407.6,833.53 408.57,834.17 410.19,834.17 
	410.84,834.17 410.84,835.14 411.49,840.32 415.05,841.94 421.19,841.62 421.19,841.29 415.69,841.29 415.69,834.82 420.87,834.82 
	420.87,834.17 418.61,833.85 415.05,833.85 414.4,833.85 414.08,834.5 410.52,834.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="384.63,932.88 383.33,933.52 382.36,935.79 382.04,938.38 381.71,941.29 381.71,943.23 382.04,947.44 
	382.04,950.67 382.68,952.62 383.01,953.91 383.33,954.23 383.66,954.23 384.3,954.56 384.63,954.23 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="405.01" y1="838.06" x2="399.51" y2="837.41"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="400.16" y1="850.35" x2="399.84" y2="891.78"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="358.09,968.15 357.44,962 361.65,965.24 358.09,968.47 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="357.44,960.38 356.8,953.59 360.68,957.47 357.44,960.71 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="357.44,952.29 356.47,947.44 360.36,950.03 357.12,952.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="356.15,945.17 356.15,942.58 358.74,943.23 356.47,945.17 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="356.15,941.61 355.82,937.73 358.74,939.02 356.15,941.61 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="355.82,936.44 355.5,933.52 358.09,934.82 355.82,936.44 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="355.82,932.55 355.5,929.64 358.09,930.93 355.82,932.55 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="354.85,928.02 354.85,925.11 357.44,926.4 354.85,928.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="354.53,922.84 354.21,920.25 356.8,921.55 354.53,922.84 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="354.21,918.31 353.88,915.4 356.47,916.69 354.21,918.31 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="352.91,913.78 352.59,910.87 355.5,912.16 352.91,913.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="352.59,908.6 352.27,905.69 354.85,906.99 352.59,908.6 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="351.29,904.4 351.29,901.49 353.88,902.78 351.29,904.4 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="350.97,899.54 350.65,896.63 353.24,897.93 350.97,899.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="350.32,895.34 350,892.42 352.91,893.72 350.32,895.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="350,888.54 350,883.36 353.56,885.95 350,888.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="350,881.42 349.68,876.24 353.24,878.83 350,881.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="349.03,867.83 349.03,862.65 352.59,865.24 349.03,867.83 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="349.03,861.03 348.71,855.86 352.27,858.44 349.03,861.03 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="348.71,854.88 348.38,849.71 351.94,852.3 348.71,854.88 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="348.71,847.12 349.03,842.91 351.94,845.5 348.38,847.12 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="349.68,840.97 350,837.09 352.59,839.68 349.35,841.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="350.65,835.47 350.97,831.58 353.88,834.17 350.65,835.79 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="351.94,830.61 352.27,826.41 355.18,829 351.62,830.61 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="352.59,823.82 352.59,818.32 355.82,820.91 352.59,823.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="352.59,816.38 352.27,810.55 355.82,813.14 352.59,816.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="352.27,808.61 352.27,802.78 355.5,805.37 352.27,808.61 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="369.42,963.62 363.59,963.62 362.3,963.3 361,962.32 359.71,961.35 360.36,959.74 361.65,958.76 
	361.97,958.12 368.45,957.79 370.06,963.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="367.8,955.85 362.94,955.85 361.65,955.53 360.03,954.56 358.74,953.59 359.38,952.94 360.36,951.65 
	361.33,951 365.21,951 366.83,951 367.8,956.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="362.94,942.26 360.36,942.58 358.74,942.26 357.77,941.61 357.44,941.29 357.77,940.64 359.06,939.67 
	362.62,939.67 362.94,942.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="362.3,938.38 358.74,938.05 357.77,938.05 357.12,936.76 357.77,936.11 358.74,935.46 360.36,935.46 
	362.3,935.79 362.3,938.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="361.65,933.52 358.74,933.52 357.44,933.52 357.12,932.88 357.44,932.55 359.06,931.58 360.36,931.26 
	361.33,931.26 361.65,933.85 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="359.71,925.11 357.12,925.11 355.82,924.79 355.5,924.46 355.5,923.81 355.82,923.17 356.47,922.84 
	357.12,922.52 357.77,922.52 359.38,922.2 360.03,925.11 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="359.06,919.93 356.47,920.58 355.18,920.25 354.21,919.61 354.53,918.96 354.85,918.96 355.5,917.99 
	356.15,917.67 357.44,917.34 358.74,917.34 359.06,920.25 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="358.09,915.72 356.15,916.05 355.18,915.72 354.21,915.08 353.88,914.43 354.53,913.78 355.5,913.46 
	356.8,913.14 357.77,913.46 358.41,915.72 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="357.12,910.87 355.5,911.19 354.21,910.87 353.56,910.22 353.24,909.9 353.56,909.25 353.88,908.93 
	354.53,908.6 354.85,908.28 355.82,908.28 356.47,908.28 357.12,910.87 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="356.15,905.69 354.21,906.02 352.91,905.04 352.59,905.04 353.24,904.07 353.88,904.07 354.53,903.75 
	354.85,903.75 356.15,903.43 356.47,905.69 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="355.82,901.16 353.24,901.49 352.27,900.51 351.62,900.19 352.27,899.54 353.56,898.9 353.88,898.9 
	354.21,898.9 355.5,898.25 355.82,901.16 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="355.18,896.63 353.88,896.95 352.59,896.63 351.94,895.66 352.59,894.69 353.56,894.37 355.18,894.04 
	355.5,893.72 355.5,896.31 355.18,896.63 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="357.12,884.01 354.53,884.66 352.27,883.69 351.29,883.36 350.65,882.39 351.94,880.77 353.88,879.8 
	356.15,879.48 356.8,881.42 356.8,883.69 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="356.47,877.21 354.21,876.89 352.27,876.24 351.29,874.95 350.97,873.98 352.59,873.01 352.91,873.01 
	"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="356.47" y1="877.54" x2="356.15" y2="874.95"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="355.82,868.8 355.82,866.21 354.21,866.86 352.27,868.15 351.29,869.12 351.94,870.42 352.27,870.42 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="355.5,863.95 352.59,863.62 351.29,862.98 350.97,861.68 351.62,860.39 352.91,859.42 354.53,859.09 
	355.5,859.09 355.5,863.95 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="355.5,857.15 352.91,856.5 351.62,855.86 351.29,854.88 352.27,853.59 353.88,853.27 355.82,852.94 
	355.5,857.8 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="356.15,844.85 354.21,844.53 352.27,844.21 351.29,842.91 350.97,842.26 352.27,841.29 353.56,840.97 
	355.5,840.97 356.47,840.97 356.15,844.85 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="356.8,839.03 355.18,839.03 353.56,838.38 352.27,837.73 352.27,837.09 352.91,836.12 354.53,835.47 
	356.47,835.47 358.41,835.14 357.12,839.03 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="358.74,832.23 356.47,832.56 355.18,832.23 354.85,830.94 355.18,830.29 356.15,829.64 357.77,829 
	359.71,829 360.03,829 358.74,832.56 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="361,819.93 358.41,820.26 355.82,819.61 354.85,818.32 354.53,817.02 355.18,816.38 356.15,815.08 
	358.41,814.76 361.65,813.79 361,819.61 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="361.33,811.52 358.09,811.84 354.53,811.2 353.56,808.93 354.21,807.64 356.8,806.67 359.06,805.7 
	361,805.05 361.33,805.05 361.33,811.52 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M372.65,821.71c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1s0.94,2.1,2.1,2.1
	C371.71,823.82,372.65,822.88,372.65,821.71"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M372.65,815.57c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C371.71,817.67,372.65,816.73,372.65,815.57"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M372.98,809.42c0-1.16-0.94-2.1-2.1-2.1s-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	S372.98,810.58,372.98,809.42"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M373.62,802.3c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C372.68,804.4,373.62,803.46,373.62,802.3"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M379.13,822.04c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1s0.94,2.1,2.1,2.1
	C378.18,824.14,379.13,823.2,379.13,822.04"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M379.45,815.89c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1s0.94,2.1,2.1,2.1
	C378.51,817.99,379.45,817.05,379.45,815.89"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M379.77,809.74c0-1.16-0.94-2.1-2.1-2.1s-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1S379.77,810.9,379.77,809.74
	"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M380.42,802.95c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C379.48,805.05,380.42,804.11,380.42,802.95"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M386.24,821.71c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1s0.94,2.1,2.1,2.1
	C385.3,823.82,386.24,822.88,386.24,821.71"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M386.24,815.57c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C385.3,817.67,386.24,816.73,386.24,815.57"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M386.57,809.42c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C385.63,811.52,386.57,810.58,386.57,809.42"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M387.22,802.3c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C386.27,804.4,387.22,803.46,387.22,802.3"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M393.04,820.74c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C392.1,822.85,393.04,821.91,393.04,820.74"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M393.36,814.6c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C392.42,816.7,393.36,815.76,393.36,814.6"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M393.69,808.45c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C392.75,810.55,393.69,809.61,393.69,808.45"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M407.93,834.01c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1s0.94,2.1,2.1,2.1
	C406.98,836.12,407.93,835.17,407.93,834.01"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M399.51,821.07c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1s0.94,2.1,2.1,2.1
	C398.57,823.17,399.51,822.23,399.51,821.07"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M399.84,814.92c0-1.16-0.94-2.1-2.1-2.1s-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	S399.84,816.08,399.84,814.92"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M400.16,808.77c0-1.16-0.94-2.1-2.1-2.1s-2.1,0.94-2.1,2.1s0.94,2.1,2.1,2.1S400.16,809.93,400.16,808.77"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M400.81,801.65c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1s0.94,2.1,2.1,2.1
	C399.87,803.75,400.81,802.81,400.81,801.65"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M405.66,821.07c0-1.16-0.94-2.1-2.1-2.1s-2.1,0.94-2.1,2.1s0.94,2.1,2.1,2.1S405.66,822.23,405.66,821.07"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M405.98,815.24c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C405.04,817.35,405.98,816.4,405.98,815.24"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M406.31,809.09c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C405.37,811.2,406.31,810.26,406.31,809.09"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M406.96,801.97c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C406.01,804.08,406.96,803.14,406.96,801.97"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="378.15,962.65 377.83,962.65 377.51,962.97 377.18,963.3 376.86,963.94 376.54,964.27 376.54,964.91 
	376.54,965.24 376.54,965.88 376.86,966.21 377.18,966.85 377.51,967.18 377.83,967.5 378.15,967.83 378.48,968.15 379.13,968.15 
	379.45,968.15 380.1,967.83 380.42,967.83 380.74,967.5 381.07,966.85 381.39,966.53 381.39,966.21 381.39,965.56 381.39,964.91 
	381.39,964.59 381.07,963.94 380.74,963.62 380.42,963.3 380.1,962.97 379.77,962.65 379.13,962.65 378.8,962.65 378.15,962.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="375.89,956.82 375.24,956.82 374.92,957.15 374.59,957.47 374.27,958.12 374.27,958.44 373.95,959.09 
	373.95,959.41 374.27,960.06 374.27,960.38 374.59,961.03 374.92,961.35 375.24,961.68 375.57,962 376.21,962 376.54,962.32 
	377.18,962.32 377.51,962 377.83,962 378.15,961.68 378.48,961.03 378.8,960.71 378.8,960.38 379.13,959.74 378.8,959.09 
	378.8,958.76 378.48,958.12 378.48,957.79 377.83,957.47 377.51,957.15 377.18,956.82 376.54,956.82 376.21,956.82 375.89,956.82 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="373.95,950.67 373.62,950.67 373.3,951 372.98,951.32 372.65,951.97 372.33,952.29 372.33,952.94 
	372.33,953.26 372.33,953.91 372.65,954.23 372.65,954.88 372.98,955.2 373.3,955.53 373.95,955.85 374.27,956.18 374.92,956.18 
	375.24,956.18 375.57,955.85 376.21,955.85 376.54,955.53 376.86,955.2 376.86,954.56 377.18,954.23 377.18,953.59 377.18,952.94 
	377.18,952.62 376.86,951.97 376.54,951.65 376.21,951.32 375.89,951 375.24,950.67 374.92,950.67 374.27,950.67 373.95,950.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="372.33,944.85 371.68,944.85 371.36,945.17 371.03,945.5 370.71,946.14 370.71,946.47 370.39,947.11 
	370.39,947.44 370.71,948.09 370.71,948.41 371.03,949.06 371.36,949.38 371.68,949.7 372.01,950.03 372.65,950.35 372.98,950.35 
	373.3,950.35 373.95,950.03 374.27,950.03 374.59,949.7 374.92,949.38 375.24,948.73 375.24,948.41 375.24,947.76 375.24,947.11 
	375.24,946.79 374.92,946.14 374.59,945.82 374.27,945.5 373.95,945.17 373.62,944.85 372.98,944.85 372.65,944.85 372.33,944.85 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="384.3,961.35 383.66,961.68 383.33,962 383.01,962.32 382.68,962.65 382.68,962.97 382.36,963.62 
	382.36,964.27 382.68,964.59 382.68,965.24 383.01,965.56 383.33,966.21 383.66,966.53 383.98,966.53 384.63,966.85 384.95,966.85 
	385.6,966.85 385.92,966.85 386.24,966.53 386.57,966.21 386.89,965.88 387.22,965.56 387.22,964.91 387.54,964.27 387.22,963.94 
	387.22,963.3 386.89,962.97 386.89,962.32 386.57,962 385.92,961.68 385.6,961.35 385.27,961.35 384.63,961.35 384.3,961.35 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="381.71,955.53 381.39,955.85 380.74,956.18 380.42,956.5 380.42,956.82 380.1,957.15 380.1,957.79 
	380.1,958.44 380.1,958.76 380.1,959.41 380.42,959.74 380.74,960.06 381.07,960.71 381.71,960.71 382.04,961.03 382.36,961.03 
	383.01,961.03 383.33,961.03 383.66,960.71 384.3,960.38 384.3,960.06 384.63,959.74 384.95,959.09 384.95,958.44 384.95,958.12 
	384.63,957.47 384.63,957.15 384.3,956.5 383.98,956.18 383.66,955.85 383.01,955.53 382.68,955.53 382.04,955.53 381.71,955.53 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="379.77,949.38 379.45,949.7 379.13,950.03 378.8,950.35 378.48,950.67 378.15,951.32 378.15,951.65 
	378.15,952.29 378.15,952.62 378.48,953.26 378.8,953.59 379.13,954.23 379.45,954.56 379.77,954.88 380.1,954.88 380.74,954.88 
	381.07,954.88 381.71,954.88 382.04,954.56 382.36,954.23 382.68,953.91 383.01,953.59 383.01,952.94 383.01,952.62 383.01,951.97 
	383.01,951.32 382.68,951 382.36,950.35 382.04,950.03 381.71,949.7 381.39,949.7 380.74,949.38 380.42,949.38 379.77,949.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="378.15,943.55 377.51,943.88 377.18,944.2 376.86,944.53 376.54,944.85 376.54,945.5 376.54,945.82 
	376.54,946.47 376.54,946.79 376.54,947.44 376.86,947.76 377.18,948.41 377.51,948.73 377.83,949.06 378.48,949.06 378.8,949.06 
	379.45,949.06 379.77,949.06 380.1,948.73 380.74,948.41 380.74,948.09 381.07,947.76 381.39,947.11 381.39,946.79 381.39,946.14 
	381.07,945.5 381.07,945.17 380.74,944.53 380.42,944.2 379.77,943.88 379.45,943.88 379.13,943.55 378.48,943.55 378.15,943.55 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="390.78,961.03 390.45,961.03 390.13,961.35 389.8,961.68 389.48,962 389.16,962.65 389.16,962.97 
	389.16,963.62 389.16,964.27 389.48,964.59 389.48,965.24 389.8,965.56 390.45,965.88 390.78,966.21 391.1,966.21 391.75,966.53 
	392.07,966.21 392.39,966.21 393.04,965.88 393.36,965.56 393.69,965.24 394.01,964.91 394.01,964.27 394.01,963.94 394.01,963.3 
	394.01,962.97 393.69,962.32 393.36,962 393.04,961.35 392.72,961.35 392.07,961.03 391.75,960.71 391.42,960.71 390.78,961.03 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="388.19,955.2 387.86,955.2 387.54,955.53 387.22,955.85 386.89,956.18 386.57,956.82 386.57,957.15 
	386.57,957.79 386.57,958.44 386.89,958.76 387.22,959.41 387.54,959.74 387.86,960.06 388.19,960.38 388.51,960.38 389.16,960.71 
	389.48,960.38 390.13,960.38 390.45,960.06 390.78,959.74 391.1,959.41 391.42,959.09 391.42,958.44 391.42,958.12 391.42,957.47 
	391.42,957.15 391.1,956.5 390.78,956.18 390.45,955.53 390.13,955.53 389.8,955.2 389.16,954.88 388.83,954.88 388.19,955.2 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="397.25,960.38 396.6,960.38 396.28,960.71 395.95,961.03 395.63,961.68 395.63,962 395.31,962.65 
	395.31,962.97 395.63,963.62 395.63,963.94 395.95,964.59 396.28,964.91 396.6,965.24 396.92,965.56 397.57,965.88 397.89,965.88 
	398.54,965.88 398.87,965.56 399.19,965.56 399.51,965.24 399.84,964.91 400.16,964.27 400.16,963.94 400.48,963.3 400.16,962.97 
	400.16,962.32 399.84,961.68 399.84,961.35 399.19,961.03 398.87,960.71 398.54,960.38 397.89,960.38 397.57,960.38 397.25,960.38 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="394.66,954.56 394.01,954.56 393.69,954.88 393.36,955.2 393.04,955.85 393.04,956.18 393.04,956.82 
	393.04,957.15 393.04,957.79 393.04,958.12 393.36,958.76 393.69,959.09 394.01,959.41 394.33,959.74 394.98,960.06 395.31,960.06 
	395.95,960.06 396.28,959.74 396.6,959.74 397.25,959.41 397.25,959.09 397.57,958.44 397.89,958.12 397.89,957.47 397.89,956.82 
	397.57,956.5 397.57,955.85 397.25,955.53 396.92,955.2 396.28,954.88 395.95,954.56 395.63,954.56 394.98,954.56 394.66,954.56 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="404.04,959.74 403.72,960.06 403.4,960.38 403.07,960.71 402.75,961.03 402.43,961.35 402.43,962 
	402.43,962.65 402.43,962.97 402.75,963.62 402.75,963.94 403.07,964.59 403.4,964.91 404.04,964.91 404.37,965.24 405.01,965.24 
	405.34,965.24 405.66,965.24 406.31,964.91 406.63,964.59 406.96,964.27 406.96,963.94 407.28,963.3 407.28,962.65 407.28,962.32 
	407.28,961.68 406.96,961.35 406.63,960.71 406.31,960.38 405.98,960.06 405.34,960.06 405.01,959.74 404.37,959.74 404.04,959.74 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="401.45,953.91 401.13,954.23 400.81,954.56 400.48,954.88 400.16,955.2 399.84,955.53 399.84,956.18 
	399.84,956.82 399.84,957.15 400.16,957.79 400.16,958.12 400.48,958.76 401.13,959.09 401.45,959.09 401.78,959.41 402.43,959.41 
	402.75,959.41 403.4,959.41 403.72,959.09 404.04,958.76 404.37,958.44 404.69,958.12 404.69,957.47 404.69,956.82 404.69,956.5 
	404.69,955.85 404.37,955.53 404.04,954.88 403.72,954.56 403.4,954.23 403.07,954.23 402.43,953.91 402.1,953.91 401.45,953.91 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="410.19,959.74 409.54,960.06 409.22,960.06 408.9,960.38 408.57,961.03 408.57,961.35 408.25,962 
	408.25,962.32 408.57,962.97 408.57,963.62 408.9,963.94 409.22,964.27 409.54,964.59 409.87,964.91 410.52,965.24 410.84,965.24 
	411.49,965.24 411.81,965.24 412.13,964.91 412.46,964.59 412.78,964.27 413.1,963.62 413.1,963.3 413.43,962.65 413.43,962.32 
	413.1,961.68 412.78,961.03 412.78,960.71 412.46,960.38 411.81,960.06 411.49,959.74 411.16,959.74 410.52,959.74 410.19,959.74 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="407.6,953.91 407.28,954.23 406.63,954.23 406.31,954.56 406.31,955.2 405.98,955.53 405.98,956.18 
	405.98,956.5 405.98,957.15 405.98,957.79 406.31,958.12 406.63,958.44 406.96,958.76 407.6,959.09 407.93,959.41 408.25,959.41 
	408.9,959.41 409.22,959.41 409.54,959.09 410.19,958.76 410.19,958.44 410.52,957.79 410.84,957.47 410.84,956.82 410.84,956.5 
	410.52,955.85 410.52,955.2 410.19,954.88 409.87,954.56 409.54,954.23 408.9,953.91 408.57,953.91 407.93,953.91 407.6,953.91 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="404.04,949.7 404.04,950.35 404.04,951 404.37,951.32 404.37,951.97 404.69,952.29 405.01,952.62 
	405.66,952.94 405.98,953.26 406.31,953.26 406.96,953.26 407.28,953.26 407.6,952.94 408.25,952.62 408.57,952.29 408.57,951.97 
	408.9,951.65 408.9,951 408.9,950.35 408.9,950.03 408.57,949.38 408.57,949.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="387.54,889.19 384.95,888.86 383.01,888.22 383.01,886.6 383.66,884.66 383.66,882.72 383.01,880.77 
	382.68,879.16 382.68,876.57 383.01,875.27 382.36,874.95 382.36,873.98 382.68,873.01 383.33,873.01 383.66,871.71 383.01,871.39 
	382.36,871.39 382.36,870.09 382.68,869.12 383.33,869.12 383.98,868.48 383.66,867.18 382.36,867.18 382.04,866.21 382.36,863.95 
	382.68,862 383.66,862.33 384.3,861.68 384.63,858.44 384.63,856.18 387.22,850.68 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="392.72,858.12 391.75,857.47 391.1,856.18 388.83,856.5 386.24,856.5 383.33,856.5 383.01,857.8 
	382.04,857.8 382.36,855.21 382.36,854.56 383.66,855.53 383.66,855.86 386.89,855.53 390.13,855.53 392.07,855.53 392.39,854.24 
	393.04,854.56 392.72,856.5 392.72,857.8 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="391.75,887.57 392.07,884.98 391.75,884.66 391.42,885.95 389.8,885.95 387.22,885.3 383.98,885.63 
	381.71,885.95 380.74,885.63 380.42,884.33 380.1,886.92 380.42,888.22 381.07,886.92 381.39,886.92 383.33,886.6 387.22,886.6 
	389.48,886.92 391.1,886.92 391.1,888.54 391.75,888.22 392.07,887.57 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="278.81,959.09 278.48,963.62 280.75,967.5 284.63,969.77 288.51,968.47 289.81,965.56 289.81,961.68 
	286.9,957.79 284.95,957.15 279.13,958.12 278.81,960.71 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="262.95,960.71 266.51,965.88 270.07,966.85 273.63,966.53 275.57,964.27 276.54,959.74 274.92,955.53 
	269.74,954.56 265.86,955.2 263.92,958.12 263.6,961.03 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="243.53,956.18 244.83,960.71 249.03,963.62 255.83,964.27 259.06,961.03 259.71,956.18 258.42,951.65 
	252.59,951 248.39,952.29 245.47,953.91 244.18,955.2 243.85,956.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="230.91,949.7 230.91,953.59 235.44,955.85 238.68,955.53 241.91,953.26 243.21,947.76 240.3,944.53 
	235.12,944.2 232.53,945.82 231.23,948.09 230.91,950.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="213.44,939.35 214.41,943.88 217.97,946.14 223.14,946.14 226.06,943.88 226.38,940 224.44,936.11 
	219.91,935.46 215.38,935.79 213.44,938.05 213.44,940.64 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="200.17,927.7 201.14,932.23 204.37,934.49 207.93,934.82 210.52,933.52 211.82,929.96 212.14,925.76 
	208.9,923.49 203.73,922.84 200.81,924.79 200.17,926.4 200.17,928.35 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="190.14,914.11 190.78,918.31 193.05,920.58 198.87,921.55 202.76,919.61 203.4,915.4 200.17,911.19 
	195.96,909.9 193.05,910.87 191.43,912.49 190.46,914.43 190.14,914.75 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="185.93,898.25 185.93,902.78 187.87,904.72 191.75,906.34 196.28,906.02 199.84,903.1 199.2,896.63 
	197.9,894.69 194.02,893.72 190.14,894.37 187.87,895.66 186.58,897.28 185.93,898.25 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="187.87,882.39 187.87,885.95 189.81,887.89 192.72,888.54 195.96,888.86 198.87,887.89 200.17,884.66 
	200.49,881.1 198.87,878.19 196.28,877.21 192.08,877.21 189.81,878.83 188.52,880.45 187.55,882.39 187.55,883.36 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="193.37,861.36 191.75,866.21 193.69,872.04 195.96,873.33 201.46,872.68 204.05,869.77 205.02,864.59 
	202.76,861.03 194.67,860.39 193.37,862 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="202.76,845.5 200.49,850.35 201.79,853.91 205.34,857.15 208.9,858.12 212.46,855.86 213.44,851.65 
	213.44,847.77 209.88,845.82 205.99,844.53 203.4,844.53 201.79,845.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="216.02,833.2 214.08,835.14 213.44,837.09 213.44,839.68 215.05,844.53 218.29,844.85 221.53,844.21 
	223.79,841.62 224.44,838.38 222.17,834.5 218.94,832.88 215.38,833.85 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="228.32,824.79 225.41,828.03 226.06,831.26 228,833.85 232.53,834.5 236.09,832.88 237.38,828.67 
	235.12,824.79 230.59,823.82 228.65,824.14 227.67,825.11 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="241.27,815.73 239.65,818.96 239.32,822.52 243.21,824.79 249.03,825.11 250.97,821.23 250,817.67 
	247.09,815.4 243.21,814.43 241.27,815.4 240.94,816.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="253.89,809.58 252.27,813.46 253.56,817.67 257.12,819.61 263.27,819.93 267.15,812.82 263.6,808.28 
	258.42,807.31 254.86,808.93 253.56,810.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="271.04,807.64 270.07,812.82 272.98,815.73 278.48,816.05 283.01,813.79 283.34,809.58 281.39,806.67 
	275.25,805.7 272.01,805.7 270.71,808.61 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="781.05" y1="868.15" x2="729.6" y2="867.51"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="724.1,867.18 439.32,866.53 434.46,862.65 429.93,864.92 426.37,860.71 411.81,861.03 412.46,877.21 
	412.46,885.3 412.13,892.42 426.05,892.75 430.26,894.69 434.46,892.75 439.64,895.34 727.66,897.93 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="730.89,897.93 780.4,899.22 781.05,868.15 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="425.4" y1="799.87" x2="427.67" y2="965.88"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="525.07,805.7 519.57,805.7 519.57,805.37 519.57,804.72 519.57,803.75 519.25,803.75 518.93,802.78 
	519.25,802.46 519.57,802.46 520.22,802.46 520.54,802.78 520.22,803.11 519.9,803.43 520.22,803.75 520.22,804.4 521.84,805.05 
	522.49,805.05 522.81,804.72 523.46,804.08 523.46,803.11 523.46,801.81 523.13,801.49 523.13,800.52 523.13,800.19 523.13,799.87 
	522.81,800.52 522.49,800.52 522.49,799.87 522.49,797.93 522.49,797.61 522.81,797.28 523.13,797.61 523.13,797.93 523.46,797.28 
	524.1,796.63 523.78,796.31 523.78,795.66 524.1,795.34 524.75,795.66 525.4,795.99 525.4,796.31 525.4,796.63 525.07,796.96 
	525.4,796.96 525.72,797.61 526.05,797.61 526.05,797.28 526.37,797.28 526.37,798.25 526.37,799.55 526.37,800.19 526.05,799.87 
	525.72,799.87 526.37,800.52 526.37,800.84 526.69,801.49 526.69,801.81 526.69,802.78 526.69,803.75 526.69,804.4 527.02,806.34 
	527.02,806.67 527.02,806.99 526.69,807.64 526.69,808.28 526.37,808.28 526.37,807.64 526.37,807.31 525.72,807.64 525.4,807.96 
	525.4,808.61 525.72,809.26 526.05,810.23 526.37,810.55 526.69,810.87 526.69,811.52 526.69,812.17 526.69,812.82 527.02,812.82 
	527.02,813.79 525.07,813.79 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="673.29,804.72 667.79,804.72 667.79,804.4 667.79,803.75 667.79,803.11 667.46,802.78 667.46,802.14 
	667.46,801.81 667.79,801.49 668.43,801.49 668.76,801.81 668.43,802.14 668.11,802.46 668.43,803.11 668.43,803.43 670.05,804.4 
	670.7,804.4 671.02,804.08 671.67,803.43 671.67,802.14 671.67,801.17 671.35,800.52 671.35,799.87 671.35,799.55 671.35,799.22 
	671.02,799.55 670.7,799.55 670.7,798.9 670.7,797.28 670.7,796.63 671.02,796.31 671.35,796.96 671.67,796.31 672.32,795.99 
	672.32,795.66 671.99,795.34 671.99,795.02 671.99,794.69 672.32,794.69 672.97,794.69 673.61,795.02 673.61,795.34 673.61,795.99 
	673.29,795.99 673.61,796.31 673.94,796.96 674.26,796.63 674.58,796.63 674.58,797.61 674.58,798.58 674.58,799.22 674.26,799.22 
	674.26,798.9 674.58,799.55 674.58,799.87 674.91,800.52 674.91,801.17 674.91,801.81 674.91,802.78 674.91,803.75 675.23,805.37 
	675.23,805.7 675.23,806.34 675.23,806.99 674.91,807.31 674.58,807.31 674.58,806.99 674.91,806.67 674.58,806.67 673.94,806.99 
	673.61,806.99 673.61,807.96 673.94,808.61 674.26,809.26 674.58,809.58 674.91,810.23 674.91,810.87 674.91,811.52 674.91,811.84 
	675.23,811.84 675.23,813.14 673.29,812.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="586.56,652.95 597.56,636.12 604.36,632.56 609.54,631.27 616.01,631.59 626.69,632.89 638.34,642.59 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.52,640.01 671.99,635.48 678.14,634.5 685.26,633.86 694.65,634.5 704.03,640.98 710.83,644.54 
	713.42,651.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="732.51,643.89 733.48,642.27 738.34,637.42 743.84,635.48 749.34,632.89 758.08,631.92 765.2,632.56 
	773.93,632.56 781.05,635.48 783.96,639.36 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="806.62,806.67 816.65,807.96 818.27,807.96 822.8,807.64 825.71,807.64 827.65,808.28 828.95,808.28 
	828.95,806.34 830.89,806.02 836.39,809.58 839.3,812.49 841.89,817.67 843.83,822.85 844.8,825.76 845.45,831.58 845.13,836.12 
	841.24,842.26 839.95,845.82 837.36,848.74 835.42,851.65 831.54,852.62 829.27,852.94 827.98,853.27 828.3,852.3 828.3,850.35 
	827.01,851 826.36,851.97 826.36,852.94 827.01,853.91 826.68,854.24 823.45,854.88 818.59,854.88 815.36,855.21 813.09,855.53 
	810.82,855.21 809.85,853.91 808.88,853.91 805.97,854.24 804.68,853.59 803.06,853.27 802.09,852.94 800.79,852.94 797.56,853.59 
	795.61,853.59 794.32,853.59 794.64,863.62 801.12,863.62 803.7,863.62 804.68,863.3 805,862 805.32,861.36 806.94,861.36 
	808.24,861.36 808.88,860.39 809.85,860.06 810.82,859.74 825.39,859.42 827.01,860.06 827.65,860.71 826.68,861.68 827.33,862.33 
	827.98,862.98 828.95,862.33 829.27,861.36 830.56,861.03 833.48,862 834.12,862.65 837.36,863.3 838.98,864.27 841.57,866.53 
	844.16,870.74 845.77,875.92 846.75,878.51 847.39,880.77 847.72,886.6 847.07,889.19 846.1,891.45 844.8,895.01 843.83,897.28 
	840.6,901.16 838.01,903.1 834.77,904.4 830.24,906.34 829.59,905.69 828.95,905.37 827.98,905.04 827.33,905.69 827.65,906.34 
	827.65,907.63 826.03,907.63 822.47,907.63 818.27,907.96 815.03,908.28 812.12,907.63 810.82,907.96 809.53,907.63 808.56,906.66 
	806.29,906.99 803.38,907.31 803.06,906.66 802.41,906.66 798.53,906.02 797.56,906.02 795.61,906.34 794.64,907.31 794.64,917.67 
	804.68,916.69 806.62,916.69 808.88,916.05 809.85,915.08 810.18,914.11 810.18,913.46 810.82,913.14 813.74,913.46 822.47,912.81 
	825.06,912.81 827.98,912.81 829.59,913.14 829.27,913.46 829.27,914.11 830.24,914.11 831.21,913.46 831.86,912.16 833.15,913.14 
	836.39,914.43 840.27,916.37 843.19,918.64 846.1,921.23 847.72,924.46 849.01,927.05 850.31,930.93 850.31,936.11 850.31,939.02 
	849.33,942.26 848.69,945.82 846.1,949.38 843.51,951.32 839.95,953.91 837.04,956.18 834.45,958.12 833.15,959.09 832.51,956.82 
	831.86,957.15 831.54,958.76 827.65,959.41 819.89,958.76 814.06,959.74 809.53,960.71 805.32,960.71 805.97,959.09 799.17,959.41 
	795.61,958.76 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="795.61,958.44 795.61,971.71 805.97,972.36 805.97,970.74 806.62,970.74 810.18,970.41 825.39,971.71 
	828.3,971.71 831.54,972.36 833.48,973 833.48,964.27 847.72,963.62 871.66,962 907.91,959.74 929.27,959.09 930.56,957.79 
	930.88,956.18 931.86,956.18 932.5,956.82 933.15,956.82 941.56,956.18 947.71,954.88 971.01,947.11 978.78,943.23 984.6,937.73 
	990.11,934.17 997.87,928.35 1003.7,919.28 1009.2,907.96 1016.32,896.31 1018.26,892.42 1020.2,882.72 1021.82,874.3 
	1021.82,872.36 1018.26,865.56 1010.17,855.86 1005.96,851.33 999.17,844.85 993.67,838.38 987.19,830.94 978.78,822.52 
	973.93,817.99 966.48,813.79 957.42,809.58 949.33,807.31 940.59,805.37 928.62,804.08 918.91,804.4 908.88,804.4 895.93,803.43 
	879.75,803.43 873.61,803.11 866.81,803.11 857.1,803.75 850.31,803.43 839.3,803.11 827.33,802.78 827.65,797.93 827.65,794.37 
	827.01,792.1 824.74,791.46 823.77,791.13 822.15,793.07 817.94,793.07 816,793.07 813.09,793.4 804.35,794.69 803.38,792.75 
	792.7,793.4 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="873.61" y1="803.11" x2="877.49" y2="961.35"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="860.98" y1="962.65" x2="857.1" y2="804.08"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="859.69,808.93 869.4,808.93 870.05,809.58 870.69,810.55 870.69,811.84 870.69,813.14 870.69,814.43 
	870.05,815.08 869.72,815.73 860.01,815.73 859.69,808.61 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="859.69,816.38 869.72,816.38 870.05,817.02 870.69,818.32 871.02,819.61 870.69,820.91 870.69,822.2 
	870.37,822.85 870.05,823.17 860.01,823.17 859.69,816.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="860.01,824.14 870.05,824.14 870.37,824.79 871.02,826.08 871.34,827.38 871.02,828.67 871.02,829.97 
	870.37,830.61 870.37,830.94 860.34,830.94 860.01,824.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="860.34,831.91 870.05,831.91 870.69,832.56 871.02,833.53 871.34,834.82 871.34,836.12 871.02,837.41 
	870.69,838.06 870.37,838.7 860.34,838.7 860.34,831.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="860.66,839.35 870.37,839.35 871.02,840 871.34,841.29 871.66,842.59 871.66,843.88 871.34,845.18 
	871.02,845.82 870.69,846.15 860.66,846.15 860.66,839.35 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="860.66,847.77 870.37,847.44 871.02,848.41 871.66,849.38 871.66,850.68 871.66,851.97 871.34,853.27 
	871.02,853.91 870.69,854.24 860.98,854.56 860.66,847.44 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="860.98,857.47 870.69,857.47 871.34,858.12 871.99,859.42 871.99,860.71 871.99,862 871.66,863.3 
	871.34,863.95 871.02,864.27 861.31,864.27 860.98,857.47 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="861.31,864.92 871.02,864.92 871.66,865.56 871.99,866.86 872.31,868.15 872.31,869.45 871.99,870.74 
	871.66,871.39 871.34,871.71 861.31,871.71 861.31,864.92 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="861.63,872.36 871.34,872.36 871.99,873.01 872.31,874.3 872.63,875.6 872.63,876.57 872.31,878.19 
	871.99,878.83 871.66,879.16 861.63,879.16 861.63,872.36 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="861.96,879.8 871.66,879.8 872.31,880.45 872.63,881.74 872.96,883.04 872.96,884.01 872.63,885.63 
	872.31,886.28 871.99,886.6 861.96,886.6 861.96,879.8 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="861.63,887.57 871.34,887.25 871.99,888.22 872.63,889.19 872.63,890.48 872.63,891.78 872.31,893.07 
	871.99,893.72 871.66,894.04 861.96,894.37 861.63,887.25 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="861.96,895.66 871.66,895.66 872.31,896.31 872.63,897.28 872.96,898.57 872.96,899.87 872.63,901.16 
	872.31,901.81 871.99,902.46 861.96,902.46 861.96,895.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="862.28,913.14 871.99,912.81 872.63,913.46 873.28,914.75 873.28,916.05 873.28,917.34 872.96,918.64 
	872.63,919.28 872.31,919.61 862.6,919.93 862.28,912.81 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="862.28,920.9 871.99,920.9 872.63,921.55 873.28,922.84 873.28,924.14 873.28,925.43 872.96,926.73 
	872.63,927.37 872.31,927.7 862.6,927.7 862.28,920.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="862.28,928.67 872.31,928.67 872.63,929.32 873.28,930.61 873.61,931.9 873.28,932.88 873.28,934.49 
	872.96,935.14 872.63,935.46 862.6,935.46 862.28,928.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="862.6,936.11 872.31,936.11 872.96,936.76 873.61,938.05 873.61,939.35 873.61,940.64 873.28,941.94 
	872.96,942.58 872.63,942.91 862.93,942.91 862.6,936.11 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="862.6,943.88 872.31,943.88 872.96,944.53 873.61,945.82 873.61,947.11 873.61,948.41 873.28,949.7 
	872.96,950.35 872.63,950.67 862.93,950.67 862.6,943.88 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="862.6,951.97 872.63,951.97 872.96,952.62 873.61,953.59 873.93,954.88 873.61,956.18 873.61,957.79 
	873.28,958.44 872.96,958.76 862.93,958.76 862.6,951.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="861.96,903.1 871.66,902.78 872.31,903.75 872.63,904.72 872.96,906.02 872.96,907.31 872.63,908.6 
	872.31,909.25 871.99,909.58 861.96,909.9 861.96,902.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="854.51,806.34 838.66,806.34 845.77,812.82 848.69,817.35 849.98,820.58 850.31,824.14 850.63,827.7 
	850.63,830.94 850.63,834.82 849.98,838.06 849.33,840.32 847.72,844.21 845.77,847.77 844.16,850.35 842.21,852.3 838.98,854.56 
	837.68,854.88 848.36,855.21 848.36,853.91 854.84,853.59 854.51,806.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="838.33,858.77 848.36,858.44 848.36,859.09 854.51,859.42 855.81,906.02 849.98,906.66 849.98,907.96 
	839.95,908.28 847.07,900.19 851.6,890.48 852.25,884.66 851.92,879.48 851.6,875.92 850.31,872.04 849.01,869.45 846.42,865.89 
	843.51,862.33 838.01,858.77 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="855.81,911.84 850.31,911.84 849.98,910.87 839.63,911.19 847.72,918.64 850.95,923.81 851.6,925.11 
	853.22,930.93 853.54,934.49 853.54,937.41 853.54,940.64 853.22,945.5 851.92,949.38 850.95,951 848.36,953.91 846.42,955.85 
	844.48,957.79 842.86,959.09 839.95,960.71 858.72,959.74 855.81,911.84 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="874.9,849.06 880.08,849.06 881.37,851 881.37,864.59 875.22,864.92 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="881.37" y1="851" x2="874.9" y2="851.33"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="881.37,860.39 884.93,860.39 884.93,856.5 884.61,856.5 884.61,860.06 881.37,860.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="877.17,920.58 888.82,920.25 890.43,921.55 891.08,923.49 891.4,926.08 891.4,926.73 891.08,928.67 
	890.76,930.61 890.43,931.58 888.49,931.9 876.84,932.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="880.08,949.38 884.28,955.85 934.77,953.59 940.27,951.65 945.77,950.03 948.68,948.73 950.63,947.44 
	949.01,946.14 944.48,945.17 939.3,945.17 934.12,945.82 879.11,948.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="882.34,952.94 925.06,949.7 927,949.06 928.62,948.41 927.97,947.44 926.35,947.11 922.79,946.79 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="925.06,949.7 928.62,949.7 931.21,950.67 931.21,951.97 930.24,952.94 928.62,953.26 927.33,953.91 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="943.51,949.06 944.8,948.41 943.83,947.44 941.89,946.79 937.36,947.11 935.42,948.09 934.44,949.7 
	934.77,951.32 938,951.65 941.24,951.32 944.15,950.03 944.48,948.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="878.14,811.52 880.08,805.05 930.88,806.34 940.59,806.99 944.8,808.28 946.09,809.26 945.77,810.55 
	943.18,811.2 939.3,811.52 937.03,811.84 932.5,811.84 904.02,812.17 899.82,812.17 890.43,811.2 877.81,811.84 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="879.11,807.96 898.52,808.93 922.79,809.58 925.71,809.26 928.62,808.28 928.62,806.99 927.97,806.34 
	926.03,806.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="922.79,809.26 925.06,809.26 926.35,809.9 926.35,810.87 926.03,811.52 924.09,811.84 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="917.29,803.11 919.56,802.14 919.56,801.17 918.59,800.52 917.29,799.87 915.35,799.22 912.12,799.55 
	909.85,799.87 909.2,802.14 910.17,802.78 912.44,803.11 914.06,803.11 916,803.11 917.62,803.11 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="873.93,824.47 885.58,824.14 887.2,825.11 887.84,827.38 888.17,829.64 888.17,830.61 888.17,832.56 
	887.84,834.17 887.2,835.14 885.58,835.47 874.58,836.12 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="897.55,811.84 898.52,846.15 899.49,846.47 900.14,847.44 900.47,848.74 900.79,850.68 900.79,853.59 
	900.79,855.53 900.79,857.15 899.82,859.74 899.17,860.39 900.47,900.19 901.76,903.43 902.41,904.72 902.41,906.34 902.73,908.28 
	902.41,910.22 902.41,912.16 901.76,914.11 901.44,915.08 900.47,915.4 902.08,947.76 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="902.73,812.17 904.02,845.5 905,847.77 905.32,849.38 905.32,851 905.64,853.27 905.64,855.21 
	905.32,857.8 905,859.42 904.02,860.71 905.32,900.51 905.97,902.13 906.61,903.43 906.94,906.34 906.94,908.28 906.94,909.58 
	906.61,911.84 906.61,913.14 906.61,914.11 906.29,915.4 905.32,916.05 906.61,947.44 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="905.32,915.72 904.67,915.4 904.02,915.08 903.7,914.75 903.38,914.75 902.73,914.75 901.44,914.75 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="905.32,900.51 904.67,900.84 904.02,901.16 903.7,901.49 903.38,901.49 902.73,901.81 900.79,901.16 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="903.38,901.49 904.02,903.1 904.67,904.72 905,906.66 905,907.96 904.67,909.58 904.67,911.19 
	904.02,913.46 903.7,914.75 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="904.02,860.71 903.05,860.71 902.41,860.39 902.08,859.74 901.44,860.06 900.79,860.06 900.14,860.39 
	899.49,860.39 899.17,860.71 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="904.02,844.85 903.05,845.18 902.08,845.82 901.76,846.15 901.11,846.47 900.79,846.47 899.82,846.15 
	898.85,846.15 898.52,846.15 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="901.11,846.47 901.76,847.77 902.41,849.38 902.73,851.65 902.73,853.91 902.73,856.18 902.41,857.8 
	902.08,859.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="897.55,812.17 889.79,817.02 891.08,840.97 893.02,841.62 894.64,841.62 895.93,841.94 897.23,843.23 
	898.2,843.56 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="900.47,914.75 899.49,916.37 897.88,917.34 895.93,917.67 893.35,917.67 892.37,917.67 893.67,938.7 
	902.41,947.76 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="893.67" y1="938.7" x2="876.52" y2="939.35"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="892.7" y1="917.67" x2="876.19" y2="918.31"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="891.08" y1="840.97" x2="874.58" y2="841.94"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="889.46" y1="817.35" x2="873.93" y2="817.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="902.73,812.82 944.48,812.17 953.86,813.79 944.8,815.08 950.95,817.35 955.48,817.35 962.92,818.64 
	955.16,819.29 950.3,819.61 945.45,821.55 954.18,823.49 945.12,824.14 903.05,824.79 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="938.65,817.67 940.59,818.32 938.33,819.29 938.65,820.91 937.03,820.26 935.74,821.55 935.74,819.61 
	933.47,818.64 935.42,817.67 935.74,815.73 936.71,816.7 938.33,815.73 938.65,817.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="942.86,814.76 943.18,822.52 932.18,822.52 931.86,814.76 942.86,814.76 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="927,816.7 927,820.26 904.67,820.91 904.67,816.7 927,816.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="903.7,844.53 931.53,843.88 942.21,844.85 931.21,846.47 904.67,846.79 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="904.67,859.42 932.83,859.09 943.83,859.74 932.18,861.36 904.02,862 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="904.02,867.18 932.83,866.53 943.83,867.18 932.18,868.8 904.02,869.45 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="928.94" y1="861.36" x2="928.94" y2="866.86"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="922.47,861.36 922.79,862 923.12,862.33 923.44,862.98 923.44,863.62 923.44,863.95 923.12,864.59 
	923.12,865.24 922.79,865.89 922.79,866.53 922.47,866.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="906.29,935.46 947.71,934.17 957.1,934.17 948.04,936.44 954.18,937.73 958.72,937.73 966.16,939.02 
	958.72,939.67 953.54,940 948.68,941.94 957.42,943.55 948.36,944.53 906.29,945.17 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="941.56,938.7 943.83,939.35 941.56,940.32 941.89,941.94 940.27,941.29 938.98,942.58 938.98,940.64 
	936.71,940 938.65,938.7 938.98,936.76 939.95,937.73 941.56,936.76 941.56,938.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="946.09,935.79 946.09,943.88 935.09,943.88 935.09,935.79 946.09,935.79 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="930.24,936.76 930.24,940.64 907.91,940.97 907.91,937.08 930.24,937.08 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="905.64,889.84 933.8,889.19 944.8,889.84 933.15,891.45 905,892.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="905,897.6 933.8,896.95 944.8,897.6 933.15,899.22 905,899.87 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="929.91" y1="891.78" x2="929.91" y2="896.95"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="922.47,891.78 923.12,892.42 923.44,892.75 923.44,893.39 923.44,894.04 923.44,894.37 923.44,895.01 
	923.44,895.66 923.12,896.31 923.12,896.63 922.47,897.28 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="905,912.81 932.5,912.16 943.51,913.46 932.5,914.75 905.97,915.4 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="904.02,876.24 911.14,875.92 911.47,886.6 904.67,886.92 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="909.2,881.1 910.82,881.42 909.2,882.39 909.53,883.36 908.23,883.04 907.26,884.01 907.26,882.39 
	905.64,882.07 906.94,881.1 907.26,879.48 907.91,880.45 909.2,879.8 909.2,881.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="960.66,825.11 960.66,826.41 960.33,827.05 959.36,827.38 910.5,827.7 904.02,826.73 904.02,826.41 
	910.82,825.44 960.66,824.79 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="956.77,827.7 956.77,829.97 954.18,829.97 910.17,830.29 904.02,829.64 904.02,829 911.47,828.03 
	956.77,827.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="952.57,830.29 952.89,831.26 952.89,832.23 952.24,832.56 910.5,832.88 904.02,832.23 904.02,831.91 
	904.35,831.58 910.82,830.61 952.57,830.29 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="949.33,832.88 949.65,833.85 949.33,834.5 948.68,834.5 910.5,835.14 904.35,834.5 904.02,834.17 
	904.35,833.85 910.82,833.2 949.33,832.88 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="946.42,835.14 946.74,836.12 946.74,836.76 946.09,836.76 910.82,837.41 904.35,836.76 904.35,836.44 
	904.35,836.12 910.82,835.47 946.09,834.82 946.42,835.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="942.86,837.09 943.18,837.73 943.18,838.06 942.86,838.06 942.53,838.7 910.82,839.35 904.35,839.03 
	904.35,838.38 911.47,837.73 942.86,837.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="940.27,839.03 940.27,839.35 940.27,840 940.27,840.32 939.95,840.65 911.14,840.97 904.35,840.65 
	904.35,840.32 904.35,840 911.14,839.68 940.27,839.03 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="937.68,840.65 937.68,841.29 937.68,841.94 937.68,842.26 937.36,842.26 911.14,842.91 904.35,842.59 
	904.67,841.94 911.14,841.29 937.36,840.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="935.09,842.59 935.09,843.23 935.09,843.88 910.82,844.21 904.67,844.21 904.67,843.56 910.82,842.91 
	935.09,842.59 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="942.53,825.11 938.65,828.35 937.68,829.32 936.71,830.94 935.42,833.2 934.44,835.79 933.8,838.38 
	932.83,841.29 932.5,843.23 932.5,843.88 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="930.88" y1="844.21" x2="929.91" y2="824.79"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="932.83,846.79 933.8,848.41 934.12,849.38 934.44,851 934.77,852.62 934.44,855.21 934.44,856.83 
	933.15,858.77 905.97,859.09 905.97,858.77 911.14,858.77 905.97,858.44 905.97,858.12 910.82,858.12 906.29,857.8 906.29,857.47 
	910.82,857.15 906.29,856.83 906.29,856.5 911.14,855.86 934.44,855.53 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="910.5" y1="857.15" x2="934.44" y2="856.5"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="910.82" y1="857.8" x2="934.12" y2="857.47"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="910.82" y1="858.77" x2="933.47" y2="858.12"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="911.14,855.86 906.29,855.53 905.97,855.21 906.29,854.88 910.5,854.24 934.77,853.59 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="910.82,854.24 905.97,853.59 906.29,852.94 906.29,852.62 909.85,852.3 934.44,851.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="934.44,851.33 910.17,851.97 905.97,851.97 905.97,851 910.17,850.35 934.12,849.71 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="910.17,850.35 905.97,850.35 905.97,849.71 910.82,849.06 905.64,849.06 905.64,848.74 910.82,848.09 
	905.64,848.09 905.64,847.77 905.64,847.44 932.83,847.12 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="911.14" y1="848.09" x2="933.47" y2="847.77"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="910.82" y1="849.06" x2="933.8" y2="848.74"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="926.35,846.47 927.65,848.09 927.97,851.65 928.3,853.59 928.3,855.53 927.97,856.5 927.33,858.12 
	926.35,859.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="922.79,859.09 924.41,858.44 925.06,857.8 926.03,856.83 926.68,855.53 927,854.24 927,852.62 
	927,851 926.68,850.03 926.35,849.06 925.06,848.41 923.12,847.12 921.82,846.15 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="943.51" y1="859.74" x2="955.48" y2="859.74"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="952.24,859.74 953.21,859.09 953.54,858.77 953.86,859.42 954.18,859.74 953.54,860.39 953.21,860.39 
	952.24,859.74 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M951.6,863.46c0-2.06-1.67-3.72-3.72-3.72c-2.06,0-3.72,1.67-3.72,3.72c0,2.06,1.67,3.72,3.72,3.72
	C949.93,867.18,951.6,865.52,951.6,863.46"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="944.15" y1="867.18" x2="955.8" y2="867.18"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="943.83" y1="859.74" x2="943.83" y2="867.18"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="944.8" y1="859.74" x2="944.8" y2="867.18"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="943.83,863.62 953.21,863.62 953.21,863.3 953.54,863.3 953.54,862.65 953.86,863.3 954.83,863.3 
	953.86,863.62 954.18,864.27 953.54,863.95 952.89,864.27 953.21,863.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="948.36,862.98 949.33,862.65 949.33,863.95 948.68,863.95 948.68,864.59 947.39,864.59 947.71,863.62 
	947.07,863.95 947.07,862.98 947.71,862.98 947.71,862.33 948.68,862.33 948.36,863.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="948.04,862.33 948.04,860.06 948.04,859.42 948.36,858.77 948.68,858.77 949.01,859.09 948.68,859.09 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="948.04,859.42 948.04,858.77 947.39,858.44 947.07,858.77 947.07,859.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="948.36,864.59 948.36,866.86 948.68,867.83 948.68,868.15 949.01,867.83 949.01,867.51 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="948.36,867.18 948.36,868.15 948.04,868.48 947.39,867.83 947.71,867.83 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="954.83,867.18 953.54,866.53 952.89,867.18 953.86,867.83 954.51,867.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="944.8" y1="889.84" x2="956.77" y2="889.84"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="953.54,889.84 954.51,889.19 954.83,888.86 955.16,889.19 955.48,889.84 954.51,890.48 953.54,889.84 
	"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M952.89,893.23c0-2.06-1.67-3.72-3.72-3.72s-3.72,1.67-3.72,3.72s1.67,3.72,3.72,3.72
	S952.89,895.29,952.89,893.23"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="944.8,889.84 945.12,897.28 957.1,897.28 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="945.77" y1="889.84" x2="946.09" y2="897.28"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="945.12,893.72 954.51,893.39 954.18,893.39 954.83,893.39 954.83,892.75 954.83,893.39 956.13,893.39 
	954.83,893.72 955.16,894.37 954.51,894.04 953.86,894.37 954.51,893.39 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="949.65,893.07 950.3,892.75 950.3,894.04 949.65,894.04 949.65,894.69 948.68,894.69 949.01,893.72 
	948.04,894.04 948.04,893.07 949.01,893.07 949.01,892.42 949.65,892.42 949.65,893.07 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="949.33,892.42 949.33,890.16 949.33,889.51 949.33,888.86 949.98,888.86 950.3,889.19 949.98,889.19 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="949.33,889.51 949.01,888.86 948.68,888.54 948.04,888.86 948.04,889.19 948.36,889.19 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="949.33,894.69 949.65,896.95 949.65,897.93 949.98,897.93 950.3,897.93 950.3,897.6 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="949.65,897.28 949.33,898.25 949.01,898.57 948.68,897.93 948.68,897.6 949.01,897.6 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="955.8,897.28 954.83,896.31 953.86,897.28 954.83,897.93 955.8,897.28 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="962.92,932.55 962.92,931.26 962.6,930.29 961.63,930.29 912.76,932.88 906.61,933.85 906.29,934.17 
	913.41,935.14 962.92,932.88 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="959.04,929.96 958.72,927.7 956.45,928.02 912.44,930.29 906.29,930.93 906.29,931.58 913.73,932.23 
	959.04,929.96 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="954.83,927.7 955.16,926.73 954.83,925.76 954.18,925.43 912.44,927.37 906.29,928.35 906.29,928.67 
	906.29,928.99 913.09,929.64 954.83,927.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="951.27,925.43 951.6,924.14 951.27,923.81 950.63,923.81 912.44,925.11 906.29,926.08 906.29,926.73 
	912.76,927.05 951.27,925.43 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="948.36,923.49 948.36,923.17 948.36,922.52 948.36,921.55 948.04,921.55 912.76,922.84 905.97,923.81 
	906.29,924.46 912.44,924.79 948.04,923.49 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="944.8,921.55 944.8,920.9 944.8,920.58 944.48,920.25 944.15,919.93 912.44,921.23 906.29,921.87 
	906.29,922.2 906.29,922.52 913.09,922.84 944.8,921.55 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="941.89,919.61 941.89,919.28 941.89,918.64 941.89,918.31 941.56,918.31 912.76,919.28 905.97,919.93 
	905.97,920.25 905.97,920.58 913.09,920.58 941.89,919.61 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="938.98,917.99 939.3,917.67 939.3,917.02 938.98,916.69 912.76,917.67 905.97,918.31 906.29,918.96 
	912.76,918.96 938.98,917.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="936.39,916.37 936.71,916.05 936.39,915.08 912.44,916.05 905.97,916.69 905.97,917.02 912.44,917.34 
	936.39,916.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="945.12,933.52 940.92,930.61 939.62,929.32 938.65,928.02 937.36,925.76 936.39,923.17 935.42,920.9 
	934.44,917.99 933.8,915.72 934.12,915.08 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="934.44,911.84 935.42,910.22 935.74,909.25 935.74,907.63 936.06,906.02 935.74,903.43 935.42,901.81 
	934.12,899.87 906.94,901.16 906.94,901.49 907.26,901.49 912.12,901.49 906.94,901.81 906.94,902.13 911.79,902.13 907.26,902.46 
	907.26,902.78 907.58,902.78 911.79,902.78 907.26,903.43 907.26,903.75 912.44,904.07 935.74,903.1 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="911.47" y1="902.78" x2="935.42" y2="902.13"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="911.79" y1="902.13" x2="935.09" y2="901.49"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="911.79" y1="901.16" x2="934.44" y2="900.51"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="912.12,904.07 907.26,904.72 907.26,905.04 907.58,905.37 911.79,905.69 936.06,905.04 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="912.12,905.69 907.26,906.66 907.58,907.31 907.58,907.63 911.47,907.63 935.74,906.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="935.74,907.31 911.47,908.28 907.58,908.28 907.58,909.25 911.47,909.58 935.74,908.93 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="911.47,909.58 907.26,909.9 907.26,910.55 912.44,910.87 907.26,911.19 907.26,911.52 912.44,911.84 
	907.26,912.16 907.26,912.49 907.26,912.81 934.44,911.84 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="912.44" y1="911.84" x2="935.09" y2="910.87"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="912.12" y1="910.87" x2="935.42" y2="909.9"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="928.3,915.08 929.27,913.46 929.59,909.9 929.59,907.96 929.59,906.02 929.27,904.72 928.62,903.43 
	927.65,902.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="923.77,902.78 925.71,903.1 926.35,903.75 927.33,904.72 927.97,906.02 928.3,907.31 928.62,908.93 
	928.62,910.55 928.3,911.52 927.97,912.49 926.68,913.46 925.06,914.75 923.44,915.4 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="976.51,877.54 973.28,863.95 970.04,856.5 967.45,848.41 963.89,842.26 960.98,837.73 952.89,831.26 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="976.84,876.89 972.95,889.19 967.45,903.1 963.57,911.84 960.66,916.37 957.1,921.55 952.57,925.76 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="997.55,879.48 995.28,868.15 987.52,853.27 981.37,845.18 976.19,840.32 967.78,836.12 961.3,832.88 
	956.45,829.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="996.9,879.48 988.81,893.72 983.63,906.02 980.4,909.9 975.22,916.37 969.39,924.79 963.25,931.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="996.58,877.54 1000.14,866.86 999.17,856.5 996.58,848.09 993.02,839.03 996.9,881.1 1001.76,894.37 
	1000.79,912.81 995.61,924.79 990.43,933.52 982.99,938.05 971.34,946.79 968.42,948.41 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="932.18,868.8 938.33,868.48 933.47,872.36 932.18,874.95 930.88,878.51 930.24,879.8 930.56,882.72 
	932.18,885.3 933.47,887.25 938.33,889.51 938.98,889.84 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="928.62" y1="889.19" x2="928.3" y2="868.8"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="939.95,868.48 940.27,869.12 940.27,869.77 912.76,870.74 905,870.74 905,870.42 913.09,869.45 
	940.27,868.48 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="922.15,862 922.47,862.33 922.15,862.65 909.85,862.98 905.32,862.98 905,862.65 910.5,862.33 
	922.15,862 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="922.47,862.65 922.79,863.62 922.47,863.95 908.56,863.95 905,863.95 905,863.62 909.85,862.98 
	922.47,862.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="923.12,863.95 922.79,864.59 922.47,864.92 908.56,865.24 905,865.24 905,864.92 908.88,864.27 
	922.79,863.95 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="922.79,865.24 922.79,865.56 922.47,865.89 921.82,865.89 908.88,866.21 905.32,866.21 905.32,865.89 
	909.53,865.56 922.47,864.92 922.79,865.24 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="922.47,866.21 922.47,866.53 922.15,866.53 909.2,866.86 905,866.86 905,866.53 909.53,866.21 
	922.15,866.21 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="905.64,871.71 912.44,871.07 940.92,870.09 941.89,870.42 941.89,870.74 941.56,871.07 911.47,872.36 
	905.64,872.04 905.64,871.71 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="905.32,873.01 912.76,872.36 943.18,871.39 944.15,871.71 943.83,872.68 942.86,873.01 941.24,872.68 
	911.47,873.65 905.32,873.65 905.32,873.33 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="905.32,874.63 911.79,873.98 945.45,873.01 946.09,873.33 946.09,873.65 945.77,874.3 944.48,874.3 
	910.82,875.27 905.32,875.27 905.32,874.63 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="911.47,876.24 916.65,875.6 946.74,874.63 948.04,874.95 948.36,875.27 948.04,875.92 946.42,875.92 
	916.65,876.89 911.47,876.89 911.47,876.24 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="911.79,878.19 916.32,877.54 949.98,876.24 950.63,876.57 950.63,877.21 950.3,877.86 916,878.83 
	911.47,878.51 911.47,877.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="952.89,878.19 953.54,879.16 952.89,879.8 916.97,881.1 911.79,880.45 911.47,880.13 911.47,879.48 
	918.59,879.16 952.89,878.19 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="940.59,890.16 940.92,889.84 940.59,889.19 913.41,889.51 905.64,890.16 905.64,890.48 913.73,890.81 
	940.92,890.16 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="922.47,896.95 922.79,896.63 922.47,896.31 910.17,896.63 905.64,896.95 905.32,897.28 911.14,897.28 
	922.47,896.95 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="922.79,896.31 923.12,895.34 922.79,895.01 908.88,895.66 905.32,895.98 905.32,896.31 910.17,896.63 
	922.79,896.31 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="923.12,895.01 923.12,894.37 922.79,894.04 908.88,894.37 905.32,894.69 905.32,895.01 909.2,895.34 
	923.12,895.01 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="922.79,893.72 923.12,893.39 922.79,893.07 921.82,893.07 909.2,893.39 905.32,893.72 905.32,894.04 
	909.53,894.37 922.79,894.04 923.12,893.72 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="922.47,892.75 922.15,892.42 909.53,892.75 905.32,893.07 905.32,893.39 909.85,893.39 922.47,892.75 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="905.97,889.19 912.76,889.51 941.24,888.54 942.53,888.22 941.89,887.57 911.79,888.22 905.97,888.54 
	905.97,888.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="905.64,887.57 913.09,887.89 943.83,887.25 944.48,886.92 944.15,885.95 943.18,885.95 941.89,885.95 
	911.79,886.92 905.64,887.25 905.64,887.57 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="905.64,885.95 912.12,886.28 946.09,885.63 946.42,885.3 946.42,884.66 946.09,884.33 944.8,884.33 
	911.14,884.98 905.64,885.63 905.64,885.95 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="911.79,884.01 916.97,884.66 947.07,883.69 948.36,883.69 948.68,883.04 948.36,882.72 946.74,882.72 
	916.65,883.36 911.79,883.69 911.79,884.33 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="911.79,882.39 916.32,882.72 950.3,882.07 950.63,881.74 950.95,881.1 950.3,880.45 916,881.1 
	911.47,881.74 911.79,882.39 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="507.6,714.44 460.35,715.41 459.7,715.41 459.38,714.76 460.03,714.44 460.03,714.11 459.38,713.47 
	459.06,713.47 458.41,714.11 458.73,714.76 458.09,715.08 457.76,716.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="507.92,708.29 459.06,709.26 458.73,708.94 458.41,708.61 459.06,707.96 458.41,707.32 458.09,707.32 
	457.44,707.96 457.44,708.61 457.12,708.94 456.79,710.23 456.47,711.52 456.79,712.17 457.12,714.11 457.44,715.08 457.76,715.73 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="457.76,716.38 457.44,717.03 457.12,718 457.44,719.29 457.44,720.26 457.44,721.56 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="567.14,715.08 566.5,714.44 566.82,713.47 566.82,713.14 567.14,712.82 567.14,712.5 566.5,712.17 
	565.85,711.85 565.53,712.5 565.85,713.14 565.53,713.47 565.53,714.11 516.66,714.44 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="566.82,712.17 567.14,712.17 567.14,711.52 567.14,710.88 567.47,710.23 567.47,709.26 567.47,708.29 
	566.82,707.32 567.14,706.67 567.14,706.35 567.47,706.02 567.47,705.38 566.82,705.05 566.17,705.05 565.85,705.7 566.17,706.02 
	565.85,706.35 565.85,707.32 516.66,706.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="566.82,715.08 567.14,716.06 566.82,717.35 566.82,717.67 566.82,718 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="460.68,992.42 460.35,985.95 485.59,986.27 485.27,991.77 460.35,992.42 459.38,992.74 459.38,997.27 
	460.35,996.95 487.54,997.27 486.89,992.74 485.92,992.1 484.95,992.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="460.03,996.63 460.03,993.71 485.27,993.07 486.89,994.04 486.56,996.63 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="460.35,985.95 459.38,986.27 460.03,987.89 460.03,988.86 460.03,990.16 459.38,992.1 459.06,992.42 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="485.59,986.27 486.24,986.92 485.92,988.21 486.24,991.13 486.56,992.42 486.56,992.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="463.26,992.42 462.62,989.51 467.15,989.83 467.47,992.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="469.74,992.42 469.74,990.16 475.56,989.83 475.56,991.77 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="477.18,992.1 477.18,989.83 483.33,989.83 483,992.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="447.08,975.27 447.41,979.48 444.82,980.77 444.17,997.6 448.05,1006.34 462.62,1016.69 
	472.33,1019.28 481.39,1018.31 491.74,1016.04 495.95,1015.07 499.19,1011.51 502.1,1007.31 504.04,1002.78 503.39,1000.83 
	504.04,999.22 505.98,992.1 506.95,994.04 508.25,993.71 507.28,979.48 505.66,978.83 504.69,976.89 505.01,967.18 509.86,965.24 
	515.37,966.21 519.25,969.12 518.28,976.56 516.34,978.18 516.34,979.8 516.34,994.04 516.66,996.3 518.6,996.3 518.28,995.01 
	519.57,995.01 520.54,995.33 520.54,997.27 520.87,1000.19 520.87,1004.07 520.87,1005.69 526.05,1011.84 531.22,1018.96 
	540.93,1022.19 549.02,1022.52 555.82,1022.19 560.67,1019.93 565.2,1016.69 569.41,1014.75 573.94,1010.54 574.26,1007.63 
	574.91,1004.72 574.26,1004.72 573.62,1002.78 575.88,1002.45 578.47,978.83 576.53,978.83 576.85,971.39 588.83,970.74 590.44,973 
	589.8,979.48 588.83,982.06 587.86,982.39 589.15,1001.81 589.8,1002.13 591.74,1002.45 591.74,1004.07 590.12,1004.39 
	589.8,1006.01 591.09,1009.57 592.71,1011.84 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="517.95,1012.81 530.25,1023.81 540.61,1027.05 548.7,1027.69 553.23,1027.37 559.05,1026.72 
	562.29,1024.78 567.79,1022.19 572,1018.96 574.59,1016.04 577.18,1011.51 578.15,1018.31 577.5,1028.67 517.95,1027.69 
	517.95,1013.13 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="506.3,1012.81 506.3,1027.37 442.23,1025.75 441.58,1010.22 452.59,1018.63 460.68,1022.52 
	467.79,1024.13 473.62,1024.78 478.15,1024.13 487.21,1021.87 493.36,1020.25 499.19,1016.69 506.95,1012.16 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="517.31,1028.34 518.28,1028.67 518.6,1029.64 519.9,1029.31 520.54,1029.96 519.25,1030.93 
	518.28,1031.58 517.95,1036.11 518.93,1043.55 518.93,1043.87 518.93,1044.52 518.6,1044.85 518.28,1045.82 518.28,1048.41 
	518.6,1049.38 516.98,1050.67 516.98,1098.24 518.28,1100.83 519.25,1100.83 519.25,1099.54 520.54,1100.18 520.54,1101.48 
	520.87,1102.13 522.16,1102.77 522.16,1105.36 519.25,1106.66 512.78,1107.3 508.57,1107.3 505.98,1105.68 504.36,1105.04 
	504.36,1103.42 504.36,1102.13 505.66,1100.83 506.63,1100.83 507.6,1100.51 508.25,1099.54 508.25,1098.24 507.6,1097.27 
	508.57,1096.95 507.92,1050.99 507.28,1050.67 506.3,1050.02 506.3,1048.41 506.95,1045.49 506.63,1045.17 506.3,1044.52 
	506.63,1042.58 506.3,1032.22 505.98,1030.93 504.69,1030.61 504.69,1029.96 505.98,1028.67 505.98,1028.34 507.6,1028.02 
	517.63,1028.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="508.89,1106.01 507.28,1127.69 506.3,1136.75 503.72,1140.96 499.19,1148.73 493.04,1154.23 
	485.59,1158.11 476.86,1162.32 469.74,1159.08 458.09,1153.9 453.88,1149.7 450.64,1143.55 446.76,1136.1 444.17,1128.34 
	444.17,1106.33 445.79,1106.01 447.08,1104.71 446.44,1103.42 445.47,1103.42 445.47,1098.89 442.88,1045.17 442.23,1038.05 
	442.55,1031.58 442.55,1030.28 443.52,1028.99 444.49,1026.72 442.23,1026.4 440.29,1026.4 438.99,1026.72 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="507.28" y1="1045.49" x2="440.94" y2="1044.2"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="506.3" y1="1043.87" x2="440.94" y2="1042.9"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="504.36,1035.46 504.36,1038.05 504.36,1038.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="504.36,1039.34 504.04,1039.67 503.39,1040.32 503.39,1040.64 503.07,1041.29 501.77,1041.29 
	501.13,1040.64 501.13,1040.32 500.8,1039.99 500.16,1039.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="500.16,1038.37 500.16,1038.05 500.16,1035.46 504.36,1035.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="504.36" y1="1039.34" x2="500.16" y2="1039.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="504.36" y1="1038.37" x2="500.16" y2="1038.37"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="498.54,1035.46 498.86,1037.73 498.54,1038.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="498.54,1039.34 498.54,1039.67 497.57,1039.99 497.57,1040.32 497.57,1041.29 496.27,1041.29 
	495.3,1040.64 495.3,1040.32 494.98,1039.99 494.33,1039.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="494.33,1038.37 494.33,1037.73 494.33,1035.46 498.86,1035.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="498.54" y1="1039.34" x2="494.33" y2="1039.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="498.54" y1="1038.37" x2="494.33" y2="1038.37"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="492.71,1035.46 492.71,1037.73 492.71,1038.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="492.39,1039.34 492.39,1039.67 491.74,1039.99 491.74,1040.32 491.42,1041.29 490.12,1041.29 
	489.48,1040.64 489.48,1040.32 489.15,1039.99 488.51,1039.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="488.51,1038.37 488.51,1037.73 488.18,1035.46 492.71,1035.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="492.39" y1="1039.34" x2="488.51" y2="1039.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="492.71" y1="1038.37" x2="488.51" y2="1038.37"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="486.56,1035.14 486.56,1037.73 486.56,1038.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="486.56,1039.02 486.24,1039.67 485.59,1039.99 485.59,1040.32 485.27,1040.96 483.98,1041.29 
	483.33,1040.64 483.33,1039.99 483,1039.99 482.36,1039.34 482.36,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="482.36,1038.37 482.36,1037.73 482.36,1035.14 486.56,1035.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="486.56" y1="1039.02" x2="482.36" y2="1039.02"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="486.56" y1="1038.37" x2="482.36" y2="1038.37"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="480.42,1035.46 480.42,1037.73 480.09,1038.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="480.09,1039.34 480.09,1039.67 479.44,1039.99 479.12,1040.32 479.12,1041.29 477.83,1041.29 
	476.86,1040.64 476.86,1040.32 476.86,1039.99 476.21,1039.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="475.89,1038.37 475.89,1037.73 475.89,1035.46 480.42,1035.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="480.09" y1="1039.34" x2="476.21" y2="1039.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="480.09" y1="1038.37" x2="475.89" y2="1038.37"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="474.27,1035.14 474.27,1037.73 473.94,1038.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="473.94,1039.02 473.94,1039.67 473.3,1039.99 472.97,1040.32 472.97,1040.96 471.68,1041.29 
	470.71,1040.64 470.71,1039.99 470.06,1039.34 470.06,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="469.74,1038.37 469.74,1037.73 469.74,1035.46 474.27,1035.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="473.94" y1="1039.02" x2="470.06" y2="1039.02"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="473.94" y1="1038.37" x2="469.74" y2="1038.37"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="467.79,1035.46 467.79,1037.73 467.79,1038.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="467.47,1039.34 467.47,1039.67 466.82,1039.99 466.82,1040.32 466.5,1041.29 465.21,1041.29 
	464.56,1040.64 464.56,1040.32 464.24,1039.99 463.59,1039.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="463.59,1038.37 463.59,1037.73 463.59,1035.46 467.79,1035.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="467.79" y1="1039.34" x2="463.59" y2="1039.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="467.79" y1="1038.37" x2="463.59" y2="1038.37"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="461.32,1035.14 461.32,1037.73 461.32,1038.05 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="461,1039.02 461,1039.34 460.35,1039.99 460.35,1040.32 460.03,1040.96 458.73,1041.29 
	458.09,1040.64 458.09,1039.99 457.76,1039.99 457.12,1039.34 457.12,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="457.12,1038.37 457.12,1037.73 456.79,1035.14 461.32,1035.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="461" y1="1039.02" x2="457.12" y2="1039.02"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="461.32" y1="1038.05" x2="457.12" y2="1038.37"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="455.17,1035.14 455.17,1037.4 455.17,1038.05 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="454.85,1039.02 454.85,1039.34 454.2,1039.67 453.88,1039.99 453.88,1040.96 452.59,1040.96 
	451.94,1040.32 451.94,1039.99 451.61,1039.67 450.97,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="450.64,1038.05 450.64,1037.4 450.64,1035.14 455.17,1035.14 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="454.85" y1="1039.02" x2="450.97" y2="1039.02"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="455.17" y1="1038.05" x2="450.64" y2="1038.05"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="448.7,1034.81 448.7,1037.08 448.38,1037.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="448.38,1038.7 448.38,1039.02 447.73,1039.34 447.41,1039.67 447.41,1040.64 446.11,1040.64 
	445.14,1039.99 445.14,1039.67 445.14,1039.34 444.49,1038.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="444.17,1037.73 444.17,1037.08 444.17,1034.81 448.7,1034.81 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="448.38" y1="1038.7" x2="444.49" y2="1038.7"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="448.38" y1="1037.73" x2="444.17" y2="1037.73"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="501.45,1042.58 499.51,1039.99 497.57,1042.58 501.45,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="495.63,1042.58 493.68,1039.99 491.74,1042.58 495.63,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="489.48,1042.58 487.54,1039.99 485.59,1042.58 489.48,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="483.33,1042.58 481.39,1039.99 479.44,1042.58 483.33,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="476.86,1042.26 474.91,1039.99 473.3,1042.26 476.86,1042.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="471.03,1042.26 469.09,1039.67 467.15,1042.26 471.03,1042.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="464.24,1042.26 462.29,1039.67 460.68,1042.26 464.24,1042.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="458.09,1041.93 456.14,1039.34 454.2,1041.93 458.09,1041.93 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="451.61,1041.93 449.35,1039.67 447.73,1041.93 451.61,1041.93 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="506.63,1039.99 505.33,1039.99 503.72,1042.26 506.63,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="442.23,1041.93 445.47,1041.93 443.2,1039.34 442.23,1039.34 442.23,1041.93 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="578.79,1039.99 578.79,1040.32 577.82,1040.64 577.82,1040.96 577.5,1041.93 576.21,1041.93 
	575.56,1041.29 575.56,1040.96 575.23,1040.64 574.59,1039.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="574.59,1039.02 574.59,1038.37 574.59,1036.11 578.79,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="578.79" y1="1039.99" x2="574.59" y2="1039.99"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="578.79" y1="1039.02" x2="574.59" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="573.29,1035.78 573.29,1038.37 572.97,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="572.97,1039.99 572.97,1040.32 572.32,1040.64 572,1040.96 572,1041.93 570.7,1041.93 569.73,1041.29 
	569.73,1040.64 569.09,1039.99 569.09,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="568.76,1039.02 568.76,1038.37 568.76,1036.11 573.29,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="572.97" y1="1039.99" x2="569.09" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="572.97" y1="1039.02" x2="568.76" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="567.14,1035.78 567.14,1038.37 567.14,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="566.82,1039.99 566.82,1040.32 566.17,1040.64 566.17,1040.96 565.85,1041.93 564.56,1041.93 
	563.91,1041.29 563.91,1040.64 563.58,1040.64 562.94,1039.99 562.94,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="562.94,1039.02 562.94,1038.37 562.94,1036.11 567.14,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="567.14" y1="1039.99" x2="562.94" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="567.14" y1="1039.02" x2="562.94" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="561,1035.78 561.32,1038.37 561,1038.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="561,1039.67 561,1039.99 560.02,1040.64 559.7,1041.61 558.41,1041.61 557.76,1040.96 557.76,1040.64 
	557.44,1040.32 556.79,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="556.79,1038.7 556.79,1038.37 556.79,1035.78 561.32,1035.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="561" y1="1039.67" x2="556.79" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="561" y1="1038.7" x2="556.79" y2="1038.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="554.85,1035.78 554.85,1038.37 554.85,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="554.52,1039.99 554.52,1040.32 553.88,1040.64 553.55,1040.96 553.55,1041.93 552.26,1041.93 
	551.61,1041.29 551.61,1040.64 551.29,1040.64 550.64,1039.99 550.64,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="550.64,1039.02 550.64,1038.37 550.32,1036.11 554.85,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="554.52" y1="1039.99" x2="550.64" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="554.85" y1="1039.02" x2="550.64" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="548.7,1035.78 548.7,1038.37 548.7,1038.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="548.37,1039.67 548.37,1039.99 547.73,1040.64 547.4,1040.96 547.4,1041.61 546.11,1041.61 
	545.46,1040.96 545.46,1040.64 545.14,1040.32 544.49,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="544.49,1038.7 544.17,1038.37 544.17,1035.78 548.7,1035.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="548.37" y1="1039.67" x2="544.49" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="548.7" y1="1038.7" x2="544.49" y2="1038.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="542.23,1035.78 542.23,1038.37 542.23,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="542.23,1039.67 542.23,1040.32 541.26,1040.64 541.26,1040.96 540.93,1041.61 539.64,1041.93 
	538.99,1041.29 538.99,1040.64 538.67,1040.64 538.02,1039.99 538.02,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="538.02,1039.02 538.02,1038.37 538.02,1036.11 542.23,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="542.23" y1="1039.67" x2="538.02" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="542.23" y1="1039.02" x2="538.02" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="535.75,1035.78 535.75,1038.05 535.75,1038.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="535.43,1039.67 535.43,1039.99 534.78,1040.64 534.46,1041.61 533.16,1041.61 532.52,1040.96 
	532.52,1040.64 532.19,1040.32 531.55,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="531.55,1038.7 531.55,1038.05 531.55,1035.78 535.75,1035.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="535.75" y1="1039.67" x2="531.55" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="535.75" y1="1038.7" x2="531.55" y2="1038.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="529.6,1035.46 529.6,1038.05 529.6,1038.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="529.28,1039.67 529.28,1039.99 528.63,1040.32 528.63,1040.64 528.31,1041.61 527.02,1041.61 
	526.37,1040.96 526.37,1040.64 526.05,1040.32 525.4,1039.67 525.4,1039.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="525.4,1038.7 525.4,1038.05 525.07,1035.78 529.6,1035.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="529.28" y1="1039.67" x2="525.4" y2="1039.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="529.6" y1="1038.7" x2="525.4" y2="1038.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="523.13,1035.14 523.13,1037.73 523.13,1038.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="522.81,1039.34 522.81,1039.67 522.16,1039.99 521.84,1040.32 521.84,1041.29 520.54,1041.29 
	519.9,1040.64 519.9,1039.99 519.57,1039.99 518.93,1039.34 518.93,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="518.93,1038.37 518.6,1037.73 518.6,1035.46 523.13,1035.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="522.81" y1="1039.34" x2="518.93" y2="1039.02"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="523.13" y1="1038.37" x2="518.93" y2="1038.37"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="575.88,1043.23 573.94,1040.64 572,1043.23 575.88,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="570.06,1043.23 568.11,1040.64 566.17,1043.23 570.06,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="563.91,1043.23 561.97,1040.64 560.02,1043.23 563.91,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="557.76,1043.23 555.82,1040.64 553.88,1042.9 557.76,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="551.61,1042.9 549.67,1040.32 547.73,1042.9 551.61,1042.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="545.46,1042.9 543.52,1040.32 541.9,1042.9 545.46,1042.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="538.67,1042.9 536.72,1040.32 535.11,1042.9 538.67,1042.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="532.52,1042.58 530.58,1039.99 528.96,1042.58 532.52,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="526.05,1042.58 524.1,1039.99 522.16,1042.58 526.05,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="581.06,1040.64 579.76,1040.64 578.15,1042.9 581.06,1042.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="518.93,1042.58 520.87,1042.58 519.25,1040.32 518.6,1039.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="516.98,1050.99 513.42,1050.35 511.81,1050.67 509.54,1050.99 507.28,1051.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="517.31,1050.35 515.04,1049.7 512.13,1050.02 509.86,1050.35 508.25,1050.35 507.28,1050.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="518.6,1044.85 524.75,1044.85 561,1045.49 577.82,1045.49 577.82,1044.52 518.93,1043.87 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="577.5,1045.49 576.85,1046.46 576.53,1047.43 576.53,1047.76 575.88,1048.41 518.28,1049.05 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="506.63,1048.73 443.2,1047.43 442.23,1047.11 441.26,1045.82 440.94,1044.2 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="567.14,1048.73 566.5,1049.38 566.82,1050.35 566.82,1050.67 567.14,1050.99 567.14,1051.32 
	566.5,1051.97 565.85,1051.97 565.53,1051.32 565.53,1050.67 565.53,1050.35 565.53,1049.7 517.95,1050.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="506.63,1050.02 461.32,1049.05 460.68,1049.05 460.68,1049.7 461,1050.02 461.32,1050.35 
	460.68,1050.99 460.03,1050.99 459.7,1050.35 459.7,1049.7 459.38,1049.38 458.73,1048.08 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="498.54,1061.35 498.21,1103.1 495.3,1111.51 489.15,1116.69 481.71,1120.57 470.38,1115.72 
	466.82,1106.98 465.21,1084.33 466.5,1063.29 466.18,1060.7 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M487.86,1112c0-2.95-2.39-5.34-5.34-5.34s-5.34,2.39-5.34,5.34s2.39,5.34,5.34,5.34S487.86,1114.94,487.86,1112
	"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M480.09,1099.37c0-2.95-2.39-5.34-5.34-5.34c-2.95,0-5.34,2.39-5.34,5.34s2.39,5.34,5.34,5.34
	C477.7,1104.71,480.09,1102.32,480.09,1099.37"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M494.98,1098.73c0-2.95-2.39-5.34-5.34-5.34c-2.95,0-5.34,2.39-5.34,5.34s2.39,5.34,5.34,5.34
	C492.59,1104.07,494.98,1101.68,494.98,1098.73"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="496.27,1061.03 496.27,1085.62 495.95,1087.56 494.98,1088.53 493.68,1089.18 492.39,1089.83 
	489.48,1088.53 489.15,1085.3 488.51,1081.41 488.18,1072.35 488.83,1061.03 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="486.24,1061.03 486.89,1088.53 486.24,1089.83 485.59,1091.12 484.95,1092.42 483.98,1093.06 
	482.68,1094.03 480.09,1092.42 478.8,1088.86 478.47,1082.06 478.47,1067.5 478.47,1061.03 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="477.18,1061.35 476.86,1086.59 476.21,1089.18 475.56,1090.47 473.62,1091.45 471.03,1089.83 
	469.09,1085.3 468.12,1075.59 467.79,1066.2 468.44,1060.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="519.25,1106.01 522.49,1136.43 534.78,1150.02 542.23,1155.84 551.61,1157.79 567.47,1149.37 
	574.91,1134.81 578.15,1117.33 576.85,1073.65 577.18,1047.11 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="578.15,1108.92 572,1125.75 567.47,1130.93 562.61,1134.81 552.58,1145.17 543.2,1143.55 
	538.34,1142.25 531.55,1136.75 528.63,1134.16 526.37,1130.6 521.84,1105.36 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="552.58,1144.84 560.02,1125.1 563.91,1112.16 566.5,1100.51 565.53,1084.33 565.53,1063.62 
	565.53,1062.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="550.96,1144.84 541.26,1134.49 537.05,1130.6 534.46,1126.72 530.58,1107.95 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="481.71,1133.52 491.42,1126.72 497.24,1120.89 502.1,1113.78 505.66,1104.71 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="483.98,1132.54 473.62,1129.96 467.15,1123.81 464.24,1119.92 461.32,1115.72 459.06,1112.16 
	457.12,1103.42 456.79,1060.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="444.82,1108.27 452.59,1133.84 472.97,1146.78 481.71,1145.17 489.48,1144.84 494.33,1142.25 
	498.54,1137.08 505.66,1125.75 508.89,1115.39 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="480.09,1145.81 492.39,1131.57 496.92,1122.51 497.57,1120.57 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="480.42,1145.49 470.06,1136.1 464.88,1126.72 460.35,1113.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="544.17,1130.28 553.23,1126.4 558.41,1119.6 564.56,1108.6 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="543.2,1129.96 534.78,1127.04 534.14,1125.75 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="563.91,1062.97 563.58,1104.71 560.67,1113.13 554.52,1118.31 547.08,1121.87 535.75,1117.33 
	531.22,1108.27 530.58,1085.94 531.87,1064.91 531.55,1062.32 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M553.23,1113.61c0-2.95-2.39-5.34-5.34-5.34c-2.95,0-5.34,2.39-5.34,5.34s2.39,5.34,5.34,5.34
	C550.84,1118.95,553.23,1116.56,553.23,1113.61"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M545.46,1100.99c0-2.95-2.39-5.34-5.34-5.34c-2.95,0-5.34,2.39-5.34,5.34s2.39,5.34,5.34,5.34
	C543.07,1106.33,545.46,1103.94,545.46,1100.99"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M560.35,1100.35c0-2.95-2.39-5.34-5.34-5.34s-5.34,2.39-5.34,5.34s2.39,5.34,5.34,5.34
	S560.35,1103.29,560.35,1100.35"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="561.64,1062.64 561.64,1087.24 561,1089.18 560.35,1090.15 559.05,1090.8 557.76,1091.45 
	554.85,1090.15 554.52,1086.92 553.88,1083.03 553.55,1073.97 554.2,1062.64 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="551.61,1062.64 552.26,1090.15 551.61,1091.45 550.96,1092.74 550.32,1094.03 549.35,1094.68 
	548.05,1095.65 545.46,1094.03 543.84,1090.47 543.84,1083.68 543.84,1069.12 543.84,1062.64 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="542.55,1062.97 542.23,1088.21 541.58,1090.8 540.61,1091.77 538.99,1093.06 536.4,1091.45 
	534.46,1086.92 533.49,1077.21 533.16,1067.82 533.81,1062.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="593.03,1010.54 601.12,1015.4 607.27,1018.31 613.74,1018.96 622.16,1019.6 629.92,1018.96 
	634.46,1018.96 638.66,1017.01 642.87,1014.75 645.78,1012.48 648.05,1006.66 648.05,1002.78 645.78,1003.1 644.81,1001.16 
	646.11,1000.83 647.4,1000.83 647.72,998.57 648.69,991.77 648.69,985.62 648.05,982.71 649.34,978.51 648.69,974.62 648.69,973.97 
	648.05,973.33 647.72,971.39 648.05,964.27 663.58,964.27 664.55,973.33 661.64,973.97 661.64,975.59 663.58,976.24 663.26,1000.51 
	663.9,1001.81 664.88,1001.81 664.23,1003.1 662.61,1003.42 666.49,1014.43 670.05,1020.25 673.29,1022.52 682.67,1024.78 
	695.62,1024.78 701.44,1024.13 710.18,1020.9 714.06,1014.75 719.57,1009.25 720.86,1006.34 720.21,1004.07 718.59,1004.07 
	718.92,1003.1 721.83,1002.13 722.15,986.27 721.83,983.04 723.45,975.92 722.8,972.03 720.54,971.71 720.54,968.8 721.18,963.62 
	738.01,963.94 738.01,972.36 735.1,973.33 733.8,973 733.8,991.13 734.13,1000.83 735.42,1002.78 736.39,1003.42 736.07,1006.01 
	734.78,1006.66 734.45,1006.66 735.1,1011.51 746.75,1020.9 754.84,1023.81 770.37,1025.43 782.99,1018.96 788.5,1014.1 
	794,1007.95 797.88,1000.83 796.26,987.57 794.97,982.06 794.32,979.8 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="588.5,1013.46 600.8,1024.46 610.83,1027.37 619.25,1028.02 623.45,1028.02 629.28,1027.37 
	632.51,1025.43 638.34,1022.52 642.55,1019.28 644.81,1016.69 647.72,1012.16 648.37,1018.63 647.72,1029.31 588.5,1028.02 
	588.18,1013.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="660.99,1012.48 673.29,1023.49 683.64,1026.72 691.73,1027.37 696.27,1027.05 701.77,1026.4 
	705,1024.46 710.83,1021.55 715.04,1018.31 717.62,1015.72 720.21,1011.19 720.86,1017.99 720.54,1028.34 660.99,1027.05 
	660.67,1012.81 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="733.8,1013.13 746.1,1024.13 756.13,1027.37 764.55,1028.02 768.75,1027.69 774.58,1027.05 
	777.82,1025.11 783.64,1022.52 787.85,1019.28 790.44,1016.37 793.03,1011.84 793.67,1018.63 793.35,1028.99 733.8,1027.69 
	733.48,1013.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="648.69,1039.99 648.37,1040.32 647.72,1040.64 647.72,1040.96 647.4,1041.93 646.11,1041.93 
	645.46,1041.29 645.46,1040.96 645.13,1040.64 644.49,1039.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="644.49,1039.02 644.49,1038.37 644.49,1036.11 648.69,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="648.69" y1="1039.99" x2="644.49" y2="1039.99"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="648.69" y1="1039.02" x2="644.49" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="643.19,1035.78 643.19,1038.37 642.87,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="642.87,1039.67 642.87,1040.32 641.9,1040.64 641.9,1040.96 641.9,1041.61 640.6,1041.93 
	639.63,1041.29 639.63,1040.64 639.31,1040.64 638.66,1039.99 638.66,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="638.66,1039.02 638.66,1038.37 638.66,1035.78 643.19,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="642.87" y1="1039.67" x2="638.66" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="642.87" y1="1039.02" x2="638.66" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="637.04,1035.78 637.04,1038.37 637.04,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="636.72,1039.67 636.72,1040.32 636.07,1040.64 636.07,1040.96 635.75,1041.61 634.46,1041.93 
	633.81,1041.29 633.81,1040.64 633.48,1040.64 632.84,1039.99 632.84,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="632.84,1039.02 632.84,1038.37 632.51,1036.11 637.04,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="636.72" y1="1039.67" x2="632.84" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="637.04" y1="1039.02" x2="632.84" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="630.9,1035.78 630.9,1038.05 630.9,1038.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="630.9,1039.67 630.9,1039.99 629.92,1040.32 629.92,1040.64 629.6,1041.61 628.31,1041.61 
	627.66,1040.96 627.66,1040.64 627.34,1040.32 626.69,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="626.69,1038.7 626.69,1038.05 626.69,1035.78 630.9,1035.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="630.9" y1="1039.67" x2="626.69" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="630.9" y1="1038.7" x2="626.69" y2="1038.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="624.75,1035.78 624.75,1038.37 624.75,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="624.42,1039.67 624.42,1040.32 623.78,1040.64 623.45,1040.96 623.45,1041.61 622.16,1041.93 
	621.19,1041.29 621.19,1040.64 620.54,1039.99 620.54,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="620.22,1039.02 620.22,1038.37 620.22,1036.11 624.75,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="624.42" y1="1039.67" x2="620.54" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="624.75" y1="1039.02" x2="620.22" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.6,1035.78 618.6,1038.05 618.27,1038.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.27,1039.67 618.27,1039.99 617.63,1040.64 617.3,1040.64 617.3,1041.61 616.01,1041.61 
	615.04,1040.96 615.04,1040.64 615.04,1040.32 614.39,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="614.07,1038.7 614.07,1038.05 614.07,1035.78 618.6,1035.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="618.27" y1="1039.67" x2="614.39" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="618.27" y1="1038.7" x2="614.07" y2="1038.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="612.13,1035.78 612.13,1038.37 612.13,1038.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="611.8,1039.67 611.8,1039.99 611.16,1040.64 611.16,1040.96 610.83,1041.61 609.54,1041.93 
	608.89,1041.29 608.89,1040.64 608.57,1040.64 607.92,1039.99 607.92,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="607.92,1039.02 607.92,1038.37 607.92,1035.78 612.13,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="612.13" y1="1039.67" x2="607.92" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="612.13" y1="1038.7" x2="607.92" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="605.65,1035.78 605.65,1038.05 605.65,1038.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="605.33,1039.67 605.33,1039.99 604.68,1040.32 604.68,1040.64 604.36,1041.61 603.07,1041.61 
	602.42,1040.96 602.42,1040.64 602.09,1040.32 601.45,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="601.45,1038.7 601.45,1038.05 601.12,1035.78 605.65,1035.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="605.33" y1="1039.67" x2="601.45" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="605.65" y1="1038.7" x2="601.45" y2="1038.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="599.51,1035.46 599.51,1038.05 599.51,1038.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="599.18,1039.67 599.18,1039.99 598.53,1040.32 598.21,1040.64 598.21,1041.61 596.92,1041.61 
	596.27,1040.96 596.27,1040.32 595.95,1040.32 595.3,1039.67 595.3,1039.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="595.3,1038.7 594.97,1038.05 594.97,1035.78 599.51,1035.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="599.18" y1="1039.67" x2="595.3" y2="1039.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="599.51" y1="1038.7" x2="595.3" y2="1038.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="593.03,1035.14 593.03,1037.73 593.03,1038.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="592.71,1039.02 592.71,1039.67 592.06,1039.99 591.74,1040.32 591.74,1040.96 590.44,1041.29 
	589.47,1040.64 589.47,1039.99 588.83,1039.34 588.83,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="588.5,1038.37 588.5,1037.73 588.5,1035.14 593.03,1035.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="592.71" y1="1039.02" x2="588.83" y2="1039.02"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="593.03" y1="1038.37" x2="588.5" y2="1038.37"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="645.78,1043.23 643.84,1040.64 641.9,1043.23 645.78,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="639.96,1043.23 638.02,1040.64 636.07,1043.23 639.96,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="633.81,1042.9 631.87,1040.64 629.92,1042.9 633.81,1042.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="627.66,1042.9 625.72,1040.64 623.78,1042.9 627.66,1042.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="621.19,1042.9 619.25,1040.32 617.63,1042.9 621.19,1042.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="615.36,1042.9 613.42,1040.32 611.48,1042.9 615.36,1042.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="608.57,1042.58 606.62,1040.32 605.01,1042.58 608.57,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="602.42,1042.58 600.48,1039.99 598.53,1042.58 602.42,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="595.95,1042.58 593.68,1039.99 592.06,1042.58 595.95,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="586.56,1042.58 589.8,1042.58 587.53,1039.99 586.56,1039.99 586.56,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="721.18,1039.99 721.18,1040.32 720.54,1040.96 720.54,1041.29 720.21,1041.93 718.92,1042.26 
	718.27,1041.61 718.27,1040.96 717.95,1040.96 717.3,1040.32 717.3,1039.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="717.3,1039.34 717.3,1038.7 716.98,1036.11 721.51,1036.43 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="721.18" y1="1039.99" x2="717.3" y2="1039.99"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="721.51" y1="1039.02" x2="717.3" y2="1039.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="715.68,1036.11 715.68,1038.37 715.68,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="715.68,1039.99 715.68,1040.32 714.71,1040.64 714.71,1040.96 714.39,1041.93 713.09,1041.93 
	712.45,1041.29 712.45,1040.96 712.12,1040.64 711.48,1039.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="711.48,1039.02 711.48,1038.37 711.48,1036.11 715.68,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="715.68" y1="1039.99" x2="711.48" y2="1039.99"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="715.68" y1="1039.02" x2="711.48" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="709.86,1036.11 709.86,1038.37 709.53,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="709.53,1039.99 709.53,1040.32 708.89,1040.64 708.56,1040.96 708.56,1041.93 707.27,1041.93 
	706.3,1041.29 706.3,1040.96 706.3,1040.64 705.65,1039.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="705.33,1039.02 705.33,1038.37 705.33,1036.11 709.86,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="709.53" y1="1039.99" x2="705.65" y2="1039.99"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="709.53" y1="1039.02" x2="705.33" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="703.71,1035.78 703.71,1038.37 703.71,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="703.39,1039.99 703.39,1040.32 702.74,1040.64 702.74,1040.96 702.41,1041.93 701.12,1041.93 
	700.47,1041.29 700.47,1040.64 700.15,1040.64 699.5,1039.99 699.5,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="699.5,1039.02 699.5,1038.37 699.18,1036.11 703.71,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="703.39" y1="1039.99" x2="699.5" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="703.71" y1="1039.02" x2="699.5" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="697.24,1036.11 697.56,1038.37 697.24,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="697.24,1039.99 697.24,1040.32 696.27,1040.64 696.27,1040.96 695.94,1041.93 694.65,1041.93 
	694,1041.29 694,1040.96 693.68,1040.64 693.03,1039.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="693.03,1039.02 693.03,1038.37 693.03,1036.11 697.56,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="697.24" y1="1039.99" x2="693.03" y2="1039.99"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="697.24" y1="1039.02" x2="693.03" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="691.09,1035.78 691.41,1038.37 691.09,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="691.09,1039.99 691.09,1040.32 690.12,1040.64 690.12,1040.96 689.79,1041.93 688.5,1041.93 
	687.85,1041.29 687.85,1040.96 687.53,1040.64 686.88,1039.99 686.88,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="686.88,1039.02 686.88,1038.37 686.88,1036.11 691.41,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="691.09" y1="1039.99" x2="686.88" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="691.09" y1="1039.02" x2="686.88" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="684.94,1036.11 684.94,1038.37 684.94,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="684.62,1039.99 684.62,1040.32 683.97,1040.64 683.64,1040.96 683.64,1041.93 682.35,1041.93 
	681.7,1041.29 681.7,1040.96 681.38,1040.64 680.73,1039.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="680.73,1039.02 680.41,1038.37 680.41,1036.11 684.94,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="684.62" y1="1039.99" x2="680.73" y2="1039.99"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="684.94" y1="1039.02" x2="680.73" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="678.47,1035.78 678.47,1038.37 678.14,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="678.14,1039.99 678.14,1040.32 677.17,1040.64 677.17,1040.96 677.17,1041.61 675.88,1041.93 
	674.91,1041.29 674.91,1040.64 674.26,1039.99 673.94,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="673.94,1039.02 673.94,1038.37 673.94,1036.11 678.47,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="678.14" y1="1039.99" x2="673.94" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="678.14" y1="1039.02" x2="673.94" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="671.99,1035.78 672.32,1038.05 671.99,1038.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="671.99,1039.67 671.99,1039.99 671.02,1040.64 670.7,1041.61 669.41,1041.61 668.76,1040.96 
	668.76,1040.64 668.43,1040.32 667.79,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="667.79,1038.7 667.79,1038.05 667.79,1035.78 672.32,1035.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="671.99" y1="1039.67" x2="667.79" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="671.99" y1="1038.7" x2="667.79" y2="1038.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.52,1035.46 665.85,1037.73 665.52,1038.37 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.52,1039.34 665.52,1039.67 664.55,1039.99 664.55,1040.32 664.23,1041.29 662.93,1041.29 
	662.29,1040.64 662.29,1040.32 661.96,1039.99 661.32,1039.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="661.32,1038.37 661.32,1037.73 661.32,1035.46 665.85,1035.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="665.52" y1="1039.34" x2="661.32" y2="1039.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="665.52" y1="1038.37" x2="661.32" y2="1038.37"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="718.59,1043.23 716.33,1040.96 714.71,1043.23 718.59,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="712.77,1043.23 710.5,1040.96 708.89,1043.23 712.77,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="706.3,1043.23 704.36,1040.64 702.74,1043.23 706.3,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="700.15,1043.23 698.21,1040.64 696.59,1043.23 700.15,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="694,1042.9 692.06,1040.64 690.44,1042.9 694,1042.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="688.18,1042.9 686.23,1040.64 684.29,1042.9 688.18,1042.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="681.38,1042.9 679.44,1040.32 677.5,1042.9 681.38,1042.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="675.23,1042.58 673.29,1039.99 671.35,1042.58 675.23,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="668.43,1042.58 666.49,1040.32 664.88,1042.58 668.43,1042.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="794.32,1040.64 794.32,1040.96 793.67,1041.29 793.35,1041.61 793.35,1042.58 792.05,1042.58 
	791.41,1041.93 791.41,1041.61 791.08,1041.29 790.44,1040.64 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="790.44,1039.67 790.11,1039.02 790.11,1036.76 794.64,1036.76 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="794.32" y1="1040.64" x2="790.44" y2="1040.64"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="794.64" y1="1039.67" x2="790.44" y2="1039.67"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="788.82,1036.43 788.82,1039.02 788.82,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="788.82,1040.32 788.82,1040.96 787.85,1041.29 787.85,1041.61 787.52,1042.26 786.23,1042.58 
	785.58,1041.93 785.58,1041.29 785.26,1041.29 784.61,1040.64 784.61,1040.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="784.61,1039.67 784.61,1039.02 784.61,1036.76 788.82,1036.76 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="788.82" y1="1040.32" x2="784.61" y2="1040.32"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="788.82" y1="1039.67" x2="784.61" y2="1039.67"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="782.99,1036.43 782.99,1039.02 782.67,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="782.67,1040.32 782.67,1040.96 781.7,1041.29 781.7,1041.61 781.7,1042.26 780.4,1042.58 
	779.43,1041.93 779.43,1041.29 778.79,1040.64 778.46,1040.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="778.46,1039.67 778.46,1039.02 778.46,1036.76 782.99,1036.76 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="782.67" y1="1040.32" x2="778.46" y2="1040.32"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="782.67" y1="1039.67" x2="778.46" y2="1039.67"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="776.85,1036.43 776.85,1038.7 776.85,1039.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="776.52,1040.32 776.52,1040.64 775.87,1041.29 775.55,1042.26 774.26,1042.26 773.61,1041.61 
	773.61,1041.29 773.29,1040.96 772.64,1040.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="772.64,1039.34 772.64,1038.7 772.31,1036.43 776.85,1036.43 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="776.52" y1="1040.32" x2="772.64" y2="1040.32"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="776.85" y1="1039.34" x2="772.64" y2="1039.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="770.37,1036.43 770.7,1039.02 770.37,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="770.37,1040.64 770.37,1040.96 769.4,1041.29 769.4,1041.61 769.08,1042.58 767.78,1042.58 
	767.14,1041.93 767.14,1041.29 766.81,1041.29 766.17,1040.64 766.17,1040.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="766.17,1039.67 766.17,1039.02 766.17,1036.76 770.7,1036.76 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="770.37" y1="1040.64" x2="766.17" y2="1040.32"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="770.37" y1="1039.67" x2="766.17" y2="1039.67"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="764.22,1036.43 764.55,1038.7 764.22,1039.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="764.22,1040.32 764.22,1040.64 763.25,1041.29 762.93,1042.26 761.64,1042.26 760.99,1041.61 
	760.99,1041.29 760.66,1040.96 760.02,1040.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="760.02,1039.34 760.02,1038.7 760.02,1036.43 764.55,1036.43 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="764.22" y1="1040.32" x2="760.02" y2="1040.32"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="764.22" y1="1039.34" x2="760.02" y2="1039.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="758.08,1036.43 758.08,1039.02 758.08,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="757.75,1040.32 757.75,1040.96 757.1,1041.29 756.78,1041.61 756.78,1042.26 755.49,1042.58 
	754.84,1041.93 754.84,1041.29 754.52,1041.29 753.87,1040.64 753.87,1040.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="753.87,1039.67 753.55,1039.02 753.55,1036.43 758.08,1036.76 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="757.75" y1="1040.32" x2="753.87" y2="1040.32"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="758.08" y1="1039.67" x2="753.87" y2="1039.67"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="751.6,1036.43 751.6,1038.7 751.28,1039.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="751.28,1040.32 751.28,1040.64 750.31,1040.96 750.31,1041.29 750.31,1042.26 749.01,1042.26 
	748.04,1041.61 748.04,1041.29 747.72,1040.96 747.07,1040.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="747.07,1039.34 747.07,1038.7 747.07,1036.43 751.6,1036.43 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="751.28" y1="1040.32" x2="747.07" y2="1040.32"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="751.28" y1="1039.34" x2="747.07" y2="1039.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="745.13,1036.11 745.45,1038.7 745.13,1039.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="745.13,1040.32 745.13,1040.64 744.16,1040.96 744.16,1041.29 743.84,1042.26 742.54,1042.26 
	741.89,1041.61 741.89,1040.96 741.57,1040.96 740.92,1040.32 740.92,1039.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="740.92,1039.34 740.92,1038.7 740.92,1036.43 745.45,1036.43 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="745.13" y1="1040.32" x2="740.92" y2="1039.99"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="745.13" y1="1039.34" x2="740.92" y2="1039.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="738.66,1035.78 738.98,1038.37 738.66,1039.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="738.66,1039.67 738.66,1040.32 737.69,1040.64 737.69,1040.96 737.36,1041.61 736.07,1041.93 
	735.42,1041.29 735.42,1040.64 735.1,1040.64 734.45,1039.99 734.45,1039.67 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="734.45,1039.02 734.45,1038.37 734.45,1036.11 738.98,1036.11 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="738.66" y1="1039.67" x2="734.45" y2="1039.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="738.66" y1="1039.02" x2="734.45" y2="1039.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="791.41,1043.87 789.47,1041.29 787.85,1043.87 791.41,1043.87 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="785.91,1043.87 783.64,1041.29 782.02,1043.87 785.91,1043.87 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="779.43,1043.87 777.49,1041.29 775.87,1043.55 779.43,1043.87 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="773.29,1043.55 771.34,1041.29 769.73,1043.55 773.29,1043.55 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="767.14,1043.55 765.2,1040.96 763.58,1043.55 767.14,1043.55 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="761.31,1043.55 759.37,1040.96 757.43,1043.55 761.31,1043.55 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="754.52,1043.55 752.57,1040.96 750.63,1043.23 754.52,1043.55 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="748.37,1043.23 746.43,1040.64 744.48,1043.23 748.37,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="741.57,1043.23 739.63,1040.64 738.01,1043.23 741.57,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="732.19,1043.23 735.42,1043.23 733.48,1040.64 732.19,1040.64 732.19,1043.23 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="588.18,1028.02 586.56,1041.93 586.88,1073.97 587.21,1114.42 589.15,1119.92 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="638.34,1127.04 641.25,1110.86 644.16,1100.83 645.78,1085.94 646.75,1077.21 648.05,1064.59 
	648.05,1059.73 647.72,1043.23 647.4,1028.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="660.99,1026.72 661.32,1059.73 661.96,1082.06 662.93,1100.51 662.93,1111.19 663.26,1120.57 
	666.49,1129.63 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="713.42,1118.31 715.36,1109.89 717.3,1093.39 717.62,1071.71 719.24,1051.32 722.48,1037.4 
	720.54,1028.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="734.13,1027.05 731.86,1043.23 731.86,1105.68 732.51,1119.6 732.83,1125.43 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="784.29,1129.96 788.82,1117.66 796.26,1047.43 795.29,1028.99 795.61,1009.25 797.88,1000.83 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="587.86,1043.87 587.86,1046.46 597.89,1047.11 599.83,1050.35 634.13,1050.67 638.02,1047.76 
	646.43,1047.43 646.43,1044.2 587.86,1043.87 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="599.83,1049.7 599.51,1079.8 600.8,1103.1 601.45,1109.57 603.71,1117.66 606.3,1121.87 
	614.72,1123.81 619.89,1124.13 628.63,1120.89 631.22,1117.98 633.16,1106.66 634.46,1096.95 636.07,1084 634.46,1050.02 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="599.51" y1="1064.91" x2="635.1" y2="1065.23"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="602.74,1064.91 603.39,1094.36 604.04,1100.83 605.65,1106.01 608.57,1106.98 610.51,1105.68 
	611.48,1102.13 611.8,1095.33 612.13,1086.92 612.45,1077.53 612.77,1070.41 613.42,1064.59 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="620.54,1065.56 619.57,1089.18 620.22,1096.62 620.54,1102.13 621.51,1106.33 623.13,1107.3 
	626.69,1104.39 628.63,1100.18 629.6,1091.45 630.25,1079.8 629.92,1071.38 629.92,1065.23 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M620.86,1113.61c0-3.31-2.68-5.99-5.99-5.99c-3.31,0-5.99,2.68-5.99,5.99c0,3.31,2.68,5.99,5.99,5.99
	C618.18,1119.6,620.86,1116.92,620.86,1113.61"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="601.77,1051.32 601.77,1062.97 633.81,1063.62 633.16,1052.61 602.09,1051.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="661.32,1046.14 660.99,1048.73 671.02,1049.38 672.97,1052.61 707.59,1052.94 711.15,1050.02 
	719.57,1049.7 719.57,1046.46 660.99,1046.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="672.97,1051.97 672.64,1082.06 673.94,1105.36 674.58,1111.83 676.85,1119.92 679.76,1124.13 
	687.85,1126.07 693.03,1126.4 701.77,1123.16 704.36,1120.25 706.3,1108.92 707.59,1099.54 709.21,1086.27 707.92,1052.29 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="672.64" y1="1067.17" x2="708.24" y2="1067.5"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="675.88,1067.17 676.85,1096.62 677.17,1103.1 678.79,1108.27 682.03,1109.24 683.64,1107.95 
	684.62,1104.39 684.94,1097.59 685.59,1089.18 685.91,1079.8 686.23,1072.68 686.56,1066.85 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="693.68,1067.82 692.71,1091.45 693.35,1098.89 693.68,1104.39 694.65,1108.6 696.27,1109.57 
	699.83,1106.98 701.77,1102.45 702.74,1093.71 703.39,1082.06 703.06,1073.65 703.06,1067.5 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M694,1115.88c0-3.31-2.68-5.99-5.99-5.99c-3.31,0-5.99,2.68-5.99,5.99c0,3.31,2.68,5.99,5.99,5.99
	C691.32,1121.87,694,1119.19,694,1115.88"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="674.91,1053.58 674.91,1065.23 706.94,1065.88 706.3,1054.88 675.23,1053.91 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="733.8,1048.08 733.48,1050.67 743.84,1050.99 745.45,1054.23 780.08,1054.55 783.64,1051.64 
	792.38,1051.32 792.05,1048.41 733.8,1048.08 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="745.78,1053.91 745.13,1084 746.75,1107.3 747.07,1113.45 749.34,1121.54 752.25,1125.75 
	760.66,1128.01 765.52,1128.34 774.58,1124.78 777.17,1121.87 779.11,1110.86 780.4,1101.15 781.7,1088.21 780.4,1054.23 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="745.45" y1="1069.12" x2="780.73" y2="1069.44"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="748.37,1068.79 749.34,1098.24 749.66,1104.71 751.28,1109.89 754.52,1111.19 756.46,1109.89 
	757.1,1106.01 757.43,1099.21 758.08,1090.8 758.4,1081.74 758.72,1074.62 759.37,1068.79 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="766.49,1069.44 765.52,1093.39 765.84,1100.51 766.49,1106.01 767.46,1110.54 769.08,1111.19 
	772.31,1108.6 774.26,1104.07 775.55,1095.33 775.87,1083.68 775.87,1075.27 775.55,1069.44 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M766.49,1117.5c0-3.31-2.68-5.99-5.99-5.99c-3.31,0-5.99,2.68-5.99,5.99c0,3.31,2.68,5.99,5.99,5.99
	C763.81,1123.48,766.49,1120.8,766.49,1117.5"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="747.72,1055.52 747.72,1066.85 779.43,1067.5 779.11,1056.5 747.72,1055.52 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="586.56,1116.36 597.89,1133.19 604.68,1137.08 609.86,1138.37 616.01,1138.05 626.69,1136.75 
	638.34,1127.04 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.85,1129.31 672.32,1134.16 678.14,1134.81 685.59,1135.78 694.97,1134.81 704.36,1128.34 
	711.15,1125.1 713.74,1117.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="732.83,1125.43 733.8,1127.04 738.66,1131.9 744.16,1134.16 749.66,1136.75 758.08,1137.4 
	765.52,1137.08 773.93,1137.08 781.38,1134.16 784.29,1129.96 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="507.92,1054.88 460.35,1053.91 460.03,1054.23 459.7,1054.55 460.35,1055.2 460.35,1055.52 
	459.7,1055.85 459.06,1055.85 458.73,1055.2 458.73,1054.55 458.41,1054.23 458.09,1052.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="507.92,1061.35 459.38,1060.38 458.73,1060.38 458.73,1060.7 459.06,1061.35 459.06,1061.67 
	458.73,1062.32 458.09,1062.32 457.76,1061.35 457.76,1060.7 457.44,1060.7 456.79,1059.41 456.79,1058.11 457.12,1057.14 
	457.12,1055.52 457.76,1054.55 458.09,1053.91 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="457.76,1053.26 457.44,1052.61 457.44,1051.32 457.76,1050.35 457.76,1049.05 457.44,1047.76 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="567.14,1054.23 566.82,1055.2 567.14,1055.85 567.14,1056.17 567.14,1056.5 567.47,1057.14 
	566.82,1057.47 566.17,1057.47 565.85,1056.82 565.85,1056.5 565.85,1056.17 565.85,1055.2 516.98,1055.2 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="567.14,1057.14 567.14,1057.79 567.47,1058.44 567.79,1059.41 567.79,1060.06 567.47,1061.35 
	567.14,1062 567.47,1062.97 567.47,1063.29 567.79,1063.94 567.14,1064.26 566.5,1064.59 566.17,1063.94 566.17,1063.29 
	566.17,1062.97 566.17,1062.32 516.66,1062.32 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="567.14,1054.23 567.14,1053.26 567.14,1052.29 566.82,1051.64 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="910.5,804.4 911.47,755.86 902.73,730.62 902.73,481.44 897.55,352.31 885.58,344.22 856.13,62.36 
	809.85,343.25 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="802.73,478.52 802.09,620.91 800.79,662.66 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="794.32" y1="720.91" x2="800.79" y2="661.04"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="947.71,509.27 954.83,704.73 970.69,726.41 972.63,817.35 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="885.58" y1="343.25" x2="933.47" y2="328.69"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="929.59,804.4 931.21,748.09 912.44,724.15 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="893.35,731.26 886.87,752.3 885.58,803.43 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="895.61" y1="730.62" x2="910.17" y2="726.41"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="914.7" y1="723.82" x2="954.18" y2="706.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="971.34,816.7 970.69,798.9 950.95,773.66 950.3,754.56 955.16,749.71 953.54,747.45 947.71,749.06 
	947.07,736.77 943.51,743.24 945.45,751.33 939.62,755.54 939.62,759.74 944.48,756.18 947.07,779.16 941.56,805.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="947.39,806.99 949.33,802.46 951.92,800.52 956.45,803.75 961.3,810.87 961.95,811.84 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="945.12,711.85 961.3,728.68 963.57,789.84 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="912.76,719.61 912.44,700.2 912.76,692.11 914.38,696.96 914.06,702.14 914.06,707.96 914.38,716.38 
	912.76,718.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="925.06,714.76 925.06,695.34 926.03,687.58 929.27,690.81 927.65,695.02 927.65,705.38 928.62,711.52 
	923.44,715.73 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="942.86,704.73 943.51,695.99 943.51,687.58 941.89,683.69 939.3,693.73 939.3,698.58 940.59,704.73 
	941.89,706.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="915.03,678.52 922.47,676.9 924.09,600.2 920.85,594.05 916.97,594.05 915.35,597.61 915.03,604.09 
	912.76,642.59 914.06,678.52 915.03,678.19 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="929.27,674.96 931.53,604.41 930.88,597.61 931.86,595.02 935.09,592.76 938.65,595.67 939.3,613.47 
	938.33,639.04 937.68,649.39 937.68,661.69 938.33,671.72 929.91,674.96 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="933.47,554.25 926.03,553.6 920.21,556.84 918.26,563.31 918.26,572.37 919.88,579.81 925.71,582.73 
	931.86,582.4 937.03,573.34 938.33,560.07 935.74,557.48 934.12,555.87 930.88,552.31 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="902.73" y1="540.98" x2="944.8" y2="511.21"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="899.49,397.62 909.2,353.93 919.23,385.65 923.77,348.75 934.77,381.44 934.77,346.17 943.51,373.67 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="921.82,491.14 922.79,424.48 919.56,404.74 923.44,383.06 926.03,380.79 930.56,383.06 933.8,391.15 
	934.77,405.71 934.12,483.7 921.82,490.17 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="920.21,490.82 914.38,407.98 917.62,405.06 914.38,405.06 909.53,380.79 907.91,405.71 905,408.3 
	908.23,408.95 906.61,490.17 901.76,491.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="940.27,400.21 940.59,379.17 942.86,384.35 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="940.27,400.53 938.65,402.47 940.59,403.45 934.12,483.05 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="943.18,381.76 944.8,400.21 946.09,399.89 944.48,403.45 953.86,505.06 944.15,511.53 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="932.5,397.62 927.97,390.18 925.71,392.77 924.09,396.97 924.41,402.47 926.03,406.03 931.21,405.71 
	932.18,400.53 932.18,396.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="941.89,338.4 933.47,329.34 856.13,62.36 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="802.09" y1="777.22" x2="801.12" y2="729.65"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="901.44,338.4 856.13,62.36 914.7,332.57 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="856.13,63 855.81,50.38 853.86,48.44 851.92,48.77 851.28,50.06 851.28,50.71 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="853.54,54.91 853.54,53.94 853.54,52 853.86,51.35 855.16,50.38 855.48,50.71 856.45,49.41 
	857.42,48.77 859.04,48.77 859.69,50.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="855.81,50.71 855.81,34.85 854.84,35.82 855.48,34.85 854.51,34.53 855.48,33.88 855.48,31.94 
	856.45,33.56 858.72,33.88 857.1,34.85 857.1,36.47 856.13,34.85 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="857.42,38.73 855.16,38.41 853.86,39.7 853.22,41.65 853.22,43.91 853.54,45.85 855.48,46.5 
	857.42,45.85 858.72,44.56 858.4,41.97 858.07,39.38 857.1,38.41 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="860.01" y1="41" x2="850.63" y2="43.91"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="809.85" y1="343.25" x2="800.47" y2="402.47"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="943.18,381.76 943.51,373.67 941.89,338.4 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="802.73" y1="478.52" x2="802.73" y2="394.38"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="508.89,663.63 506.95,641.62 505.98,632.89 503.72,628.68 498.86,620.91 492.71,615.41 485.59,611.2 
	476.86,607.32 469.41,610.23 457.76,615.41 453.88,619.62 450.32,625.77 446.44,633.21 444.17,640.98 443.85,662.98 445.47,663.31 
	446.76,664.6 446.44,666.22 445.14,665.9 445.14,670.43 442.55,724.47 442.23,731.26 442.55,738.06 442.23,739.36 443.52,740.33 
	444.17,742.91 442.23,742.91 439.96,742.91 438.99,742.91 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="416.02,719.94 400.48,722.2 400.48,763.63 379.77,763.63 379.77,778.84 362.3,778.84 361.97,791.46 
	342.23,776.25 312.14,778.19 299.19,795.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="803.38,387.59 804.68,444.54 800.79,451.34 804.68,463.31 803.7,513.47 520.87,516.39 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="510.51,515.74 428.31,515.74 428.31,502.15 431.87,497.94 425.08,491.47 413.43,495.67 414.08,501.5 
	416.66,504.74 417.63,648.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="430.26,647.13 428.31,524.8 423.46,510.56 415.69,527.39 426.7,527.39 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="430.58,641.95 515.69,507.32 591.74,629.65 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="416.34,548.42 382.36,548.42 380.74,541.63 383.33,537.1 380.1,535.16 375.89,539.36 378.48,540.98 
	379.13,549.07 322.49,646.48 318.29,648.74 319.9,679.16 306.64,697.29 305.99,745.83 303.4,759.74 305.99,762.01 301.13,793.4 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="163.28,841.62 163.6,731.91 159.39,729.32 159.72,725.12 167.81,725.12 171.37,733.21 169.42,734.82 
	270.71,736.77 270.71,729.97 277.51,731.59 277.19,737.41 277.51,797.61 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="431.87,642.92 416.99,644.54 416.02,719.94 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="417.96" y1="646.15" x2="321.52" y2="647.13"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="319.9" y1="663.31" x2="416.34" y2="664.28"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="318.93" y1="653.92" x2="418.61" y2="653.92"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="610.18,616.38 621.19,589.2 620.54,584.02 619.25,582.73 619.25,581.76 622.16,580.46 623.45,582.73 
	624.42,583.05 623.45,584.34 622.16,584.02 621.83,591.14 633.16,617.35 609.86,616.71 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M627.01,617.68c-0.09-3.52-2.92-6.37-6.44-6.47c-3.52-0.1-6.51,2.57-6.8,6.08"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="680.73,617.35 691.73,590.17 691.09,584.99 689.79,583.7 689.79,582.73 692.71,581.43 694,583.7 
	694.97,584.02 694,584.99 692.38,584.67 692.38,592.11 703.71,618.32 680.41,617.68 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M697.56,618.32c-0.09-3.52-2.92-6.37-6.44-6.47c-3.52-0.1-6.51,2.57-6.8,6.08"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="751.6,615.41 762.61,588.55 762.28,583.05 760.66,582.08 760.66,581.11 763.58,579.49 765.2,581.76 
	766.17,582.4 765.2,583.37 763.58,583.05 763.25,590.17 774.58,616.38 751.28,615.74 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M768.43,616.7c-0.09-3.53-2.92-6.37-6.44-6.47c-3.52-0.1-6.51,2.57-6.8,6.08"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="430.26,647.13 427.34,718.97 400.16,722.53 400.16,764.27 379.45,763.95 379.45,779.16 361.97,779.16 
	361.97,791.78 341.91,776.57 311.81,778.51 299.52,795.99 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="201.46,842.59 163.28,842.59 162.95,927.37 199.2,927.37 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="174.93" y1="851.33" x2="196.61" y2="851.33"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="194.67,920.58 174.93,920.58 175.25,848.09 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="336.08,708.61 337.38,697.61 340.62,691.78 343.85,688.22 351.29,683.37 352.27,682.72 364.56,690.81 
	368.12,698.26 369.74,703.11 369.74,707.64 365.86,707.64 364.56,696.64 361,691.78 357.77,688.87 352.91,687.58 347.09,690.81 
	344.5,693.73 342.56,696.96 341.59,701.82 340.94,708.61 336.41,708.61 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="342.56,762.98 363.92,762.98 363.92,706.67 361.97,698.58 358.74,693.4 352.91,689.52 345.79,695.02 
	342.56,705.7 341.91,732.24 342.56,762.98 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M359.71,698.42c0-3.31-2.68-5.99-5.99-5.99s-5.99,2.68-5.99,5.99s2.68,5.99,5.99,5.99
	S359.71,701.72,359.71,698.42"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="354.21,761.04 354.85,718.64 352.91,712.82 351.29,710.23 348.38,709.58 347.41,710.23 346.12,713.47 
	345.15,718.32 344.82,736.12 344.82,761.36 353.56,761.04 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="356.15,761.68 355.82,714.76 356.47,711.2 358.41,708.94 361,708.94 361.97,713.79 362.62,760.71 
	356.15,761.04 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="506.3,581.43 507.6,573.67 509.54,569.78 512.13,567.52 516.98,563.96 517.63,563.63 526.05,569.13 
	528.63,574.31 529.93,577.55 529.93,580.78 527.34,580.78 526.05,573.34 523.78,569.78 521.51,567.84 518.28,566.87 514.07,569.13 
	512.13,571.08 510.84,573.34 510.51,576.58 509.86,581.43 506.63,581.43 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="511.16,619.29 525.72,619.29 525.72,580.14 524.43,574.64 522.16,571.08 518.28,568.49 513.42,572.05 
	511.16,579.49 510.51,597.94 511.16,619.29 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M522.81,574.47c0-2.23-1.81-4.05-4.05-4.05c-2.23,0-4.05,1.81-4.05,4.05s1.81,4.05,4.05,4.05
	C521,578.52,522.81,576.71,522.81,574.47"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="518.93,618 519.25,588.55 518.28,584.34 516.98,582.4 515.04,582.08 514.4,582.4 513.42,584.67 
	512.78,588.23 512.78,600.53 512.45,618 518.6,618 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="520.54,618.32 520.22,585.64 520.87,583.05 522.16,581.76 523.78,581.76 524.43,584.99 524.75,617.35 
	520.54,618 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="591.09" y1="629.33" x2="802.73" y2="628.68"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="447.08" y1="975.27" x2="439.64" y2="965.24"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="804.03" y1="535.16" x2="903.7" y2="537.74"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="885.9" y1="343.25" x2="809.85" y2="343.25"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="846.42,474.64 836.71,471.4 828.95,465.26 830.89,458.46 836.39,455.87 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="852.57,451.02 855.81,450.69 861.31,451.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="878.46,452.31 882.67,453.61 884.93,457.16 881.7,464.61 877.81,469.46 871.02,473.99 871.02,474.64 
	885.9,476.91 894.32,476.26 902.41,476.91 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="846.42,475.29 818.59,472.05 804.35,472.37 803.38,472.05 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="842.86,396.65 844.48,396 845.13,397.62 845.13,398.91 847.39,400.21 850.31,403.12 851.92,409.27 
	852.89,414.45 855.81,418.98 843.83,421.89 840.27,421.89 833.48,421.24 830.56,419.95 830.89,417.04 831.86,409.92 834.77,404.09 
	839.95,401.18 840.6,398.59 840.92,397.94 842.54,397.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="825.39,463.64 823.77,393.74 803.38,392.44 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="803.38" y1="387.59" x2="899.49" y2="386.62"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="898.52" y1="390.18" x2="822.47" y2="393.74"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="824.09,449.07 829.59,443.25 832.83,443.25 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="849.98" y1="441.31" x2="860.66" y2="440.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="861.96,440.34 862.6,440.01 864.22,440.01 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="877.49" y1="439.37" x2="879.11" y2="439.04"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="881.05,439.04 882.34,439.04 887.52,443.25 883.31,448.75 877.49,453.61 871.66,457.81 871.34,461.7 
	871.02,469.79 871.34,474.64 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="846.75,474.96 848.04,463.64 847.39,457.81 836.07,455.87 827.65,449.72 823.45,447.13 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="822.8" y1="464.28" x2="820.53" y2="393.09"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="891.4" y1="388.88" x2="894.32" y2="446.81"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="892.05" y1="446.81" x2="890.11" y2="426.75"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="889.46" y1="422.21" x2="887.2" y2="390.5"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="839.95,421.24 839.3,423.51 839.63,424.8 842.21,425.77 843.83,424.16 843.83,422.21 843.19,421.89 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="842.21,432.89 844.16,432.57 844.8,434.19 844.8,435.16 846.75,436.78 849.66,439.37 851.6,445.84 
	852.25,450.69 855.16,455.22 845.77,457.49 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="832.83" y1="457.49" x2="832.83" y2="457.49"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="830.56,451.99 831.21,446.16 834.45,440.34 839.3,437.75 840.27,435.16 840.27,434.19 841.89,434.19 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="842.86,431.92 841.89,429.66 841.24,428.69 840.92,431.6 840.92,432.89 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="823.77" y1="428.69" x2="828.95" y2="428.04"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="832.83" y1="427.72" x2="851.6" y2="426.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="853.86,425.77 890.11,422.54 889.79,426.75 853.22,429.66 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="850.63" y1="429.66" x2="842.21" y2="430.3"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="840.92" y1="430.3" x2="833.15" y2="430.95"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="829.59" y1="431.28" x2="824.09" y2="431.6"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="865.84,394.38 866.49,391.8 867.78,391.8 868.1,394.38 868.1,395.03 864.22,395.03 861.31,399.56 
	860.34,403.45 859.37,408.62 860.01,415.42 859.04,418.65 864.54,418.98 869.72,418.01 876.84,418.01 882.34,418.65 882.34,415.1 
	881.05,409.59 878.78,400.21 874.25,396.65 869.72,395.68 866.49,395.03 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="867.13,418.65 867.78,419.95 869.72,420.92 870.69,418.65 869.72,417.68 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="868.1,428.69 867.46,431.6 868.75,431.28 869.4,430.95 868.75,428.36 867.78,427.72 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="867.46,431.28 864.54,438.4 862.93,444.87 862.93,447.46 860.66,452.31 860.98,454.9 863.25,454.25 
	869.4,454.25 875.22,454.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="883.64,449.4 877.81,439.69 875.55,432.25 873.28,430.63 870.37,430.3 868.75,430.95 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="870.37,453.61 869.4,455.22 870.05,456.84 871.66,456.84 872.31,454.25 871.66,453.61 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="886.23,444.87 896.26,446.16 898.52,447.13 900.47,446.81 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="878.14,428.04 880.4,429.33 881.37,433.22 881.7,437.1 880.72,440.01 878.78,441.96 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="862.28,442.6 859.37,438.4 859.04,436.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="832.51,421.24 830.56,424.16 828.95,427.39 829.59,431.92 830.56,433.86 832.51,436.13 834.77,437.1 
	837.04,437.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="851.28,419.95 852.57,422.21 854.19,426.42 852.57,431.28 850.95,433.86 849.01,435.81 846.75,436.45 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="859.04,436.78 859.04,434.51 859.69,431.6 860.01,430.3 860.66,429.01 860.98,429.01 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="863.25,441.96 861.31,439.69 860.98,437.42 861.31,434.84 861.63,432.25 862.6,429.98 863.25,429.33 
	863.57,428.69 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="878.14,440.34 879.43,439.04 879.75,436.13 879.75,432.89 879.11,430.3 877.49,429.01 875.87,428.04 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="849.66,420.6 850.63,421.57 851.28,424.48 851.6,426.75 850.95,429.33 849.33,431.6 848.04,433.22 
	847.07,434.51 844.8,435.48 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="838.98,436.13 837.36,435.48 834.45,433.86 833.48,432.25 832.83,429.98 832.51,426.75 832.83,424.16 
	833.8,421.57 834.12,420.92 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="877.81" y1="523.18" x2="889.79" y2="476.26"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="888.49" y1="479.82" x2="902.73" y2="479.17"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="887.2" y1="484.02" x2="903.38" y2="484.67"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="885.9" y1="489.85" x2="900.79" y2="489.85"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="884.61" y1="494.06" x2="902.73" y2="495.03"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="883.31" y1="499.56" x2="902.73" y2="499.56"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="882.99" y1="503.44" x2="902.73" y2="504.09"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="881.7" y1="507.65" x2="902.73" y2="507.97"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="881.7" y1="511.53" x2="902.73" y2="511.53"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="878.78" y1="516.71" x2="902.73" y2="516.39"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="879.75" y1="519.62" x2="902.73" y2="521.24"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="877.17" y1="523.18" x2="902.73" y2="523.51"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="875.55,522.53 868.1,521.89 852.25,520.27 841.89,519.62 831.21,519.3 840.6,508.62 853.54,500.53 
	883.64,499.56 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="885.9,488.56 850.63,486.61 835.1,496.65 832.18,509.59 881.7,511.21 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="831.86" y1="518.97" x2="832.18" y2="509.59"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="832.18" y1="511.53" x2="881.7" y2="513.15"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="878.46" y1="519.62" x2="830.24" y2="516.06"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="848.36" y1="509.59" x2="847.72" y2="519.62"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="850.63" y1="509.91" x2="850.63" y2="519.95"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="865.84" y1="509.59" x2="865.84" y2="520.92"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="867.46" y1="509.59" x2="867.46" y2="521.89"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="852.57" y1="500.21" x2="851.6" y2="485.64"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="833.8,509.59 837.68,498.59 852.57,489.53 888.17,491.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="873.93,538.07 874.58,543.25 867.78,544.86 868.1,549.39 862.28,549.07 862.28,554.9 856.45,555.22 
	857.1,560.07 849.98,560.07 849.66,566.22 844.8,566.22 844.16,571.72 838.98,571.4 838.66,579.81 832.51,579.81 832.51,586.29 
	827.01,587.58 827.33,595.02 802.09,596.97 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="827.65,595.67 825.06,606.03 883.64,608.29 894.64,586.93 848.04,582.4 826.36,596.64 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="894.64" y1="586.61" x2="877.81" y2="659.75"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="868.43" y1="675.6" x2="868.75" y2="657.48"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="868.1" y1="657.48" x2="801.12" y2="657.16"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="868.1" y1="675.6" x2="800.47" y2="677.55"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="829.92" y1="656.19" x2="830.89" y2="606.03"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="835.1,658.78 835.74,623.18 856.13,621.88 854.84,657.48 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="837.68,649.07 839.63,649.71 839.95,650.36 838.98,651.66 838.98,650.36 838.01,650.04 837.36,650.36 
	837.36,649.07 837.68,649.07 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="804.35" y1="657.16" x2="804.35" y2="677.87"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="810.5" y1="657.48" x2="810.18" y2="677.55"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="816.65" y1="656.51" x2="816.97" y2="677.55"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="822.15" y1="657.8" x2="821.83" y2="677.87"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="827.01" y1="657.48" x2="826.68" y2="677.55"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="832.51" y1="657.48" x2="832.83" y2="677.55"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="838.01" y1="657.16" x2="838.01" y2="677.55"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="844.16" y1="657.16" x2="843.83" y2="677.22"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="849.98" y1="656.83" x2="849.66" y2="677.22"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="855.16" y1="657.16" x2="854.51" y2="676.57"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="860.01" y1="657.16" x2="860.01" y2="676.57"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="864.87" y1="657.48" x2="864.87" y2="672.69"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="849.01,581.76 848.36,570.75 897.88,568.16 879.75,593.41 829.27,592.76 825.39,608.62 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="880.72" y1="592.11" x2="882.67" y2="607.97"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="897.55,567.52 893.99,588.88 903.05,588.88 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="877.81" y1="659.42" x2="877.81" y2="607.32"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="877.17" y1="660.39" x2="902.73" y2="660.39"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="879.43" y1="653.27" x2="903.05" y2="652.63"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="881.37" y1="646.15" x2="902.73" y2="646.15"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="882.67" y1="639.68" x2="903.05" y2="640.65"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="883.96" y1="632.24" x2="902.73" y2="632.89"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="885.9" y1="625.44" x2="902.73" y2="625.12"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="886.87" y1="619.29" x2="902.73" y2="619.29"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="888.49" y1="614.12" x2="903.38" y2="614.12"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="891.4" y1="607" x2="902.73" y2="607.64"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="891.4" y1="601.17" x2="902.73" y2="600.85"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="893.67" y1="595.35" x2="902.73" y2="595.02"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="868.1" y1="675.6" x2="902.73" y2="675.6"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="820.53,677.55 820.53,683.05 826.36,683.05 826.68,688.22 831.21,688.22 831.21,693.73 837.04,694.37 
	838.01,700.52 841.89,699.87 842.21,705.38 848.04,706.99 846.75,712.17 852.57,712.82 852.89,718 859.04,718.32 858.72,723.17 
	864.22,724.47 862.6,733.21 869.4,734.82 870.05,744.21 889.14,744.53 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="870.05,734.82 869.72,713.14 872.96,713.14 872.96,744.21 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="869.72,743.56 819.24,742.91 819.89,793.4 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="819.24,743.24 813.41,709.91 847.39,708.94 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="813.74" y1="709.91" x2="812.44" y2="793.07"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="844.16,802.14 844.16,762.33 863.57,762.01 862.93,803.43 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="846.75,783.37 848.04,783.37 849.33,784.01 849.33,785.31 849.01,785.31 848.36,784.01 847.07,784.01 
	846.75,784.34 846.1,783.37 846.42,782.72 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="823.12" y1="463.64" x2="825.71" y2="463.31"/>
</svg>
</div>
)
}

export default KirkeKart