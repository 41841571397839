import React, {useEffect, useState, useRef} from "react";
import {PortableText} from '@portabletext/react'
import imageUrlBuilder from '@sanity/image-url'
import client from "../client";

import { Navigation, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './fortelling.styles.scss'

import Ikon from './graphics/Ikon.component'
import Ramme from './graphics/Ramme.component'

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

const Fortelling=({data, fortellingOpen, setFortellingOpenHandler, zoomLevel, setBigPicture})=>{
// console.log(data); //
// console.log(zoomLevel); //
//580px, 310px
//59.926065430933505

//  10.751211
//- 10.759831205941738

//0,004528

//gang med et tall som er windowWidth / faktor

//128091
//157022
//window == 1440 = -79.45
    // -18.1

// window == 1400 = -81.6
//window == 1200 = -95.25
    // -12.59
//window == 1000 = 1016.868px = 114.25
    // - 8.75
    // 6 i forskjell
//windowwidth * 88.95

let y = ((59.930594 - data.location.lat) * 870000)
let x = ((10.751211 - data.location.lng) * -436000)



// let y = ((59.930594 - data.location.lat) * (window.innerHeight * 305))
// let x = ((10.751211 - data.location.lng) * ((window.innerWidth * -79.45))) // trekke fra 1440 (som er min referanse?)
    // const location={y:(59.930594 - data.location.lat) * 871000, x:((10.751211 - data.location.lng) * -438500) - 25}
    const location={y:y, x:x}
    //console.log(location)
    //location må settes i relation to window.innerHeight / width
    // bruke zoomlevel for å sette riktig høyde på ting
    const openStoryHandler=()=>{
        setFortellingOpenHandler({id:data._id,pos:location})
        if ('url' in data){
            window.history.replaceState(null, '', "/fortelling/" + data.url.current)
        }
    }

    const myPortableTextComponents = {
        marks:{
            em: ({children}) => {
                return (
                  <em>
                    {children}
                  </em>
                )
              },
        },
    }
    
return(
<div className="fortelling-wrapper"
style={{transform:`translate(${location.x}px, ${location.y}px)`,zIndex: `${fortellingOpen == data._id || fortellingOpen == 1 ? 9 : 8}`}}
>
    {fortellingOpen == data._id ?
    <div className={`fortelling-content-wrapper ${data.kategori}`}>
    <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}>
        {data.tekst &&
        <SwiperSlide>
            <div className='swiper-text'>
                <div className="swiper-text-title">
                    {data.title}
                </div>
                <PortableText
                    value={data.tekst}
                    components={myPortableTextComponents}
                />
            </div>
       </SwiperSlide>
        }
        {data.bilder ? data.bilder.map((bilde,index) =>{
            return <SwiperSlide key={index}>
                
                <div className='swiper-image' onClick={(e)=>{setBigPicture(bilde.image.bildefil)}}>
                    <img src={urlFor(bilde.image.bildefil).width(500).url()} />
                    <div className="swiper-image-caption">
                        {bilde.image.title}
                        
                    </div>
                    {/* {console.log(bilde)} */}
                </div>
            </SwiperSlide>
        }) : ""}
    </Swiper>
    <div className="fortelling-ramme">
        <Ramme kategori={data.kategori}/>
    </div>
    </div> : ''}
    
    <div className="ikon-wrapper" style={{opacity: `${fortellingOpen == data._id || fortellingOpen == 1 ? 1 : 0.5}`}} onClick={()=>openStoryHandler()}>
        <Ikon kategori={data.kategori}/>
    </div>
</div>
)
}
export default Fortelling