import React, {useEffect, useState, useRef} from "react";
import {PortableText} from '@portabletext/react'
import imageUrlBuilder from '@sanity/image-url'
import client from "../client";

import { Navigation, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './event.styles.scss'

// import Ikon from './graphics/Ikon.component'
// import Ramme from './graphics/Ramme.component'

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

const Event=({data, language, fortellingOpen, setFortellingOpenHandler, zoomLevel, setBigPicture})=>{

    let y = ((59.930594 - data.location.lat) * 870000)
let x = ((10.751211 - data.location.lng) * -436000)

const location={y:y, x:x}

const openStoryHandler=()=>{
    setFortellingOpenHandler({id:data._id,pos:location})
}

const returnTime =(date)=>{
    const time = new Date(date).toLocaleTimeString('no-NO', {timeZone:'Europe/Oslo', timeStyle: 'short', hour12: false });
    return time;
}
// console.log(data)
return(
<div className="event-wrapper"
style={{transform:`translate(${location.x}px, ${location.y}px)`,zIndex: `${fortellingOpen == data._id || fortellingOpen == 1 ? 9 : 8}`}}
>
    {fortellingOpen == data._id ?
    <div className={`event-content-wrapper`}>
    <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}>
        
        <SwiperSlide>
            <div className='swiper-text'>
                {/* <p> */}
                <div className="swiper-text-title-wrapper">

                    <div className="swiper-text-title border-top">
                        {language == 'NO' ? data.title : data.titleEng}
                    </div>
                    
                    <div className="swiper-text-subtitle border-bottom">
                        {/* {'undertittel' in data ? language == 'NO' ? <>{data.undertittel}<br/></> : <>{data.undertittelEng}<br/></> :''} */}
                        {returnTime(data.tidspunkt)}
                    </div>

                </div>
            
                <div className="swiper-event-text-wrapper">
                    <PortableText
                        value={language == 'NO' ? data.tekst : data.tekstEng}
                    />
                </div>
                {'underevent' in data ?
                <>
                {/* {console.log(data.underevent[0])} */}
                <div className="swiper-text-title-wrapper">
                    <div className="swiper-text-title border-top">
                        {language == 'NO' ? data.underevent[0].title : 'titleEng' in data.underevent[0] ? data.underevent[0].titleEng : data.underevent[0].title}
                    </div>
                    
                    <div className="swiper-text-subtitle border-bottom">
                        {/* {'undertittel' in data ? language == 'NO' ? data.underevent[0].undertittel : 'titleEng' in data.underevent[0] ? data.underevent[0].undertittelEng : data.underevent[0].undertittel :''} */}
                        {returnTime(data.underevent[0].tidspunkt)}
                    </div>
                </div>

                {'tekst' in data.underevent[0] ? 
                    <div className="swiper-event-text-wrapper border-top margin-top">
                        <div className="swiper-text-subtitle">
                            {'undertittel' in data ? language == 'NO' ? data.underevent[0].title : 'titleEng' in data.underevent[0] ? data.underevent[0].titleEng : data.underevent[0].title :''}
                        </div>
                        <PortableText
                            value={language == 'NO' ? data.underevent[0].tekst : data.underevent[0].tekstEng}
                        />
                    </div>
                    :''}
                </> :""}
                {/* </p> */}
            </div>
       </SwiperSlide>
        
       {data.bilder ? data.bilder.map((bilde,index) =>{
            return <SwiperSlide key={index}>
                
                <div className='swiper-image' onClick={(e)=>{setBigPicture(bilde.image.bildefil)}}>
                    <img src={urlFor(bilde.image.bildefil).width(500).url()} />
                    <div className="swiper-image-caption">
                        {/* {bilde.image.title} */}
                    </div>
                    {/* {console.log(bilde)} */}
                </div>
            </SwiperSlide>
        }) : ""}
    </Swiper>
    <div className="event-ramme">
        {/* <Ramme kategori={data.kategori}/> */}
    </div>
    </div> : ''}
    
    <div className="ikon-wrapper" style={{opacity: `${fortellingOpen == data._id || fortellingOpen == 1 ? 1 : 0.5}`}} onClick={()=>openStoryHandler()}>
        {returnTime(data.tidspunkt)}
        <div className="point"></div>
    </div>
</div>
)
}
export default Event