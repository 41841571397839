import React from "react";

import './graphics.styles.scss'

const Ramme =({kategori})=>{
return(
<div>
        {kategori == 'kat1'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 139.05 200">
<path className="fødeRamme" d="M134.88,179.74V20.26c3.69-0.22,3.95-1.06,4.16-2.51c0.3-2.1-2.94-2.65-2.94-2.65s2.06-0.13,1.93-2.23
	c-0.15-2.56-3.57-2.56-3.57-2.56s2.69-0.34,2.44-2.52c-0.25-2.19-2.52-2.65-2.52-2.65s2.02-0.55,1.22-1.68
	c-0.8-1.14-3.45-1.39-3.45-1.39c-0.04-0.59,0.17-1.98-1.93-2.06c-2.1-0.08-1.56,2.48-1.56,2.48c-0.38,0-2.48,0.29-2.61,1.56
	c-0.13,1.26,2.86,1.89,2.86,1.89c-1.98,0.25-2.86,0.8-2.86,2.23s3.53,1.81,3.53,1.81c-2.02-0.08-3.53-0.04-3.53,0.97
	s1.64,1.93,1.64,1.93l0.03,0.03H11.34l0.03-0.03c0,0,1.64-0.93,1.64-1.93S11.5,9.88,9.48,9.97c0,0,3.53-0.38,3.53-1.81
	s-0.88-1.98-2.86-2.23c0,0,2.99-0.63,2.86-1.89c-0.13-1.26-2.23-1.56-2.61-1.56c0,0,0.55-2.56-1.56-2.48
	c-2.1,0.08-1.89,1.47-1.93,2.06c0,0-2.65,0.25-3.45,1.39s1.22,1.68,1.22,1.68S2.42,5.59,2.16,7.78C1.91,9.97,4.6,10.3,4.6,10.3
	s-3.42,0-3.57,2.56c-0.13,2.1,1.93,2.23,1.93,2.23s-3.24,0.55-2.94,2.65c0.2,1.41,0.48,2.24,3.88,2.49v159.53
	c-3.4,0.25-3.68,1.08-3.88,2.49c-0.3,2.1,2.94,2.65,2.94,2.65s-2.06,0.13-1.93,2.23c0.15,2.56,3.57,2.56,3.57,2.56
	s-2.69,0.34-2.44,2.52c0.25,2.19,2.52,2.65,2.52,2.65s-2.02,0.55-1.22,1.68s3.45,1.39,3.45,1.39c0.04,0.59-0.17,1.98,1.93,2.06
	c2.1,0.08,1.56-2.48,1.56-2.48c0.38,0,2.48-0.29,2.61-1.56c0.13-1.26-2.86-1.89-2.86-1.89c1.98-0.25,2.86-0.8,2.86-2.23
	c0-1.43-3.53-1.81-3.53-1.81c2.02,0.08,3.53,0.04,3.53-0.97c0-0.76-0.92-1.46-1.38-1.77h115.79c-0.46,0.31-1.38,1.02-1.38,1.77
	c0,1.01,1.51,1.05,3.53,0.97c0,0-3.53,0.38-3.53,1.81c0,1.43,0.88,1.98,2.86,2.23c0,0-2.99,0.63-2.86,1.89
	c0.13,1.26,2.23,1.56,2.61,1.56c0,0-0.55,2.56,1.56,2.48c2.1-0.08,1.89-1.47,1.93-2.06c0,0,2.65-0.25,3.45-1.39
	c0.8-1.14-1.22-1.68-1.22-1.68s2.27-0.46,2.52-2.65c0.25-2.19-2.44-2.52-2.44-2.52s3.42,0,3.57-2.56c0.13-2.1-1.93-2.23-1.93-2.23
	s3.24-0.55,2.94-2.65C138.83,180.8,138.56,179.97,134.88,179.74z"/>
        </svg>
    :''}
        {kategori == 'kat2'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 150.21 193.86">

<path className="byggeRamme" d="M146.9,31.17c3.41,0.24,3.31-3.41,3.31-3.41s0.19-1.9-2.83-3.02c-3.02-1.12-3.26-1.36-3.26-1.36
	s0.24-0.24,3.26-1.36c3.02-1.12,2.83-3.02,2.83-3.02s0.1-3.65-3.31-3.41c-3.41,0.24-2.98,3.65-2.98,3.79c0,0.15-1.64,0.35-2.11,1.25
	c-0.6,1.14-1.11,0.9-1.11,0.9V9.52h-11.98c0,0-0.24-0.51,0.9-1.11c0.9-0.47,1.1-2.11,1.25-2.11c0.15,0,3.55,0.43,3.79-2.98
	c0.24-3.41-3.41-3.31-3.41-3.31s-1.9-0.19-3.02,2.83c-1.12,3.02-1.36,3.26-1.36,3.26s-0.24-0.24-1.36-3.26
	C124.39-0.19,122.49,0,122.49,0s-3.65-0.1-3.41,3.31c0.24,3.41,3.65,2.98,3.79,2.98c0.15,0,0.35,1.64,1.25,2.11
	c1.14,0.6,0.9,1.11,0.9,1.11H25.26c0,0-0.24-0.51,0.9-1.11c0.9-0.47,1.1-2.11,1.25-2.11c0.15,0,3.55,0.43,3.79-2.98
	C31.45-0.09,27.79,0,27.79,0s-1.9-0.19-3.02,2.83c-1.12,3.02-1.36,3.26-1.36,3.26s-0.24-0.24-1.36-3.26C20.92-0.19,19.02,0,19.02,0
	s-3.65-0.1-3.41,3.31c0.24,3.41,3.65,2.98,3.79,2.98c0.15,0,0.35,1.64,1.25,2.11c1.14,0.6,0.9,1.11,0.9,1.11H9.52v11.98
	c0,0-0.51,0.24-1.11-0.9c-0.47-0.9-2.11-1.1-2.11-1.25c0-0.15,0.43-3.55-2.98-3.79C-0.09,15.3,0,18.95,0,18.95s-0.19,1.9,2.83,3.02
	c3.02,1.12,3.26,1.36,3.26,1.36s-0.24,0.24-3.26,1.36C-0.19,25.82,0,27.72,0,27.72s-0.1,3.65,3.31,3.41
	c3.41-0.24,2.98-3.65,2.98-3.79c0-0.15,1.64-0.35,2.11-1.25c0.6-1.14,1.11-0.9,1.11-0.9V168.6c0,0-0.51,0.24-1.11-0.9
	c-0.47-0.9-2.11-1.1-2.11-1.25c0-0.15,0.43-3.55-2.98-3.79c-3.41-0.24-3.31,3.41-3.31,3.41s-0.19,1.9,2.83,3.02
	c3.02,1.12,3.26,1.36,3.26,1.36s-0.24,0.24-3.26,1.36c-3.02,1.12-2.83,3.02-2.83,3.02s-0.1,3.65,3.31,3.41
	c3.41-0.24,2.98-3.65,2.98-3.79c0-0.15,1.64-0.35,2.11-1.25c0.6-1.14,1.11-0.9,1.11-0.9v12.04h11.98c0,0,0.24,0.51-0.9,1.11
	c-0.9,0.47-1.1,2.11-1.25,2.11c-0.15,0-3.55-0.43-3.79,2.98c-0.24,3.41,3.41,3.31,3.41,3.31s1.9,0.19,3.02-2.83s1.36-3.26,1.36-3.26
	s0.24,0.24,1.36,3.26c1.12,3.02,3.02,2.83,3.02,2.83s3.65,0.1,3.41-3.31c-0.24-3.41-3.65-2.98-3.79-2.98s-0.35-1.64-1.25-2.11
	c-1.14-0.6-0.9-1.11-0.9-1.11h99.78c0.03,0.12,0.06,0.57-0.91,1.08c-0.9,0.47-1.1,2.11-1.25,2.11s-3.55-0.43-3.79,2.98
	c-0.24,3.41,3.41,3.31,3.41,3.31s1.9,0.19,3.02-2.83c1.12-3.02,1.36-3.26,1.36-3.26s0.24,0.24,1.36,3.26
	c1.12,3.02,3.02,2.83,3.02,2.83s3.65,0.1,3.41-3.31c-0.24-3.41-3.65-2.98-3.79-2.98c-0.15,0-0.35-1.64-1.25-2.11
	c-0.98-0.51-0.94-0.96-0.91-1.08h12.05v-12.01c0,0,0.51-0.24,1.11,0.9c0.47,0.9,2.11,1.1,2.11,1.25c0,0.15-0.43,3.55,2.98,3.79
	c3.41,0.24,3.31-3.41,3.31-3.41s0.19-1.9-2.83-3.02c-3.02-1.12-3.26-1.36-3.26-1.36s0.24-0.24,3.26-1.36
	c3.02-1.12,2.83-3.02,2.83-3.02s0.1-3.65-3.31-3.41c-3.41,0.24-2.98,3.65-2.98,3.79c0,0.15-1.64,0.35-2.11,1.25
	c-0.6,1.14-1.11,0.9-1.11,0.9V25.22c0,0,0.51-0.24,1.11,0.9c0.47,0.9,2.11,1.1,2.11,1.25C143.91,27.52,143.49,30.92,146.9,31.17z"/>
        </svg>
    :''}
        {kategori == 'kat3'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 158.91 174.85">
<path className="eieRamme" d="M158.9,81.28c-0.2-1.63-1.82-1.94-3.89-1.68c-1.88,0.23-8.31,1.61-10.06,2.83V22.54c0,0,4.72,3.03,8.75,3.09
	c4.03,0.07,5.11-1.08,5.19-1.88c0.08-0.81-0.85-2.09-3.02-2.49c0,0,3.18-0.13,3.02-1.88c-0.16-1.75-3.33-1.88-3.33-1.88
	s3.58,0.13,3.34-1.68c-0.24-1.82-2.56-1.95-3.57-2.02c-1.01-0.07,3.84-0.33,3.58-2.35c-0.2-1.63-1.82-1.94-3.89-1.68
	c-1.88,0.23-8.31,1.61-10.06,2.83V0H13.97v12.77c0,0-4.72-3.03-8.75-3.09c-4.03-0.07-5.11,1.08-5.19,1.88
	c-0.08,0.81,0.85,2.09,3.02,2.49c0,0-3.18,0.13-3.02,1.88c0.16,1.75,3.33,1.88,3.33,1.88s-3.58-0.13-3.34,1.68s2.56,1.95,3.57,2.02
	c1.01,0.07-3.84,0.33-3.58,2.35c0.2,1.63,1.82,1.94,3.89,1.68c1.88-0.23,8.31-1.61,10.06-2.83v59.88c0,0-4.72-3.03-8.75-3.09
	c-4.03-0.07-5.11,1.08-5.19,1.88c-0.08,0.81,0.85,2.09,3.02,2.49c0,0-3.18,0.13-3.02,1.88c0.16,1.75,3.33,1.88,3.33,1.88
	s-3.58-0.13-3.34,1.68c0.24,1.82,2.56,1.95,3.57,2.02c1.01,0.07-3.84,0.33-3.58,2.35c0.2,1.63,1.82,1.94,3.89,1.68
	c1.88-0.23,8.31-1.61,10.06-2.83v59.88c0,0-4.72-3.03-8.75-3.09c-4.03-0.07-5.11,1.08-5.19,1.88c-0.08,0.81,0.85,2.09,3.02,2.49
	c0,0-3.18,0.13-3.02,1.88c0.16,1.75,3.33,1.88,3.33,1.88s-3.58-0.13-3.34,1.68c0.24,1.82,2.56,1.95,3.57,2.02
	c1.01,0.07-3.84,0.33-3.58,2.35c0.2,1.63,1.82,1.94,3.89,1.68c1.88-0.23,8.31-1.61,10.06-2.83v12.45h130.98v-12.64
	c0,0,4.72,3.03,8.75,3.09c4.03,0.07,5.11-1.08,5.19-1.88c0.08-0.81-0.85-2.09-3.02-2.49c0,0,3.18-0.13,3.02-1.88
	c-0.16-1.75-3.33-1.88-3.33-1.88s3.58,0.13,3.34-1.68s-2.56-1.95-3.57-2.02c-1.01-0.07,3.84-0.33,3.58-2.35
	c-0.2-1.63-1.82-1.94-3.89-1.68c-1.88,0.23-8.31,1.61-10.06,2.83V92.38c0,0,4.72,3.03,8.75,3.09c4.03,0.07,5.11-1.08,5.19-1.88
	c0.08-0.81-0.85-2.09-3.02-2.49c0,0,3.18-0.13,3.02-1.88c-0.16-1.75-3.33-1.88-3.33-1.88s3.58,0.13,3.34-1.68
	c-0.24-1.82-2.56-1.95-3.57-2.02C154.31,83.56,159.16,83.3,158.9,81.28z"/>
        </svg>
    :''}
        {kategori == 'kat4'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 145.47 195.12">
<path className="traskeRamme" d="M144.34,191.51c-1.03-2.01-3.51-2.81-3.51-2.81c1.97-0.05,4.41,1.31,4.41,1.31c0-5.01-4.32-6-4.32-6
	c3.05-0.09,4.32,0.98,4.32,0.98c-0.22-1.24-0.56-3.74-2.14-5.2c-1.15-1.07-2.84-1.05-3.67-0.97V13.41h-0.03l-0.04-8.53
	c0.73,0,1.85-0.4,1.85-1.89s-2.32-1.34-2.32-1.34S139.08,0,137.9,0c-1.18,0-1.1,1.58-1.1,1.58c-1.26,0-2.21,0.11-2.21,1.26
	c0,1.42,2.21,1.42,2.21,1.42c0,7.02-2.68,9.15-2.68,9.15H14.75l-3.01-2.62c0,0-0.74-0.79-2.01-0.79s-2.58,1.05-2.58,3.41v156.43
	c0,0-5.11,0.13-6.42-1.18c0,0-1.19,3.82,4.44,5.54c0,0-2.6,0.68-4.22-0.94c0,0-0.17-0.03-0.14,1.32c0.03,1.44,0.93,3.69,4.46,4.82
	c0,0-2.45,0.83-4.02-0.88c0,0-0.88,0.88-0.05,2.65c0.83,1.77,2.84,2.89,4.66,3.38c0,0-3.92,0.34-5.64-1.13c0,0-0.69,1.23,0.34,3.09
	c1.03,1.86,2.87,2.99,6.58,2.99h127.08c0.37,0.69,1.87,1.6,1.87,1.6c-3.05,1.27-2.67,3.42-2.67,3.42c1.78-0.98,3.56-0.89,6.09-0.89
	c4.26,0,5.95,1.5,5.95,1.5C145.32,193.57,145.37,193.52,144.34,191.51z"/>
        </svg>
    :''}
        {kategori == 'kat5'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 167.96 193.03">
<path className="lekeRamme" d="M92.42,193.03h56.84v-78.91h2.53c0,0,0.31-5.55,4.14-6.8c0,0-1.8,1.48-0.94,2.81c0.86,1.33,2.4,0.36,2.63,0.2
	c-0.07,0.61,0.1,2.06,1.28,2.22c1.17,0.16,1.88-0.63,1.95-1.41c0.44,1.05,0.91,1.43,1.72,1.33c1.24-0.15,1.64-1.25,1.41-2.11
	c1.14,0.71,2.17,0.7,2.73-0.39c0.56-1.09-0.39-1.8-0.78-2.11c0.76-0.08,2.03-0.31,2.03-1.88c0-1.56-1.64-1.56-1.64-1.56
	s1.17-1.17,0.7-2.34c-0.47-1.17-2.53-0.95-2.53-0.95s0.33-1.91-0.86-2.31c-0.98-0.33-1.91,0.06-2.45,1.31c0,0-0.92-1.76-2.43-1.48
	c-0.91,0.17-1.59,1.29-1.25,2.73c0,0-1.48-1.09-2.27-0.08c-0.78,1.02,0.63,2.81,0.63,2.81s-4.06-1.95-4.06-6.88h-2.53V18.7H92.42
	v-2.53c0,0-5.55-0.31-6.8-4.14c0,0,1.48,1.8,2.81,0.94c1.33-0.86,0.36-2.4,0.2-2.63c0.61,0.07,2.06-0.1,2.22-1.28
	c0.16-1.17-0.63-1.88-1.41-1.95c1.05-0.44,1.43-0.91,1.33-1.72c-0.15-1.24-1.25-1.64-2.11-1.41c0.71-1.14,0.7-2.17-0.39-2.73
	c-1.09-0.56-1.8,0.39-2.11,0.78C86.09,1.28,85.85,0,84.29,0c-1.56,0-1.56,1.64-1.56,1.64s-1.17-1.17-2.34-0.7s-0.95,2.53-0.95,2.53
	s-1.91-0.33-2.31,0.86c-0.33,0.98,0.06,1.91,1.31,2.45c0,0-1.76,0.92-1.48,2.43c0.17,0.91,1.29,1.59,2.73,1.25
	c0,0-1.09,1.48-0.08,2.27c1.02,0.78,2.81-0.63,2.81-0.63s-1.95,4.06-6.88,4.06v2.53H18.7v78.54h-2.53c0,0-0.31,5.55-4.14,6.8
	c0,0,1.8-1.48,0.94-2.81c-0.86-1.33-2.4-0.36-2.63-0.2c0.07-0.61-0.1-2.06-1.28-2.22c-1.17-0.16-1.88,0.63-1.95,1.41
	c-0.44-1.05-0.91-1.43-1.72-1.33c-1.24,0.15-1.64,1.25-1.41,2.11c-1.14-0.71-2.17-0.7-2.73,0.39c-0.56,1.09,0.39,1.8,0.78,2.11
	C1.28,103.57,0,103.81,0,105.37c0,1.56,1.64,1.56,1.64,1.56s-1.17,1.17-0.7,2.34c0.47,1.17,2.53,0.95,2.53,0.95
	s-0.33,1.91,0.86,2.31c0.98,0.33,1.91-0.06,2.45-1.31c0,0,0.92,1.76,2.43,1.48c0.91-0.17,1.59-1.29,1.25-2.73
	c0,0,1.48,1.09,2.27,0.08c0.78-1.02-0.63-2.81-0.63-2.81s4.06,1.95,4.06,6.88h2.53v78.91h56.84H92.42z"/>
        </svg>
    :''}

</div>
)
}

export default Ramme