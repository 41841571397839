import React, {useEffect, useState,createRef, useRef} from "react";

import sanityClient from "../client.js";
import imageUrlBuilder from '@sanity/image-url'
import {PortableText} from '@portabletext/react'
import {getFile,getFileAsset, buildFilePath,buildFileUrl} from '@sanity/asset-utils'

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { Navigation, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { BiX } from 'react-icons/bi';
import { AiOutlineMenu } from 'react-icons/ai';


import './musikkArtikkel.styles.scss';
import MusikkRamme from './graphics/MusikkRamme.component'

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}

const MusikkArtikkel=({artikkelContent, language, currentIndex, setContentExpanded, setBigPicture})=>{

    const [audioPlaying, setAudioPlaying] = useState(null)

    const containerRef = useRef();
    const scrollRefs = useRef([]);
    const menuScrollRef = useRef([]);

    scrollRefs.current = [...Array(artikkelContent.orgelAvsnitt.length).keys()].map(
        (_, i) => scrollRefs.current[i] ?? createRef()
    );

    const scrollSmoothHandler = (index) => {
        scrollRefs.current[index].current.scrollIntoView({ behavior: "smooth" });
    };

    const menuScrollHandler = () => {
        menuScrollRef.current.scrollIntoView({ behavior: "smooth" });
    };

      useEffect(()=>{
        scrollSmoothHandler(currentIndex)
      },[currentIndex])

    const onPlayAudio = (src) =>{
        const allAudio = Array.from(
            document.querySelectorAll('audio')
          );

          for(let i = 0; allAudio.length > i; i++){
            if(allAudio[i].currentSrc != src){
                allAudio[i].pause()
            }
          }
    }

    const myPortableTextComponents = {
        marks:{
            em: ({children}) => {
                return (
                  <em>
                    {children}
                  </em>
                )
              },
        },
        types: {
          image: ({value}) => {
              console.log(value)
              return(<div className="avsnitt-image-wrapper">
                <img src={urlFor(value.asset).width(1200).url()} onClick={()=>{setBigPicture(value.asset)}}/>
                <div className="avsnitt-image-caption">{language == 'NO' ? value.caption : value.captionEng}</div>
                </div>)},
          slideshow: ({value}) => {
              return <Swiper
              autoHeight={true}
              spaceBetween={20}
              className="avsnitt-slideshow-wrapper"
              modules={[Navigation, Pagination]}
              navigation
              pagination={{ clickable: true }}
              loop={true}
              >
              {value.slideshowArray.map((slide,index)=>{
                return <SwiperSlide key={index}>
                    <div className='avsnitt-swiper-image'>
                        <img src={urlFor(slide.asset).width(1200).url()} onClick={()=>{setBigPicture(slide.asset)}}/>
                        <div className="avsnitt-swiper-image-caption"> {language == 'NO' ? slide.bildetekst : slide.bildetekstEng}</div>
                    </div>
                </SwiperSlide>    
              })}
              </Swiper>
            },
          audioFile: ({value}) =>{
            // console.log(value)
            if ('audioFile' in value){
                let file = getFile(value.audioFile.asset, sanityClient.config())
                return <div className="lydsnutt-wrapper">
                      <AudioPlayer
                          className="lydsnutt"
                          src={file.asset.url}
                          onPlay={e => onPlayAudio(file.asset.url)}
                          // other props here
                      />
                </div>
            }else{
                return <></>
            }
             
          },
          videoEmbed: ({value}) =>{
                // console.log(value.videoEmbed);
                let video;
                if (value.videoEmbed.includes("vimeo")){
                    const code = value.videoEmbed.split('/');
                    // console.log(code)
                    video = {__html: `<iframe src="https://player.vimeo.com/video/${code[code.length - 1]}" width="640" height="400" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`}
                }else if (value.videoEmbed.includes("youtube")){
                    const code = value.videoEmbed.split('v=');
                    // console.log(code)
                    video = {__html:  `<iframe width="560" height="315" src="https://www.youtube.com/embed/${code[code.length - 1]}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}
                } else{
                    return <></>
                }
                return <div className="avsnitt-video-wrapper" dangerouslySetInnerHTML={video} >
                    {/* <div className="avsnitt-video" dangerouslySetInnerHTML={video} /> */}
                </div>
                    {/* <div dangerouslySetInnerHTML={createMarkup()} />; */}
                //     {video}
                // </div>
            },
        },
      }

      //https://www.youtube.com/watch?v=eesj3pJF3lA
    //   https://vimeo.com/699382114

    //<iframe width="560" height="315" src="https://www.youtube.com/embed/eesj3pJF3lA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    // <iframe src="https://player.vimeo.com/video/699382114?h=743bb55143&color=ef1100" width="640" height="400" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

// console.log(artikkelContent)
    return(
        <div className="musikk-artikkel-wrapper">
            <div className="close-button" onClick={()=>{setContentExpanded(false)}}><BiX/></div>
            <div className="menu-button" onClick={()=>{menuScrollHandler()}}><AiOutlineMenu/></div>
            <div className="button-background"></div>

            <div className="musikk-artikkel-meny" ref={menuScrollRef}>
                {artikkelContent.orgelAvsnitt.map((avsnitt, index)=>{
                    return <div key={'a' + avsnitt._key} className="meny-item" onClick={()=>scrollSmoothHandler(index)}>{language == 'NO' ? avsnitt.tittel : 'tittelEng' in avsnitt ? avsnitt.tittelEng : avsnitt.tittel}</div>
                })}
            </div>
            
            <div className="musikk-artikkel-content" ref={containerRef}>
                {artikkelContent.orgelAvsnitt.map((avsnitt,index)=>{
                    if (language == 'NO'){
                        return <div key={index} className="musikk-artikkel-avsnitt" ref={scrollRefs.current[index]}>
                        <div className="musikk-artikkel-avsnitt-tittel">
                            {avsnitt.tittel}
                        </div>
                        <div className="musikk-artikkel-avsnitt-content">
                            
                            <PortableText
                                value={avsnitt.innhold}
                                components={myPortableTextComponents}
                            />
                
                        </div>
                    </div>
                    } else if('innholdEngelsk' in avsnitt){
                        return <div key={index} className="musikk-artikkel-avsnitt" ref={scrollRefs.current[index]}>
                        <div className="musikk-artikkel-avsnitt-tittel">
                        {'tittelEng' in avsnitt ? avsnitt.tittelEng : avsnitt.tittel}
                        </div>
                        <div className="musikk-artikkel-avsnitt-content">
               
                            <PortableText
                            value={avsnitt.innholdEngelsk}
                            components={myPortableTextComponents}
                            />
                            
                        </div>
                    </div>
                    }else{
                        return <div key={index} className="musikk-artikkel-avsnitt" ref={scrollRefs.current[index]}>  <div className="musikk-artikkel-avsnitt-tittel">
                        {'tittelEng' in avsnitt ? avsnitt.tittelEng : avsnitt.tittel}
                        </div>
                        <div className="musikk-artikkel-avsnitt-content">
               
                            English translation coming soon.
                            
                        </div></div>
                    }
                    
                })}
            </div>
            {/* <MusikkRamme kategori={artikkelContent._id}/> */}
        </div>
    )
}

export default MusikkArtikkel