import React, {useEffect, useState, useRef} from "react";
import styled from 'styled-components'

import './kart.styles.scss'

const Kart =({zoomLevel})=>{

let zoomLevelFactor = 0.4;
if (zoomLevel > 4){
	zoomLevelFactor = 0.1;
}else if (zoomLevel <= 4 && zoomLevel > 3){
	zoomLevelFactor = 0.2;
}else if (zoomLevel <= 3 && zoomLevel > 2){
	zoomLevelFactor = 0.25;
}else if (zoomLevel <= 2){
	zoomLevelFactor = 0.3;
}

return(
    <div>
<svg version="1.1" id="kartSvg" x="0px" y="0px" viewBox="0 0 1190 1190">

<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="420.2,467.98 544.52,464.74 546.68,462.46 545.96,424.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="562.76,424.42 562.64,461.62 566.6,464.74 580.28,464.86 676.88,462.58 679.64,459.46 679.76,442.18 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="702.2,401.86 703.04,454.54 707.36,458.26 811.04,456.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="831.2,480.46 702.56,482.26 701.72,537.34 706.88,541.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="789.32,553.18 794.6,553.18 794.48,552.1 799.76,552.1 799.76,553.3 803.84,553.18 805.16,555.34 
	805.16,572.62 792.92,572.86 792.8,571.42 790.16,571.18 789.8,553.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="803.36,627.34 698.36,628.18 695,631.06 695.12,658.18 695.96,658.18 695.84,676.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="695.84,680.14 695.72,685.54 700.04,688.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="804.32,684.1 808.88,668.14 806.72,631.3 803.36,627.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="424.88,691.78 422.48,631.3 424.88,628.18 470.24,627.22 472.4,629.62 472.4,638.5 500.36,637.3 
	500.12,629.14 502.76,626.98 544.16,625.78 547.28,628.66 548.96,689.86 545.12,692.74 427.88,694.9 424.88,691.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="419.36,833.74 417.32,727.18 419.48,720.34 539.48,716.62 544.52,720.94 545.84,835.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="697.64,744.58 696.56,709.3 700.16,706.3 731.48,706.42 750.32,708.7 749.84,746.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="811.04,456.34 815,452.62 814.4,421.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="548.12,423.94 548.6,464.74 548.12,467.26 547.04,468.34 545.48,468.94 543.2,469.54 541.76,469.54 
	539,469.66 535.76,469.9 525.44,469.54 523.28,469.54 521.48,469.18 519.08,468.58 517.4,468.34 514.52,467.86 510.8,467.74 
	432.56,470.26 430.4,470.5 430.04,470.98 429.44,471.82 428.36,472.42 426.92,472.54 425,472.54 422.84,472.42 420.8,472.42 
	419.36,472.54 417.8,472.54 415.28,472.3 414.08,471.82 413.36,470.98 412.88,470.02 412.64,469.42 412.04,467.74 412.28,457.18 
	412.52,455.86 413.36,454.66 414.32,453.94 415.28,453.22 420.44,451.06 421.64,450.58 422.6,450.1 423.56,449.98 425,450.46 
	425.96,450.34 427.04,449.86 428.36,449.02 456.2,424.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="577.88,466.66 623.84,465.58 657.8,465.22 676.4,464.5 678.68,463.06 681.08,459.46 680.72,442.3 
	680.12,440.38 678.68,440.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="702.92,451.66 701.24,459.22 702.56,461.02 707.72,461.86 718.76,461.74 750.68,461.14 811.76,458.86 
	813.92,457.3 815.36,455.5 817.04,451.9 815.72,421.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="603.8,515.62 604.4,517.54 613.28,525.34 615.92,528.58 617.96,527.14 620.6,527.02 622.52,527.14 
	623.36,527.98 634.76,517.54 633.08,515.14 633.08,514.78 632.72,512.98 632.84,511.3 633.8,509.5 630.92,507.34 627.44,506.14 
	621.56,504.82 618.92,504.7 613.04,505.9 607.64,507.58 605.72,509.5 603.92,510.7 604.28,512.98 604.28,514.18 603.8,515.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="626.96,531.34 627.8,532.66 627.8,534.58 627.8,536.38 627.2,537.82 638.84,548.38 639.32,548.5 
	640.16,548.5 640.04,544.78 640.16,523.54 639.92,521.14 639.8,520.42 639.08,519.94 638.36,519.94 638,519.94 634.64,523.18 
	626.96,531.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="600.56,550.54 600.2,525.1 599.84,522.82 599.84,521.02 600.92,520.54 602.24,520.78 603.2,521.74 
	613.76,532.06 613.16,533.26 612.92,535.18 612.92,536.86 613.4,538.42 606.68,545.26 601.52,550.54 600.8,551.26 600.56,551.38 
	600.56,550.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="616.76,541.54 617.72,542.5 619.04,542.5 620.36,542.5 622.16,542.5 624.08,541.42 636.08,552.34 
	638.12,554.38 638.6,556.66 639.56,559.9 639.68,564.82 633.44,564.94 633.44,563.74 628.04,563.62 628.04,564.7 623.36,564.7 
	623.36,563.5 617,563.5 617,564.94 612.56,564.82 612.56,563.86 606.44,563.74 606.44,565.06 600.56,565.06 600.56,559.54 
	601.28,556.66 605.84,552.58 613.64,545.62 616.76,541.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="619.52,526.3 620.84,526.3 620.72,522.1 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="619.52" y1="522.1" x2="619.64" y2="526.42"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="619.52,522.1 619.04,521.74 618.68,521.14 618.56,520.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="620.72,522.1 621.56,521.74 621.92,521.14 622.28,520.54 622.16,520.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.96,491.62 646.76,509.86 646.76,512.02 646.76,513.82 646.04,514.9 644.12,516.22 642.92,516.7 
	642.08,517.9 642.08,520.18 643.64,521.5 643.76,543.58 661.88,512.38 664.4,509.86 665.84,507.1 665.96,502.06 666.68,491.86 
	665.96,491.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="644.72,507.22 657.32,495.58 657.68,492.22 653.72,492.58 642.08,493.3 634.52,492.94 628.76,492.94 
	625.04,493.42 625.16,497.74 625.4,499.66 625.04,501.1 631.04,503.26 633.08,504.1 634.28,504.34 635.6,505.66 637.04,508.18 
	639.8,507.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="639.92,507.34 640.16,507.34 641.6,507.34 643.04,507.34 644.72,507.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="613.28,495.22 613.52,499.06 613.52,500.26 612.32,501.82 609.44,502.66 607.16,504.1 604.52,505.18 
	603.2,506.62 602,508.42 599.6,507.94 598.04,507.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="597.92,507.94 597.8,507.94 596.24,508.18 595.52,508.54 587.12,501.58 583.76,498.82 583.04,497.74 
	583.28,495.94 585.08,494.98 598.88,494.38 613.28,495.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="577.88,498.94 591.32,509.5 591.32,512.74 591.92,515.38 593.72,518.98 596.48,520.54 596.72,541.06 
	596.72,554.5 597.08,564.7 590.6,565.18 572,565.54 566.84,565.54 564.32,565.54 563.12,561.1 563.6,530.38 563.24,513.82 
	564.8,506.74 568.04,503.14 573.08,500.5 576.44,498.7 577.88,498.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="644,565.18 643.52,564.46 643.16,558.34 643.16,552.46 643.52,551.14 649.28,552.94 653.84,555.22 
	656.72,557.14 658.64,559.06 661.16,561.34 664.52,564.22 664.16,571.3 661.28,575.5 657.2,575.5 655.64,572.38 653.24,569.62 
	650.6,566.86 647.48,565.18 645.56,565.18 644,565.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="644.48,547.3 664.16,514.06 665.48,513.7 665.12,561.82 663.2,559.54 659.84,557.14 657.92,555.34 
	654.44,552.94 650,550.42 647,549.1 644.96,548.62 644.48,547.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="567.68,580.3 569.48,581.02 570.68,582.58 571.64,584.14 572.24,586.18 572.36,587.5 595.04,569.74 
	570.8,569.5 569,569.62 567.92,570.22 567.92,572.14 567.8,575.02 567.68,577.06 567.68,580.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="572,589.66 597.2,569.86 597.32,573.46 597.56,579.46 597.92,594.82 598.04,601.66 597.2,603.46 
	590.72,603.46 569.72,603.46 568.16,602.5 567.2,600.7 566.96,599.62 566.96,597.58 566.84,596.14 566.84,595.66 568.64,594.94 
	569.96,593.74 571.64,591.22 571.76,589.66 572,589.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="564.08,606.46 585.2,606.58 595.04,606.22 598.16,606.34 598.04,611.38 598.04,612.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="598.04,619.3 598.04,623.74 598.16,630.94 599.24,649.9 596.6,650.86 594.92,652.54 594.32,654.7 
	594.2,656.98 594.2,659.26 594.56,660.94 585.2,669.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="566.36,676.9 566.36,676.78 565.52,675.1 563.72,672.46 563.48,670.42 563.6,668.86 565.04,666.94 
	565.76,665.5 565.52,647.02 564.08,606.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="574.64" y1="687.34" x2="577.88" y2="683.98"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="597.44,663.58 599.84,663.94 603.08,663.46 605,662.62 606.32,660.7 606.32,659.38 619.16,659.26 
	620.36,659.86 620.6,667.06 620.6,678.34 620.6,680.26 580.88,680.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="574.76,687.22 584,687.34 590.84,687.1 590.96,692.5 593.6,692.5 593.72,687.1 615.68,687.1 
	615.68,692.26 619.76,692.14 620,687.7 619.76,685.9 617.24,683.74 613.76,683.74 593.84,683.86 577.88,683.98 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="580.76" y1="680.86" x2="597.44" y2="663.58"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="645.92,661.66 661.64,678.1 661.88,679.18 655.04,679.78 630.8,680.02 624.92,680.38 624.56,673.9 
	624.8,661.42 625.04,659.86 628.64,659.86 639.2,659.38 639.44,660.58 640.04,661.06 641.48,661.66 643.4,661.66 646.16,661.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="625.04,691.9 624.92,686.26 626.84,683.26 648.08,682.9 663.56,683.02 668.96,683.02 671.36,686.26 
	673.52,688.78 673.52,690.58 672.44,692.38 669.68,692.38 665.72,692.38 665.72,687.46 630.44,686.74 629,688.78 629.12,691.54 
	625.04,691.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="669.08,677.62 673.04,681.82 677.36,684.58 679.4,685.18 680.12,685.18 679.88,651.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="679.88,650.98 679.64,611.26 677.84,613.06 675.2,613.78 672.08,615.7 670.04,616.54 668.96,616.9 
	669.08,677.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="666.08,621.34 666.2,675.94 660.56,670.06 654.68,663.7 650.6,660.1 649.4,658.9 649.4,654.94 
	648.56,653.5 647,651.58 645.44,651.1 645.44,635.26 650.36,630.58 656.48,627.58 660.56,625.3 663.32,622.9 665.36,620.86 
	665.84,620.86 666.08,621.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="645.08,604.66 645.8,630.82 650.24,627.34 654.56,625.42 659,622.06 661.76,620.38 664.52,618.1 
	665.48,617.38 665.72,594.7 654.56,594.46 654.56,597.1 653.72,599.86 652.28,602.14 650.12,603.46 647.48,604.54 645.08,604.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="669.32,613.78 673.76,612.34 677.72,609.1 680,606.82 680.12,584.5 676.76,590.38 673.76,595.54 
	671.72,599.02 669.68,600.22 669.32,614.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="670.04,596.26 678.2,583.66 674.84,578.86 669.68,572.5 668.84,571.54 669.2,598.42 670.04,596.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="679.28,580.18 673.64,574.06 670.4,570.34 668.24,568.9 668.48,490.54 672.44,489.58 675.68,488.14 
	677.24,486.58 677.96,486.82 679.76,580.66 679.28,580.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="602.36,650.38 603.8,650.62 605.6,652.3 606.68,653.74 606.8,655.3 615.2,655.42 614.96,650.86 
	609.44,651.1 606.68,648.1 606.68,644.62 602.36,644.62 602.36,650.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="630.56,651.1 630.56,655.18 637.4,655.06 637.28,653.62 640.52,650.26 641.84,650.02 641.48,643.66 
	637.76,643.54 637.88,648.46 635.12,651.22 630.44,651.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="623.96,601.9 645.08,601.42 647.24,601.06 649.04,600.22 649.4,599.98 649.64,599.74 649.76,599.62 
	649.88,599.38 650,599.14 650,598.9 650.12,598.66 650.12,598.42 650.12,597.46 650.36,596.38 650.48,594.46 650.72,593.26 
	650.48,591.82 646.52,591.82 646.4,578.26 650.6,578.14 650.6,576.94 650.6,575.98 650.48,574.54 650.24,573.34 649.64,572.38 
	648.2,570.58 646.64,569.74 646.04,569.74 644.36,569.62 623.12,569.86 597.2,569.86 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="598.04" y1="601.66" x2="623.96" y2="601.9"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="601.64" y1="606.1" x2="605.24" y2="606.1"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="608.96" y1="606.1" x2="609.44" y2="606.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="613.16,606.1 617.96,606.1 617.96,608.38 616.88,608.5 615.68,608.98 615.2,609.7 614.84,611.02 
	614.84,612.22 608.36,612.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="606.68,614.14 606.68,626.02 601.64,625.9 601.64,606.1 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="625.04" y1="605.98" x2="629.24" y2="605.98"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="632.48" y1="606.1" x2="632.6" y2="606.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="636.32,606.1 641.24,606.1 641.12,625.42 636.92,625.18 636.92,613.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="635.12,611.62 626.6,611.62 626.72,610.06 626.36,609.34 625.64,608.38 624.32,607.9 624.2,605.98 
	625.04,605.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="598.04,612.82 576.68,668.26 574.4,673.54 572.96,675.94 568.4,676.9 566.24,676.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="598.04,619.3 586.52,648.1 582.56,656.74 578.84,667.06 577.4,674.38 585.2,669.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="674.84,468.82 670.76,472.78 662.96,476.86 659.24,478.9 648.8,479.86 619.28,481.54 595.04,481.54 
	576.32,482.02 563.84,486.46 557.24,490.54 550.64,501.34 548.96,508.18 548.6,531.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="548.6,531.58 548.36,545.98 549.32,579.82 550.04,608.14 550.88,637.9 550.64,666.7 551.48,694.42 
	551.84,720.34 552.44,744.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="553.76,744.58 553.64,700.9 552.68,671.26 552.56,647.5 552.2,626.74 551.96,605.38 551.24,572.38 
	550.4,541.9 550.76,512.26 550.88,507.58 551.6,504.7 554.72,497.98 558.56,492.34 564.92,488.74 576.56,484.54 588.92,484.42 
	616.04,483.58 638.36,482.86 654.44,482.02 661.28,481.3 665.36,479.5 669.8,476.86 672.56,474.58 674.36,472.54 676.4,470.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="681.32,473.38 675.44,478.9 666.8,484.18 657.92,486.7 645.92,486.94 617.96,488.26 592.4,488.5 
	583.28,488.74 574.76,491.02 569.48,494.26 564.68,497.26 558.92,500.98 556.16,504.46 554.84,506.14 553.64,511.78 552.56,518.5 
	552.68,532.42 553.28,569.98 554.24,620.74 554.72,650.38 555.68,696.1 555.68,744.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="557.72,744.58 557,682.3 556.28,625.9 554.6,561.58 554.72,519.1 555.44,511.9 556.28,508.9 
	559.52,502.78 566.12,498.7 576.44,492.46 582.92,490.9 599.84,490.3 648.32,490.06 659,488.74 667.88,486.46 674.48,482.74 
	682.16,475.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="677.96,486.82 677.84,480.82 671.48,485.98 657.68,489.46 648.68,490.54 634.4,490.66 611.36,491.14 
	594.08,491.38 583.88,492.22 577.88,493.54 576.68,492.34 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="575" y1="490.9" x2="570.44" y2="486.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="568.4,484.78 566,482.62 564.44,481.18 564.44,479.74 564.92,478.9 567.68,478.78 594.92,478.78 
	597.92,479.62 599.84,479.98 604.16,479.86 645.2,479.26 654.68,477.7 658.52,477.22 660.92,476.5 659.24,473.62 566.36,475.78 
	562.88,476.14 562.4,476.62 562.64,487.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="562.64" y1="489.94" x2="562.88" y2="498.46"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="562.88" y1="500.62" x2="563" y2="501.82"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="574.16" y1="495.1" x2="573.32" y2="494.38"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="571.64" y1="492.94" x2="566.24" y2="488.26"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="564.08" y1="486.34" x2="562.52" y2="485.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="577.88,493.54 568.64,497.5 563.24,501.46 560.72,504.7 558.68,507.7 558.08,512.98 558.2,525.82 
	558.8,560.98 558.56,565.66 555.92,565.66 556.4,513.46 557.36,509.5 558.56,507.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="564.8,587.02 564.2,586.42 564.2,585.7 564.8,585.22 565.52,585.1 566,585.7 566,586.54 565.52,586.9 
	564.68,587.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="556.04,565.42 557.96,686.86 557.96,688.9 558.08,690.1 558.32,693.82 558.44,695.5 559.64,697.06 
	561.56,698.02 584.96,697.66 584.84,687.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="557.96,689.02 561.32,688.9 558.68,605.38 556.76,605.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="423.92,693.7 426.44,696.82 429.2,696.94 515.12,695.86 531.08,696.1 537.44,696.22 539.36,696.1 
	540.56,696.82 542.12,697.9 543.92,698.26 545.96,698.26 548.72,698.02 549.8,696.58 550.28,694.42 550.4,690.58 549.32,625.42 
	548.24,571.3 547.64,546.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="648.56,589.18 649.76,586.42 649.76,584.86 648.44,582.94 648.56,589.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="649.76,584.86 651.08,584.86 651.08,586.3 649.76,586.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="651.08,586.3 649.76,589.18 648.56,589.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="648.44,582.94 649.52,582.94 651.08,584.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="651.08,586.3 651.44,587.62 651.68,588.34 653.96,590.02 655.04,590.02 656.72,590.02 658.4,589.9 
	660.44,588.82 661.64,587.38 662,585.82 661.88,585.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="654.08,579.58 653.72,579.7 653,580.42 652.16,581.26 651.44,582.22 651.08,582.94 650.96,584.62 
	650.96,584.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="649.76,589.06 650.24,589.54 650.72,590.38 652.76,591.1 653.72,591.22 654.68,591.46 655.76,591.58 
	657.56,591.46 658.4,591.1 659.24,590.02 660.44,588.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="651.08,584.74 653.24,581.5 653.48,581.98 651.08,584.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="651.32,586.42 653.84,583.54 653.96,584.02 651.2,587.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="653.84,589.78 655.76,586.78 656.24,587.02 654.2,589.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="658.4,589.9 660.44,586.06 660.8,586.42 658.88,589.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="654.68,578.62 653.84,580.06 653.6,581.5 653.6,582.1 653.84,583.3 654.08,584.62 655.16,586.42 
	655.88,586.9 657.2,587.14 659,587.14 659.84,586.54 661.16,586.06 662,585.58 662.72,584.26 662.84,582.58 662.84,581.26 
	662.36,579.58 661.16,578.38 659.36,577.66 657.2,577.9 655.88,578.02 654.68,578.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="653.96,584.02 657.08,582.22 659.12,580.9 659.36,581.14 659.48,582.34 659.96,584.02 660.44,585.94 
	660.56,586.3 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="659.12" y1="581.14" x2="662.6" y2="579.82"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="659.24" y1="581.02" x2="655.64" y2="587.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="658.88,581.02 653.72,579.7 653.12,580.66 652.4,581.14 651.92,581.86 651.56,582.46 651.08,583.18 
	"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="659" y1="580.66" x2="656.72" y2="578.02"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="659.12" y1="580.9" x2="659.72" y2="577.78"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="658.76,580.66 659.36,580.42 659.72,580.18 659.84,579.94 659.96,580.18 659.72,580.42 659.36,580.54 
	659.36,580.78 659.48,581.14 659.24,581.38 658.88,580.9 658.88,580.78 658.76,580.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.56,520.42 618.44,520.06 618.2,518.98 617.84,518.14 617.48,517.42 617.12,517.42 616.52,517.42 
	616.28,517.06 615.92,516.22 616.04,516.22 617,516.34 617.96,516.22 619.28,516.22 620.12,516.22 620.6,516.46 620.6,517.06 
	620.72,517.54 620.24,517.66 620.12,517.66 620.84,518.74 621.2,519.34 621.56,519.82 622.04,520.54 621.08,520.54 620.72,520.18 
	620.48,519.82 620.12,519.22 619.64,518.74 619.04,518.14 618.92,517.54 618.56,517.54 618.92,518.5 619.04,519.22 619.28,519.82 
	619.52,520.18 619.52,520.42 619.28,520.42 618.8,520.54 618.44,520.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="617.48,517.42 618.08,517.54 618.56,517.54 619.16,517.66 619.52,517.66 620.24,517.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="622.4,520.54 622.4,520.3 622.04,519.46 621.8,519.1 621.2,518.86 621.08,518.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="620,518.98 619.52,519.1 619.4,519.22 619.28,519.22 619.16,519.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="722.84,599.14 721.16,589.78 721.88,570.46 721.76,569.5 722.48,569.38 727.76,569.62 727.76,568.54 
	727.52,568.06 728.24,567.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="728.12,567.94 729.44,567.7 729.92,567.7 730.64,567.94 731.12,568.42 731.48,568.9 731.24,569.26 
	730.16,569.5 729.44,569.5 728.6,569.38 727.52,569.02 727.52,568.42 728.12,567.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="727.64,568.9 727.76,569.98 728,570.34 728.84,570.7 729.56,570.82 730.64,570.82 731.12,570.46 
	731.48,568.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="731.36,569.74 734.48,569.26 737.72,577.54 730.76,570.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="728.48,570.58 728.48,571.18 728.36,571.66 723.8,577.9 737.72,577.54 736.88,578.38 736.4,578.74 
	724.04,578.86 723.8,577.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="723.8" y1="578.02" x2="721.76" y2="569.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="724.28,578.98 723.2,597.58 722.96,598.06 722.96,599.98 734.72,599.86 734.84,596.5 736.52,578.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="736.4,580.18 750.68,579.82 750.8,579.22 755.12,578.26 758.24,579.1 758.48,579.7 750.44,579.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="755.12,578.26 753.92,576.7 751.04,579.1 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="754.04" y1="576.58" x2="758.24" y2="579.1"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="758.48" y1="579.46" x2="772.4" y2="579.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="750.68,579.82 749.84,580.54 749.84,581.38 749.84,581.86 750.56,581.74 747.68,598.78 754.64,598.66 
	757.76,581.02 758.36,580.9 758.48,579.82 758.36,579.7 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="772.4" y1="579.22" x2="771.56" y2="584.26"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="748.16" y1="596.38" x2="734.84" y2="596.98"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="734.84,596.74 723.08,597.22 721.52,591.58 721.28,595.06 723.2,600.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="722.6,569.26 720.2,560.98 726.56,558.46 731.12,559.18 735.08,562.18 737.84,570.22 735.32,571.42 
	"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="729.44" y1="567.82" x2="726.56" y2="558.22"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="737.72,570.22 739.28,571.18 742.88,571.06 741.8,568.54 757.16,568.06 758.36,570.58 761.36,570.7 
	762.56,569.86 760.64,561.82 756.2,561.22 736.28,561.82 735.2,562.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="736.28,574.18 770.36,572.5 772.4,579.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="773,576.7 770.12,568.18 775.76,568.06 776.36,567.58 775.88,566.74 777.32,566.14 778.52,566.26 
	779.24,566.86 779.48,567.22 779,567.94 777.8,568.3 776.84,567.7 776.12,567.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="775.64,568.06 776,568.42 776.72,568.78 777.2,568.9 778.52,569.02 779.12,568.78 779.48,568.54 
	779.36,568.18 779.48,567.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="779.48,567.1 779.48,568.06 780.44,567.7 786.2,576.34 778.88,568.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="776.72,568.9 772.88,576.7 786.2,576.1 784.88,577.06 783.68,579.58 784.28,580.66 781.28,589.3 
	781.88,590.26 780.8,591.22 779.6,594.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="768.32,595.42 769.52,590.86 769.28,590.14 769.88,589.54 771.32,584.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="777.56,566.26 774.92,557.98 779.84,558.46 786.32,576.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="768.8,557.62 780.08,558.58 780.2,558.34 775.4,552.58 775.4,552.22 774.32,552.58 773.36,552.46 
	773,552.1 768.92,557.74 766.04,551.26 772.88,551.26 773,551.86 772.64,550.18 773.48,549.82 774.68,550.06 775.28,550.42 
	775.52,550.66 775.52,551.02 774.8,551.5 773.24,551.26 773.24,551.02 772.64,550.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="775.76,550.78 775.4,552.58 776.84,552.1 780.44,558.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="766.4" y1="552.7" x2="729.68" y2="554.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="728.84,551.98 730.64,558.22 725.6,552.7 725.6,551.98 725.48,551.98 728.96,551.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="730.52,558.22 719,558.1 723.44,552.34 723.92,552.7 724.76,552.7 725.36,552.34 725.6,551.98 
	725.72,551.14 725.84,550.42 725.12,550.3 724.4,550.18 723.8,550.3 723.44,550.42 722.84,550.54 722.72,550.9 723.32,551.14 
	723.92,551.26 724.76,551.26 725.36,551.14 725.72,551.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="725.72,551.02 722.96,550.9 723.08,551.86 723.2,552.1 723.44,552.34 722.96,551.62 717.56,552.46 
	719.24,557.98 719.96,559.3 719,577.18 719.72,576.82 720.2,575.62 720.68,562.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="719.72,576.7 721.4,591.7 722,569.62 722.36,569.14 727.64,569.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="717.56,552.46 717.56,553.54 717.56,553.9 717.92,554.74 718.04,555.46 717.8,568.54 717.2,571.3 
	717.32,572.26 719,577.06 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="768.68" y1="556.9" x2="756.32" y2="561.22"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="760.88" y1="562.18" x2="769.52" y2="557.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="742.88,571.06 753.32,572.62 758.6,570.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="753.32,572.74 751.64,569.62 741.8,568.66 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="751.64" y1="569.74" x2="757.04" y2="568.06"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="739.4" y1="571.9" x2="739.4" y2="571.9"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="707.6,573.34 707.6,572.38 707.84,572.38 708.68,572.38 708.92,572.26 709.28,571.66 709.64,571.42 
	710.24,571.54 710.84,571.42 711.32,570.82 711.56,570.7 712.4,571.06 712.88,571.18 713.84,570.82 714.44,570.7 714.32,569.5 
	713.6,568.78 712.76,568.78 712.16,569.14 711.92,569.14 711.56,568.42 711.2,567.46 710.84,567.1 710.72,566.5 710.84,565.9 
	710.6,565.66 709.76,565.18 709.52,564.58 708.8,563.38 708.2,562.42 707,563.02 706.28,563.86 706.28,563.98 704.96,563.98 
	704.96,564.22 705.08,564.7 704.72,564.94 703.76,565.66 703.88,566.14 703.16,566.26 703.04,566.74 702.8,565.78 702.2,565.42 
	702.2,566.98 702.44,567.46 702.68,568.18 701.72,568.78 701.48,569.14 701.96,569.62 702.56,569.62 702.68,569.86 702.32,570.7 
	702.92,571.18 703.04,572.02 702.92,572.62 704.12,572.98 705.44,572.86 705.56,572.62 705.92,572.86 706.4,572.98 706.88,572.86 
	707,572.86 707.12,573.22 707.36,573.34 707.6,573.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="698,578.14 698.24,577.3 698.48,576.82 699.2,576.82 699.8,576.46 699.92,575.86 700.52,575.26 
	700.52,574.42 700.28,573.58 699.56,572.74 699.44,571.66 698.96,571.18 697.88,570.7 697.16,570.82 697.04,570.82 696.32,570.94 
	695.96,571.78 695.72,571.9 695.12,572.02 695.12,572.86 695.36,573.46 695.48,574.06 695.12,574.3 694.76,574.3 694.64,574.9 
	694.88,575.38 694.28,575.62 694.04,576.22 694.16,577.18 694.88,577.78 695.24,577.9 696.08,577.9 696.44,577.9 696.56,578.14 
	696.92,578.38 697.52,578.38 697.76,578.38 698.12,578.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="699.92,573.34 700.16,572.74 700.04,571.9 699.68,571.42 699.8,571.06 699.92,570.82 700.4,570.7 
	700.64,570.46 700.76,569.62 700.4,569.26 700.28,569.14 700.64,568.66 700.4,568.3 699.92,567.94 699.44,567.46 698.72,567.46 
	698.36,567.58 698.24,567.1 697.76,566.86 697.52,566.98 697.28,567.1 697.04,566.74 696.56,566.5 695.84,566.5 695.48,566.62 
	695.24,566.74 694.88,566.74 694.52,566.74 694.04,567.22 693.8,567.34 693.68,568.06 693.68,568.54 693.92,569.26 694.04,569.74 
	693.2,570.46 692.84,571.06 693.32,571.66 693.08,572.38 693.68,572.98 694.16,572.98 694.28,573.82 694.52,574.18 694.76,574.18 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="714.08,595.54 715.4,596.5 716.12,596.74 716.84,596.5 717.08,595.78 717.2,595.54 717.44,595.42 
	717.68,595.42 717.8,595.06 718.4,594.46 718.64,593.98 718.52,593.62 718.4,593.26 718.64,593.14 718.88,593.02 719,592.9 
	718.76,592.42 718.28,592.18 718.04,591.82 718.16,591.46 718.52,591.46 718.76,591.1 718.64,590.74 718.64,590.26 719.12,590.02 
	719.6,590.02 719.84,589.54 719.72,588.94 719,588.58 718.76,588.46 718.28,588.58 718.04,588.34 717.8,587.5 717.2,587.5 
	716.72,587.26 716.84,586.54 717.56,586.42 717.8,586.3 717.56,585.82 716.84,585.82 716,585.58 715.64,585.82 715.52,586.42 
	715.28,586.66 714.08,586.54 713.72,586.78 713.72,587.38 713.6,588.22 713.48,588.82 714.32,589.66 714.44,590.26 714.08,590.74 
	713.6,590.62 713.36,590.86 713.36,591.34 713.24,591.94 712.76,592.06 712.16,592.42 711.8,593.02 711.92,593.62 713.12,593.98 
	713.84,593.86 714.08,594.22 714.32,594.58 714.08,595.54 713.96,595.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="696.68,596.98 695.72,596.86 695.72,595.9 695.24,595.66 695,595.54 695.24,594.94 695.48,594.58 
	695.48,594.1 696.2,593.86 696.44,593.74 696.92,594.58 697.52,594.7 697.52,595.06 696.92,595.78 696.32,595.9 696.2,596.14 
	695.72,596.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="697.88,590.74 696.8,590.74 695.84,590.38 695.24,589.9 694.28,589.18 694.64,588.82 695.36,588.34 
	695.48,587.86 695.48,587.14 695.48,587.02 696.32,586.18 696.2,585.58 696.08,585.22 696.68,584.74 697.28,584.5 697.88,584.98 
	698.24,585.34 698.84,585.46 699.32,585.82 699.32,586.54 698.96,587.38 699.32,587.62 699.44,588.34 699.2,589.18 699.44,589.54 
	699.32,590.14 698.72,590.26 698.12,590.5 697.76,590.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="696.08,585.34 695.6,585.58 695.12,585.1 695.24,584.5 696.08,583.9 696.32,583.3 696.08,582.34 
	696.08,581.5 696.68,581.26 697.4,581.38 697.64,581.62 698.12,581.86 698.36,581.86 698.84,582.46 699.08,582.82 698.84,583.42 
	698.96,583.66 699.2,584.26 699.32,584.62 699.32,584.86 699.2,585.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="695.12,584.86 694.76,585.1 694.28,584.86 694.28,584.14 694.4,583.66 694.88,583.3 694.88,582.94 
	694.52,582.46 694.16,581.74 694.28,581.02 694.76,580.3 695.84,579.82 696.44,579.34 696.44,578.98 697.16,578.74 698,578.74 
	698.72,578.74 699.08,580.18 699.08,581.02 699.68,581.26 700.04,581.38 700.16,582.22 699.92,582.82 699.8,583.18 699.2,583.18 
	698.96,583.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="696.2,599.86 696.08,609.94 715.28,609.82 715.16,605.38 696.2,605.74 699.08,607.9 696.08,609.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="698.72,607.66 712.04,607.66 715.16,605.5 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="712.28" y1="607.42" x2="715.52" y2="609.82"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="700.52" y1="569.26" x2="701.72" y2="569.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="711.56,568.42 715.04,568.18 714.8,565.54 710.84,565.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="702.56,565.9 695.48,566.02 695.36,566.62 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="702.32" y1="566.98" x2="698.24" y2="567.1"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="700.04" y1="571.66" x2="702.92" y2="571.66"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="714.08,572.38 714.44,575.02 714.32,575.86 713.24,575.98 712.88,577.06 712.76,577.42 712.52,577.9 
	713.12,579.58 713.48,585.1 713.6,585.58 713.84,586.54 713.84,587.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="563.24,450.22 565.64,452.38 586.76,452.26 586.76,444.58 570.08,444.7 570.32,436.54 563.48,440.26 
	563.24,450.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="570.68,436.54 577.52,440.62 577.28,444.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="577.64,440.98 576.56,434.86 570.32,432.1 563.6,435.58 563.84,439.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="563.96,435.46 563,435.94 564.56,419.74 568.88,418.3 591.44,418.54 591.2,425.62 586.76,425.5 
	586.52,428.62 584.48,428.62 584.48,425.26 578,425.26 576.68,435.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="570.44,432.1 571.28,419.02 578,425.5 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="591.44" y1="419.38" x2="571.16" y2="418.78"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="578.36,425.26 579.44,441.1 578.96,445.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="579.32,440.74 585.2,440.86 584.6,428.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="585.32,440.98 585.2,442.18 586.16,442.3 587,442.3 587.36,441.94 586.4,428.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="587,425.62 587.6,439.18 587.84,441.1 587.36,441.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="587.96" y1="440.5" x2="588.2" y2="440.5"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="591.32,425.38 591.44,427.06 613.28,426.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="586.52,452.38 586.64,452.62 602.48,452.5 602.12,451.18 586.88,451.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="586.76,444.46 586.76,443.98 602.36,444.1 602.24,451.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="602.36,448.66 602.72,450.34 624.2,449.62 623.72,440.98 614.72,440.86 614.6,442.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="602.6,444.22 602.36,441.94 623.84,442.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="623.72,440.86 640.4,440.74 641.12,449.02 624.44,448.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="613.04,449.14 613.04,445.18 617.24,445.42 618.8,447.46 618.68,449.38 613.04,449.26 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="613.04" y1="448.06" x2="618.56" y2="447.82"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="640.28,440.98 641.48,440.74 639.8,431.74 644.12,433.3 645.56,436.42 645.8,440.74 641.24,440.74 
	672.2,440.14 672.08,432.94 669.92,434.5 660.2,434.86 661.04,436.18 660.44,439.54 665.36,439.42 669.8,434.5 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="665.12" y1="439.3" x2="672.2" y2="434.86"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="665.36" y1="439.3" x2="665.72" y2="440.38"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="660.32" y1="439.54" x2="660.2" y2="440.38"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="672.08,433.18 678.56,436.66 679.88,445.42 676.28,446.98 641,448.42 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="672.2" y1="440.02" x2="676.28" y2="446.98"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="679.76" y1="445.42" x2="672.32" y2="440.14"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="678.68,436.3 678.68,433.9 674,430.3 665,434.62 663.92,428.14 657.56,428.38 655.16,426.82 
	655.28,423.1 668.84,422.98 663.8,428.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="668.96,422.98 672.44,421.66 673.88,430.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="678.8,433.66 677.12,424.66 672.32,421.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="585.44,452.38 585.92,453.34 585.92,464.86 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="602.48" y1="452.74" x2="602.48" y2="464.38"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="623.24" y1="463.9" x2="623" y2="449.86"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="640.76" y1="449.26" x2="640.76" y2="463.3"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="585.92,460.06 566,460.54 563.72,458.98 563.24,450.22 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="570.08" y1="444.7" x2="569.84" y2="452.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="565.64,452.38 570.08,444.58 563.24,449.98 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="639.8" y1="431.74" x2="639.56" y2="440.74"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="696.08,609.82 695.72,614.14 714.68,614.26 715.16,609.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="717.32,596.38 719.72,603.22 737.24,602.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="747.44,598.78 748.04,599.26 747.68,599.74 748.16,599.98 747.8,600.7 748.28,601.06 747.8,601.54 
	748.28,601.78 747.92,602.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="754.64,598.78 755,599.02 754.76,599.62 755,599.74 755.24,599.86 755,600.46 755.24,600.58 
	755,600.94 755.36,601.3 755.24,601.54 747.92,602.02 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="715.64" y1="609.7" x2="783.68" y2="609.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="768.44,595.3 767.96,597.1 754.88,597.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="779.6,594.82 778.52,597.82 767.6,597.22 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="737.36" y1="602.86" x2="748.4" y2="602.62"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="696.2" y1="600.1" x2="696.2" y2="596.74"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="696.2" y1="593.98" x2="696.2" y2="590.5"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="696.56" y1="590.86" x2="696.56" y2="593.62"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="696.56" y1="596.86" x2="696.44" y2="605.62"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="715.4" y1="610.18" x2="783.56" y2="609.58"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="783.56" y1="609.46" x2="781.64" y2="604.9"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="781.64" y1="604.9" x2="780.8" y2="609.58"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="781.76" y1="604.9" x2="785.24" y2="605.5"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="785.24" y1="605.5" x2="783.68" y2="609.46"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="785.24" y1="605.5" x2="803.48" y2="605.38"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="803.48" y1="605.38" x2="805.16" y2="608.98"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="805.16" y1="608.98" x2="783.68" y2="609.58"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="804.92" y1="608.98" x2="802.4" y2="613.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="802.4" y1="613.66" x2="782.72" y2="613.78"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="803.6" y1="605.38" x2="801.56" y2="604.42"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="801.56" y1="604.42" x2="781.76" y2="604.9"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="782.36" y1="613.78" x2="714.56" y2="614.26"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="755.24" y1="600.22" x2="778.4" y2="600.1"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="778.4" y1="600.1" x2="778.52" y2="597.7"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="747.8" y1="600.22" x2="734.72" y2="599.74"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="770.12" y1="568.18" x2="768.32" y2="557.86"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="769.64" y1="567.82" x2="762.56" y2="569.62"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="761.6" y1="570.58" x2="771.2" y2="571.18"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="771.2" y1="571.18" x2="770.36" y2="572.5"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="772.52" y1="579.22" x2="772.88" y2="576.58"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="695.36" y1="566.02" x2="695.48" y2="561.82"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="695.48" y1="561.82" x2="695.36" y2="560.62"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="695.36" y1="560.62" x2="700.04" y2="559.78"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="700.04" y1="559.78" x2="695.48" y2="562.3"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="700.04" y1="560.02" x2="713" y2="559.54"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="713" y1="559.54" x2="715.16" y2="561.22"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="713.12" y1="559.42" x2="718.52" y2="559.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="715.4" y1="559.42" x2="715.4" y2="561.22"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="715.4" y1="561.22" x2="695.48" y2="562.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="715.04" y1="561.1" x2="714.8" y2="565.54"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="591.56,419.26 638.96,419.26 639.92,425.74 633.56,426.22 633.2,428.62 630.44,428.5 630.68,425.86 
	629.6,425.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="627.08,419.26 626.96,418.42 629.96,416.74 633.8,418.3 633.92,419.26 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="629.96" y1="416.86" x2="630.08" y2="419.38"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="639.92" y1="425.62" x2="639.44" y2="440.5"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="632.96" y1="428.5" x2="633.08" y2="440.62"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="630.44" y1="428.26" x2="630.2" y2="440.98"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="619.76,438.34 621.68,438.46 621.8,437.98 621.2,437.38 621.44,436.9 622.4,436.66 622.88,436.9 
	623.6,436.66 623.96,436.06 623.84,435.46 623.36,434.98 624.68,434.86 624.8,434.5 624.92,433.66 625.04,432.82 625.64,432.34 
	626,432.22 626.36,431.5 626.6,431.26 626.96,430.78 626.84,430.06 626.96,429.7 627.32,428.98 627.44,428.14 627.32,427.06 
	627.8,426.82 628.64,426.82 629,426.94 629.12,426.58 629.24,426.34 629.36,425.98 629.36,424.9 628.52,423.94 627.8,422.86 
	627.8,422.62 627.68,422.14 627.2,420.7 627.2,420.22 626.24,420.22 625.64,420.1 624.8,420.22 623.84,419.98 623,419.86 
	621.92,420.34 621.2,420.7 620.84,421.06 620,421.42 619.52,421.66 619.16,422.14 618.32,422.38 617.36,422.5 616.28,422.5 
	615.2,423.34 614.96,423.46 614.36,424.78 613.88,426.22 613.76,427.18 614.48,427.78 614.72,428.62 614.72,429.58 614.72,430.42 
	614.72,431.5 614.36,432.46 614.24,432.94 615.32,434.74 615.92,435.22 617,436.42 617.96,437.74 619.16,438.1 619.4,438.22 
	619.76,438.22 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="623.12" y1="437.5" x2="622.76" y2="436.78"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="623.24,437.5 623.24,437.86 623.48,438.46 624.2,438.7 624.8,438.7 625.88,439.06 626.84,439.3 
	627.32,438.94 627.8,438.58 628.04,437.74 627.8,437.02 627.92,436.06 627.8,435.58 627.44,435.46 627.08,435.46 626.72,435.34 
	626.48,434.98 626.36,434.62 626.36,434.26 626.12,433.78 625.52,433.42 625.4,433.42 624.92,433.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="615.92,435.46 614.84,434.98 614,434.86 613.4,435.7 613.28,436.54 612.8,437.74 613.52,438.22 
	614.36,438.58 614.72,439.18 615.2,439.78 616.52,440.02 616.88,439.3 617.36,438.34 617.24,437.86 616.88,436.9 616.52,436.06 
	615.8,435.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="613.88,442.06 614.48,441.22 614.48,440.5 614.84,439.9 613.64,439.3 613.28,438.94 612.32,438.82 
	612.08,439.18 611.6,440.02 611.36,440.14 610.52,439.78 610.28,439.9 609.92,440.62 609.92,441.22 610.28,442.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="609.44,441.94 609.56,441.22 609.56,439.9 609.92,439.18 608.96,438.46 608.48,437.62 608.36,436.54 
	607.16,435.82 606.92,434.14 606.92,433.42 607.04,432.22 606.2,430.54 605.6,430.42 604.88,430.42 604.76,429.7 604.88,427.78 
	604.52,427.66 603.08,427.54 601.88,427.54 601.52,427.54 600.08,427.06 599.12,427.18 598.4,427.54 598.52,428.5 598.76,429.46 
	598.64,430.06 598.04,430.06 597.2,430.18 597.2,430.78 597.32,431.98 597.68,432.22 597.44,433.06 596.72,433.78 596.36,434.26 
	596.48,435.1 596.24,436.18 596.12,437.02 596.96,438.1 596.48,439.06 595.64,439.54 595.64,440.5 596.12,441.7 595.76,442.66 
	596.24,443.38 597.08,443.62 598.52,443.74 599.48,443.86 600.32,443.38 600.2,442.54 599.6,441.82 600.08,441.82 600.68,443.02 
	601.76,443.26 602.24,442.42 602.12,441.34 602.96,440.5 603.44,440.74 603.2,439.78 603.32,438.82 604.04,439.42 603.8,440.38 
	604.28,442.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="596,439.42 594.92,438.7 594.56,437.86 593.72,437.38 592.76,437.26 591.8,437.38 590.6,437.62 
	590.36,438.58 590,439.54 589.04,440.02 588.44,440.5 588.32,441.22 587.96,441.94 587.36,442.3 587.48,443.14 588.68,443.38 
	589.64,443.38 590.72,443.62 591.8,443.74 593,443.74 594.08,443.5 594.32,442.3 594.56,441.46 594.8,440.74 595.16,440.26 
	595.64,440.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="639.8,425.02 650.72,424.54 655.76,423.58 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="650.48" y1="424.66" x2="649.64" y2="438.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="655.16" y1="426.7" x2="654.8" y2="440.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="645.56,437.62 645.92,437.26 646.16,437.02 646.52,437.02 647,437.14 647.36,437.02 647.72,436.9 
	647.96,436.78 648.44,436.78 648.56,437.14 648.8,437.5 649.16,437.74 649.4,438.1 649.64,438.22 649.76,438.34 650.36,438.58 
	650.96,438.82 651.08,438.82 651.08,439.42 650.96,439.66 651.2,439.9 651.2,440.14 650.96,440.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="676.64,424.18 676.28,420.46 674.24,419.5 638.72,418.9 638.84,419.38 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="679.76" y1="580.66" x2="680.12" y2="584.5"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="679.64" y1="611.26" x2="680" y2="606.82"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="524.6,574.42 526.4,575.86 527,578.5 527.12,580.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="527.12,581.02 527.12,581.5 527.12,596.62 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="527" y1="596.62" x2="526.64" y2="599.38"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="526.52,599.98 526.4,600.94 525.08,603.1 522.08,603.94 519.32,604.42 518.12,604.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="418.64,623.5 519.2,622.18 526.88,621.94 532.52,621.82 537.68,621.34 540.8,617.98 540.56,593.14 
	533.36,593.38 533.48,597.34 535.28,601.66 536.96,605.38 539.96,610.3 540.56,611.26 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="420.56" y1="561.1" x2="512" y2="558.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="540.56,570.58 540.44,586.06 533.24,585.94 533.6,583.3 534.92,579.22 536.84,575.74 538.64,572.74 
	540.56,570.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="518.12,604.3 490.28,605.5 484.64,605.5 469.28,605.62 461.96,603.7 459.08,598.9 458.12,595.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="454.64,570.22 428.24,570.22 428.24,568.66 420.32,568.66 420.32,576.94 423.08,576.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="426.2,576.94 428.6,576.94 428.6,574.42 454.88,574.42 454.88,575.38 429.08,575.38 429.08,577.42 
	426.92,577.42 426.92,579.22 430.76,579.22 430.76,576.7 454.88,576.7 454.88,577.78 431.96,577.78 431.96,580.06 426.2,580.06 
	426.2,576.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="422.24,577.54 419.48,577.54 419.48,567.94 428.84,567.94 428.84,569.5 454.64,569.5 454.64,570.22 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="422.24,577.54 422.24,579.82 423.08,579.82 423.08,576.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="426.44,586.9 453.68,586.3 453.68,595.9 426.32,595.9 426.44,586.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="428.24,588.46 452.12,587.86 452.12,594.22 428.24,594.22 428.24,588.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="426.44" y1="586.9" x2="426.44" y2="580.06"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="426.32,595.9 426.44,608.14 455.96,608.14 457.28,605.74 457.52,604.9 458.36,604.18 459.08,603.7 
	459.68,603.22 461.12,602.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="416.84,620.98 418.04,620.5 419.36,619.3 420.68,617.62 421.64,615.34 422.6,613.18 422.84,611.74 
	423.68,611.14 426.44,611.14 439.04,611.38 442.04,611.26 446,611.26 452.24,611.5 454.16,611.5 454.76,611.74 456.56,612.22 
	461.12,612.22 487.16,611.86 492.92,611.86 499.04,611.86 520.16,611.26 523.16,610.9 526.28,610.78 529.04,610.9 530.24,611.86 
	531.08,613.18 531.8,614.74 532.28,616.3 533.24,617.62 534.44,619.42 535.52,621.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="539.12,574.54 531.8,560.02 532.16,559.66 532.4,559.54 532.88,559.42 532.64,558.94 532.52,558.94 
	532.28,559.06 531.8,559.3 531.56,559.66 531.56,560.14 538.76,574.66 539.12,574.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="540.32,603.46 533,588.94 533.36,588.58 533.6,588.46 534.08,588.34 533.84,587.98 533.72,587.86 
	533.48,587.98 533.12,588.34 532.76,588.58 532.76,589.06 539.96,603.58 540.44,603.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="529.4,584.98 526.88,579.7 526.88,579.58 527,579.58 527.12,579.58 527,579.22 526.76,579.1 
	526.52,579.34 526.4,579.22 526.28,578.86 526.28,578.5 526.4,578.14 525.92,577.78 525.44,577.78 525.56,578.26 525.68,578.62 
	525.92,578.62 526.04,578.86 526.16,579.22 526.16,579.58 526.28,579.7 526.16,579.94 526.04,580.42 526.16,580.54 526.28,580.54 
	526.28,580.3 526.28,580.06 526.4,579.94 526.4,579.82 526.52,579.82 526.64,580.06 527,580.78 527.84,582.34 529.28,585.22 
	529.52,584.98 529.52,584.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="528.68,603.46 526.16,598.3 526.04,598.18 526.28,598.06 526.4,598.06 526.28,597.82 525.92,597.7 
	525.68,597.82 525.44,597.34 525.44,597.1 525.68,596.62 525.08,596.38 524.72,596.26 524.84,596.74 524.84,597.1 525.08,597.1 
	525.2,597.34 525.32,597.7 525.44,598.18 525.56,598.3 525.32,598.54 525.32,598.9 525.32,599.14 525.44,599.14 525.56,598.9 
	525.56,598.66 525.56,598.42 525.68,598.3 525.8,598.42 525.92,598.66 526.16,599.38 527,600.82 528.56,603.82 528.68,603.58 
	528.68,603.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="413.72,619.42 414.68,619.3 416.24,618.58 417.2,617.62 418.04,616.42 418.52,615.1 419.36,614.02 
	420.2,612.7 420.8,611.5 421.4,609.58 421.88,607.9 422.36,606.34 422.72,605.14 423.08,603.22 423.32,601.54 423.2,599.38 
	423.32,598.18 423.8,591.46 423.8,590.74 423.8,588.46 423.8,586.3 423.8,583.9 423.8,582.94 423.92,581.86 423.8,580.9 
	423.8,580.18 423.56,579.7 423.44,579.58 422.96,579.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="422.96,579.22 424.28,579.34 424.76,579.22 425.72,579.22 426.08,579.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="426.2,578.86 425.6,578.86 424.4,578.86 423.44,578.74 423.08,578.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="423.08,578.26 423.68,578.38 424.52,578.38 425.96,578.38 426.2,578.38 426.44,578.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="422.96,577.9 423.44,577.9 424.28,577.9 425.12,577.9 425.84,577.9 426.32,577.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="423.2,577.42 423.56,577.42 424.4,577.42 425.36,577.42 425.84,577.54 425.96,577.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="426.2,576.94 425.36,576.94 423.68,577.06 423.2,577.06 423.08,576.94 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="451.28" y1="587.74" x2="451.28" y2="594.1"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="449.84" y1="587.86" x2="450.08" y2="594.1"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="448.64" y1="587.98" x2="448.76" y2="594.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="413.84,585.94 412.52,585.7 412.52,584.74 413,583.54 413,582.58 412.28,582.22 411.44,581.98 
	411.44,580.66 412.28,580.3 412.4,579.94 411.92,579.22 410.72,579.1 410.6,578.02 411.2,576.94 411.92,576.22 411.92,575.02 
	411.92,574.06 413.12,574.54 413.36,575.74 414.32,575.14 414.32,574.3 415.64,574.66 415.88,575.5 415.88,577.18 417.8,577.54 
	418.76,578.26 418.76,579.58 418.88,580.42 420.08,580.9 421.4,581.14 421.4,581.98 421.4,583.06 420.92,584.14 422,584.38 
	422.84,584.38 422.96,585.22 422.24,585.34 420.56,585.1 420.44,585.7 420.92,586.42 421.4,586.66 421.4,587.62 420.92,588.22 
	420.68,588.58 421.16,589.18 421.76,589.9 421.16,590.62 420.68,590.98 421.04,591.94 421.16,592.54 420.8,593.38 420.68,593.74 
	420.68,594.94 420.44,596.38 421.52,596.74 422.48,596.5 423.32,597.58 423.32,599.02 422.96,599.62 423.56,601.18 422.48,601.78 
	421.64,602.26 421.28,602.62 421.28,603.58 421.16,604.18 421.04,604.54 421.64,605.14 421.4,606.58 421.16,606.94 420.92,607.78 
	420.44,608.26 420.2,608.74 420.08,610.42 419.36,611.14 418.88,611.98 418.76,612.34 417.32,611.62 417.2,610.3 416,611.26 
	416,612.22 417.08,613.66 417.56,613.78 417.08,614.74 416.24,615.34 415.16,615.46 414.2,615.1 413.36,614.5 413.12,613.42 
	414.32,612.7 414.2,611.5 413.36,610.78 413.36,609.34 413.96,608.62 414.32,607.78 413.48,606.7 412.88,606.22 413,604.9 
	413.48,604.42 415.16,604.66 415.76,605.26 417.44,605.86 418.04,605.74 418.4,606.46 418.64,607.54 419.24,607.78 419.84,608.02 
	420.2,608.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="421.76,605.02 421.52,605.74 421.04,606.1 419.12,605.62 418.64,604.66 415.88,603.7 415.52,604.06 
	414.56,604.66 413.12,604.3 412.88,603.22 413.48,601.66 414.56,601.3 415.52,600.58 416.36,599.98 418.04,600.58 418.88,601.42 
	419.48,601.66 420.32,600.94 421.16,600.94 421.52,601.54 421.52,602.26 421.28,602.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="423.32,599.62 422.36,600.1 420.56,600.1 418.88,599.62 418.52,598.78 419.36,597.58 419.12,597.1 
	419.24,596.14 420.2,596.02 420.92,596.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="419.12,597.34 418.88,598.06 418.4,598.78 418.4,599.74 417.56,599.86 416.24,599.5 414.8,599.86 
	414.08,600.22 413.36,600.22 412.76,599.5 412.76,598.18 413.36,597.7 413.48,597.46 412.88,596.86 412.76,595.9 412.88,594.58 
	413.48,593.98 414.08,593.86 414.56,594.1 415.28,593.98 416,593.86 417.08,593.98 417.56,594.1 418.4,594.34 418.76,594.94 
	418.76,595.42 418.88,595.9 419.12,596.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="420.44,593.38 420.2,594.1 420.2,594.7 419.84,595.9 418.88,595.66 418.76,594.7 418.64,593.74 
	418.16,591.46 418.04,591.1 418.16,590.02 418.64,589.42 419,589.18 419.96,588.82 420.8,588.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="413.96,586.06 415.52,585.58 416.24,583.66 416.24,582.22 416.84,581.74 417.2,581.98 417.8,582.1 
	418.28,581.62 418.52,581.14 418.76,580.54 418.88,580.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="420.08,582.1 419.24,581.86 418.64,582.22 418.16,583.06 416.96,583.9 416.36,584.74 416.36,586.06 
	416.48,587.5 416.96,588.34 417.32,589.78 418.04,589.9 418.16,589.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="417.68,591.34 417.68,590.62 416.96,590.02 416.84,589.3 416.24,587.5 415.4,586.54 414.44,586.42 
	413.48,587.38 413,587.62 412.76,588.34 412.76,591.1 412.88,592.18 412.4,593.14 412.64,593.5 413.36,593.62 414.68,593.38 
	415.88,593.86 416.96,593.86 417.56,593.98 418.04,593.86 418.16,593.14 417.8,592.42 417.56,591.94 417.8,591.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="453.56,570.22 453.68,570.7 454.52,571.66 455.36,570.94 456.08,570.1 456.56,569.26 458.12,568.66 
	461.72,569.02 470,568.78 473.96,568.78 475.4,568.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="475.76,568.78 476.36,568.78 478.76,568.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="572.6,618.58 572.36,619.3 571.88,620.02 571.88,621.1 571.04,621.22 569.72,620.86 568.28,621.1 
	567.56,621.58 566.84,621.58 566.24,620.74 566.24,619.42 566.84,618.94 566.96,618.7 566.36,618.22 566.24,617.14 566.36,615.82 
	566.96,615.22 567.56,615.1 568.04,615.34 568.76,615.34 569.48,615.22 570.56,615.22 571.04,615.34 571.88,615.58 572.24,616.18 
	572.24,616.66 572.36,617.26 572.6,618.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="571.16,612.7 571.16,611.86 570.44,611.38 570.32,610.54 569.72,608.74 568.88,607.9 567.92,607.78 
	566.96,608.62 566.48,608.98 566.24,609.58 566.24,612.34 566.36,613.42 565.88,614.38 566.12,614.74 566.84,614.98 568.16,614.62 
	569.36,615.1 570.44,615.22 571.04,615.22 571.52,615.1 571.64,614.5 571.28,613.66 571.04,613.18 571.16,612.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="578.36,609.46 577.4,608.74 576.8,608.62 575.72,608.62 574.88,609.1 574.4,609.46 574.28,610.06 
	574.16,610.54 573.68,611.14 573.2,611.62 572.72,612.34 572.36,613.3 572.6,614.86 572.72,614.98 573.68,616.42 574.28,616.06 
	575.12,616.18 576.2,617.14 576.56,617.74 577.76,617.5 578.24,616.54 578.96,615.7 580.16,615.34 579.92,614.14 579.32,612.94 
	578.84,612.46 578.72,611.86 578.96,611.26 578.96,610.18 578.36,609.46 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M568.28,610.6c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S568.28,610.7,568.28,610.6"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M576.8,610.96c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S576.8,611.06,576.8,610.96"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M575.96,614.56c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S575.96,614.66,575.96,614.56"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M583.64,621.04c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S583.64,621.14,583.64,621.04"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M572.24,627.76c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S572.24,627.86,572.24,627.76"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M572.12,633.76c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S572.12,633.86,572.12,633.76"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M568.28,637.48c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S568.28,637.58,568.28,637.48"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M580.28,648.4c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S580.28,648.5,580.28,648.4"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M576.8,645.04c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S576.8,645.14,576.8,645.04"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M580.52,641.2c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S580.52,641.3,580.52,641.2"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M578.6,654.88c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S578.6,654.98,578.6,654.88"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M590.48,650.56c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S590.48,650.66,590.48,650.56"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M617.72,662.68c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S617.72,662.78,617.72,662.68"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M617.72,668.56c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S617.72,668.66,617.72,668.56"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M613.52,663.76c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S613.52,663.86,613.52,663.76"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M616.4,673c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S616.4,673.1,616.4,673"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M605,669.16c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C604.92,669.34,605,669.26,605,669.16"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M595.52,671.08c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S595.52,671.18,595.52,671.08"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M606.68,675.52c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S606.68,675.62,606.68,675.52"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M611.84,684.88c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S611.84,684.98,611.84,684.88"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M628.28,687.64c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S628.28,687.74,628.28,687.64"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M630.68,675.76c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S630.68,675.86,630.68,675.76"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M627.08,669.28c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S627.08,669.38,627.08,669.28"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M639.68,665.32c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S639.68,665.42,639.68,665.32"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M658.64,685.24c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S658.64,685.34,658.64,685.24"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M645.8,683.8c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S645.8,683.9,645.8,683.8"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M662.36,658.36c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S662.36,658.46,662.36,658.36"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M673.52,677.32c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S673.52,677.42,673.52,677.32"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M678.32,674.44c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C678.24,674.62,678.32,674.54,678.32,674.44"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M674,669.64c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C673.92,669.82,674,669.74,674,669.64"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M673.04,656.68c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S673.04,656.78,673.04,656.68"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M679.04,650.8c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S679.04,650.9,679.04,650.8"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M672.68,647.44c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S672.68,647.54,672.68,647.44"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M672.8,641.68c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S672.8,641.78,672.8,641.68"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M677.48,639.88c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S677.48,639.98,677.48,639.88"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M664.04,667.84c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S664.04,667.94,664.04,667.84"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M654.44,655.72c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C654.36,655.9,654.44,655.82,654.44,655.72"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M662.48,640.96c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S662.48,641.06,662.48,640.96"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M656.72,632.2c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S656.72,632.3,656.72,632.2"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M663.68,628.24c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S663.68,628.34,663.68,628.24"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M662.48,614.44c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S662.48,614.54,662.48,614.44"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M677.72,605.44c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S677.72,605.54,677.72,605.44"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M677.72,593.08c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S677.72,593.18,677.72,593.08"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M671.12,606.88c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S671.12,606.98,671.12,606.88"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M672.32,619.84c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C672.24,620.02,672.32,619.94,672.32,619.84"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M677.24,616.84c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S677.24,616.94,677.24,616.84"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M677,568.12c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C676.92,568.3,677,568.22,677,568.12"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M670.52,558.04c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S670.52,558.14,670.52,558.04"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M670.88,550.72c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C670.8,550.9,670.88,550.82,670.88,550.72"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M670.04,542.08c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S670.04,542.18,670.04,542.08"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M676.04,539.92c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S676.04,540.02,676.04,539.92"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M676.4,517.6c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S676.4,517.7,676.4,517.6"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M671.48,517.24c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S671.48,517.34,671.48,517.24"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M670.52,504.76c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S670.52,504.86,670.52,504.76"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M676.04,497.44c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S676.04,497.54,676.04,497.44"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M670.4,512.2c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S670.4,512.3,670.4,512.2"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M676.04,506.2c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S676.04,506.3,676.04,506.2"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M663.08,521.44c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S663.08,521.54,663.08,521.44"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M660.68,523.72c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S660.68,523.82,660.68,523.72"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M661.28,530.44c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S661.28,530.54,661.28,530.44"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M660.92,537.64c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S660.92,537.74,660.92,537.64"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M662.48,542.32c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S662.48,542.42,662.48,542.32"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M661.28,551.92c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S661.28,552.02,661.28,551.92"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M650.96,546.4c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S650.96,546.5,650.96,546.4"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M656.6,506.32c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S656.6,506.42,656.6,506.32"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M652.76,510.64c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C652.68,510.82,652.76,510.74,652.76,510.64"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M657.08,513.04c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S657.08,513.14,657.08,513.04"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M655.88,517.36c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	C655.8,517.54,655.88,517.46,655.88,517.36"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M653.84,495.16c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S653.84,495.26,653.84,495.16"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M647.72,501.04c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S647.72,501.14,647.72,501.04"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M642.44,502.48c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	C642.36,502.66,642.44,502.58,642.44,502.48"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M639.2,500.92c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S639.2,501.02,639.2,500.92"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M636.44,497.8c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	C636.36,497.98,636.44,497.9,636.44,497.8"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M634.64,501.28c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S634.64,501.38,634.64,501.28"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M631.04,498.04c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S631.04,498.14,631.04,498.04"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M611.36,499.24c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S611.36,499.34,611.36,499.24"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M608.6,499.96c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S608.6,500.06,608.6,499.96"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M603.44,501.88c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C603.36,502.06,603.44,501.98,603.44,501.88"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M595.64,501.4c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S595.64,501.5,595.64,501.4"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M590.84,500.68c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S590.84,500.78,590.84,500.68"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M583.16,506.8c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S583.16,506.9,583.16,506.8"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M587,508.6c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C586.92,508.78,587,508.7,587,508.6"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M585.92,513.64c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S585.92,513.74,585.92,513.64"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M576.68,508.36c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S576.68,508.46,576.68,508.36"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M569,511.84c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C568.92,512.02,569,511.94,569,511.84"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M569.24,522.28c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S569.24,522.38,569.24,522.28"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M578.48,522.76c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S578.48,522.86,578.48,522.76"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M581.72,531.16c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S581.72,531.26,581.72,531.16"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M576.8,532.96c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S576.8,533.06,576.8,532.96"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M589.04,538c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S589.04,538.1,589.04,538"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M580.16,547.24c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S580.16,547.34,580.16,547.24"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M581.96,554.44c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S581.96,554.54,581.96,554.44"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M570.2,555.28c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C570.12,555.46,570.2,555.38,570.2,555.28"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M569.96,561.64c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S569.96,561.74,569.96,561.64"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M579.56,557.32c0-0.1-0.08-0.18-0.18-0.18c-0.1,0-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	C579.48,557.5,579.56,557.42,579.56,557.32"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M571.64,551.8c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S571.64,551.9,571.64,551.8"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M452.6,597.76c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S452.6,597.86,452.6,597.76"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M462.56,579.04c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18c0,0.1,0.08,0.18,0.18,0.18
	S462.56,579.14,462.56,579.04"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M452.72,571.84c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S452.72,571.94,452.72,571.84"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M415.16,616.84c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S415.16,616.94,415.16,616.84"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M490.52,618.88c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S490.52,618.98,490.52,618.88"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M530.36,618.64c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S530.36,618.74,530.36,618.64"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M470.96,562.72c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18
	S470.96,562.82,470.96,562.72"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M604.4,614.08c0-0.1-0.08-0.18-0.18-0.18s-0.18,0.08-0.18,0.18s0.08,0.18,0.18,0.18S604.4,614.18,604.4,614.08"
	/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M639.8,608.8c0-0.17-0.13-0.3-0.3-0.3s-0.3,0.13-0.3,0.3c0,0.17,0.13,0.3,0.3,0.3S639.8,608.97,639.8,608.8"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="635.12" y1="611.62" x2="636.92" y2="613.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="608.36" y1="612.34" x2="606.68" y2="614.14"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="614.72,491.26 614.96,495.22 623.72,494.74 623.6,491.62 614.36,491.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="677.36,500.98 675.56,501.22 675.8,504.1 677.72,504.1 677.6,500.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="572.84,561.7 572.84,563.26 570.8,564.22 569.6,563.98 567.44,563.98 566.48,563.74 565.88,562.54 
	565.64,561.94 565.88,561.1 566.48,560.5 566.36,559.66 566.36,559.18 566.96,558.58 568.16,558.82 569,559.18 569.96,559.18 
	570.32,558.94 571.4,558.94 571.88,559.18 572.12,559.66 572.48,560.14 572.72,560.26 572.84,560.5 572.84,561.1 572.84,561.7 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="582.08" y1="557.74" x2="581.6" y2="556.66"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="578.24,554.86 577.88,554.98 577.04,555.34 576.44,556.42 576.2,557.86 576.44,559.06 576.92,561.46 
	578,561.7 578.96,561.1 579.8,560.74 580.4,559.66 581.72,559.06 582.08,557.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="581.96,547.54 581.96,548.62 581.12,549.34 579.92,549.7 578.24,549.7 577.4,548.74 577.28,548.14 
	577.28,547.3 576.56,545.98 576.32,545.26 577.04,544.78 577.76,544.66 578.72,544.3 579.8,543.82 580.64,544.18 581,544.78 
	581.12,545.5 581.36,546.1 581.96,547.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="578.36,534.1 578.36,533.38 579.08,533.26 579.92,533.38 580.16,532.54 579.32,531.7 578.36,530.86 
	578.6,530.62 578.6,529.54 577.64,528.58 576.68,528.34 576.32,529.54 576.32,530.86 575.6,531.34 574.76,530.98 573.68,531.1 
	573.56,531.7 573.68,532.54 574.52,533.5 574.28,534.34 574.16,535.18 574.76,535.78 575.36,535.78 575.36,535.06 576.2,535.3 
	576.44,535.42 577.16,535.9 578.12,536.26 577.88,535.06 577.52,534.7 577.64,534.34 578.24,534.46 578.48,534.46 578.24,533.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="581.6,532.18 581,532.18 580.28,531.22 580.16,530.26 580.28,529.78 580.64,529.42 579.92,528.34 
	579.8,527.38 581.36,526.78 582.32,527.02 582.8,527.14 583.64,527.14 584.48,527.14 584.36,527.98 584.72,528.7 585.44,530.02 
	585.2,530.5 585.68,532.3 585.32,532.42 584.6,532.3 583.88,532.06 583.4,532.42 582.92,532.54 582.44,532.66 581.84,532.42 
	581.72,532.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="580.88,523.78 580.52,522.46 581,521.14 579.56,520.3 578.48,520.42 578.24,520.66 577.4,520.18 
	576.68,519.58 576.32,520.66 576.44,521.26 576.44,521.74 575.48,522.46 575.12,523.18 575.12,524.86 575.96,526.06 576.92,526.18 
	577.28,525.22 577.88,524.98 578.48,525.1 579.2,525.46 580.4,525.82 580.52,524.98 580.28,524.14 580.52,523.9 580.88,523.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="570.44,556.42 570.44,557.38 570.08,557.74 569.36,557.74 568.52,556.9 567.56,557.38 566.6,557.5 
	566,557.02 566.72,555.7 566.12,554.98 565.4,553.78 565.64,553.18 566.24,553.3 566.48,553.3 566.48,552.58 566.36,551.86 
	567.08,552.1 567.44,552.34 567.56,551.98 567.32,551.38 567.2,550.9 567.2,549.94 567.44,549.34 567.8,548.98 568.16,549.1 
	568.64,549.34 569.24,549.58 569.6,549.1 569.84,548.74 570.2,549.1 570.56,549.58 571.28,549.34 571.76,549.1 572.24,549.46 
	572.48,550.06 573.08,550.3 574.28,551.02 574.04,551.5 573.44,551.86 572.84,552.1 572.6,552.58 572.84,552.94 572.96,553.54 
	572.48,553.9 571.76,553.78 571.4,553.9 571.28,554.26 571.88,554.26 572.24,554.38 572.24,554.62 571.88,554.86 571.4,554.98 
	571.28,555.22 571.52,555.58 571.64,555.82 571.52,556.3 571.16,556.42 570.32,556.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="582.2,556.06 582.2,556.18 581.12,556.9 580.52,556.42 580.52,555.94 580.04,555.7 579.08,555.82 
	578.12,555.34 578.24,554.5 578.84,553.78 578.84,553.3 578.72,552.46 579.08,551.74 579.8,552.1 580.16,552.46 580.64,552.22 
	580.88,551.62 581.6,551.5 581.72,552.1 582.08,552.46 582.56,552.34 583.16,552.1 583.64,552.34 583.64,552.82 583.16,553.42 
	583.28,554.02 583.88,554.5 584.24,555.1 584.12,555.46 583.52,555.46 583.28,555.82 583.28,556.3 583.04,556.66 582.68,556.66 
	582.32,556.06 582.2,556.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="587.36,537.58 586.4,537.7 585.68,536.86 585.8,535.78 586.64,536.02 587.24,536.5 587.84,535.78 
	588.2,534.94 588.92,535.42 589.04,536.26 589.28,536.74 589.88,536.5 590.36,536.38 590.84,536.98 590.48,537.58 590.6,538.06 
	591.08,538.66 591.32,539.62 590.6,539.98 590.24,539.38 589.76,539.26 589.28,539.86 588.92,540.58 588.44,540.94 587.84,540.7 
	587.6,539.62 587.36,539.26 586.4,539.26 585.68,539.5 585.32,539.14 585.68,538.3 586.16,538.06 586.4,537.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="565.64,521.74 566.12,521.86 566.36,521.5 566.36,521.02 566.6,520.54 566.96,520.54 567.32,521.02 
	567.8,520.9 568.04,520.3 568.28,520.06 569.12,520.06 569.6,519.58 569.84,519.34 570.32,519.58 570.32,519.94 570.56,520.3 
	571.04,520.3 571.28,520.42 570.8,520.78 570.68,521.38 571.16,521.74 571.64,522.1 571.64,522.7 571.16,523.3 570.56,523.42 
	570.44,523.9 570.32,524.38 569.72,524.62 569.48,524.02 569,523.9 568.4,524.02 567.8,524.38 567.2,523.9 566.84,523.3 
	566.24,522.82 565.88,522.82 565.88,522.46 565.64,521.86 565.76,521.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="570.56,509.98 570.32,510.1 569.84,509.98 569.48,509.38 568.88,509.02 568.16,509.26 568.04,509.74 
	567.32,510.1 566.48,510.22 566.24,510.58 566.48,511.06 566.6,511.66 566.36,512.26 566.12,513.34 566.48,513.7 566.84,513.58 
	567.32,513.94 567.44,514.18 568.28,514.18 568.76,513.94 569.6,514.06 569.96,513.58 569.84,513.1 570.08,512.26 570.32,511.9 
	570.08,511.3 569.96,510.94 570.2,510.82 570.32,510.7 570.44,510.46 570.44,509.98 570.32,509.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="577.64,505.9 577.04,506.74 576.08,506.38 575.6,505.9 574.76,506.26 574.52,507.1 574.28,507.82 
	573.32,507.94 573.08,508.18 573.44,508.78 573.92,509.02 574.16,509.74 574.28,510.46 575,510.46 575.24,510.34 576.2,511.06 
	576.68,510.94 576.92,510.22 577.4,509.86 577.88,509.74 578.24,509.02 578,508.18 577.88,507.82 578,506.86 577.76,506.5 
	577.52,506.38 576.8,506.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="583.16,504.46 582.8,504.82 582.44,504.34 582.08,504.1 581.48,504.46 581.36,504.94 581.12,505.78 
	580.52,506.02 580.4,506.38 581.12,506.62 581.36,507.1 581.12,507.94 581.36,508.78 581.84,508.9 582.08,508.78 582.44,509.26 
	582.68,509.38 582.92,508.78 583.28,508.54 583.88,508.54 584.6,508.3 584.84,507.58 584.36,507.1 584.48,506.38 584.6,505.66 
	584.6,505.18 584.12,505.18 583.64,505.42 583.16,505.66 582.8,505.54 582.8,505.06 582.92,504.58 582.92,504.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="587.24,507.22 587.6,507.94 588.8,508.06 588.92,508.42 588.2,508.78 587.84,508.9 587.72,509.86 
	587.12,510.34 586.64,509.98 586.04,509.86 585.32,509.5 585.44,508.66 585.68,508.18 585.44,507.46 585.68,506.98 586.16,507.22 
	586.76,507.58 586.88,507.1 587,506.86 587.24,507.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="584.96,511.54 584.96,512.5 584.6,512.5 584,511.9 583.4,512.38 583.4,512.74 584,513.7 583.04,514.3 
	582.92,514.54 583.4,515.02 584.12,514.78 584.36,515.26 584.36,515.98 584.96,516.1 585.44,515.74 586.04,515.38 586.88,515.26 
	587.24,515.02 587.12,514.06 587,513.82 587.36,513.22 587.12,512.74 586.88,512.5 586.64,512.5 586.16,512.62 585.8,512.02 
	585.56,511.42 585.08,511.3 584.96,511.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="583.64,618.82 584.48,619.66 585.68,619.18 586.64,619.18 586.88,620.14 586.52,621.46 586.52,623.02 
	586.52,623.62 585.44,624.94 584,624.46 583.64,623.62 582.32,623.86 581.36,624.1 581.12,623.38 581.6,621.94 581.36,621.1 
	580.76,620.62 580.88,619.42 581.6,619.18 582.32,619.18 583.4,619.06 583.64,618.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="570.8,629.98 570.44,630.22 569.96,629.62 570.08,628.9 570.32,628.42 570.44,627.94 570.2,627.58 
	570.44,626.62 570.8,626.62 571.4,626.5 571.88,626.26 572.84,626.26 573.68,626.62 574.04,627.34 574.64,627.82 574.64,629.26 
	574.4,630.1 573.92,630.7 573.8,630.7 573.2,630.7 573.08,629.98 572.72,629.98 572.24,630.22 572,630.58 571.64,630.7 
	571.16,630.46 570.92,630.1 570.56,629.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="571.88,631.42 571.88,631.66 572.36,631.78 572.96,631.66 573.32,632.02 573.44,632.74 574.04,633.22 
	574.4,633.82 574.04,634.3 573.68,634.18 573.32,634.18 573.2,634.54 573.08,634.9 572.84,635.5 572.48,635.5 572.12,635.26 
	571.52,635.26 571.16,635.38 570.68,635.38 570.56,634.78 570.32,634.3 569.84,634.18 569.96,633.58 570.2,633.34 570.56,633.22 
	570.92,632.62 571.04,632.14 571.28,631.9 571.64,631.66 572,631.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="567.68,636.22 568.04,636.58 568.4,636.22 568.76,635.98 569.36,636.22 569.6,636.58 569.96,636.82 
	570.32,637.54 569.84,637.9 569.72,638.26 569.84,638.74 569.6,638.98 569.12,638.86 568.76,638.98 568.28,639.34 567.8,639.58 
	567.44,639.34 567.2,638.98 566.72,638.74 566.36,638.26 566.72,637.66 566.72,637.06 566.6,636.7 566.84,636.46 567.08,636.46 
	567.44,636.34 567.68,636.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="576.2,640.54 576.32,640.66 576.8,640.42 577.04,639.82 577.28,639.22 577.76,639.22 578.6,639.46 
	578.96,638.98 579.2,638.5 580.04,638.38 580.64,639.22 581.12,639.34 581.84,639.1 582.32,639.34 582.56,640.42 582.56,640.9 
	583.04,641.02 583.4,641.74 583.16,641.98 582.56,642.1 582.44,642.34 582.32,642.7 582.44,643.06 581.72,643.3 581.12,643.3 
	581,643.42 581,644.02 580.64,644.38 579.92,644.38 579.56,644.38 579.44,644.74 579.44,645.34 579.2,645.82 578.6,645.82 
	578.48,645.94 578.12,646.42 578,646.66 577.64,646.9 577.16,646.78 576.8,646.54 576.44,646.66 575.96,646.78 575.72,646.06 
	575.6,644.98 575.36,644.02 574.76,643.42 575.12,642.7 575.48,642.7 575.84,642.82 576.08,642.34 575.96,641.98 575.72,641.38 
	575.96,641.02 576.08,640.9 576.2,640.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="580.76,646.78 580.76,646.9 581.48,646.78 582.08,647.26 582.08,647.98 582.08,648.82 582.32,649.06 
	581.96,649.9 581.48,650.02 580.88,650.26 580.04,650.62 579.44,650.38 578.84,650.14 578.24,649.78 578.36,648.94 578.84,648.46 
	578.6,647.86 578.72,647.26 579.2,647.02 579.8,647.14 580.16,647.26 580.52,647.02 580.76,646.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="578.12,652.54 578.36,652.78 578.84,652.54 579.56,652.18 579.8,652.66 579.8,653.5 579.92,654.22 
	580.52,654.58 579.68,655.54 579.2,656.14 579.08,656.98 578.36,657.22 578.24,656.38 577.64,656.02 577.04,656.14 576.44,656.02 
	576.44,655.3 576.8,655.06 576.92,654.34 576.68,653.5 576.92,653.14 577.52,653.14 577.76,653.14 578.12,652.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="590.6,647.74 591.08,648.1 592.04,647.98 593.12,647.14 594.08,647.74 594.08,648.82 593.36,650.5 
	593.72,651.46 593,652.42 591.68,652.9 591.68,654.58 591.2,655.06 590.24,654.22 589.16,653.86 587.96,654.22 587.6,653.38 
	588.2,652.66 587.84,651.1 587.6,650.5 589.04,649.3 589.76,648.58 590.48,647.86 590.6,647.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="596.24,668.74 596.24,669.1 596.96,669.1 597.56,669.1 597.68,669.7 597.92,670.3 598.4,670.66 
	598.28,671.26 597.8,671.5 597.92,671.98 598.16,672.94 597.68,673.18 596.96,672.46 595.88,672.22 595.52,672.58 595.16,673.42 
	594.56,673.9 593.96,673.3 593.96,672.34 593.84,671.98 593.12,671.62 593.48,670.9 594.08,670.78 594.44,670.78 594.44,669.82 
	594.56,669.1 595.04,669.46 595.16,669.7 595.64,669.46 596,668.98 596.24,668.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="603.32,665.62 603.32,665.98 603.8,666.7 604.52,666.34 605.24,665.74 606.2,665.98 605.84,667.18 
	606.2,667.54 607.28,668.02 607.52,668.74 606.68,669.34 606.56,669.82 606.8,671.14 605.72,670.66 605,670.3 604.28,670.66 
	603.68,670.66 603.56,669.82 603.08,669.34 602.36,668.74 602.72,667.9 602.84,667.18 602.48,666.22 602.72,665.86 603.08,665.62 
	603.32,665.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="606.8,673.3 606.8,673.54 607.4,673.66 608.24,673.42 608.6,674.26 608.12,674.74 608.24,675.46 
	608.48,676.18 608,676.78 607.76,677.74 606.8,677.62 606.2,676.9 605.48,676.9 604.76,676.78 605.12,675.94 605.12,675.34 
	604.4,674.26 605,673.78 605.6,673.66 605.72,673.9 606.08,673.9 606.44,673.42 606.8,673.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="612.32,684.22 612.44,684.58 612.92,684.46 613.28,685.06 613.04,685.54 613.16,686.02 613.16,686.26 
	612.44,686.26 612.08,685.9 611.72,686.02 611.24,686.38 610.64,686.02 610.4,685.66 609.8,685.66 609.44,685.66 609.32,685.18 
	609.56,684.58 609.92,684.34 610.52,684.1 610.88,683.86 611.36,683.98 611.6,683.98 611.84,683.98 612.32,684.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="615.68,670.54 616.4,670.78 617,670.54 617.96,670.78 617.96,671.38 618.2,671.74 618.68,672.58 
	618.56,673.66 617.72,673.66 617.12,673.66 616.52,674.26 616.16,674.38 615.68,674.14 614.6,674.14 613.88,674.38 613.52,674.02 
	613.52,673.18 614.12,672.94 614.6,672.7 614.96,671.98 614.96,671.38 615.2,671.02 615.56,670.9 615.8,670.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="617.12,666.58 616.28,666.82 615.92,667.54 616.28,668.5 616.64,669.1 616.64,669.58 617.12,669.58 
	617.48,669.34 618.44,668.98 618.44,667.9 617.96,667.54 617.72,667.18 617.48,666.82 617.24,666.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="614.24,662.86 614.24,662.62 613.88,661.66 613.16,661.66 612.68,662.26 612.44,662.62 611.84,662.74 
	611.6,662.98 611.84,663.46 611.84,663.94 611.72,664.42 611.72,664.9 612.08,665.14 612.68,665.02 612.8,665.14 613.04,665.38 
	613.4,665.5 613.76,665.02 613.88,664.78 614.36,664.66 614.72,664.42 614.72,663.7 614.72,663.1 614.24,662.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="616.52,661.18 616.88,661.3 617.24,661.18 617.48,660.94 617.96,661.06 618.32,661.42 618.44,661.9 
	618.8,662.02 618.92,662.26 618.68,662.86 618.56,663.1 618.68,663.7 618.44,663.94 617.84,664.06 617.6,664.42 617.12,664.66 
	616.64,664.54 616.52,663.94 616.16,663.22 615.56,662.62 615.68,662.26 615.92,662.02 616.16,661.78 616.28,661.42 616.52,661.18 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="638.6,662.02 638.6,662.26 638.84,662.62 639.44,662.74 639.68,662.5 640.28,662.62 640.4,663.1 
	640.4,663.58 641,664.06 640.88,664.66 640.76,664.9 640.64,665.62 640.64,665.98 640.64,666.34 639.92,666.7 639.44,666.7 
	638.84,666.7 638.36,666.82 638,666.82 637.76,666.22 637.52,665.38 637.28,664.66 637.76,663.94 637.88,663.58 638,662.86 
	638.36,662.5 638.72,662.38 639.08,662.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="627.44,667.66 627.44,667.78 627.8,668.02 628.16,667.9 628.4,668.5 628.28,668.86 628.4,669.46 
	628.52,669.7 628.52,670.06 628.16,670.18 627.92,670.18 627.56,670.54 627.44,670.78 627.08,670.9 626.72,670.66 626.36,670.54 
	625.88,670.54 625.88,669.94 626,669.34 625.76,668.98 625.64,668.62 625.88,668.26 626.24,668.14 626.72,668.02 627.08,667.78 
	627.44,667.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="631.16,674.74 631.16,674.98 631.64,675.1 631.88,675.1 632.12,675.82 632,676.3 632.12,676.78 
	632.12,677.14 631.52,677.26 631.04,677.26 630.68,677.38 630.2,677.62 629.96,677.5 629.6,677.02 629.12,677.02 629,676.06 
	629.36,675.34 629.12,674.62 629.48,674.38 629.96,674.38 630.44,674.5 630.8,674.5 631.04,674.62 631.16,674.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="655.88,652.54 655.88,653.14 656.6,653.74 656.48,654.58 655.88,654.94 655.64,656.5 655.16,656.98 
	653.96,657.22 653.72,656.38 653.6,655.3 653.96,654.46 654.44,653.74 654.92,652.66 655.28,652.42 655.64,652.3 655.88,652.42 
	655.88,652.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="662.84,652.78 661.88,653.5 661.04,653.02 660.32,652.54 659.12,653.26 658.76,654.82 658.76,655.9 
	657.68,656.5 656.72,657.34 656.6,659.26 656,660.7 657.44,662.14 659,662.38 660.32,663.34 661.64,664.18 662.84,663.7 
	663.08,663.22 663.2,661.3 663.56,660.46 664.16,660.1 664.76,659.14 664.52,657.58 663.8,656.74 662.72,656.5 662.24,656.14 
	662.48,655.54 663.56,655.3 663.92,654.82 664.04,653.98 663.44,653.02 662.84,652.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="664.76,666.82 663.56,666.94 662.84,666.7 661.88,667.78 663.08,668.74 663.68,668.86 664.76,668.5 
	664.88,667.18 664.64,666.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="629.12,686.98 628.52,686.14 628.04,685.9 627.08,686.38 626.72,687.1 626.48,687.46 626.24,688.18 
	626.6,688.9 627.32,689.38 628.04,689.38 628.16,688.78 628.4,688.54 628.88,688.3 629,688.06 629,687.34 629.12,686.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="646.4,683.38 645.92,683.02 645.44,683.5 645.32,683.5 644.96,683.38 644.6,683.5 644.36,684.22 
	644.48,684.7 644.12,685.3 644.6,685.9 645.44,685.9 645.68,685.54 646.28,685.54 646.52,685.18 646.28,684.22 646.76,684.1 
	647,683.86 647,683.5 646.52,683.38 646.4,683.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="658.76,683.62 658.76,683.98 659.24,683.98 659.6,683.98 659.84,684.22 659.72,684.7 659.72,685.06 
	660.08,685.66 659.84,685.9 659.48,685.9 659.24,686.26 658.76,686.5 658.16,686.5 657.8,686.5 657.08,686.62 656.6,686.38 
	656.6,686.02 656.96,685.78 657.2,685.54 657.08,685.06 657.2,684.82 657.68,684.58 657.92,684.7 658.16,684.1 658.16,683.74 
	658.64,683.62 658.76,683.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="673.16,674.62 673.4,675.1 674.12,674.98 674.72,675.58 674.84,676.3 674.96,677.02 675.8,677.62 
	675.32,678.7 674.72,679.06 673.76,679.18 673.04,678.94 671.72,678.7 671.12,678.1 671.12,677.02 671.36,676.3 671.36,675.46 
	671.96,674.86 672.44,674.86 672.8,674.74 673.16,674.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="679.04,673.66 679.04,674.02 679.28,674.26 679.28,674.86 679.04,675.34 679.04,676.06 678.08,676.3 
	677.36,675.82 676.76,675.34 676.52,674.5 676.76,673.78 677.12,673.66 677.48,673.66 677.96,673.54 678.08,673.42 678.56,673.66 
	678.8,673.78 679.04,673.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="674.84,670.18 674.72,670.54 675.08,670.66 675.32,670.78 674.96,671.14 674.48,670.78 674.24,670.54 
	673.88,670.9 673.64,671.38 673.52,671.74 672.68,671.74 672.8,671.02 672.8,670.78 672.32,670.54 671.96,670.66 671.72,670.42 
	671.84,669.94 672.08,669.94 672.32,669.94 672.56,669.58 672.44,669.22 672.68,668.74 672.92,668.86 673.04,668.62 673.16,668.14 
	673.52,668.02 673.64,668.26 673.76,668.62 674.24,668.74 674.6,668.38 674.96,668.38 675.08,668.74 674.96,668.86 675.08,669.1 
	675.44,669.22 675.56,669.46 675.44,669.82 675.32,670.06 675.08,670.18 674.84,670.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="673.16,655.42 673.28,655.66 673.64,655.78 674.12,655.54 674.36,655.78 674.36,656.26 674.12,656.62 
	674.36,656.98 674.36,657.46 673.88,657.22 673.64,657.1 673.4,657.7 673.16,658.06 672.92,657.94 672.32,657.94 671.72,658.54 
	671,658.54 670.76,658.06 671.36,657.7 671.72,657.1 670.88,656.26 670.28,656.02 670.28,655.66 671.36,655.42 671.72,656.02 
	671.96,655.42 672.2,654.82 672.8,654.82 673.4,655.18 673.16,655.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="672.08,644.62 672.08,645.22 672.56,645.22 672.8,645.1 673.52,645.34 673.52,645.7 673.52,646.3 
	674.12,646.42 674.48,646.9 674.36,647.5 673.88,647.38 673.4,647.5 673.4,647.98 673.52,648.34 673.52,648.82 673.4,649.54 
	672.68,649.42 672.44,649.18 671.72,649.18 671,649.42 670.52,649.18 670.52,648.34 670.64,647.98 671,647.74 671,647.02 
	670.52,646.54 670.64,646.06 671,646.18 671.12,646.06 671.36,645.22 671.48,644.74 671.6,644.62 672.08,644.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="679.4,649.66 679.4,650.62 680,650.98 680.24,651.34 680.24,651.7 679.4,652.06 679.28,652.06 
	678.68,652.42 678.32,652.66 677.84,652.18 677.72,651.7 677.12,651.7 676.88,651.34 677.12,650.62 677.48,650.62 677.84,650.14 
	677.84,649.66 678.32,649.66 678.56,649.66 678.8,649.66 679.16,649.54 679.4,649.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="673.4,640.3 673.4,640.66 673.88,640.9 674.36,641.14 674.24,641.74 673.88,641.98 674,642.34 
	674,642.82 673.52,643.06 672.8,642.7 672.68,642.94 672.44,643.42 671.84,643.54 671.96,642.94 671.48,642.82 671,642.22 
	671.12,641.86 671.48,641.74 671.6,640.9 671.48,640.42 671.84,640.18 672.2,640.78 672.44,640.9 672.68,640.42 672.8,640.18 
	673.16,640.3 673.4,640.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="677.6,639.1 677.84,639.34 678.32,639.22 678.56,639.82 678.08,640.3 677.96,640.66 678.2,641.26 
	677.24,641.62 677,641.02 676.28,640.78 675.68,640.66 675.92,640.18 676.16,640.06 675.8,639.34 675.92,638.98 676.52,638.86 
	676.64,638.98 677.24,639.22 677.48,639.1 677.6,639.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="663.92,638.5 663.8,639.34 664.04,639.58 664.52,639.46 665,639.58 664.88,640.06 663.92,640.18 
	663.92,641.02 664.64,641.5 665.12,641.86 664.28,642.7 664.16,642.1 663.56,642.46 663.44,643.3 662.72,643.18 662.36,642.7 
	661.88,642.94 661.4,643.3 659.96,643.18 660.44,642.46 661.28,641.74 660.56,641.14 660.08,640.9 659.96,640.42 660.44,639.7 
	661.16,640.06 661.64,639.82 661.76,638.98 662.24,638.62 662.36,639.1 662.48,639.7 662.96,639.46 663.2,639.1 663.32,638.86 
	663.68,638.74 664.04,638.74 664.28,638.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="659.12,631.78 658.4,631.78 658.16,630.82 657.68,629.74 656.36,629.5 656.24,629.5 656,630.1 
	654.68,629.86 653.96,629.98 653,630.58 652.16,631.06 651.56,631.18 651.44,632.14 652.04,632.62 652.88,632.74 652.88,633.34 
	652.28,634.18 652.88,634.78 653.24,634.78 653.96,634.78 654.56,636.22 654.92,636.82 655.76,636.58 656.12,635.86 656.48,635.74 
	657.56,635.86 658.04,635.86 658.04,634.66 658.76,634.42 659.6,634.18 659.72,633.7 659.48,633.34 658.88,632.86 658.76,632.02 
	658.76,631.66 658.88,631.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="664.16,625.9 663.92,626.86 664.52,626.86 665,627.46 664.76,627.94 665,628.3 665.48,628.9 
	665,629.26 664.64,629.26 664.4,630.34 664.28,630.7 663.68,630.34 663.44,629.86 662.48,629.86 661.52,629.5 661.16,629.02 
	661.52,628.54 661.64,628.18 661.16,627.46 661.64,626.74 662.36,626.74 662.84,626.74 663.08,626.14 663.56,626.14 663.8,626.38 
	664.28,626.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="678.32,614.98 678.32,615.34 678.92,616.54 679.04,617.14 678.44,618.34 678.32,619.54 678.08,620.38 
	677.24,620.02 676.88,618.82 674.96,618.82 674.48,616.78 674.84,615.82 675.2,614.98 675.8,614.74 676.16,614.98 676.76,614.74 
	677.24,613.78 677.72,613.78 678.08,614.26 677.96,614.86 678.32,614.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="674.36,619.66 674.12,619.18 674.12,618.1 673.16,617.98 672.44,618.94 671.84,618.1 671.36,617.38 
	670.76,617.5 670.76,618.34 670.64,618.94 669.8,619.78 670.16,620.62 670.52,621.34 670.76,622.3 670.76,623.38 671.72,622.9 
	672.32,622.18 672.92,622.66 673.64,622.54 673.52,621.7 673.64,621.22 674,620.86 674.24,620.5 674.36,619.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="663.68,611.62 663.68,612.1 664.04,612.58 664.4,612.94 664.52,614.14 664.16,614.62 664.4,615.7 
	664.16,616.78 662.96,616.66 662.84,616.54 661.28,617.26 660.32,617.86 659.84,616.78 659.72,616.3 658.28,615.22 658.28,614.62 
	659.24,614.38 659.96,614.62 660.32,613.78 660.32,612.94 660.8,612.58 661.28,612.58 661.88,612.94 662.36,612.82 662.6,612.58 
	662.96,612.58 663.32,612.82 663.8,612.58 664.04,612.34 664.28,612.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="672.8,605.14 672.56,605.38 672.92,605.74 673.76,606.34 673.76,607.06 673.04,607.54 672.92,608.14 
	673.04,608.62 672.56,609.1 671.6,608.74 671.12,608.86 670.28,608.98 670.28,608.14 670.16,607.54 669.8,606.82 669.92,606.1 
	670.16,605.5 670.88,603.58 671.12,603.46 671.48,604.06 671.72,604.54 672.2,604.78 672.44,604.9 672.8,605.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="678.2,603.82 678.2,603.94 678.92,604.3 679.16,605.38 678.92,605.62 678.8,606.22 678.68,606.82 
	678.08,606.94 677.36,607.42 677.24,607.78 676.64,607.54 676.64,606.94 676.4,606.34 675.8,605.86 675.68,605.26 676.28,605.02 
	676.64,604.66 676.64,603.82 676.76,603.58 677.24,603.82 677.6,604.06 677.72,604.06 677.96,603.94 678.2,603.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="678.2,590.98 678.2,591.82 678.68,591.94 679.04,592.42 678.8,593.14 678.68,593.62 678.68,594.46 
	677.96,594.7 677.72,594.46 677.24,594.46 676.76,594.82 676.4,594.46 676.28,593.86 675.8,593.62 675.92,592.9 676.28,592.54 
	676.52,591.82 676.88,591.58 677.24,591.58 677.72,591.34 678.32,591.22 678.56,591.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="678.32,565.9 678.44,567.34 679.04,567.58 678.92,568.78 678.44,569.38 678.56,570.46 677.48,570.7 
	677,570.1 676.4,570.1 675.32,570.22 675.08,569.5 674.84,568.78 674.72,567.58 675.56,566.98 676.64,566.5 677.48,566.38 
	678.08,566.38 678.8,566.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="671.12,555.82 670.28,556.42 670.16,556.18 669.56,555.82 669.32,556.54 669.44,557.26 669.32,557.38 
	668.96,558.34 669.32,558.94 669.32,559.66 669.08,560.98 670.16,561.34 670.76,560.5 670.88,560.02 671.6,559.42 671.24,558.1 
	671.12,556.9 671.24,556.06 671,555.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="670.52,548.74 670.88,549.1 671.6,548.74 672.2,548.38 673.28,548.98 673.28,549.46 674,550.42 
	674.36,550.66 674.24,551.38 673.4,551.5 673.16,551.98 673.28,552.46 673.28,553.06 673.28,553.42 672.44,552.94 672.2,552.94 
	671.72,553.42 671,553.54 670.52,552.94 670.04,552.58 669.56,552.58 668.72,552.7 669.2,551.62 669.32,550.66 669.08,549.94 
	669.44,549.7 669.92,549.34 670.28,548.86 670.52,548.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="671,540.58 670.88,541.42 671.72,541.9 671.72,542.38 671.6,544.18 670.4,544.42 670.04,544.3 
	669.32,545.26 668.96,545.26 668.96,543.58 669.08,542.86 668.72,542.14 668.72,541.3 669.08,540.58 669.32,540.34 669.32,539.02 
	670.16,539.14 670.52,539.38 670.52,539.74 670.76,540.34 671,540.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="677.36,537.7 677.12,538.42 677.48,538.78 677.84,539.14 677.84,539.98 677.72,540.82 678.08,541.78 
	677.6,542.62 676.52,542.38 675.92,541.9 674.6,541.66 674.12,540.82 674.12,540.1 673.04,539.5 673.04,538.54 673.88,537.94 
	674.36,537.94 675.08,538.18 675.92,537.7 676.52,537.34 677.12,537.7 677.36,537.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="662.48,548.38 662.6,548.62 663.68,549.1 664.4,549.58 664.4,551.26 664.16,552.82 664.76,553.54 
	664.4,554.74 663.56,555.82 662.12,555.46 661.4,554.98 659.72,555.1 658.64,553.9 657.44,553.3 655.76,552.58 655.64,551.02 
	656.24,549.22 657.32,547.3 658.28,546.7 658.88,547.9 659.24,548.74 659.96,549.1 660.68,548.26 661.76,548.14 662.12,548.5 
	662.48,548.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="651.32,548.38 651.44,548.98 650.12,549.58 648.68,548.38 647.96,547.3 647.6,546.34 648.44,544.54 
	649.28,543.1 650.12,542.02 651.8,541.66 652.64,541.9 652.4,543.34 652.28,543.58 652.76,544.78 653.36,545.98 653,547.18 
	652.16,547.9 651.8,548.14 651.32,548.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="663.44,540.46 661.52,539.98 660.08,541.18 660.08,542.26 660.68,543.46 660.92,543.7 661.64,544.78 
	662.96,545.26 663.92,543.82 664.4,543.34 664.28,542.14 663.68,541.54 663.32,541.06 663.2,540.34 662.96,539.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="659.96,535.06 659.96,535.66 659.48,535.78 658.28,535.9 657.44,535.66 657.2,536.14 657.2,536.26 
	657.32,536.62 657.32,536.98 656.96,537.94 657.2,538.18 657.68,538.42 658.16,538.42 658.64,538.66 658.76,539.26 659.12,539.5 
	659.84,539.14 660.2,538.9 660.92,539.14 661.4,539.26 661.76,538.42 662.72,537.82 663.32,537.22 663.68,535.66 663.08,535.3 
	662.36,535.3 662,534.94 661.76,534.34 661.04,533.86 660.8,534.7 660.68,534.94 660.44,535.06 659.96,535.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="661.64,528.46 660.2,527.98 659.6,527.98 659,528.82 659,529.78 658.16,530.62 657.68,531.46 
	658.76,532.54 659.6,531.94 660.56,532.06 661.4,532.54 662.48,532.54 662.96,531.7 662.96,530.74 662.48,530.02 662,529.42 
	661.76,528.58 661.64,528.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="661.88,522.94 661.16,522.34 659.48,522.1 659.12,522.46 659.12,523.18 658.4,524.38 657.92,524.98 
	657.92,525.82 658.64,526.54 659.96,526.9 661.16,527.02 662.36,526.42 662.36,524.98 661.88,524.14 661.88,522.82 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="661.88" y1="522.58" x2="662" y2="522.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="663.68,520.3 662.24,519.82 660.92,519.94 660.8,520.42 661.04,521.14 661.52,521.62 661.88,521.86 
	661.64,522.1 661.64,522.58 662.84,522.34 663.32,523.06 663.92,524.02 664.64,523.54 664.88,522.58 664.52,521.86 663.56,521.38 
	663.56,520.66 663.68,520.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="654.92,510.94 654.32,509.86 654.08,508.66 653.6,508.18 652.76,508.06 651.92,508.54 651.68,509.14 
	651.2,509.5 650,509.62 650,510.46 650.36,510.94 650.6,511.3 650.84,512.86 652.28,513.94 653.48,512.5 653.48,511.54 
	654.32,510.7 654.68,510.46 654.92,510.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="656.96,504.22 656.24,503.98 655.4,503.74 654.8,503.98 654.56,504.46 654.8,504.82 654.68,505.18 
	654.08,505.42 654.08,505.78 654.8,506.02 654.92,506.14 654.8,506.86 654.68,507.58 654.8,508.3 655.52,508.54 655.88,507.94 
	656.24,507.82 656.72,507.94 657.08,507.94 658.4,507.94 658.88,507.58 658.88,506.98 658.64,506.5 657.8,506.5 657.92,505.78 
	658.16,505.06 657.8,504.82 657.08,504.58 656.84,504.34 656.84,504.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="657.68,509.74 657.56,510.58 657.08,510.7 656.84,510.58 656.36,510.94 656.24,511.18 656.24,511.54 
	655.88,511.9 655.28,511.9 655.16,512.26 654.92,512.62 654.8,513.46 654.8,513.82 655.28,514.42 656.24,514.42 656.48,514.3 
	657.08,514.3 657.44,514.54 658.04,514.54 658.16,514.18 658.04,513.94 658.28,513.34 658.88,513.1 659,512.62 658.76,512.14 
	658.76,511.66 659.12,511.06 659.36,510.58 659.24,510.22 659,510.22 658.88,510.22 658.52,510.22 657.92,510.22 657.68,510.1 
	657.32,510.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="656.96,515.86 656.6,515.86 656.12,515.5 655.52,515.02 655.16,514.9 654.8,515.26 655.04,515.5 
	654.8,516.1 654.32,516.22 654.08,516.22 653.48,516.46 653.24,516.94 653.48,517.18 653.84,517.42 653.72,518.26 653.36,518.62 
	653.6,518.98 654.32,518.86 654.8,518.62 655.04,519.22 655.4,519.82 656.12,519.7 656.24,519.34 656.36,518.62 656.84,518.5 
	657.08,518.62 657.68,518.38 657.68,517.66 657.32,517.42 657.32,516.94 657.44,516.58 657.56,516.22 657.32,515.98 656.96,515.86 
	656.72,515.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="626.84,495.58 626.96,493.9 627.2,494.26 627.8,493.78 627.92,494.38 629,493.66 629.24,494.14 
	629.6,493.66 629.96,494.02 630.8,493.54 631.16,494.26 631.64,493.66 631.88,494.26 632.48,493.54 633.08,494.14 633.32,493.42 
	633.68,494.02 634.28,493.66 634.4,494.38 635,493.66 635.48,494.14 635.84,493.54 636.08,494.26 636.92,493.66 637.4,494.14 
	637.64,493.66 637.88,494.14 638.6,493.54 638.84,494.14 639.2,493.66 639.32,494.14 639.8,493.42 640.28,494.14 640.64,493.66 
	641.36,494.14 641.48,493.54 641.84,494.14 642.32,493.66 642.56,494.38 642.68,493.66 643.28,494.14 643.52,493.42 643.88,494.02 
	644.48,493.54 644.72,494.14 644.96,493.42 645.44,494.14 645.56,493.3 646.04,494.02 646.28,493.42 646.64,494.02 646.88,493.42 
	647.24,493.9 647.24,493.18 647.72,493.78 647.96,493.3 648.2,494.02 648.56,493.18 648.92,493.78 649.28,493.18 649.64,493.78 
	649.88,493.3 650.24,493.78 650.48,493.3 650.84,493.78 650.96,493.3 651.32,493.66 651.44,493.18 651.8,493.54 652.04,493.06 
	652.28,493.54 652.64,493.06 652.88,493.66 653.12,493.18 653.36,493.54 653.6,493.06 653.84,493.54 654.08,492.94 654.32,493.54 
	654.56,492.82 654.92,493.42 655.04,492.94 655.4,493.42 655.64,492.94 655.88,493.54 656.12,492.94 656.48,493.54 656.72,492.94 
	657.08,495.34 656.6,494.86 656.48,495.46 656.12,494.86 655.88,495.46 655.64,494.86 655.28,495.58 654.92,494.98 654.56,495.7 
	654.32,494.98 653.84,495.7 653.72,494.86 653.24,495.58 653,494.98 652.88,495.58 652.52,495.1 652.4,495.58 652.04,495.22 
	651.8,495.7 651.68,495.22 651.32,495.82 650.96,495.22 650.72,495.82 650.36,495.34 650.24,495.82 650,495.46 649.88,495.94 
	649.64,495.34 649.16,495.94 648.92,495.46 648.68,496.06 648.44,495.46 648.08,495.94 647.84,495.46 647.36,495.94 647,495.58 
	646.76,496.06 646.76,495.58 646.4,496.06 646.16,495.7 645.92,496.06 645.8,495.58 645.32,496.18 645.08,495.7 644.96,496.18 
	644.72,495.7 644.48,496.18 644.36,495.58 643.88,496.18 643.76,495.82 643.52,496.18 643.16,495.82 642.8,496.3 642.56,495.7 
	642.2,496.18 641.96,495.82 641.72,496.42 641.36,495.82 641.12,496.3 640.76,495.82 640.4,496.3 640.16,495.7 639.68,496.18 
	639.56,495.7 639.32,496.3 638.96,495.82 638.84,496.3 638.48,495.82 638.12,496.42 637.88,495.94 637.64,496.42 637.28,495.82 
	636.92,496.18 636.8,495.7 636.44,496.18 636.32,495.58 636.08,496.18 635.72,495.58 635.6,496.06 635.24,495.58 635,496.06 
	634.76,495.58 634.4,495.94 634.16,495.58 633.8,496.18 633.68,495.58 633.44,496.06 633.2,495.58 632.84,496.06 632.72,495.58 
	632.36,496.06 632.12,495.58 631.88,496.06 631.64,495.58 631.4,496.06 631.28,495.7 630.92,496.18 630.8,495.58 630.56,496.18 
	630.08,495.58 629.84,496.18 629.72,495.7 629.36,496.18 629.12,495.7 629,496.18 628.64,495.82 628.4,496.3 628.28,495.82 
	627.92,496.18 627.68,495.82 627.32,496.42 627.2,495.82 626.84,496.3 626.84,495.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="585.44,496.18 585.68,496.78 586.16,496.18 586.52,496.78 587.12,496.06 587.6,496.66 587.96,496.06 
	588.32,496.54 588.92,496.18 589.04,496.9 589.52,496.18 590,496.66 590.48,496.06 590.72,496.78 591.44,496.18 591.92,496.66 
	592.28,496.18 592.4,496.66 593.24,496.06 593.36,496.66 593.72,496.18 593.96,496.66 594.32,496.06 594.92,496.66 595.28,496.18 
	595.88,496.66 596.12,496.06 596.48,496.66 596.96,496.18 597.08,496.9 597.32,496.18 597.92,496.66 598.16,495.94 598.52,496.66 
	599.12,496.06 599.36,496.66 599.6,496.06 599.96,496.66 600.08,495.94 600.68,496.54 600.92,495.94 601.16,496.54 601.52,495.94 
	601.88,496.42 601.88,495.82 602.36,496.3 602.6,495.82 602.84,496.54 603.08,495.7 603.56,496.3 603.92,495.82 604.28,496.3 
	604.52,495.82 604.88,496.3 605.12,495.82 605.48,496.42 605.6,495.82 605.96,496.18 605.96,495.7 606.44,496.06 606.56,495.7 
	606.8,496.06 607.28,495.58 607.52,496.18 607.64,495.7 607.88,496.06 608.24,495.58 608.48,496.06 608.6,495.46 608.96,496.06 
	609.2,495.46 609.56,496.06 609.68,495.46 610.04,495.94 610.28,495.46 610.4,496.06 610.76,495.46 611,496.06 611.6,495.46 
	611.6,495.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="611.6,497.38 611.6,497.86 611.24,497.38 611,497.98 610.64,497.38 610.52,497.98 610.16,497.38 
	609.92,498.1 609.56,497.5 609.2,498.22 608.96,497.5 608.48,498.22 608.24,497.38 607.88,498.1 607.64,497.62 607.52,498.1 
	607.04,497.74 607.04,498.1 606.68,497.74 606.44,498.22 606.32,497.74 605.96,498.34 605.6,497.74 605.36,498.34 605,497.98 
	604.88,498.46 604.64,497.98 604.52,498.46 604.28,497.98 603.8,498.46 603.56,497.98 603.32,498.7 602.96,497.98 602.72,498.46 
	602.48,497.98 602,498.46 601.64,498.1 601.4,498.58 601.28,498.1 601.04,498.58 600.8,498.22 600.56,498.7 600.44,498.22 
	599.84,498.7 599.72,498.22 599.48,498.7 599.36,498.22 599.12,498.7 598.88,498.22 598.52,498.7 598.28,498.34 598.16,498.82 
	597.68,498.34 597.44,498.82 597.08,498.22 596.72,498.7 596.6,498.34 596.36,498.94 595.88,498.34 595.64,498.82 595.4,498.34 
	594.92,498.82 594.8,498.34 594.2,498.7 594.2,498.22 593.84,498.82 593.6,498.34 593.36,498.94 593.12,498.34 592.76,498.94 
	592.4,498.46 592.28,498.94 591.8,498.34 591.56,498.7 591.32,498.22 591.08,498.7 590.96,498.1 590.72,498.7 590.36,498.1 
	590.12,498.58 589.88,498.1 589.64,498.58 589.4,498.1 589.04,498.58 588.68,498.1 588.44,498.7 588.2,498.1 588.08,498.58 
	587.72,498.1 587.48,498.58 587.36,498.1 587,498.7 586.64,498.1 586.52,498.58 586.28,498.1 586.04,498.7 585.8,498.22 
	585.56,498.7 585.44,498.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="585.44,496.18 585.2,496.66 584.96,496.78 585.68,497.02 584.96,497.14 585.56,497.5 584.96,497.62 
	585.68,497.86 584.96,498.1 585.44,498.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="611.6,495.46 611.6,495.94 612.44,495.94 611.96,496.18 612.56,496.54 611.84,496.78 612.44,496.9 
	611.96,497.14 612.56,497.38 611.6,497.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="588.2,509.98 588.44,509.26 588.68,509.86 589.64,509.86 589.16,510.34 589.64,510.7 589.28,510.94 
	590,511.42 589.4,511.78 590,512.02 589.52,512.38 589.76,512.5 589.52,512.98 589.88,513.1 589.52,513.34 590.24,513.46 
	589.64,513.94 590.36,514.06 589.76,514.42 590.36,514.66 589.76,515.14 590.36,515.26 590,515.62 590.6,515.74 590.24,516.34 
	590.84,516.34 590.24,516.7 591.08,516.94 590.36,517.54 591.2,517.54 590.36,518.38 591.2,518.26 590.84,518.86 591.44,518.74 
	591.2,519.46 591.92,519.1 591.8,519.82 592.4,519.58 592.4,520.18 593,519.82 593.12,520.54 593.72,520.3 593.72,520.78 
	594.32,520.66 594.32,521.14 594.92,521.02 594.8,521.62 595.52,521.5 595.28,521.86 596.12,521.86 595.4,522.22 595.76,522.94 
	594.92,522.7 594.68,523.3 594.56,522.46 593.6,522.58 593.84,522.22 593,522.46 593,521.74 592.4,522.1 592.28,521.5 
	591.56,521.74 591.56,521.02 590.72,521.26 590.72,520.66 590,521.02 590,520.3 589.28,520.42 589.52,519.94 588.8,519.82 
	589.16,519.34 588.44,519.22 588.8,518.74 588.2,518.62 588.8,518.26 587.96,518.02 588.8,517.54 587.96,517.54 588.56,516.7 
	588.08,516.7 588.44,516.1 587.96,515.86 588.44,515.5 587.84,515.38 588.2,514.66 587.6,514.18 588.44,514.18 587.72,513.7 
	588.08,513.46 587.6,513.1 588.44,512.98 587.72,512.38 588.56,512.02 587.72,511.66 588.08,511.18 587.6,510.94 588.08,510.7 
	587.6,510.46 588.08,510.22 587.84,509.86 588.2,509.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="605.36,511.06 605.6,510.34 605.96,510.94 607.28,511.18 606.68,511.54 607.88,511.78 607.28,512.14 
	608.12,512.62 607.52,512.98 608,513.7 607.4,514.06 608,514.66 607.4,514.78 607.88,515.38 607.16,515.38 607.64,516.1 
	606.56,515.98 606.8,516.7 606.2,516.58 606.2,517.54 605.72,517.3 605.36,518.02 605.12,517.18 604.52,516.34 605.12,516.22 
	604.76,515.74 605.36,515.38 604.76,515.14 605.36,514.66 604.88,514.54 605.12,513.94 604.88,513.82 605.24,513.1 604.88,512.98 
	605.12,512.38 604.88,512.26 605.24,511.54 604.64,511.18 605.36,511.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="648.2,510.46 647.48,510.22 648.08,510.1 648.08,509.38 648.56,509.86 648.92,509.26 648.92,509.98 
	649.76,509.86 649.4,510.34 650,510.46 649.52,510.82 650.24,511.06 649.64,511.3 650.24,511.66 649.76,512.02 650.24,512.26 
	649.76,512.62 650.36,513.22 649.64,513.22 650.12,513.94 649.4,513.94 649.76,514.66 649.16,514.54 649.28,515.26 648.56,515.26 
	648.8,516.1 648.2,515.86 648.44,516.7 647.96,516.58 647.84,517.42 647.36,517.06 647.36,517.66 646.64,517.54 646.76,518.26 
	646.04,517.9 646.16,518.98 645.44,518.5 645.56,519.46 644.96,518.86 644.72,519.7 644.24,519.1 643.88,519.82 643.64,519.1 
	642.8,519.1 643.28,518.62 642.92,517.9 643.64,518.02 643.64,517.3 644,517.54 644.12,516.94 644.84,517.18 644.6,516.58 
	645.44,516.82 645.2,516.22 645.8,516.46 645.68,515.86 646.4,516.1 646.16,515.5 646.76,515.62 646.52,515.14 647.12,515.02 
	646.88,514.54 647.6,514.54 647.12,514.06 647.84,513.82 647.48,513.46 647.96,513.22 647.6,513.1 648.2,512.62 647.72,512.38 
	648.08,512.02 647.36,511.78 647.96,511.06 647.12,510.7 648.2,510.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="630.92,510.1 630.8,509.74 631.4,509.98 631.64,509.5 631.76,510.1 632.48,509.86 632.12,510.58 
	632.72,510.82 632,511.06 632.6,511.66 632,512.14 632.24,512.5 631.88,512.86 632.36,513.1 632,513.58 632.48,513.82 632,514.06 
	632.48,514.42 632.12,514.9 632.72,515.02 632.6,515.38 632.96,515.38 632.96,515.86 633.32,516.22 632.84,516.34 633.44,516.7 
	632.84,516.82 633.44,517.3 632.36,517.18 632,517.54 632,516.82 631.4,516.82 631.52,516.34 631.16,516.22 631.28,515.74 
	630.68,515.62 631.16,515.02 630.44,514.9 630.92,514.18 630.56,513.94 630.68,513.46 630.44,513.22 630.8,512.74 630.32,512.26 
	630.68,511.78 630.44,511.3 630.92,511.06 630.56,510.58 630.92,510.34 630.92,510.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="606.8,558.34 606.92,556.54 607.76,557.14 608.12,556.18 608.72,556.9 609.08,556.3 609.68,557.14 
	610.28,556.42 610.88,557.02 611.36,556.54 611.72,557.38 612.56,556.3 613.16,557.5 613.64,556.54 614.12,557.5 614.48,556.66 
	614.96,557.62 615.32,556.9 615.8,557.98 616.28,557.26 617,558.1 617.48,557.26 617.96,558.1 618.56,557.5 619.04,558.34 
	619.4,557.5 619.88,558.46 620.6,557.62 621.32,558.46 621.68,557.5 622.52,558.58 623.12,557.62 623.96,558.46 624.32,557.62 
	624.92,558.58 625.64,557.74 626.48,558.46 626.72,557.74 627.32,558.46 628.16,557.86 628.64,558.58 629.6,558.1 629.84,558.94 
	631.28,559.18 630.08,559.66 631.16,560.62 629.72,560.62 629.84,561.58 628.76,560.62 628.4,561.22 628.04,560.5 627.68,561.46 
	626.96,560.5 626.72,561.58 626,560.62 625.52,561.7 624.56,560.62 624.32,561.58 623.12,560.98 622.64,561.82 621.8,560.86 
	621.08,561.34 619.76,560.5 619.4,561.58 618.32,560.5 617.48,561.46 616.88,560.5 616.28,561.7 615.68,560.62 614.72,561.58 
	614,560.5 613.28,561.46 612.92,560.38 612.08,561.46 611,560.38 610.4,561.7 609.92,560.26 609.08,561.58 608.36,560.38 
	607.4,561.34 607.28,560.02 606.44,560.74 606.32,559.78 604.76,559.42 606.2,559.18 605,557.86 606.32,558.1 605.96,557.14 
	607.28,557.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="632.24,559.3 632.12,558.46 632.72,559.06 633.08,558.22 633.92,559.06 634.4,558.34 635.36,559.18 
	636.08,558.22 636.44,558.82 637.4,558.46 638.12,558.46 637.88,559.18 638.84,559.18 638.24,559.9 638.96,560.74 637.76,560.98 
	637.52,561.82 637.04,561.22 636.32,561.94 635.96,561.22 635.12,561.82 634.76,561.1 633.92,561.58 633.56,560.5 632.48,560.86 
	632.6,559.9 631.76,560.02 632.36,558.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="568.4,600.46 568.76,599.26 569.12,600.34 569.84,599.26 570.44,600.34 570.8,599.14 571.4,600.58 
	572.12,599.02 572.6,600.7 573.44,599.74 573.92,600.82 574.4,599.26 575,600.34 575.72,599.14 576.08,600.22 576.8,599.14 
	577.52,600.22 578.12,599.02 578.72,600.34 579.44,599.26 580.16,600.58 580.64,599.26 581.6,600.58 582.32,599.26 583.04,600.22 
	583.88,599.26 584.36,600.46 584.84,599.26 585.68,600.34 585.92,599.14 586.76,600.34 587.36,599.26 587.84,600.34 588.44,599.14 
	588.92,600.22 589.64,599.26 590.48,600.22 590.72,599.14 591.8,600.34 592.28,599.02 592.76,599.98 593.12,599.02 593.84,600.1 
	594.44,599.02 595.28,600.22 595.76,599.02 596.24,600.22 597.08,599.14 597.08,602.86 596.24,601.9 595.76,603.1 595.28,602.02 
	594.68,603.1 593.96,602.02 593.36,603.22 592.4,601.9 591.8,602.98 591.32,602.02 590.6,602.98 590,601.9 589.64,603.1 
	588.8,602.14 588.2,602.98 587.84,602.02 587.36,603.1 586.88,602.26 586.16,603.22 585.44,602.38 584.84,603.34 584,602.14 
	582.92,603.22 582.2,602.26 581.72,602.98 580.88,602.26 580.16,603.1 579.56,602.02 578.84,603.1 578,602.02 577.4,603.1 
	576.8,602.14 576.44,602.98 576.08,602.02 575.12,602.98 574.64,601.9 574.04,602.98 573.32,602.14 572.24,603.1 571.88,602.02 
	570.8,602.98 570.68,602.02 569.48,603.1 569,601.78 568.28,602.38 567.92,600.46 568.64,600.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="569,571.3 568.52,570.7 569.6,570.7 569.72,570.1 570.44,570.7 570.68,570.1 571.28,570.7 
	571.64,570.1 572.24,570.82 572.6,570.22 573.08,570.82 573.56,570.1 574.16,570.82 574.64,569.98 575.24,570.82 575.6,570.22 
	576.08,570.94 576.68,570.34 577.28,570.82 577.76,570.1 578.36,570.58 578.96,570.22 579.44,570.7 579.8,569.86 580.52,570.58 
	580.88,569.86 581.48,570.7 582.08,569.98 582.56,570.7 583.04,569.98 583.52,570.46 583.88,569.98 584.6,570.46 584.96,569.98 
	585.8,570.82 586.04,569.98 586.76,570.7 587.48,570.22 587.84,570.94 588.32,570.1 588.8,570.82 589.4,570.22 589.88,570.82 
	590.96,570.46 590.72,571.3 591.44,571.66 590.24,571.9 590,572.86 589.64,572.26 589.16,573.1 588.68,572.02 588.32,573.1 
	588.08,572.26 587.72,573.1 586.88,572.26 586.28,572.98 585.8,572.02 585.32,572.98 584.72,572.02 584.36,572.86 583.76,572.14 
	582.8,572.98 582.32,572.26 581.72,573.22 581.24,572.26 580.52,573.1 579.92,572.02 579.32,572.74 578.48,571.9 578.24,572.86 
	577.52,572.02 577.16,572.86 576.56,571.78 575.96,572.86 575.24,571.78 575,572.86 574.16,572.14 573.56,573.34 573.08,572.02 
	572.36,572.74 571.88,572.02 571.04,572.98 570.68,572.02 569.96,572.74 569.84,571.9 568.4,572.26 569,571.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="630.8,496.78 630.56,497.38 630.2,497.14 629.72,497.02 629.36,497.38 629.36,497.86 628.76,498.7 
	628.04,499.54 628.64,499.9 629.48,499.9 630.08,500.14 630.08,500.98 629.96,501.46 631.04,501.1 631.4,501.34 631.52,501.94 
	631.64,502.3 632.12,501.46 632.24,501.34 632.6,501.94 633.2,502.78 633.8,502.54 633.92,501.94 634.52,502.18 634.88,502.9 
	635.36,502.54 635.6,502.18 636.44,502.9 636.8,502.06 636.92,501.58 636.44,500.38 636.32,499.9 636.44,499.54 636.92,499.54 
	637.04,500.26 637.04,500.62 637.16,500.98 637.52,500.38 637.76,500.74 637.76,501.46 637.76,502.06 639.2,502.78 638.96,503.62 
	639.32,503.86 639.8,503.38 640.4,503.74 641.24,504.1 642.2,503.74 642.32,503.5 643.16,503.74 643.52,503.14 643.4,502.18 
	643.76,501.7 644.84,501.7 645.2,502.18 646.28,502.3 646.52,502.3 647,502.42 647.24,502.66 647.36,502.06 647.72,502.18 
	648.44,501.94 648.56,501.1 648.68,500.74 649.4,499.42 648.8,498.58 648.32,499.18 648.08,499.3 647.84,498.22 647.6,498.22 
	646.76,498.94 645.8,498.82 644.96,497.86 643.52,497.62 643.04,497.86 641.6,498.46 641,498.94 639.68,499.06 639.2,498.58 
	638.24,498.34 638.6,497.5 638,497.26 636.68,497.26 636.44,496.66 635.84,496.66 635.24,497.38 635,497.98 633.92,498.22 
	633.56,498.22 633.32,497.26 632.36,497.14 631.64,497.26 631.16,497.14 630.44,497.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="589.16,501.46 588.44,500.62 588.56,500.14 589.4,500.38 589.52,499.66 589.64,499.42 590.36,499.54 
	590.84,499.9 591.68,499.42 592.16,499.9 592.28,500.38 593,500.62 593.36,499.66 594.2,499.66 594.56,500.38 594.8,499.9 
	595.16,499.66 595.88,500.14 596.24,500.74 596.72,500.38 596.96,499.78 599.36,500.38 600.8,499.54 602.24,499.66 603.08,500.38 
	604.16,499.54 604.76,499.42 605.84,500.02 606.8,499.18 607.4,499.06 608.24,499.3 608.72,498.94 608.96,498.58 609.44,499.66 
	609.92,499.54 610.16,498.82 611.24,498.58 612.2,498.34 612.92,499.18 612.32,500.38 612.2,501.1 611.12,500.74 610.64,501.1 
	610.52,501.7 609.92,501.82 609.44,501.34 608.6,501.46 607.28,501.22 607.16,500.86 606.08,500.62 605.72,501.1 605.12,501.46 
	605.84,501.82 606.44,501.82 606.2,502.66 606.08,503.38 605.6,503.62 604.88,503.14 603.68,503.62 602.96,504.58 602.12,503.74 
	601.76,502.9 600.32,502.66 599.6,501.82 599.24,501.58 598.76,502.3 599,503.02 598.04,503.62 597.56,502.3 596.84,501.58 
	596.36,502.78 596.24,503.62 594.68,503.62 594.2,502.18 593.84,501.7 593.96,500.98 593.12,501.58 592.64,502.3 591.68,502.3 
	591.56,501.82 590.84,502.3 590.24,502.66 589.52,501.94 588.8,501.82 588.68,501.22 588.8,500.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="676.4,515.14 676.64,515.86 677.36,515.74 677.72,515.26 677.96,516.22 677.72,517.06 677.72,517.42 
	678.08,517.9 678.08,518.38 677.72,518.38 677.48,518.62 677.36,519.1 677.12,519.7 676.28,519.34 675.8,519.1 675.32,519.46 
	674.84,518.74 674.96,517.42 674.24,516.82 674.48,516.22 675.56,516.34 675.92,515.86 675.8,515.26 676.28,515.26 676.4,515.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="671,515.74 670.52,516.22 669.92,515.62 669.44,515.98 669.32,516.82 669.68,517.3 669.2,518.26 
	669.32,518.98 670.4,518.86 671.6,519.46 672.44,518.74 672.32,517.78 672.8,517.18 673.04,516.46 671.96,515.98 671.6,516.1 
	671.24,515.98 671,515.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="671.36,511.18 671.6,511.66 671.84,511.78 671.96,512.5 671.36,512.86 671.12,514.06 670.76,513.82 
	670.4,513.58 669.92,513.82 669.56,514.3 669.44,513.46 669.32,512.74 668.72,512.26 668.96,511.54 669.32,511.18 669.32,510.22 
	669.68,509.98 669.92,510.46 670.52,511.06 670.88,511.18 671.36,511.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="675.44,504.82 675.44,504.94 674.72,504.82 674.36,504.34 674,505.18 674.24,506.14 673.88,507.34 
	673.88,508.06 674.72,507.82 675.2,507.7 675.68,508.06 676.4,508.3 676.64,507.22 677.36,506.86 677.36,505.9 676.88,505.42 
	676.4,505.06 676.04,504.82 675.56,504.94 675.44,504.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="671,503.26 670.52,503.62 669.92,503.38 669.44,503.02 669.2,503.38 669.32,504.1 669.08,504.82 
	668.84,505.42 669.68,506.14 669.92,506.86 669.92,507.58 670.52,507.58 671.72,506.26 671.6,504.94 672.44,504.34 672.2,503.74 
	671.36,503.74 671.24,503.62 670.88,503.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="674.12,493.06 674.72,494.02 675.56,492.82 675.92,492.46 676.52,493.42 676.4,494.38 676.88,494.62 
	677.24,495.34 677,495.94 677.48,496.54 677.24,499.18 676.4,499.3 675.32,499.18 673.76,498.82 673.16,497.5 674,496.66 
	674.12,495.58 672.92,494.98 673.04,494.14 673.64,493.78 674.24,493.42 674.6,493.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="613.4,611.38 614.6,610.42 614,610.42 613.88,609.7 613.76,609.7 613.04,610.06 612.56,609.34 
	612.2,609.94 611.84,609.1 611.48,609.7 610.52,609.1 610.28,609.82 609.44,609.1 608.96,609.82 608.24,609.22 608.12,609.94 
	606.92,609.46 607.16,610.06 606.08,610.06 605.96,610.66 605.12,610.3 605.6,610.9 604.4,610.9 604.76,611.26 603.68,611.62 
	604.4,611.98 604.4,612.58 603.56,613.06 604.76,613.3 603.8,613.66 604.76,614.02 603.68,615.22 604.52,615.58 603.68,616.18 
	604.28,616.66 603.2,617.14 604.04,617.5 603.08,618.46 603.92,618.7 602.96,619.3 603.92,620.02 603.44,620.38 604.4,620.38 
	603.8,621.34 604.52,621.58 604.16,622.42 604.64,622.3 604.64,623.38 605.24,623.02 605.72,623.5 605.72,621.94 606.44,621.58 
	605.84,620.74 606.08,620.38 605.36,620.02 606.32,619.18 605.84,618.7 606.44,617.98 605.72,616.78 606.44,616.66 605.84,615.82 
	606.32,615.7 605.72,614.62 606.44,614.26 605.96,613.3 606.56,613.3 606.2,611.98 607.04,612.22 607.28,611.38 607.76,611.98 
	608.96,611.38 609.8,611.98 610.16,611.14 610.88,611.86 611.36,611.26 612.08,611.74 612.44,611.26 613.52,611.86 613.64,611.02 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="603.2,612.58 603.08,612.94 602.72,612.94 602.48,612.58 602.24,612.58 601.88,613.06 602,613.42 
	602.48,613.78 602.36,614.38 601.88,614.98 601.76,615.22 602,615.82 602.24,616.3 602.6,617.14 604.52,616.78 604.88,616.42 
	605,615.82 604.88,614.98 604.88,614.62 605,613.9 605.12,613.3 605,612.58 604.64,612.22 604.16,612.22 603.8,612.22 
	603.32,612.34 603.2,612.82 602.96,612.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="627.68,610.66 627.8,609.58 628.04,608.74 628.76,609.82 629.12,608.86 629.84,609.7 630.2,608.74 
	631.16,609.7 631.4,608.74 632.24,609.58 632.6,608.62 633.56,609.58 634.28,608.74 635,609.7 635.36,608.86 636.08,609.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="635.6,609.46 636.32,608.98 636.92,610.06 637.76,609.58 637.76,610.42 639.2,610.3 638.84,611.02 
	639.68,611.5 639.32,611.98 639.92,612.46 639.2,612.82 640.04,614.14 639.44,614.38 640.4,614.62 639.2,615.22 640.52,615.7 
	639.68,616.3 640.76,617.38 639.68,617.62 640.4,618.34 639.32,618.34 639.32,619.3 638.6,618.82 638.24,619.78 637.88,618.58 
	637.16,618.34 637.88,617.26 637.28,617.02 638,616.18 637.16,615.7 637.88,614.86 637.16,614.62 637.64,613.9 637.04,613.66 
	637.4,612.58 636.56,612.7 636.8,612.1 636.68,611.14 635.96,611.74 635.72,610.78 635.36,611.38 634.64,610.78 634.04,611.26 
	633.68,610.66 633.44,611.26 632.96,610.78 632.6,611.38 632.24,610.9 631.76,611.38 631.28,610.9 630.8,611.38 630.44,610.9 
	629.84,611.38 629.48,610.9 628.76,611.38 628.64,610.54 627.8,610.9 627.2,610.54 627.32,609.82 628.52,610.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="639.44,606.94 638.72,607.42 638.12,606.94 637.52,607.18 637.28,607.78 636.8,608.26 636.8,609.82 
	637.04,610.9 637.88,611.14 638.96,611.26 639.8,610.54 640.52,610.06 640.64,608.98 640.88,607.54 640.4,606.82 639.8,606.82 
	639.44,607.18 638.96,607.18 638.6,606.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="925.4,488.02 953,479.14 979.64,481.06 995.48,489.94 1007.36,500.74 1012.28,517.54 1017.2,544.18 
	1018.16,550.18 1012.28,560.98 1009.28,563.98 1004.36,563.02 998.48,565.9 994.52,567.94 924.44,513.58 937.28,498.82 
	925.4,488.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="919.76,518.86 989.72,574.42 910.52,669.58 843.2,615.82 920.48,518.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="858.68,627.58 873.68,609.82 910.04,638.5 895.04,656.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="869.6,636.34 873.68,629.98 887.72,641.86 882.92,647.14 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="882.8" y1="566.14" x2="952.28" y2="620.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="906.92,584.02 910.76,580.18 913.64,579.46 918.56,579.46 924.08,581.62 925.4,583.54 927.8,587.5 
	929.24,590.62 929.24,593.5 928.28,595.54 926.12,600.1 924.56,602.5 921.92,604.18 917.84,605.14 914.12,605.38 910.28,604.9 
	906.68,602.26 903.68,599.14 902,594.94 901.76,592.3 904.16,587.74 905.36,586.06 906.92,584.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="935.12,530.86 919.04,551.26 954.56,579.1 971.6,559.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="946.88,540.82 943.16,545.98 943.4,546.7 952.88,554.5 957.08,557.62 962.12,552.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="816.68,480.7 817.04,481.9 816.68,498.22 822.08,498.22 823.64,514.42 820.64,514.78 822.08,534.58 
	818.24,540.1 704.84,540.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="697.4,744.58 701.84,840.1 710.6,852.58 723.2,860.14 767.12,858.94 764,876.46 794.72,893.38 
	803,893.38 818,831.7 816.2,812.74 814.88,798.34 783.44,798.34 781.52,756.1 763.88,756.82 765.2,813.46 756.32,813.46 
	756.32,807.1 753.2,807.1 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="749.36" y1="746.02" x2="753.8" y2="807.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="717.32,730.3 736.88,730.3 740,831.7 717.32,832.3 717.32,730.9 717.32,730.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="757.64,712.06 758.84,743.5 783.44,742.3 782.84,713.98 757.64,714.58 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="702.56" y1="860.98" x2="1128.92" y2="1066.18"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="1046,1129.66 1062.56,1139.86 1045.4,1166.5 1028.6,1154.02 1046,1129.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="1049.72,1131.34 1056.68,1123.42 1064.24,1127.86 1056.92,1137.1 1056.92,1123.66 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="1064.48" y1="1128.1" x2="1049" y2="1131.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="421.64,43.42 419.96,48.1 415.16,59.02 411.08,66.58 406.64,70.66 398.12,83.62 392.12,88.9 
	385.64,94.54 380.36,99.34 376.76,104.98 372.32,112.3 365.36,121.54 364.16,122.86 352.88,147.7 338.72,157.78 334.64,162.22 
	330.56,164.74 325.76,167.14 312.8,181.3 303.08,196.66 301.88,200.62 294.2,219.58 290.6,229.78 287.36,239.02 283.76,250.3 
	280.04,260.5 277.64,273.82 276.44,288.34 276.8,299.26 276.8,304.06 275.96,314.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="271.28,333.7 270.32,336.34 267.92,356.14 266.24,368.26 264.68,388.06 263,402.22 259.04,420.7 
	257.84,424.78 255.32,436.54 251.72,448.66 244.04,467.98 240.44,478.18 233.48,485.02 221,490.66 213.68,494.26 204.08,497.98 
	187.64,503.02 177.08,506.62 166.28,510.22 152.12,514.3 140.36,520.42 137.96,521.62 129.08,528.82 123.32,536.14 115.28,546.22 
	110,553.54 104.84,558.34 99.92,572.5 99.08,580.54 100.76,592.3 106.76,606.82 113.96,619.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="120.32,626.98 128.24,633.1 136.76,639.58 139.16,641.98 142.76,646.9 144.8,650.02 148.88,658.54 
	154.88,664.42 152.48,683.02 150.44,689.14 148.4,698.74 145.16,712.9 142.4,723.46 135.92,742.42 131.84,748.54 125.84,761.86 
	119.36,774.34 112.88,786.1 106.76,797.02 102.32,806.62 98.72,815.5 97.52,814.18 95.84,825.82 94.64,837.1 93.08,842.02 
	92.24,846.46 91.88,855.7 93.44,873.94 97.88,884.38 102.32,891.7 111.68,900.58 122.12,907.9 135.44,910.3 146.36,913.06 
	152.12,915.1 164.24,918.34 170.24,921.58 179.6,927.22 186.44,934.54 185.96,944.62 177.56,950.74 175.52,952.66 172.64,957.1 
	172.28,964.3 171.08,969.58 170.36,976.06 169.52,984.1 169.04,991.78 166.28,1004.26 162.2,1013.62 158.96,1023.34 156.92,1032.22 
	156.2,1034.98 152.96,1042.66 150.92,1048.78 149.24,1056.46 146.48,1066.54 144.08,1075.06 140.84,1083.46 137.6,1094.02 
	132.32,1101.34 124.28,1108.18 120.56,1110.58 114.56,1112.98 109.28,1119.46 104.84,1127.14 100.4,1132.78 95.12,1141.3 
	91.52,1147.78 88.28,1156.18 88.28,1172.14 86.6,1184.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="80.6,1184.74 82.16,1152.58 87.44,1142.5 91.52,1132.78 95.96,1124.26 104.48,1114.66 116.6,1106.14 
	128.24,1095.58 131.96,1089.94 134.72,1084.66 140.84,1074.22 142.4,1067.74 144.44,1059.22 146,1051.54 148.52,1045.54 
	151.76,1032.58 154.52,1025.74 156.92,1017.7 159.44,1010.02 161,1004.74 163.4,996.58 165.44,989.74 167.12,982.9 167.84,977.26 
	168.68,966.7 169.52,961.06 170.72,954.22 171.2,946.18 171.2,936.58 166.76,930.94 159.92,926.38 151.4,923.26 142.52,922.42 
	138.92,919.54 129.92,916.78 125.12,916.78 118.64,915.1 110.96,911.86 103.76,908.26 97.64,903.46 91.16,896.5 87.08,888.82 
	83.48,878.74 83.12,868.3 82.64,862.18 85.16,845.62 86.72,835.18 89.12,824.62 91.16,816.1 93.2,807.7 98,792.34 102.92,782.98 
	104.96,776.98 118.28,751.66 122.72,736.66 126.8,727.42 131.24,717.7 134.48,707.98 137.24,690.22 136.4,677.74 127.52,657.94 
	119.84,648.58 113.36,642.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="106.76,637.42 106.52,637.3 96.44,633.22 89.48,623.38 83.48,620.5 77,612.82 74.48,603.58 
	73.76,593.86 73.28,582.46 73.76,569.98 79.04,557.5 86.24,548.62 95.12,542.14 112.16,532.06 118.64,526.3 126.32,518.62 
	133.52,514.18 147.68,508.54 155.36,505.78 177.56,497.62 184.04,496.42 192.92,493.18 199.88,489.58 207.08,485.98 211.16,483.1 
	218.48,475.42 224.48,466.54 231.8,452.02 243.2,425.5 244.4,414.22 248.36,399.22 254,384.34 259.28,362.5 260.48,351.94 
	260.96,343.54 261.32,334.54 261.44,334.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="263.48,314.14 263.72,311.62 264.2,297.82 264.92,285.7 273.92,257.14 275.96,241.78 280.88,225.22 
	284.48,214.78 288.56,204.22 292.16,194.5 298.28,183.7 301.04,179.98 305.96,176.74 313.64,169.9 320,163.06 326.48,155.38 
	332.12,148.06 343.04,132.7 350,120.58 362.12,106.9 367.76,98.74 390.2,78.7 398.24,68.98 401.84,58.06 403.04,48.34 404.24,43.42 
	"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="1102.52" y1="257.38" x2="964.04" y2="272.26"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="950.36" y1="273.82" x2="838.52" y2="285.82"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="821" y1="288.7" x2="704.36" y2="297.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="682.64,299.5 615.32,307.54 561.68,317.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="533.48,321.1 485.48,322.06 409.76,323.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="395.48,323.74 372.2,324.1 307.76,332.5 247.52,334.54 198.2,338.62 64.16,343.78 21.68,347.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="21.68,327.94 228.32,317.86 267.8,313.66 301.04,316.78 309.32,316.78 331.16,315.82 361.28,311.62 
	383.12,308.5 416.36,308.5 439.16,307.42 454.76,306.46 475.64,305.38 503.6,305.38 517.16,305.38 539.96,301.18 533.72,301.18 
	537.92,291.94 534.8,253.42 531.68,238.9 531.68,218.14 528.56,168.34 527.48,147.58 527.48,120.46 527.48,96.58 527.48,76.9 
	526.52,59.26 525.68,43.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="544.28,43.42 542.96,61.06 545.12,112.3 545.12,146.98 545.12,183.82 544.04,225.82 544.04,245.86 
	545.12,267.94 548.36,296.26 564.08,292.06 638.72,282.58 682.04,277.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="704.12,274.18 712.4,273.1 790.28,273.1 848.12,264.7 921.8,256.3 950.12,251.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="962.72,248.98 1009.04,243.7 1030.04,242.62 1054.28,240.58 1085.84,239.5 1100.96,239.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="571.04,834.1 568.4,719.86 572.36,714.94 619.4,714.58 623.36,718.06 625.16,835.42 622.52,837.58 
	572.84,836.26 570.56,833.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="631.16,716.38 634.76,713.14 679.16,711.7 684.56,718.06 686.48,832.06 683.24,836.98 636.68,838.9 
	633.08,835.9 631.16,716.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="419,833.38 422.96,838.78 541.88,839.74 545.96,835.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="450.8,808.54 444.56,808.54 444.56,787.66 450.44,787.66 450.8,774.22 461.72,775.06 462.8,794.74 
	470.12,793.9 470.48,774.46 482.84,776.74 482.84,791.38 505.64,792.22 505.04,798.34 506.48,798.1 507.32,815.86 495.2,815.26 
	494.96,818.14 481.76,817.18 481.16,809.38 448.76,809.38 449,808.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="439.76,1017.34 536.12,1018.18 544.04,1018.18 546.68,1022.74 542.72,1027.54 520.28,1049.62 
	505.04,1041.22 494.84,1041.22 489.44,1041.46 482.84,1042.06 476.96,1043.14 467,1046.86 465.32,1048.54 443.72,1026.46 
	439.76,1021.9 438.92,1020.22 439.76,1017.94 440.36,1016.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="516.92,1055.62 509,1050.82 501.08,1046.86 490.76,1045.66 480.92,1046.02 474.68,1048.54 
	469.52,1050.82 467.84,1052.26 483.44,1072.66 488.84,1071.7 491.96,1071.7 494.48,1072.3 495.92,1073.5 516.56,1055.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="525.44,1055.62 546.08,1033.54 548.24,1142.98 533.84,1129.06 527.6,1122.22 520.76,1115.98 
	527.24,1109.74 530.96,1102.42 532.4,1097.26 532.64,1090.78 533.84,1083.1 534.08,1078.54 533.24,1073.38 532.4,1067.74 
	530.12,1064.86 526.4,1059.82 522.44,1057.3 525.56,1055.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="520.76,1060.3 500.6,1082.26 500.24,1087.66 500.24,1090.18 511.4,1103.26 518.72,1111.42 
	526.16,1100.98 527.84,1094.74 528.68,1089.34 528.92,1082.26 528.92,1073.74 526.64,1067.14 524.12,1064.38 520.4,1060.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="461.12,1056.34 479.6,1076.5 477.32,1080.82 476.12,1083.58 477.8,1088.14 478.4,1089.94 
	463.04,1105.78 457.64,1110.94 453.08,1104.7 450.32,1092.46 449.96,1085.62 449.96,1079.38 451.76,1074.58 456.8,1063.78 
	460.52,1058.38 462.56,1055.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="495.68,1094.26 515.24,1115.98 511.88,1119.34 504.8,1121.86 502.64,1123.66 503.24,1117.54 
	500.72,1110.7 497.12,1107.1 492.8,1105.78 489.2,1106.14 485,1106.86 482.36,1108.3 481.16,1113.94 481.64,1116.82 482.6,1121.14 
	482.96,1124.86 481.52,1124.74 475.4,1121.86 471.56,1121.14 467.96,1119.1 463.88,1117.18 462.56,1116.22 482.72,1094.38 
	485.72,1096.54 488.12,1096.66 492.2,1096.66 493.88,1095.82 496.16,1093.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="485.72,1124.02 486.44,1117.3 487.16,1113.34 489.68,1111.78 496.52,1112.26 497.96,1114.42 
	499.04,1117.9 499.28,1121.5 498.92,1123.78 496.16,1124.38 490.28,1124.02 487.04,1124.02 485.72,1124.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="483.8,1128.58 487.16,1129.9 491.36,1128.82 495.68,1128.58 499.76,1128.94 500.84,1128.82 
	500.48,1131.7 500.72,1135.42 500.12,1137.82 496.52,1139.98 494.72,1141.42 491.48,1141.06 486.8,1140.82 483.44,1138.9 
	481.88,1135.78 482.36,1131.46 483.32,1128.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="503.6,1127.5 510.92,1125.46 514.4,1123.66 516.32,1121.98 517.4,1120.9 530.12,1133.62 
	532.16,1136.74 534.44,1139.02 535.04,1141.06 531.56,1141.9 525.8,1142.14 517.76,1142.14 510.44,1141.78 506.12,1141.78 
	504.8,1141.06 504.44,1133.86 503.96,1127.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="478.04,1127.86 470.36,1125.82 467.12,1123.42 462.2,1120.9 457.76,1118.62 448.76,1130.98 
	444.32,1136.74 443.6,1139.02 451.28,1139.74 457.88,1140.82 466.04,1141.54 472.52,1142.14 477.2,1142.14 478.64,1139.38 
	479.48,1132.42 479.48,1129.3 479.48,1127.74 477.92,1127.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="431.36,1140.7 451.4,1117.18 455,1115.5 450.2,1107.1 445.52,1084.9 446.24,1077.7 447.68,1072.66 
	449.6,1068.22 451.76,1063.78 454.88,1058.38 457.88,1053.82 447.8,1039.3 443,1034.98 439.88,1031.02 436.16,1026.82 
	433.64,1023.94 433.64,1025.02 433.64,1035.7 433.88,1048.9 433.88,1056.7 434.96,1077.34 434.96,1084.78 434.96,1096.54 
	434.48,1107.82 433.88,1118.98 432.68,1127.62 431.84,1139.14 432.44,1142.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="700.04,875.86 719.12,899.62 728,907.9 739.04,919.06 751.16,928.9 759.56,937.18 763.76,939.94 
	769.28,934.42 774.92,928.42 782.36,920.98 790.16,910.3 786.44,907.9 726.56,879.1 715.4,874.54 709.4,871.66 704.72,869.86 
	699.56,868.42 698.72,869.38 699.08,872.62 700.04,875.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="798.08,910.78 786.44,926.02 779.96,935.86 769.76,944.62 766.52,946.54 778.16,959.5 790.64,971.14 
	803.24,986.02 813.92,996.22 822.32,1003.18 829.76,1004.62 838.04,999.94 845.96,996.7 845.48,986.86 837.2,965.14 833.96,949.78 
	830.24,931.66 829.76,926.5 815.72,920.02 807.44,914.86 798.08,910.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="754.88,947.86 746.48,958.06 736.28,966.46 725.12,977.62 714.44,988.78 709.4,996.7 707.48,999.46 
	706.52,982.3 708.44,952.54 703.76,920.02 701.48,904.66 699.56,894.46 697.28,882.34 711.2,898.18 720.92,909.82 728.84,919.54 
	732.56,923.26 738.2,927.94 746.12,935.38 753.56,942.34 756.32,945.58 754.88,947.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="761.6,950.74 814.52,1006.66 820.16,1009.06 825.44,1012.06 826.64,1014.94 826.88,1019.38 
	817.76,1018.54 797.36,1014.1 789.8,1011.7 780.92,1009.42 774.08,1007.62 767.12,1005.82 760.64,1005.22 750.92,1003.78 
	741.68,1003.18 725.84,1002.58 718.16,1001.74 711.32,1000.18 741.08,971.14 746.12,964.66 753.2,960.22 755.6,957.82 
	761.48,951.94 761.72,950.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="705.44,1006.9 740.36,1009.3 763.64,1011.22 775.88,1013.14 789.68,1015.9 800.48,1018.78 
	811.4,1021.66 818.96,1023.94 831.32,1025.38 843.2,1028.74 852.08,1031.02 863.48,1035.34 875.36,1039.06 886.64,1042.9 
	874.4,1043.38 857.84,1054.3 838.4,1062.34 820.88,1071.34 811.4,1076.02 798.68,1085.02 788.72,1089.7 777.32,1095.46 764,1105.9 
	756.92,1112.62 745.16,1122.46 736.64,1130.98 729.56,1137.22 718.64,1143.34 712.88,1145.74 710.6,1145.26 712.04,1098.34 
	710.12,1028.86 707.24,1005.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="893.24,1048.18 880.04,1048.66 867.68,1054.9 858.32,1058.62 843.56,1065.22 835.04,1069.06 
	825.08,1073.74 806.24,1085.62 797.72,1090.78 789.2,1096.54 778.28,1102.66 769.28,1108.78 760.28,1115.86 747.92,1123.9 
	735.2,1139.02 720.56,1145.74 712.88,1150.42 710.12,1153.3 708.2,1157.98 707.24,1165.06 861.56,1166.5 866.36,1156.06 
	872.96,1126.3 879.08,1100.26 882.92,1085.62 887.6,1069.06 892.4,1054.9 891.92,1047.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="835.52,930.82 839.36,952.66 841.76,963.46 843.56,971.02 846.44,980.5 851.24,991.9 855.92,999.46 
	860.6,1011.7 859.28,1015.54 861.56,1023.1 867.8,1027.9 876.8,1031.62 886.64,1034.02 893.36,1034.98 897.08,1026.46 
	900.44,1011.7 903.32,1001.86 906.08,992.38 910.88,977.26 912.68,970.06 908.48,964.9 896.6,958.3 867.8,945.94 833.72,931.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="917.96,971.98 913.64,978.58 909.44,1004.62 907.04,1016.98 906.08,1022.14 911.36,1027.42 
	918.44,1031.62 929.24,1038.22 935,1041.58 943.52,1043.5 953.96,1039.18 960.56,1035.46 969.08,1033.54 979.04,1028.74 
	989,1026.94 995.6,1025.98 1003.64,1025.02 1015.4,1024.06 1026.32,1025.98 1027.76,1025.02 1014.56,1017.94 973.76,998.98 
	953,990.46 936.92,981.46 921.2,973.9 914.6,971.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="888.68,1094.26 934.16,1160.5 940.76,1174.78 941.24,1178.02 918.56,1173.34 901.52,1170.94 
	889.64,1166.74 880.16,1167.1 873.08,1168.54 867.8,1167.58 866.48,1167.1 877.28,1129.78 879.68,1116.58 884.84,1095.22 
	886.28,1091.98 892.52,1098.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="982.88,1090.06 984.8,1115.14 985.28,1132.18 983.84,1142.98 981.92,1163.38 980.96,1174.3 
	979.04,1181.38 965.36,1177.54 957.8,1176.1 951.68,1177.06 943.16,1166.26 930.8,1142.98 920.36,1125.46 913.28,1115.14 
	907.16,1104.22 899.12,1095.7 892.04,1090.06 891.56,1079.62 893.84,1070.62 893.84,1069.18 899.6,1073.5 926.6,1073.5 
	936.92,1077.22 949.28,1080.1 964.4,1083.82 976.28,1087.18 984.8,1089.1 982.88,1090.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="880.76,1004.86 875.12,1015.78 874.16,1018.66 878.36,1022.38 883.64,1026.7 888.32,1029.58 
	891.2,1026.22 892.64,1020.94 895.04,1017.7 881.24,1005.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="986.6,1058.02 969.44,1053.7 965.72,1081.18 984.68,1085.5 987.56,1058.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="420.56,561.1 420.2,561.22 417.32,563.38 416.36,565.18 415.76,568.9 415.64,574.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="418.64,623.5 416.12,623.62 415.04,622.66 413.6,620.62 413.72,619.42 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="416.84" y1="620.98" x2="414.8" y2="622.3"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="424.88,628.18 420.92,629.62 417.32,635.74 417.68,645.22 418.52,664.9 419.48,672.7 419.72,681.82 
	421.28,687.82 423.92,693.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="584.96,697.66 654.56,699.82 672.68,700.18 680.24,696.46 682.88,693.82 683.6,686.26 683.48,662.5 
	683.24,632.62 682.64,484.9 682.16,475.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="891.56,707.86 762.68,706.78 701,703.42 694.52,707.26 694.52,712.42 698.6,842.86 705.92,853.18 
	906.32,946.42 1042.52,1009.9 1128.92,1049.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="702.56,860.98 697.88,865.18 696.32,867.46 695.6,877.06 695.96,884.5 703.28,984.22 703.28,1154.98 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="703.28,1154.98 695.48,1156.54 695,1007.98 690.56,854.62 675.32,855.1 618.56,852.82 589.04,854.5 
	575.12,855.1 568.4,855.46 568.88,866.14 571.76,1109.5 571.76,1147.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="557.96,1161.22 558.32,1023.94 549.08,1012.54 515.6,1012.18 483.2,1011.82 449.96,1011.46 
	432.2,1013.86 427.16,1027.54 428.48,1155.22 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="421.04" y1="1154.98" x2="421.04" y2="1142.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="420.92,1132.9 420.32,1014.94 420.08,1011.46 408.56,1009.78 388.52,1011.7 326,1008.46 
	312.2,1007.74 311.24,1008.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="307.76,990.1 317.72,995.5 349.28,996.1 364.28,995.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="380,994.66 397.76,993.82 414.56,993.94 417.8,989.62 416.72,959.02 415.16,847.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="415.04,835.42 414.8,823.18 407.84,747.1 405.08,716.62 391.88,712.42 375.2,713.86 350.24,716.5 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="341" y1="717.1" x2="331.88" y2="717.82"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="347.6,699.82 376.88,700.66 406.52,699.58 410,694.42 405.08,602.62 400.16,596.26 370.28,597.7 
	349.04,598.66 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="538.16" y1="333.94" x2="456.2" y2="424.3"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="533.6,327.58 421.76,430.78 411.8,430.78 409.76,323.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="395.48,323.74 395.12,457.54 387.32,463.18 364.28,462.46 338.84,462.82 326.12,462.82 314.48,473.02 
	321.56,581.74 304.16,582.82 296.12,583.42 289.76,486.22 247.16,524.02 261.68,532.18 271.52,537.7 272.72,550.9 266.6,562.18 
	258.56,568.3 258.8,576.46 272.72,593.74 313.04,591.58 326.84,600.46 332.96,601.66 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="345.56" y1="599.38" x2="349.04" y2="598.66"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="401.24,487.78 394.04,479.26 381.92,479.62 368.6,491.74 336.68,518.62 341,579.22 346.04,583.54 
	398,583.18 404.12,579.22 401.24,487.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="548.12,423.94 546.92,339.82 538.16,333.94 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="533.6" y1="327.58" x2="533.48" y2="321.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="577.88,466.66 565.4,467.02 560.72,464.26 560.72,455.74 561.92,401.5 567.08,394.66 593.36,393.22 
	672.68,396.58 684.08,397.06 688.88,400.66 684.8,436.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="561.68,317.26 565.28,377.02 593.48,380.38 642.32,378.82 670.04,378.82 684.44,376.66 684.08,361.54 
	682.64,299.5 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="682.04" y1="277.06" x2="676.28" y2="43.42"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="701.72" y1="43.42" x2="704.12" y2="274.18"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="704.36,297.7 705.2,371.14 722.24,377.38 785.84,378.82 816.92,379.54 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="684.8" y1="436.3" x2="678.68" y2="436.3"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="674.84,468.82 686.36,457.18 693.56,433.78 692.96,403.18 691.76,335.26 690.8,275.98 685.76,43.42 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="676.52,470.38 688.04,458.74 695.24,435.22 694.64,404.74 693.44,336.82 692.48,277.54 687.44,43.42 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="681.44,473.26 692.96,461.74 700.28,438.22 699.56,407.74 698.36,339.7 697.4,280.42 692.24,43.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="682.28,475.9 693.8,464.38 701,440.86 700.28,410.38 699.2,342.34 698.12,283.06 693.08,43.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="548.6,997.06 553.28,991.18 552.2,856.06 544.76,847.42 433.04,849.82 427.88,854.5 429.92,989.26 
	434.24,997.06 548.6,997.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="579.44,1001.62 572.96,993.46 572.36,863.02 577.64,857.98 683.12,859.9 687.92,863.86 692,997.9 
	687.44,1002.7 579.44,1001.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="574.76,1016.5 578.6,1012.3 687.44,1015.06 691.76,1018.3 691.64,1144.66 686.48,1151.26 
	580.64,1148.74 575.12,1144.54 574.76,1016.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="290.96,474.94 312.32,473.98 319.16,578.86 298.04,580.3 290.96,474.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="290.96,474.94 301.76,484.06 312.32,473.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="301.76,484.06 307.28,569.38 298.04,580.3 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="307.28" y1="569.38" x2="319.16" y2="578.86"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="296.96,564.22 306.44,558.58 317.36,551.26 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="306.56" y1="558.58" x2="318.08" y2="562.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="306.44" y1="558.58" x2="296.36" y2="554.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="294.92,532.3 304.4,526.66 315.32,519.34 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="304.52" y1="526.66" x2="316.04" y2="530.86"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="304.4" y1="526.66" x2="294.2" y2="522.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="292.52,498.1 302.24,492.46 313.04,485.02 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="302.24" y1="492.46" x2="313.76" y2="496.54"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="302.24" y1="492.46" x2="291.92" y2="488.38"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="849.68,551.98 849.32,553.06 849.68,553.3 850.04,553.66 850.04,554.5 850.04,555.34 850.4,556.42 
	849.8,557.14 848.72,556.9 848.12,556.42 846.8,556.18 846.44,555.34 846.44,554.62 845.24,554.02 845.36,553.18 846.08,552.46 
	846.68,552.46 847.4,552.7 848.24,552.34 848.84,551.86 849.32,552.22 849.68,552.34 850.04,552.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="834.56,562.42 834.92,563.14 836,563.62 836.72,564.1 836.72,565.9 836.36,567.34 836.96,568.06 
	836.72,569.38 835.88,570.34 834.44,569.98 833.6,569.5 831.92,569.62 830.96,568.42 829.76,567.82 828.08,567.1 827.84,565.54 
	828.44,563.74 829.64,561.82 830.6,561.34 831.08,562.42 831.44,563.26 832.16,563.74 833,562.78 833.96,562.66 834.44,563.02 
	835.28,562.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="835.76,554.98 833.84,554.5 832.4,555.82 832.4,556.78 832.88,557.98 833.24,558.22 833.84,559.42 
	835.28,559.9 836.24,558.46 836.72,557.86 836.48,556.66 836,556.06 835.52,555.7 835.4,554.86 835.28,554.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="832.4,549.34 832.16,550.18 831.8,550.3 830.6,550.42 829.64,550.3 829.4,550.66 829.4,550.9 
	829.64,551.14 829.64,551.5 829.28,552.46 829.52,552.82 830,552.94 830.48,552.94 830.84,553.18 831.08,553.9 831.44,554.14 
	832.04,553.66 832.4,553.42 833.12,553.66 833.72,553.78 833.96,552.94 834.92,552.34 835.52,551.74 835.88,550.3 835.4,549.82 
	834.68,549.82 834.32,549.46 834.08,548.86 833.24,548.38 833,549.22 833,549.46 832.64,549.58 832.16,549.7 831.8,549.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="834.2,543.1 832.4,542.5 831.92,542.5 831.32,543.34 831.2,544.3 830.36,545.14 830,545.98 
	831.08,547.06 831.92,546.46 832.88,546.58 833.72,547.06 834.68,547.18 835.16,546.34 835.28,545.26 834.8,544.54 834.2,543.94 
	833.96,543.1 833.84,542.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="847.28,560.02 847.64,560.62 848.72,561.1 849.44,561.7 849.44,563.38 849.08,564.82 849.68,565.66 
	849.44,566.86 848.6,567.94 847.16,567.46 846.32,566.98 844.64,567.22 843.68,566.02 842.48,565.42 840.8,564.7 840.56,563.02 
	841.16,561.34 842.36,559.42 843.32,558.82 843.8,560.02 844.16,560.74 844.88,561.22 845.72,560.38 846.68,560.26 847.16,560.5 
	848,560.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="837.68,528.46 838.04,529.06 839.12,529.54 839.84,530.02 839.84,531.82 839.48,533.26 840.08,534.1 
	839.72,535.3 839,536.38 837.56,535.9 836.72,535.42 835.04,535.66 833.96,534.34 832.88,533.74 831.2,533.02 830.96,531.46 
	831.56,529.78 832.76,527.86 833.72,527.26 834.2,528.46 834.56,529.18 835.28,529.66 836.12,528.7 837.08,528.58 837.56,528.94 
	838.28,528.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="875.84,480.22 875.84,480.46 877.04,480.94 877.76,481.42 877.76,483.22 877.4,484.66 878,485.5 
	877.64,486.7 876.8,487.66 875.36,487.3 874.64,486.82 872.96,486.94 871.88,485.74 870.68,485.14 869.12,484.42 868.88,482.86 
	869.48,481.18 870.68,479.14 871.52,478.66 872.12,479.74 872.48,480.58 873.2,481.06 873.92,480.1 875,479.98 875.36,480.34 
	875.84,480.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="861.56,488.62 861.56,488.86 862.76,489.34 863.48,489.82 863.48,491.62 863.12,493.06 863.72,493.78 
	863.36,495.1 862.52,496.06 861.08,495.7 860.36,495.22 858.68,495.34 857.6,494.14 856.52,493.54 854.84,492.82 854.6,491.26 
	855.2,489.46 856.4,487.54 857.24,487.06 857.84,488.14 858.2,488.98 858.92,489.46 859.64,488.5 860.72,488.38 861.08,488.74 
	861.56,488.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="858.2,475.78 858.32,476.02 859.4,476.5 860.12,476.98 860.12,478.66 859.76,480.22 860.36,480.94 
	860,482.14 859.16,483.22 857.72,482.74 857,482.38 855.32,482.5 854.24,481.3 853.16,480.7 851.48,479.98 851.24,478.42 
	851.84,476.62 853.04,474.7 853.88,474.1 854.48,475.3 854.84,476.14 855.56,476.5 856.28,475.66 857.36,475.54 857.72,475.9 
	858.2,475.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="863.84,473.86 863.96,474.1 865.04,474.58 865.76,475.18 865.76,476.86 865.4,478.3 866,479.14 
	865.64,480.34 864.8,481.42 863.36,480.94 862.64,480.46 860.96,480.7 859.88,479.5 858.8,478.78 857.12,478.06 856.88,476.5 
	857.48,474.82 858.68,472.9 859.64,472.3 860.12,473.5 860.48,474.22 861.2,474.7 861.92,473.74 863,473.74 863.36,473.98 
	863.84,473.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="872.96,488.14 873.08,488.38 874.28,488.86 875,489.34 875,491.14 874.64,492.58 875.24,493.42 
	874.88,494.62 874.04,495.7 872.6,495.22 871.88,494.74 870.2,494.98 869.12,493.66 867.92,493.06 866.36,492.34 866.12,490.78 
	866.72,489.1 867.8,487.18 868.76,486.58 869.36,487.78 869.72,488.5 870.44,488.98 871.16,488.02 872.24,487.9 872.6,488.26 
	872.96,488.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="891.68,473.26 891.8,473.5 893,473.98 893.72,474.46 893.72,476.14 893.36,477.7 893.96,478.42 
	893.6,479.62 892.76,480.7 891.32,480.22 890.6,479.86 888.92,479.98 887.84,478.78 886.64,478.18 885.08,477.46 884.84,475.9 
	885.44,474.1 886.52,472.18 887.48,471.58 888.08,472.78 888.44,473.62 889.16,473.98 889.88,473.14 890.96,473.02 891.32,473.38 
	891.68,473.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="918.56,483.58 918.68,483.7 919.76,484.18 920.48,484.78 920.48,486.46 920.24,487.9 920.84,488.74 
	920.48,489.94 919.64,491.02 918.2,490.54 917.48,490.06 915.8,490.3 914.72,489.1 913.52,488.5 911.84,487.78 911.72,486.1 
	912.32,484.42 913.4,482.5 914.36,481.9 914.96,483.1 915.32,483.82 916.04,484.3 916.76,483.46 917.84,483.34 918.2,483.58 
	918.56,483.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="901.28,471.58 901.4,471.82 902.48,472.3 903.2,472.78 903.2,474.58 902.84,476.02 903.56,476.86 
	903.2,478.06 902.36,479.14 900.92,478.66 900.08,478.18 898.52,478.42 897.44,477.1 896.24,476.5 894.56,475.78 894.32,474.22 
	894.92,472.54 896.12,470.62 897.08,470.02 897.68,471.22 897.92,471.94 898.64,472.42 899.48,471.46 900.56,471.34 900.92,471.7 
	901.28,471.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="843.44,477.7 843.56,477.94 844.64,478.42 845.36,478.9 845.36,480.7 845,482.14 845.6,482.98 
	845.36,484.18 844.52,485.26 843.08,484.78 842.24,484.3 840.56,484.54 839.6,483.22 838.4,482.62 836.72,481.9 836.48,480.34 
	837.08,478.66 838.28,476.62 839.24,476.14 839.84,477.34 840.08,478.06 840.8,478.54 841.64,477.58 842.72,477.46 843.08,477.82 
	843.44,477.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="1048.04,521.62 1048.28,522.34 1049.48,522.82 1050.2,523.3 1050.2,524.98 1049.84,526.54 
	1050.44,527.26 1050.08,528.58 1049.24,529.54 1047.8,529.18 1047.08,528.7 1045.4,528.82 1044.32,527.62 1043.24,527.02 
	1041.56,526.3 1041.32,524.74 1041.92,522.94 1043.12,521.02 1043.96,520.54 1044.56,521.62 1044.92,522.46 1045.64,522.82 
	1046.36,521.98 1047.44,521.86 1047.8,522.22 1048.64,521.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="224.96,503.26 225.32,503.86 226.4,504.34 227.12,504.82 227.12,506.62 226.76,508.06 227.36,508.9 
	227.12,510.1 226.28,511.18 224.84,510.7 224,510.22 222.32,510.46 221.36,509.14 220.16,508.54 218.48,507.82 218.24,506.26 
	218.84,504.58 220.04,502.66 221,502.06 221.48,503.26 221.84,503.98 222.56,504.46 223.4,503.5 224.36,503.38 224.84,503.74 
	225.68,503.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="216.44,508.66 216.68,509.26 217.88,509.74 218.6,510.22 218.6,512.02 218.24,513.46 218.84,514.3 
	218.48,515.5 217.64,516.58 216.2,516.1 215.48,515.62 213.8,515.86 212.72,514.54 211.64,513.94 209.96,513.22 209.72,511.66 
	210.32,509.98 211.52,508.06 212.36,507.46 212.96,508.66 213.32,509.38 214.04,509.86 214.76,508.9 215.84,508.78 216.2,509.14 
	217.04,508.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="202.4,510.46 202.76,511.06 203.84,511.54 204.56,512.02 204.56,513.82 204.32,515.26 204.92,516.1 
	204.56,517.3 203.72,518.38 202.28,517.9 201.44,517.42 199.88,517.66 198.8,516.34 197.6,515.74 195.92,515.02 195.68,513.46 
	196.4,511.78 197.48,509.86 198.44,509.26 199.04,510.46 199.28,511.18 200,511.66 200.84,510.7 201.92,510.58 202.28,510.94 
	203.12,510.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="184.88,522.58 185.24,523.3 186.32,523.78 187.04,524.26 187.04,525.94 186.68,527.5 187.28,528.22 
	186.92,529.42 186.2,530.5 184.76,530.02 183.92,529.66 182.24,529.78 181.16,528.58 180.08,527.98 178.4,527.26 178.16,525.58 
	178.76,523.9 179.96,521.98 180.92,521.38 181.4,522.58 181.76,523.3 182.48,523.78 183.32,522.94 184.28,522.82 184.76,523.18 
	185.48,522.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="166.88,534.34 167.24,534.94 168.32,535.42 169.04,535.9 169.04,537.7 168.68,539.14 169.28,539.98 
	168.92,541.18 168.08,542.26 166.64,541.78 165.92,541.3 164.24,541.54 163.16,540.22 162.08,539.62 160.4,538.9 160.16,537.34 
	160.76,535.66 161.96,533.62 162.8,533.14 163.4,534.34 163.76,535.06 164.48,535.54 165.2,534.58 166.28,534.46 166.64,534.82 
	167.48,534.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="144.8,555.94 145.16,556.54 146.24,557.02 146.96,557.62 146.96,559.3 146.6,560.74 147.2,561.58 
	146.84,562.78 146,563.86 144.56,563.38 143.84,562.9 142.16,563.14 141.08,561.94 140,561.22 138.32,560.5 138.08,558.94 
	138.68,557.26 139.88,555.34 140.84,554.74 141.32,555.94 141.68,556.66 142.4,557.14 143.12,556.18 144.2,556.18 144.56,556.42 
	145.4,556.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="139.76,577.06 140.12,577.78 141.2,578.26 141.92,578.74 141.92,580.42 141.68,581.98 142.28,582.7 
	141.92,583.9 141.08,584.98 139.64,584.5 138.92,584.14 137.24,584.26 136.16,583.06 134.96,582.46 133.28,581.74 133.16,580.06 
	133.76,578.38 134.84,576.46 135.8,575.86 136.4,577.06 136.76,577.9 137.48,578.26 138.2,577.42 139.28,577.3 139.64,577.66 
	140.48,577.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="148.88,595.06 149.12,595.78 150.32,596.26 151.04,596.74 151.04,598.42 150.68,599.98 151.28,600.7 
	150.92,601.9 150.08,602.98 148.64,602.5 147.92,602.14 146.24,602.26 145.16,601.06 143.96,600.46 142.4,599.74 142.16,598.18 
	142.76,596.38 143.84,594.46 144.8,593.86 145.4,595.06 145.76,595.9 146.48,596.26 147.2,595.42 148.28,595.3 148.64,595.66 
	149.48,595.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="147.08,603.22 147.32,603.82 148.52,604.3 149.24,604.9 149.24,606.58 148.88,608.02 149.48,608.86 
	149.12,610.06 148.28,611.14 146.84,610.66 146.12,610.18 144.44,610.42 143.36,609.22 142.16,608.5 140.6,607.78 140.36,606.22 
	140.96,604.54 142.04,602.62 143,602.02 143.6,603.22 143.96,603.94 144.68,604.42 145.4,603.46 146.48,603.46 146.84,603.7 
	147.68,603.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="119.12,576.58 119.48,577.3 120.56,577.78 121.28,578.26 121.28,579.94 120.92,581.5 121.52,582.22 
	121.16,583.54 120.32,584.5 118.88,584.14 118.16,583.66 116.48,583.78 115.4,582.58 114.32,581.98 112.64,581.26 112.4,579.7 
	113,577.9 114.2,575.98 115.16,575.5 115.64,576.58 116,577.42 116.72,577.78 117.44,576.94 118.52,576.82 118.88,577.18 
	119.72,576.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="116.36,572.86 116.72,573.46 117.8,573.94 118.52,574.54 118.52,576.22 118.16,577.66 118.76,578.5 
	118.4,579.7 117.68,580.78 116.24,580.3 115.4,579.82 113.72,580.06 112.64,578.86 111.56,578.14 109.88,577.42 109.64,575.86 
	110.24,574.18 111.44,572.26 112.4,571.66 112.88,572.86 113.24,573.58 113.96,574.06 114.8,573.1 115.76,573.1 116.24,573.34 
	116.96,573.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="125.84,554.14 126.2,554.74 127.28,555.22 128,555.82 128,557.5 127.64,558.94 128.24,559.78 
	128,560.98 127.16,562.06 125.72,561.58 124.88,561.1 123.2,561.34 122.24,560.14 121.04,559.42 119.36,558.7 119.12,557.14 
	119.72,555.46 120.92,553.54 121.88,552.94 122.36,554.14 122.72,554.86 123.44,555.34 124.28,554.38 125.24,554.38 125.72,554.62 
	126.56,554.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="135.32,536.5 135.68,537.22 136.76,537.7 137.48,538.18 137.48,539.86 137.12,541.42 137.72,542.14 
	137.36,543.46 136.64,544.42 135.2,544.06 134.36,543.58 132.68,543.7 131.6,542.5 130.52,541.9 128.84,541.18 128.6,539.62 
	129.2,537.82 130.4,535.9 131.36,535.42 131.84,536.5 132.2,537.34 132.92,537.7 133.76,536.86 134.72,536.74 135.2,537.1 
	135.92,536.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="150.2,526.18 150.56,526.9 151.64,527.38 152.36,527.86 152.36,529.54 152,531.1 152.6,531.82 
	152.24,533.02 151.4,534.1 149.96,533.62 149.24,533.26 147.56,533.38 146.48,532.18 145.4,531.58 143.72,530.86 143.48,529.18 
	144.08,527.5 145.28,525.58 146.24,524.98 146.72,526.18 147.08,526.9 147.8,527.38 148.52,526.54 149.6,526.42 149.96,526.78 
	150.8,526.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="70.88,608.14 71.24,608.86 72.32,609.34 73.04,609.82 73.04,611.5 72.68,613.06 73.28,613.78 
	73.04,614.98 72.2,616.06 70.76,615.58 69.92,615.22 68.24,615.34 67.28,614.14 66.08,613.54 64.4,612.82 64.16,611.14 
	64.76,609.46 65.96,607.54 66.92,606.94 67.4,608.14 67.76,608.86 68.48,609.34 69.32,608.5 70.28,608.38 70.76,608.74 71.6,608.5 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="77.6,625.66 77.96,626.38 79.04,626.86 79.76,627.34 79.76,629.02 79.52,630.58 80.12,631.3 
	79.76,632.62 78.92,633.58 77.48,633.22 76.76,632.74 75.08,632.86 74,631.66 72.8,631.06 71.12,630.34 70.88,628.78 71.6,626.98 
	72.68,625.06 73.64,624.58 74.24,625.66 74.6,626.5 75.32,626.86 76.04,626.02 77.12,625.9 77.48,626.26 78.32,626.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="85.28,634.66 85.64,635.38 86.72,635.86 87.44,636.34 87.44,638.14 87.08,639.58 87.8,640.3 
	87.44,641.62 86.6,642.58 85.16,642.22 84.32,641.74 82.64,641.86 81.68,640.66 80.48,640.06 78.8,639.34 78.56,637.78 
	79.16,635.98 80.36,634.06 81.32,633.58 81.92,634.66 82.16,635.5 82.88,635.98 83.72,635.02 84.8,634.9 85.16,635.26 86,635.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="125.36,657.22 125.72,657.94 126.8,658.42 127.52,658.9 127.52,660.58 127.28,662.14 127.88,662.86 
	127.52,664.06 126.68,665.14 125.24,664.66 124.4,664.3 122.84,664.42 121.76,663.22 120.56,662.62 118.88,661.9 118.64,660.22 
	119.36,658.54 120.44,656.62 121.4,656.02 122,657.22 122.24,657.94 122.96,658.42 123.8,657.58 124.88,657.46 125.24,657.82 
	126.08,657.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="113.72,668.98 113.96,669.58 115.16,670.06 115.88,670.54 115.88,672.34 115.52,673.78 116.12,674.62 
	115.76,675.82 114.92,676.9 113.48,676.42 112.76,675.94 111.08,676.18 110,674.86 108.92,674.26 107.24,673.54 107,671.98 
	107.6,670.3 108.8,668.38 109.64,667.78 110.24,668.98 110.6,669.7 111.32,670.18 112.04,669.22 113.12,669.1 113.48,669.46 
	114.32,669.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="125.24,674.98 125.6,675.7 126.68,676.18 127.4,676.66 127.4,678.34 127.04,679.9 127.64,680.62 
	127.4,681.82 126.56,682.9 125.12,682.42 124.28,682.06 122.6,682.18 121.52,680.98 120.44,680.38 118.76,679.66 118.52,677.98 
	119.12,676.3 120.32,674.38 121.28,673.78 121.76,674.98 122.12,675.7 122.84,676.18 123.68,675.34 124.64,675.22 125.12,675.58 
	125.96,675.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="108.44,734.26 108.68,734.5 109.04,734.26 109.4,733.54 109.64,733.06 110.12,733.06 110.96,733.18 
	111.32,732.82 111.56,732.34 112.4,732.22 113,733.06 113.48,733.06 114.2,732.94 114.68,733.18 114.92,734.26 114.8,734.62 
	115.4,734.86 115.76,735.46 115.4,735.82 114.92,735.82 114.68,736.06 114.68,736.42 114.68,736.9 114.08,737.02 113.48,737.14 
	113.36,737.26 113.36,737.74 113,738.22 112.28,738.22 111.92,738.22 111.8,738.58 111.68,739.18 111.56,739.54 110.96,739.54 
	110.84,739.66 110.48,740.14 110.36,740.38 110,740.62 109.52,740.5 109.16,740.38 108.8,740.5 108.32,740.5 107.96,739.78 
	107.96,738.82 107.72,737.86 107.12,737.26 107.48,736.54 107.84,736.54 108.08,736.66 108.32,736.18 108.2,735.82 108.08,735.22 
	108.32,734.86 108.44,734.74 108.68,734.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="112.88,740.5 113.12,740.74 113.84,740.62 114.44,740.98 114.44,741.82 114.44,742.54 114.68,742.9 
	114.32,743.74 113.84,743.86 113.24,744.1 112.4,744.46 111.8,744.22 111.2,743.86 110.6,743.62 110.72,742.66 111.08,742.3 
	110.84,741.7 111.08,741.1 111.56,740.86 112.16,740.98 112.52,740.98 112.88,740.86 113.36,740.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="78.08,655.66 78.32,655.9 78.8,655.66 79.04,654.94 79.28,654.46 79.76,654.46 80.6,654.58 
	80.96,654.22 81.2,653.74 82.04,653.62 82.64,654.46 83.12,654.46 83.84,654.34 84.32,654.58 84.56,655.66 84.44,656.02 
	85.04,656.26 85.4,656.86 85.16,657.22 84.56,657.22 84.32,657.46 84.32,657.82 84.44,658.3 83.72,658.42 83.12,658.54 83,658.66 
	83,659.14 82.64,659.62 81.92,659.62 81.56,659.62 81.44,659.98 81.32,660.58 81.2,660.94 80.6,660.94 80.48,661.06 80.12,661.54 
	80,661.78 79.64,662.02 79.16,661.9 78.8,661.78 78.44,661.9 77.96,661.9 77.72,661.18 77.6,660.22 77.36,659.26 76.76,658.66 
	77.12,657.94 77.48,657.94 77.84,658.06 77.96,657.58 77.96,657.22 77.72,656.62 77.96,656.26 78.08,656.14 78.32,655.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="82.52,661.9 82.76,662.14 83.48,662.02 84.08,662.38 84.08,663.22 84.08,663.94 84.32,664.3 
	83.96,665.14 83.48,665.26 82.88,665.5 82.04,665.86 81.44,665.62 80.84,665.26 80.24,665.02 80.36,664.06 80.72,663.7 80.6,663.1 
	80.72,662.5 81.2,662.26 81.8,662.38 82.16,662.38 82.52,662.26 83,661.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="122.24,590.86 122.48,591.1 122.84,590.86 123.2,590.14 123.44,589.66 123.92,589.66 124.76,589.78 
	125.12,589.42 125.36,588.94 126.2,588.82 126.8,589.66 127.16,589.66 128,589.54 128.48,589.78 128.72,590.86 128.6,591.22 
	129.2,591.46 129.56,592.06 129.2,592.3 128.72,592.42 128.48,592.66 128.48,593.02 128.48,593.38 127.88,593.62 127.28,593.74 
	127.16,593.86 127.04,594.34 126.8,594.82 126.08,594.82 125.72,594.82 125.6,595.18 125.48,595.78 125.36,596.14 124.76,596.14 
	124.64,596.26 124.28,596.74 124.16,596.98 123.8,597.22 123.32,597.1 122.96,596.98 122.6,597.1 122.12,597.1 121.76,596.38 
	121.76,595.42 121.52,594.46 120.92,593.86 121.28,593.14 121.64,593.14 121.88,593.14 122.12,592.78 122,592.42 121.88,591.82 
	122.12,591.46 122.24,591.34 122.48,590.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="126.68,597.1 126.92,597.22 127.64,597.22 128.24,597.58 128.24,598.42 128.24,599.14 128.48,599.5 
	128.12,600.22 127.64,600.46 127.04,600.7 126.2,601.06 125.6,600.7 125,600.46 124.28,600.22 124.52,599.26 124.88,598.9 
	124.64,598.3 124.88,597.7 125.24,597.46 125.96,597.58 126.32,597.58 126.68,597.46 127.16,596.98 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="815.72" y1="421.42" x2="818.72" y2="287.98"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="838.52,285.82 834.92,452.62 847.52,456.22 926.12,453.82 984.2,450.46 1009.64,437.26 
	1097.84,288.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="1118.24,300.22 1117.28,302.74 1034,446.14 1101.68,515.26 1082.6,542.86 1026.68,589.06 
	943.64,682.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="924.08" y1="704.86" x2="891.56" y2="707.86"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="924.08" y1="704.86" x2="1071.92" y2="704.74"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="1077.32" y1="682.9" x2="943.64" y2="682.78"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="557.72,744.58 563.36,843.82 565.52,881.62 563.6,934.42 564.92,996.34 569,1184.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="555.68,744.58 561.44,843.82 563.6,881.62 561.56,934.42 562.88,996.34 566.96,1184.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="553.76,744.58 559.4,843.82 561.56,881.62 559.64,934.42 560.96,996.34 565.04,1184.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="552.44,744.58 558.08,843.82 560.24,881.62 558.2,934.42 559.52,996.34 563.72,1184.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="415.04,835.42 363.2,832.66 359.6,832.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="348.8,831.94 292.88,828.94 291.8,829.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="281.24,833.26 258.68,841.54 216.32,844.9 217.4,856.9 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="230.36" y1="854.98" x2="286.04" y2="847.54"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="293.96,844.42 301.4,841.54 349.4,844.06 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="360.68" y1="844.66" x2="415.16" y2="847.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="307.76" y1="990.1" x2="307.76" y2="990.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="295.4,989.98 281.72,989.86 232.04,989.38 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="217.04" y1="989.14" x2="216.68" y2="989.14"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="226.88,1001.5 293.6,1002.1 300.32,1006.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="464,575.98 464.24,576.1 465.44,575.86 466.16,576.46 465.8,577.3 465.56,577.66 466.52,578.38 
	466.4,580.06 465.08,579.94 464.48,579.94 464.36,580.66 464.48,580.54 464.48,582.82 463.04,582.82 462.92,581.74 461,581.98 
	460.04,582.1 458.84,581.5 457.4,580.66 457.52,579.1 458.72,578.26 458.84,577.18 459.08,575.74 460.16,574.78 461.6,576.22 
	462.32,576.46 462.92,575.62 463.04,574.9 464.12,574.66 464,575.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="453.08,570.94 452.48,570.58 451.88,570.58 451.52,571.06 451.64,571.54 451.04,571.9 450.56,572.14 
	450.44,572.86 450.8,573.46 451.04,573.82 451.88,573.82 452.48,573.46 452.96,573.58 453.8,573.58 454.28,573.22 454.64,572.74 
	454.16,572.14 453.44,571.66 452.84,571.18 452.84,571.06 452.84,570.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="472.52,561.22 471.32,561.22 470.48,560.86 468.8,560.62 468.44,561.58 469.28,561.94 469.16,562.06 
	467.84,561.94 467.36,562.06 467.24,562.66 468.56,563.02 468.8,563.86 468.8,565.18 468.92,566.38 469.76,565.42 470.24,565.06 
	470.96,565.42 472.4,565.42 472.52,564.7 472.16,564.1 472.64,563.26 473.24,562.54 473.24,561.82 472.28,561.58 471.92,560.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="490.28,614.5 489.56,615.82 488.48,615.46 487.4,614.98 486.68,615.46 486.68,616.06 487.64,617.14 
	486.68,617.74 486.32,618.7 486.08,619.9 486.8,621.1 488,620.74 489.08,620.86 490.04,621.7 491.48,621.1 492.2,620.5 
	493.88,620.5 495.44,619.3 495.56,617.5 495.08,616.18 494.24,614.98 493.28,615.1 492.8,615.34 491.6,615.58 491.24,615.58 
	490.88,615.46 490.16,614.98 489.44,614.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="530.72,617.26 530.12,615.22 528.56,613.78 527.12,613.66 527,614.38 527.36,615.1 525.56,615.46 
	524,615.22 523.04,615.82 524.36,617.38 524.96,617.86 524.36,618.58 522.92,618.94 522.68,620.02 524.36,620.5 525.68,620.38 
	527.12,621.1 527.6,620.14 528.2,620.14 528.8,620.74 529.64,620.98 529.88,620.62 531.32,620.86 533.24,621.1 534.08,620.02 
	533.48,618.94 532.4,618.58 531.8,618.46 532.04,616.9 531.08,616.18 530.24,616.06 529.76,616.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="452.6,596.5 451.88,597.1 450.92,596.62 450.2,596.38 449.72,596.86 449.96,597.94 450.08,598.42 
	448.4,598.66 447.32,599.38 447.32,600.58 448.04,601.3 449.12,600.94 449.84,602.5 450.56,603.94 451.52,603.58 452.6,603.34 
	454.52,602.74 453.92,601.18 454.28,600.1 455.12,599.86 455.72,598.78 455.36,597.7 454.4,597.34 453.56,596.86 452.6,596.86 
	452.36,596.62 452.12,596.38 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="413.84" y1="585.94" x2="413.96" y2="586.9"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="414.68" y1="593.38" x2="414.56" y2="594.1"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="414.08" y1="600.22" x2="414.08" y2="601.54"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="413.72" y1="619.42" x2="413.84" y2="614.86"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="524.48" y1="558.7" x2="536.12" y2="557.74"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="536.12" y1="557.74" x2="539.72" y2="561.58"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="539.72" y1="561.58" x2="540.56" y2="570.46"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="696.92,477.22 996.08,464.5 1075.52,521.5 908.84,689.74 879.08,692.74 769.28,692.14 699.2,693.58 
	692.84,688.3 691.52,484.18 696.92,477.22 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M609.08,585.64c0-1.42-1.16-2.58-2.58-2.58c-1.42,0-2.58,1.16-2.58,2.58s1.16,2.58,2.58,2.58
	C607.92,588.22,609.08,587.06,609.08,585.64"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="606.56,623.74 609.08,623.74 609.08,621.82 609.32,620.74 609.92,619.66 611.12,618.82 611.96,618.58 
	613.4,618.58 632.24,618.1 633.2,618.34 633.44,618.58 634.04,619.42 634.4,620.38 634.4,621.94 634.52,623.62 637.04,623.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="606.56,623.26 608.6,623.26 608.72,621.82 608.84,620.74 608.84,620.62 609.56,619.3 610.88,618.46 
	611.96,618.22 613.4,618.1 632.24,617.74 633.44,617.98 633.8,618.22 634.4,619.18 634.88,620.26 634.88,621.94 635,623.14 
	637.04,623.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="606.56,622.78 608.24,622.78 608.24,621.7 608.36,620.74 608.36,620.5 609.32,619.06 610.76,618.1 
	611.84,617.74 613.4,617.62 632.36,617.26 633.56,617.5 634.16,617.86 634.88,619.06 635.36,620.26 635.36,621.82 635.36,622.66 
	637.04,622.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="606.56,622.42 607.76,622.42 607.76,621.7 608,620.62 608,620.26 608.96,618.7 610.52,617.62 
	611.84,617.26 613.4,617.26 632.36,616.78 633.8,617.14 634.52,617.62 635.24,618.82 635.84,620.14 635.84,621.82 635.84,622.18 
	637.04,622.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="606.56,621.94 607.28,621.94 607.4,621.58 607.52,620.62 607.52,620.14 608.6,618.34 610.4,617.26 
	611.72,616.9 613.4,616.78 632.36,616.3 633.92,616.78 634.76,617.26 635.6,618.58 636.2,620.02 636.2,621.82 637.04,621.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="604.64,582.34 603.68,582.94 603.32,583.42 602.6,584.14 602.24,584.86 602.12,585.7 602,587.02 
	602.24,587.62 602.6,588.94 603.8,590.38 604.52,590.86 604.88,591.1 605,592.9 604.76,593.62 604.76,596.02 604.88,596.62 
	605,597.46 605.24,597.94 605.84,598.3 607.28,598.3 608.72,598.42 610.52,598.42 611.84,598.54 613.28,598.42 614.48,598.3 
	617.24,598.3 618.2,598.3 619.16,598.18 619.52,597.82 619.64,596.86 619.52,595.9 619.4,594.58 619.4,591.58 619.4,589.9 
	619.4,588.46 619.52,586.66 619.52,584.62 619.16,578.5 619.28,577.06 619.28,575.74 619.28,575.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="619.52,573.46 618.2,572.74 617,572.5 615.44,572.5 612.8,572.5 611.24,572.5 609.8,572.74 
	607.28,572.98 606.2,572.98 605.6,573.22 605.24,573.7 604.88,574.66 604.64,575.74 604.52,577.06 604.4,578.5 604.4,579.22 
	604.4,580.18 604.64,582.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="606.56,583.06 606.68,575.02 618.44,575.02 618.68,596.5 618.2,596.98 606.56,596.86 606.68,589.66 
	606.68,589.18 608.24,588.82 609.08,587.74 609.44,586.9 609.68,585.82 609.44,584.98 608.96,584.02 608.72,583.66 608.12,583.06 
	607.4,582.7 606.56,582.46 606.32,582.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="619.28,580.3 620,580.18 620.36,591.58 619.4,591.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="620,580.18 619.88,577.78 619.88,576.94 619.88,575.98 619.76,574.9 619.76,573.94 619.52,573.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="619.28,575.02 619.04,574.42 618.56,574.06 617.96,573.94 617.48,573.94 616.28,573.94 615.44,574.06 
	613.52,573.82 612.92,573.94 611.96,573.94 610.88,573.94 609.68,573.94 608.72,573.94 607.76,574.06 606.92,574.18 606.44,574.42 
	605.96,575.02 605.72,575.62 605.72,576.46 605.6,577.18 605.72,578.98 605.72,579.58 605.72,580.78 605.72,581.62 605.72,582.34 
	606.44,582.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="605.84,582.34 605.24,582.22 605,582.34 604.76,582.34 604.64,582.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="604.88,591.1 605.84,591.1 605.84,591.22 605.72,597.1 605.72,597.58 606.68,597.94 608.48,597.82 
	611.48,597.82 616.76,597.7 619.04,597.58 619.16,596.62 619.04,593.38 619.04,591.58 619.4,591.58 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="619.04" y1="591.58" x2="618.68" y2="591.58"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="605.36,591.1 605.36,594.7 605.36,596.74 605.24,597.22 605.36,597.58 605.72,597.58 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="605.72" y1="597.46" x2="619.04" y2="597.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="620.12,597.22 620,595.3 619.88,593.5 619.88,591.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="620.12,597.22 620,597.34 619.88,597.46 619.64,597.58 619.52,597.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="607.52,564.22 607.52,564.34 607.4,564.46 607.4,564.7 607.4,564.94 607.52,565.18 611,565.18 
	611.24,565.18 611.36,564.34 611.36,564.22 611.12,564.22 607.4,564.22 607.4,563.98 607.76,563.98 608.6,563.98 609.32,563.98 
	610.04,563.98 610.76,563.98 611.12,563.98 611.24,564.1 611.24,564.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="607.52,564.22 607.4,564.34 607.28,564.46 607.28,564.7 607.16,564.82 607.28,564.7 607.4,564.58 
	607.4,564.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="607.52,565.18 607.4,565.3 607.28,565.66 607.28,565.54 607.28,565.42 607.4,565.18 607.52,565.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="611.24,565.18 611.12,565.3 611,565.54 610.88,565.66 610.76,565.66 610.88,565.54 611,565.3 
	611.12,565.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="601.28,565.42 601.16,565.42 600.92,565.54 600.68,565.78 600.68,566.02 600.8,566.26 601.28,566.38 
	601.52,566.5 601.76,566.26 601.88,566.02 601.88,565.78 601.64,565.54 601.28,565.54 601.28,565.42 601.16,565.42 600.92,565.3 
	600.8,565.54 600.68,565.66 600.56,565.9 600.56,566.14 600.68,566.38 600.92,566.38 601.28,566.5 601.52,566.5 601.76,566.5 
	601.88,566.38 602,566.14 602,565.78 601.88,565.54 601.76,565.42 601.52,565.42 601.28,565.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="600.56,566.14 600.56,566.26 600.68,566.5 600.68,566.86 600.56,567.1 600.56,567.22 600.92,567.46 
	601.28,567.46 601.52,567.46 601.88,567.34 601.88,566.86 602,566.74 602,566.62 602,566.38 602,566.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.2,564.1 618.2,564.22 618.2,564.46 618.2,564.58 618.2,564.82 618.2,565.06 618.32,565.06 
	621.68,565.06 622.04,565.06 622.04,564.22 621.8,564.1 618.2,564.22 618.2,564.1 618.2,563.98 618.08,563.86 618.56,563.86 
	619.28,563.86 620,563.86 620.84,563.86 621.56,563.86 621.8,563.86 621.92,563.98 622.04,564.1 622.04,564.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.2,564.1 618.2,564.34 618.08,564.34 617.96,564.58 617.96,564.7 617.96,564.82 617.96,564.58 
	618.08,564.46 618.2,564.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.2,565.06 618.2,565.3 617.96,565.54 617.96,565.42 618.08,565.3 618.2,565.06 618.2,564.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="622.04,565.06 621.92,565.3 621.68,565.42 621.68,565.54 621.56,565.54 621.56,565.42 621.68,565.3 
	621.92,565.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="629,564.22 629,564.34 628.88,564.46 628.88,564.7 628.88,564.94 629,565.06 629,565.18 
	632.48,565.18 632.72,565.18 632.84,564.34 632.84,564.22 632.6,564.22 628.88,564.22 628.88,564.1 628.88,563.98 628.88,563.86 
	629.24,563.86 630.08,563.86 630.8,563.86 631.52,563.86 632.24,563.86 632.6,563.98 632.72,563.98 632.72,564.1 632.72,564.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="629,564.22 628.88,564.34 628.76,564.46 628.76,564.7 628.64,564.82 628.76,564.7 628.88,564.58 
	628.88,564.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="629,565.18 628.88,565.3 628.76,565.54 628.76,565.42 628.76,565.3 628.88,565.18 629,565.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="632.72,565.18 632.6,565.3 632.48,565.42 632.36,565.54 632.24,565.54 632.36,565.42 632.48,565.3 
	632.6,565.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="632.84" y1="564.58" x2="629" y2="564.58"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="540.44" y1="586.06" x2="540.68" y2="593.14"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="86.6" y1="1184.74" x2="80.6" y2="1184.74"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="840.8,538.42 853.28,537.1 854.6,549.7 840.8,538.66 863.84,512.02 863.84,522.58 876.32,522.22 
	863.6,511.9 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="876.2" y1="522.1" x2="854.48" y2="549.58"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="853.28" y1="536.98" x2="864.2" y2="522.22"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="857.48,551.62 868.52,537.94 880.04,548.98 870.2,561.22 857.24,551.26 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="858.92" y1="552.82" x2="869.6" y2="538.9"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="870.56" y1="539.62" x2="859.64" y2="553.42"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="860.72" y1="554.02" x2="871.64" y2="540.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="872.12" y1="541.54" x2="861.44" y2="554.98"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="863" y1="555.58" x2="873.44" y2="542.5"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="874.16" y1="543.46" x2="863.84" y2="556.42"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="865.16" y1="557.14" x2="874.76" y2="544.54"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="876.68" y1="545.62" x2="866.12" y2="558.1"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="878.12" y1="546.7" x2="867.68" y2="559.18"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="878.72" y1="548.26" x2="868.76" y2="560.14"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="843.68,488.02 851.6,488.14 851.48,492.7 844.16,492.46 843.8,487.9 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="847.64" y1="488.02" x2="847.76" y2="492.58"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="831.44,501.34 832.88,503.26 837.68,506.26 841.64,508.18 846.56,509.38 853.52,508.54 859.28,506.5 
	862.76,503.74 863.96,500.74 865.76,499.66 870.32,500.26 874.52,500.26 876.44,498.94 878,494.86 878.72,492.58 878.72,487.9 
	879.8,482.5 880.76,479.26 880.28,472.66 879.32,470.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="883.88,469.18 881.84,477.58 881.72,486.1 881.72,492.22 887.72,495.46 893.36,491.02 899,485.5 
	903.8,480.94 906.32,476.74 909.08,471.7 911.96,468.82 912.2,467.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="920.24,468.1 915.32,473.74 908,483.7 902.12,491.5 893.96,498.7 887.6,503.26 881.84,507.1 
	873.68,515.14 872.84,516.46 867.56,511.42 861.68,509.38 859.16,513.1 854.84,518.02 848.96,522.58 845.72,526.66 842.84,531.94 
	839.12,540.1 836.24,541.06 833.72,541.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="831.68,512.86 838.52,515.14 840.56,518.74 838.76,524.62 837.08,526.42 832.4,527.62 829.64,527.62 
	830.72,519.22 831.44,512.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="831.2,501.46 831.2,471.7 879.8,470.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="868.04,563.38 864.8,569.86 856.28,580.06 852.2,585.34 843.2,597.1 836.36,606.46 834.32,612.58 
	830.24,619.06 822.08,619.9 822.92,608.98 822.08,596.38 821.24,584.5 820.88,567.1 820.88,560.5 822.08,549.94 824.96,542.62 
	834.32,541.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="869.6,536.5 880.64,522.7 892.16,533.86 882.32,546.1 869.36,536.14 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="871.04" y1="537.7" x2="881.72" y2="523.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="882.68" y1="524.5" x2="871.76" y2="538.18"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="872.84" y1="538.9" x2="883.76" y2="525.22"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="884.24" y1="526.42" x2="873.56" y2="539.74"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="875.12" y1="540.46" x2="885.56" y2="527.26"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="886.28" y1="528.34" x2="875.96" y2="541.3"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="877.28" y1="542.02" x2="886.88" y2="529.42"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="888.8" y1="530.38" x2="878.24" y2="542.98"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="890.24" y1="531.58" x2="879.8" y2="543.94"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="890.84" y1="533.02" x2="880.88" y2="545.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="977.96,661.06 1037.96,591.1 1046.6,600.34 983.24,671.62 975.92,659.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="958.52,675.46 955.16,674.26 953.6,677.62 954.8,679.9 956.96,680.38 959,679.42 959,676.3 
	957.2,675.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="958.76,665.38 963.44,670.9 963.92,675.82 963.92,680.14 963.08,684.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="964.4,659.5 969.32,664.9 970.64,671.62 971.96,676.3 971.96,682.18 971.36,683.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="983,670.54 985.16,671.86 994.64,676.3 999.32,670.9 1026.8,641.02 1035.68,635.62 1041.08,629.14 
	1047.56,622.9 1051.16,618.82 1052.24,618.34 1055.6,621.1 1062.08,616.78 1065.2,614.74 1046,599.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="1063.88,636.1 1094.6,607.18 1112.48,622.66 1105.52,624.46 1103.72,628.18 1103.48,629.74 
	1102.16,629.98 1096.76,631.18 1095.2,633.82 1094.6,635.86 1094.6,638.26 1092.8,638.26 1089.2,639.22 1087.88,641.38 
	1087.64,644.14 1087.64,644.74 1082.96,645.22 1080.68,649.06 1080.44,650.14 1063.4,635.86 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="1087.16" y1="645.22" x2="1071.08" y2="627.82"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="1094.36" y1="638.26" x2="1078.4" y2="623.5"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="1103.24" y1="629.38" x2="1086.32" y2="614.98"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="1130.12,623.62 1082.36,675.58 1077.32,682.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="1071.92,704.74 1077.8,705.94 1107.68,787.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="1131.32,773.98 1095.2,687.22 1141.76,642.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="806.24,728.62 805.52,759.34 790.64,758.02 790.64,713.74 828.92,713.74 827.84,724.66 806,728.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="849.44,727.06 848.48,754.66 845.12,756.46 838.76,763.18 833.84,771.82 828.08,781.78 824.24,791.38 
	824,799.18 822.68,798.7 806.72,790.78 804.68,788.98 814.28,768.7 818.24,758.02 822.44,750.46 831.2,749.98 834.92,749.74 
	833.12,712.42 872,711.7 871.76,729.58 847.76,728.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="840.68,906.22 834.68,890.86 836,876.82 841.28,866.14 843.32,856.78 840.68,836.02 839.36,828.7 
	856.04,834.1 868.76,841.42 888.08,846.7 912.2,855.46 933.56,862.06 948.2,867.46 961.64,878.14 971.6,885.46 940.16,947.62 
	920.84,946.9 895.4,934.3 853.28,913.54 839.96,904.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="857.24,864.46 851.84,873.22 850.52,884.98 850.52,892.9 860.6,902.5 868.88,910.42 876.92,907.18 
	877.76,895.42 863.12,890.86 870.56,879.58 925.4,905.5 913.28,920.14 883.16,905.86 882.8,912.1 926.24,931.78 940.52,900.82 
	935,897.1 937.52,892.06 929.6,886.66 929.96,881.62 896.96,865.78 893.24,871.54 857.6,851.5 855.56,860.26 856.4,864.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="878.96,790.3 891.56,763.54 878.96,755.26 872.24,771.58 864.32,770.74 854.24,797.02 865.64,803.74 
	873.08,789.58 884,792.82 907.4,804.94 926.72,811.3 920,830.5 880.64,813.34 878.6,816.34 863.96,808.3 866.84,802.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="915.32,729.46 921.2,732.58 922.16,732.94 926.96,728.86 931.88,729.34 932.6,729.7 933.92,730.54 
	936.32,724.9 938.12,721.18 939.2,719.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="942.68,722.74 940.16,728.86 939.08,731.26 938.6,732.82 941.72,733.9 945.08,735.7 947.24,738.34 
	947.24,741.34 946.28,742.78 940.64,752.62 934.64,749.98 930.08,748.66 924.32,759.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="920,758.38 924.92,749.02 921.68,746.02 920.84,742.9 920.72,740.62 920.96,737.5 921.32,736.42 
	914,733.42 912.8,736.18 907.76,748.18 920.36,758.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="925.28,759.22 938.72,763.54 944.96,764.86 948.92,763.3 953,754.3 957.08,741.82 961.88,731.62 
	965,723.94 966.8,718.18 959.24,717.58 951.2,717.34 947.24,717.7 945.32,718.18 942.8,723.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="938.84,718.3 938.84,717.1 931.04,716.5 926.72,717.22 923.36,718.66 920.84,721.42 915.32,729.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="937.88,735.46 932.48,746.26 937.28,749.02 942.8,738.22 937.28,735.46 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M928.52,740.92c0-1.23-0.99-2.22-2.22-2.22c-1.23,0-2.22,0.99-2.22,2.22s0.99,2.22,2.22,2.22
	C927.53,743.14,928.52,742.15,928.52,740.92"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M932,734.32c0-1.16-0.94-2.1-2.1-2.1c-1.16,0-2.1,0.94-2.1,2.1c0,1.16,0.94,2.1,2.1,2.1
	C931.06,736.42,932,735.48,932,734.32"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="1012.16,714.58 1036.04,724.66 1015.04,764.98 995.36,756.58 1011.68,713.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="989.84,761.98 1009.16,771.7 971.36,862.3 954.56,856.9 985.28,790.18 978.92,785.14 989.48,761.62 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="954.2,777.58 975.56,784.3 939.92,846.34 924.8,839.62 953.36,775.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="347.24,595.18 343.16,605.26 342.44,622.78 343.16,671.38 347.6,699.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="350.24,716.38 350.6,718.66 351.92,746.98 354.68,773.38 356.72,803.02 358.64,821.26 359.6,832.42 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="360.68,844.66 366.2,911.14 366.8,921.94 372.92,950.38 379.64,981.34 379.64,990.82 380,994.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="364.28,995.38 358.88,933.94 350.84,867.58 349.4,844.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="348.8,831.94 348.32,822.7 344.6,752.86 344,739.66 341,717.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="338.6,699.58 334.28,668.26 334.28,650.86 334.28,634.66 334.76,615.58 331.76,601.66 330.08,592.78 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="338.12,699.22 310.16,697.9 288.92,697.42 272.84,697.42 261.32,701.26 254.6,710.98 253.28,721.66 
	257.96,744.58 261.32,760.78 271.04,798.22 276.56,818.14 280.04,829.54 281.24,833.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="285.92,846.1 292.28,928.06 291.8,941.26 291.8,965.38 295.52,990.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="300.32,1006.9 301.16,1015.9 305.84,1113.34 305.84,1128.94 305.12,1141.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="305.48,1140.94 305.12,1156.78 305.84,1180.42 306.08,1190.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="319.64,1190.5 317.96,1170.94 315.92,1147.9 315.56,1140.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="314.96,1130.86 313.88,1114.66 313.88,1104.46 313.88,1072.66 311.84,1048.18 311.24,1008.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="307.88,991.9 302.36,920.86 301.04,899.14 296.96,871.3 295.64,855.7 293.96,844.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="291.8,829.3 291.56,827.86 286.76,808.18 280.64,782.38 272.6,754.54 267.8,737.62 262.4,724.06 
	267.08,715.3 281.36,714.58 308.48,714.58 332.36,717.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="314.96,1130.86 324.2,1130.38 372.56,1132.42 419.6,1133.14 420.92,1132.9 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="428.24" y1="1131.7" x2="431.84" y2="1131.1"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="430.4" y1="1142.02" x2="428.36" y2="1142.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="421.04,1142.02 399.8,1142.02 371.84,1142.02 343.28,1140.58 322.88,1139.98 315.56,1140.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="362.84,312.22 360.8,289.54 362.12,273.1 371.72,262.06 382.76,257.98 392.36,257.26 402.68,259.3 
	409.52,266.14 415.04,267.58 422.6,268.3 445.28,267.58 454.88,256.54 454.88,241.42 460.4,238.06 461.72,232.54 461.72,199.54 
	462.44,187.18 469.28,183.1 467.96,175.54 471.44,143.98 479.6,137.74 482.36,108.94 475.52,112.42 459.68,129.58 448.76,146.74 
	437.72,170.74 432.2,184.42 422.6,211.9 415.76,229.06 399.2,235.3 382.04,239.38 376.52,244.18 366.92,250.42 355.28,257.26 
	349.04,272.38 347.72,285.46 349.52,313.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="446.24,46.42 446.24,67.3 439.64,90.46 433.04,102.46 415.52,117.94 401.24,129.94 391.28,145.3 
	378.08,168.46 363.8,185.98 344,214.54 327.56,237.7 314.36,251.98 304.52,276.1 301.16,290.38 295.4,316.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="293,332.98 292.4,337.66 290.24,366.22 286.88,398.02 281.36,428.86 275.96,446.38 263.84,466.18 
	248.48,487.06 215.48,506.86 192.32,516.7 167.12,526.66 147.32,536.5 140.72,546.46 134.12,562.9 131.84,581.62 131.84,590.38 
	126.44,605.74 120.92,625.54 110.96,649.66 74.6,723.94 62.48,760.18 55.88,861.46 56.96,890.14 53.72,927.58 47,966.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="21.68,969.34 44.84,962.02 66.92,958.66 80.12,957.58 87.8,942.22 87.8,934.54 99.92,926.74 
	109.76,925.66 136.28,937.78 141.8,945.46 145.04,957.58 139.52,979.66 126.32,987.34 110.96,990.58 92.24,981.82 82.28,971.86 
	80.12,956.5 80.12,953.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="121.88,927.82 126.32,915.82 136.28,892.66 155,881.62 171.44,870.7 199.04,860.74 217.28,856.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="187.88,1108.18 193.52,1097.38 190.16,1080.82 190.16,1068.82 200.12,1051.18 212.24,1032.46 
	213.32,1016.98 197.96,998.38 188,983.98 182.48,969.7 197.96,949.9 207.8,936.7 212.24,921.22 193.52,904.78 173.72,892.66 
	152.72,880.54 141.8,873.94 127.4,860.74 125.24,840.94 132.92,818.86 143.96,804.58 155,773.74 161.6,750.7 165.92,731.98 
	167.12,709.9 165.92,694.54 164.84,679.18 160.52,674.74 155,665.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="261.32,700.42 160.52,717.7 130.76,727.54 120.8,727.54 117.56,727.54 104.36,727.54 87.8,729.7 
	77.84,731.98 66.92,738.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="89.96,939.94 64.64,922.42 41.48,916.9 25.04,922.42 21.68,923.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="59.12,873.94 50.36,890.5 39.32,920.14 26.12,939.94 21.68,944.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="22.88,973.54 40.76,986.62 44.24,1007.26 47,1018.3 49.76,1032.82 49.04,1050.82 43.52,1068.7 
	38.72,1081.9 36.68,1096.42 35.96,1106.74 35.24,1114.3 29,1116.34 21.68,1116.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="21.68,1121.14 28.4,1121.86 34.52,1126.06 33.92,1138.42 27.68,1155.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="21.68,1190.5 23.84,1168.42 28.28,1154.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="35.96,1190.5 35.96,1168.42 40.4,1143.1 45.92,1123.18 58.04,1119.82 74.6,1125.34 97.88,1125.34 
	112.28,1126.54 126.56,1126.54 148.64,1126.54 152,1134.22 152,1143.1 144.32,1151.86 129.92,1162.9 121.04,1180.66 117.44,1190.5 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="127.64,1190.5 136.52,1172.86 149.84,1157.38 161.96,1148.62 171.92,1161.82 174.08,1173.94 
	177.2,1190.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="197.36,1190.5 182.96,1161.82 175.16,1146.34 179.6,1132.06 189.56,1124.26 187.4,1105.54 
	182.96,1103.26 176.36,1096.66 161.96,1095.58 149.84,1106.62 145.4,1111.06 142.04,1116.58 132.08,1119.82 107.84,1119.82 
	87.92,1119.82 63.56,1117.66 49.28,1105.54 50.36,1085.62 60.32,1061.38 62.48,1042.54 62.48,1032.58 63.56,1012.78 73.52,996.22 
	81.32,992.86 81.32,988.42 61.4,974.02 49.28,967.42 47,962.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="277.04,448.18 293.6,422.62 300.56,401.26 306.08,384.58 310.28,373.54 313.04,363.22 315.8,347.98 
	319.28,332.02 317.84,345.94 312.32,379.06 313.04,399.1 316.52,417.1 323.36,437.14 327.56,449.62 331.04,460.66 332.12,462.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="317.36,316.42 326.84,305.14 330.32,288.46 338.72,276.46 340.04,265.42 344.24,261.94 349.04,260.5 
	355.28,257.74 349.04,248.14 346.28,236.98 346.28,221.86 346.28,212.14 347,207.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="446,45.22 432.08,72.22 420.32,79.18 413.48,89.5 405.08,104.02 390.56,123.46 379.52,138.58 
	369.8,149.74 355.28,168.34 338,191.86 331.16,205.06 323.48,220.9 319.4,231.94 317,249.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="418.28,119.98 448.04,136.54 416.84,189.1 382.28,167.62 417.56,117.82 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="400.28" y1="139.3" x2="433.52" y2="162.1"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M427.4,151c0-6.13-4.97-11.1-11.1-11.1s-11.1,4.97-11.1,11.1c0,6.13,4.97,11.1,11.1,11.1
	S427.4,157.13,427.4,151"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="347.48,606.58 350.84,602.02 401.36,600.58 403.28,606.58 408.44,694.3 404.12,697.9 351.68,698.26 
	351.08,662.98 347.48,605.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="364.04,614.14 395.72,613.3 398.24,684.46 396.32,688.06 368,686.98 364.04,613.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="356.96,529.18 388.64,499.9 390.68,499.9 392.6,566.62 392.96,569.14 386.96,569.38 365.36,569.98 
	357.2,569.74 357.2,527.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="436.64,397.06 437.48,346.3 505.52,339.1 492.32,352.9 487.4,356.98 484.88,355.18 448.04,391.9 
	449.84,395.62 435.8,408.94 436.64,396.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="652.16,312.34 652.16,325.54 587.48,338.02 581.48,337.66 581.72,365.62 644.12,366.22 653.36,367.18 
	652.76,374.02 652.52,378.7 576.32,374.62 568.52,373.78 565.64,371.74 566.12,324.94 570.44,321.82 653.36,309.7 652.16,314.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="667.52,308.5 681.32,307.66 681.68,313.42 681.92,372.58 675.56,375.22 670.4,374.86 667.52,374.86 
	666.92,309.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="471.44,759.82 470.6,744.94 490.52,742.06 489.08,730.66 461,731.5 444.08,732.1 444.08,770.98 
	448.4,770.38 449.24,764.86 459.56,765.7 462.44,768.1 471.2,768.34 471.44,771.82 480.2,771.58 480.56,759.58 470.6,759.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="368.96,732.82 401.36,731.38 401.6,815.38 378.44,815.98 368.36,731.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="588.2,904.3 587,871.78 592.76,868.54 672.32,868.54 674.48,872.14 675.68,870.58 677.24,976.42 
	596.96,976.9 584,970.18 588.32,902.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="598.52,911.98 595.88,883.3 600.44,880.9 610.04,883.54 610.04,904.78 624.32,904.78 624.68,912.1 
	599.12,911.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="631.52,902.02 641.48,901.78 641.24,919.54 629.96,919.66 630.68,899.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="653,878.86 668.24,879.58 667.88,923.62 653,923.26 653,878.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="653.12,923.86 650.36,923.98 650.84,944.38 665.6,944.5 665.36,923.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="605.72,955.18 626.6,953.62 625.4,967.78 604.88,967.54 605.48,954.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="626,956.62 646.52,957.58 646.52,970.18 624.44,969.58 624.8,968.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="645.92,957.22 646.28,953.86 668.24,954.82 667.64,969.94 645.56,969.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="631.4,922.78 642.32,923.14 640.76,943.3 632.36,943.66 630.56,923.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="632.6,943.06 621.2,942.7 621.2,947.02 638.96,947.86 638.36,943.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="608.48,950.02 592.52,950.02 592.76,942.1 607.88,942.46 609.08,950.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="447.92,864.22 534.56,859.9 539.84,864.7 539.36,947.5 530.12,948.94 531.56,965.38 541.76,969.22 
	540.32,974.02 514.64,974.02 448.4,974.5 451.28,953.74 455.6,944.98 448.4,944.5 451.28,917.98 451.76,905.38 443.96,903.46 
	443.48,887.02 449.36,885.58 448.88,865.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="457.4,884.02 472.28,884.02 472.28,903.46 457.4,903.46 457.4,884.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="458.6,902.86 472.76,902.86 472.76,921.82 458.6,921.82 458.6,902.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="457.64,923.74 472.16,923.74 472.16,942.1 457.64,942.1 457.64,923.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="490.64,947.5 515.36,947.5 515.36,969.82 490.64,969.82 490.64,947.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="495.44,911.62 536.24,911.62 536.24,927.1 495.44,927.1 495.44,911.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="517.76,883.54 532.88,883.54 532.88,908.26 517.76,908.26 517.76,883.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="494.48,926.62 477.56,926.62 477.56,904.78 494.48,904.78 494.48,926.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="502.28,888.34 478.04,888.34 478.04,882.1 502.28,882.1 502.28,888.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="766.52,823.78 798.92,823.54 801.68,827.5 792.44,863.5 776,855.22 763.52,848.5 763.76,824.74 
	768.44,823.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="715.76,315.34 796.16,305.5 795.2,355.54 793.76,362.86 753.56,363.82 754.04,359.38 770.72,359.38 
	775.04,351.58 766.28,348.1 747.68,348.58 746.72,338.38 739.76,338.38 738.8,349.66 716.84,348.58 717.8,355.06 741.8,357.94 
	739.76,366.22 712.4,363.82 715.28,313.9 718.28,313.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="845.72,290.38 875.84,287.38 882.2,294.82 881.24,449.02 875.84,452.98 840.2,450.94 841.76,292.42 
	849.08,289.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="896.6,291.34 900.08,285.94 947.96,281.5 947.96,442.06 937.16,449.98 900.08,450.94 895.64,443.02 
	897.08,291.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="945.56,453.94 951.92,444.58 950.36,273.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="950.12,251.26 948.8,112.3 908.24,83.14 862.64,69.22 749.84,40.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="937.4,103.42 937.4,89.5 951.32,86.98 990.68,111.1 1067.96,164.26 1104.8,211.18 1133.6,235.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="1095.92,290.86 1101.8,282.94 1104.32,270.46 1102.4,256.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="1118.24,300.22 1124.72,283.9 1130.72,270.46 1133.36,268.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="1100.96,239.5 1101.32,232.78 1089.44,218.86 1025.72,156.1 1013.84,145.18 998.48,135.22 
	986.48,129.22 963.2,119.38 957.68,127.3 957.2,141.7 961.16,159.1 963.2,226.06 964.16,246.46 964.16,248.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="964.04,272.26 963.32,370.78 967.28,440.02 969.68,448.78 973.64,452.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="981.8,423.22 976.64,344.14 984.68,344.14 983.6,295.18 1010.6,288.7 1011.68,294.7 1039.64,293.62 
	1038.2,288.22 1072.16,287.62 1044.68,319.66 1049.72,324.7 989.12,420.22 982.16,423.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="566.6,287.14 567.56,265.66 594.68,267.58 594.08,285.58 565.04,287.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="565.88,227.98 593.48,227.74 593.84,253.66 566.6,253.66 565.88,228.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="565.04,188.86 593.84,188.14 592.88,213.46 565.04,211.9 564.68,187.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="604.28,284.62 647.24,279.34 646.64,265.06 641,262.78 638.72,270.46 609.56,270.1 607.4,262.78 
	603.32,262.18 603.32,283.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="601.4,247.3 601.4,226.18 631.4,228.94 632.36,221.98 641,221.74 640.64,252.7 602,249.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="649.52,276.7 650.48,255.22 677.72,257.14 677,275.14 647.96,277.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="648.92,217.54 676.4,217.3 676.76,243.22 649.52,243.22 648.92,217.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="477.8,172.66 526.52,171.7 525.92,191.38 480.08,191.74 479.72,171.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="472.4,220.18 526.4,219.22 525.08,242.5 474.08,243.22 472.76,220.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="418.64,277.18 417.08,271.42 418.4,268.78 433.76,272.98 496.16,267.82 515.72,265.66 516.44,272.02 
	513.8,273.94 499.76,276.22 497.48,279.1 499.4,281.62 534.68,281.02 533.36,295.42 426.08,301.54 425.36,294.1 437.24,292.9 
	438.2,284.5 410.96,286.18 411.68,275.26 417.68,276.22 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="519.92" y1="219.46" x2="519.8" y2="191.5"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="494.72" y1="191.62" x2="494.6" y2="219.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="169.4,585.22 158.48,560.38 242,530.26 248.72,550.54 256.04,568.66 174.56,604.42 170.36,585.7 
	252.44,549.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="178.76,602.86 185.48,625.18 237.92,615.82 239.48,624.1 257.6,621.58 254.48,608.62 279.92,605.5 
	274.16,592.54 246.2,597.7 244.64,581.14 251.36,577.42 247.16,567.58 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="247.16" y1="597.22" x2="180.32" y2="614.74"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="162.08,646.42 241.04,625.66 243.08,647.5 256.04,644.86 253.4,623.62 292.88,614.26 299.12,639.7 
	168.32,669.22 167.36,658.9 163.64,646.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="214.52,658.9 223.4,689.02 304.76,666.7 298.52,639.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="220.28,682.78 206.24,686.86 201.08,662.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="206.24,686.86 208.28,694.18 187.52,696.22 178.76,700.9 175.04,701.38 174.08,684.34 168.32,675.94 
	168.8,667.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="252.2,749.74 250.52,713.02 176.84,731.62 181.64,764.38 182.6,768.22 195.92,766.3 196.64,753.7 
	194.96,741.7 213.2,737.38 229.4,731.62 235.88,732.22 235.64,750.46 254.48,750.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="258.08,753.7 246.68,764.02 242.12,767.62 238.16,763.42 233.36,763.78 196.64,774.46 184.28,777.7 
	188.12,791.02 191.12,796.54 201.8,796.9 221.6,791.98 234.32,788.74 243.68,786.1 245.72,788.14 247.28,795.22 249.92,802.42 
	258.08,801.34 260.6,797.14 261.56,787.42 258.08,779.26 254.12,778.06 245,780.94 244.04,782.62 243.44,786.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="256.4,779.26 255.8,769.9 260.24,764.98 263.24,763.06 258.68,752.02 256.04,754.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="183.68,776.98 213.8,781.3 196.64,795.82 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="213.56" y1="781.54" x2="245.96" y2="780.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="269.96,807.94 270.56,828.82 194.36,838.78 193.88,819.46 269.96,809.5 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="269.96" y1="817.9" x2="193.28" y2="828.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="217.88,857.02 217.04,990.94 219.68,996.22 224.12,1000.66 226.88,1001.5 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="232.04" y1="989.38" x2="230.36" y2="856.18"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="429.08,1153.78 448.28,1160.86 479.6,1163.14 500.6,1163.74 524.36,1164.34 547.64,1163.14 
	558.68,1160.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="434.96,1188.1 433.16,1173.58 443.6,1172.98 479,1177.06 513.32,1177.06 541.76,1175.86 
	558.68,1172.98 559.16,1172.74 556.76,1185.22 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="420.44" y1="1153.78" x2="419.84" y2="1189.78"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="570.8,1144.54 573.8,1153.78 576.68,1162.54 641.48,1162.66 662,1164.46 683.36,1165.42 
	696.32,1155.22 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="703.76" y1="1152.34" x2="704.72" y2="1190.5"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="579.2,1184.02 580.16,1174.66 595.04,1172.86 628.52,1175.62 652.64,1176.58 676.76,1176.58 
	687.92,1176.58 689.84,1182.1 686.6,1190.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="521.12,593.74 521.36,584.74 520.52,582.58 520.52,561.58 520.16,550.66 519.08,550.06 516.68,526.18 
	"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="512.72" y1="549.94" x2="516.68" y2="526.18"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="512.6,550.06 512.12,550.9 512.12,561.34 511.76,561.94 512.12,562.9 512,567.1 488.24,567.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="486.8,566.74 479.72,566.74 479.72,565.54 480.08,565.18 479.48,564.58 478.52,564.94 478.52,565.54 
	478.76,565.78 478.88,578.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="479.96,578.02 479.72,567.58 479.36,566.26 478.64,567.7 479.6,567.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="479.96,577.66 487.28,566.02 493.76,576.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="512.24,566.98 512.12,573.34 514.4,573.58 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="512.24" y1="566.14" x2="520.64" y2="566.38"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="511.88,554.86 513.32,550.66 514.64,554.74 516.2,550.54 517.76,555.1 518.6,550.54 520.28,554.86 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="519.2" y1="549.94" x2="512.72" y2="549.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="478.76,569.5 475.76,569.5 475.64,568.9 475.88,568.54 475.64,568.42 475.28,568.78 475.52,568.9 
	475.52,569.62 470.6,578.02 470.24,578.14 470.36,580.78 469.28,582.34 469.28,586.54 469.04,587.74 469.28,587.98 468.8,590.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="480.08,577.66 478.76,577.78 478.64,584.38 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="478.88" y1="577.9" x2="470.6" y2="578.02"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="470.48" y1="579.46" x2="478.76" y2="579.46"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="470.36" y1="578.62" x2="478.88" y2="578.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="511.76,582.46 511.52,581.74 512,581.5 514.52,573.58 514.4,572.38 514.16,572.38 514.4,572.02 
	514.76,572.38 514.52,572.62 514.88,574.54 517.16,581.74 516.8,582.1 516.92,582.58 511.76,582.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="495.44,575.38 496.4,573.1 496.28,572.62 496.16,572.5 496.16,572.38 496.4,572.26 496.52,572.5 
	496.64,572.5 496.52,572.62 496.4,572.62 496.4,573.22 497.36,575.5 495.32,575.38 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M496.88,575.43c-0.01-0.29-0.24-0.52-0.52-0.53c-0.29-0.01-0.53,0.21-0.55,0.5"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="501.44,575.5 502.4,573.1 502.4,572.74 502.28,572.62 502.28,572.5 502.52,572.38 502.64,572.62 
	502.76,572.62 502.64,572.74 502.52,572.62 502.52,573.34 503.48,575.5 501.44,575.5 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M503,575.55c-0.01-0.29-0.24-0.52-0.52-0.53c-0.29-0.01-0.53,0.21-0.55,0.5"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="507.56,575.38 508.52,572.98 508.52,572.5 508.4,572.38 508.64,572.26 508.76,572.38 508.88,572.5 
	508.76,572.5 508.64,572.5 508.64,573.1 509.6,575.38 507.56,575.38 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M509.12,575.43c-0.01-0.29-0.24-0.52-0.52-0.53c-0.29-0.01-0.53,0.21-0.55,0.5"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M517.52,568.96c0-0.63-0.51-1.14-1.14-1.14c-0.63,0-1.14,0.51-1.14,1.14s0.51,1.14,1.14,1.14
	C517.01,570.1,517.52,569.59,517.52,568.96"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="518.12,563.98 513.92,563.86 513.8,562.18 514.04,562.06 514.04,561.7 513.2,555.7 513.32,555.58 
	512.96,555.22 512.96,554.98 512.96,553.06 512.72,555.34 512.48,555.58 512.72,555.94 512.12,561.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="518.24,563.86 518.24,562.42 518.12,562.18 518.12,561.82 518.24,561.46 518.96,555.82 518.72,555.7 
	518.84,555.46 519.2,553.18 519.44,555.22 519.44,555.46 519.8,555.58 519.68,555.7 520.16,561.46 520.4,561.94 520.16,562.42 
	520.52,562.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="517.4,578.74 517.4,571.42 517.28,571.06 517.16,570.82 517.04,570.82 516.8,570.82 516.68,571.18 
	516.56,571.54 516.56,571.78 516.56,578.74 517.4,578.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="516.08,578.26 516.08,571.54 515.84,571.06 515.6,570.82 515.36,570.94 515.12,571.3 515.12,571.66 
	515.24,574.42 515.24,575.5 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="493.76" y1="576.58" x2="512" y2="576.46"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="512" y1="576.82" x2="512.12" y2="573.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="511.4" y1="581.74" x2="512" y2="576.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="523.76,549.46 524.96,580.42 526.28,582.22 526.52,590.02 526.4,592.9 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="519.08" y1="549.94" x2="523.16" y2="548.74"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="522.56,595.78 522.92,584.02 521.36,581.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="518.84,582.46 518.6,584.5 519.08,591.22 518.96,592.06 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="519.92" y1="582.58" x2="521.24" y2="582.22"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="521.6" y1="581.98" x2="524.84" y2="580.54"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="525.44,587.38 525.32,584.86 524.36,583.54 524.36,582.7 524.6,582.46 524.48,582.34 524.24,582.46 
	524.24,581.86 524,582.1 524.12,582.58 523.88,582.7 523.88,582.94 524.12,582.82 524.24,583.9 523.64,586.66 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="526.52" y1="593.38" x2="526.4" y2="592.54"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="522.56,595.78 522.8,595.78 523.04,595.54 523.28,595.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="523.28,591.58 523.04,592.42 523.04,593.14 523.04,595.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="523.28,595.3 523.88,594.58 524.24,594.1 524.84,593.86 525.2,593.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="525.44,593.74 525.56,593.62 526.64,593.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="524.12,581.02 525.44,582.46 525.68,587.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="521.36,581.62 521.36,580.06 521.36,579.34 521.6,579.7 521.48,580.18 521.48,580.66 521.6,581.38 
	521.36,581.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="522.44,581.26 522.44,579.58 522.56,578.98 522.8,579.22 522.68,579.58 522.68,580.42 522.8,581.02 
	522.32,581.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="524,580.42 524,579.7 524,578.98 523.88,578.62 523.64,579.46 523.64,579.94 523.76,580.42 
	523.88,580.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="521.6,578.14 522.2,578.02 522.32,571.54 522.08,571.06 521.72,571.06 521.6,571.42 521.6,571.9 
	521.36,575.14 521.48,578.14 521.6,578.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="522.8,577.9 522.92,571.9 522.92,571.42 523.04,571.18 523.28,570.94 523.64,571.18 523.64,572.74 
	523.52,574.9 523.52,575.74 523.52,576.82 523.52,577.66 522.8,577.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="523.16,567.7 522.56,567.7 522.08,567.94 521.84,568.42 521.84,569.26 521.96,569.86 522.44,570.1 
	523.04,570.1 523.52,569.38 523.52,568.18 523.28,567.94 523.16,567.82 522.92,567.58 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="520.52" y1="566.62" x2="524.12" y2="564.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="520.28,554.5 521.12,550.78 521.96,553.54 522.32,550.42 523.28,553.18 523.28,550.18 524,552.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="522.2,562.42 522.32,556.78 521.96,555.1 522.32,553.3 522.56,553.06 522.92,553.3 523.16,553.9 
	523.28,555.22 523.16,561.82 522.2,562.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="522.08,562.3 521.48,555.34 521.84,555.1 521.48,555.1 521.12,553.06 521,555.22 520.76,555.46 
	521,555.46 520.88,562.3 520.52,562.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="523.76,554.74 523.76,552.94 524,553.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="523.76,554.74 523.64,554.86 523.76,554.98 523.16,561.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="524,553.18 524.12,554.74 524.24,554.74 524.12,554.98 524.84,563.62 524,564.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="523.04,554.5 522.68,553.9 522.44,554.14 522.32,554.5 522.44,554.98 522.56,555.22 522.92,555.22 
	523.04,554.74 523.04,554.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="523.88,549.46 523.16,548.74 516.68,526.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="471.92,582.1 472.04,581.14 472.28,580.66 472.64,580.3 473.24,579.94 473.36,579.82 474.44,580.54 
	474.68,581.14 474.8,581.62 474.8,581.98 474.56,581.98 474.44,581.02 474.08,580.66 473.84,580.42 473.36,580.3 472.88,580.54 
	472.64,580.78 472.52,581.02 472.4,581.5 472.4,582.1 471.92,582.1 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M474.08,581.2c0-0.3-0.24-0.54-0.54-0.54S473,580.9,473,581.2s0.24,0.54,0.54,0.54S474.08,581.5,474.08,581.2"
	/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="473.48,586.54 473.6,582.94 473.36,582.46 473.24,582.22 473,582.1 472.88,582.22 472.76,582.46 
	472.76,582.94 472.76,584.38 472.64,586.66 473.48,586.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="473.72,586.66 473.6,582.58 473.72,582.22 473.84,582.1 474.08,582.1 474.2,582.46 474.2,586.54 
	473.72,586.54 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="516.8" y1="582.7" x2="516.92" y2="586.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="512.12" y1="586.54" x2="512" y2="582.46"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="515.72,562.42 515.72,556.3 515.84,555.34 516.08,555.1 516.32,554.98 517.16,555.1 517.28,555.82 
	517.4,557.14 517.4,558.7 517.4,560.02 517.28,562.54 515.6,562.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="516.92,555.46 516.56,555.34 516.32,555.46 516.08,555.58 515.96,555.82 516.08,556.42 516.32,556.66 
	516.68,556.66 516.92,556.42 517.16,555.94 516.92,555.58 516.8,555.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="486.44,572.38 486.56,571.78 486.8,571.42 486.92,571.18 487.4,570.94 487.4,570.82 488.24,571.3 
	488.36,571.78 488.48,572.02 488.48,572.38 488.24,572.38 488.24,571.66 488,571.42 487.76,571.18 487.52,571.18 487.16,571.3 
	487.04,571.54 486.92,571.66 486.8,572.02 486.8,572.38 486.56,572.38 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M487.88,571.72c0-0.17-0.13-0.3-0.3-0.3c-0.17,0-0.3,0.13-0.3,0.3s0.13,0.3,0.3,0.3
	C487.75,572.02,487.88,571.89,487.88,571.72"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="487.52,575.5 487.64,572.98 487.52,572.62 487.4,572.5 487.28,572.5 487.16,572.5 487.04,572.74 
	487.04,572.98 487.04,574.06 487.04,575.5 487.52,575.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="487.64,575.5 487.64,572.74 487.76,572.5 487.88,572.38 488,572.38 488,572.74 488.12,575.5 
	487.64,575.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="516.68,526.3 516.56,525.22 516.44,525.1 516.32,525.1 516.2,525.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="516.56,525.22 516.68,525.1 516.92,525.1 516.92,525.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="516.56,525.22 516.44,525.34 516.44,525.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="516.56,525.22 516.56,523.9 516.56,524.02 516.56,523.9 516.44,523.9 516.56,523.78 516.56,523.66 
	516.68,523.78 516.8,523.9 516.68,523.9 516.68,524.02 516.68,523.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="516.68,524.26 516.56,524.26 516.44,524.38 516.44,524.5 516.32,524.62 516.44,524.86 516.56,524.86 
	516.8,524.86 516.8,524.74 516.8,524.5 516.8,524.26 516.68,524.26 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="516.92" y1="524.38" x2="516.2" y2="524.62"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="468.8" y1="590.5" x2="468.44" y2="595.54"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="479.84,578.02 480.56,577.9 481.88,575.98 481.88,576.58 487.4,567.58 492.8,576.58 492.8,575.86 
	494.12,577.66 494.36,577.42 493.88,576.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="481.88,576.82 481.88,579.94 481.88,592.42 482.36,592.9 482.24,594.94 482.36,595.18 482.36,596.86 
	480.32,596.98 480.32,595.18 480.68,594.82 480.68,592.78 481.04,592.54 480.56,580.42 480.32,580.06 480.44,577.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="481.88" y1="579.1" x2="492.8" y2="579.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="492.8,576.58 492.92,592.54 492.68,593.14 492.92,595.06 492.56,595.18 492.56,596.98 494.6,596.86 
	494.6,594.94 494.24,594.7 494.36,592.9 494.12,592.42 494.12,579.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="495.08,581.98 495.2,581.02 495.44,580.54 495.68,580.3 496.4,579.82 497.48,580.42 497.84,581.14 
	497.96,581.5 497.96,581.98 497.6,581.98 497.48,581.02 497.24,580.54 496.88,580.3 496.52,580.18 496.04,580.42 495.8,580.78 
	495.56,581.02 495.56,581.38 495.44,581.98 495.08,581.98 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M497.24,581.08c0-0.3-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54s0.24,0.54,0.54,0.54
	C497,581.62,497.24,581.38,497.24,581.08"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="496.64,586.54 496.64,582.94 496.52,582.34 496.4,582.1 496.16,582.1 496.04,582.1 495.92,582.46 
	495.8,582.82 495.8,584.38 495.8,586.54 496.52,586.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="496.76,586.54 496.76,582.58 496.76,582.22 497,582.1 497.24,582.1 497.24,582.46 497.36,586.54 
	496.76,586.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="501.2,582.1 501.32,581.14 501.56,580.66 501.92,580.42 502.52,579.94 502.64,579.94 503.6,580.54 
	503.96,581.26 504.08,581.62 504.08,582.1 503.84,582.1 503.6,581.14 503.36,580.66 503.12,580.42 502.64,580.3 502.16,580.54 
	501.92,580.78 501.8,581.14 501.68,581.5 501.68,582.1 501.2,582.1 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M503.36,581.2c0-0.3-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54s0.24,0.54,0.54,0.54
	C503.12,581.74,503.36,581.5,503.36,581.2"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="502.76,586.66 502.76,582.94 502.64,582.46 502.52,582.22 502.28,582.22 502.16,582.22 502.04,582.58 
	502.04,582.94 501.92,584.5 501.92,586.66 502.76,586.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="502.88,586.66 502.88,582.7 503,582.34 503.12,582.1 503.36,582.1 503.48,582.58 503.48,586.66 
	502.88,586.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="506.96,581.98 507.2,581.02 507.44,580.54 507.68,580.3 508.28,579.82 508.4,579.82 509.48,580.42 
	509.84,581.14 509.96,581.5 509.96,581.98 509.6,581.98 509.48,581.02 509.12,580.54 508.88,580.3 508.52,580.18 507.92,580.42 
	507.68,580.78 507.56,581.02 507.56,581.38 507.44,581.98 507.08,581.98 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M509.12,581.08c0-0.3-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54s0.24,0.54,0.54,0.54
	C508.88,581.62,509.12,581.38,509.12,581.08"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="508.64,586.54 508.64,582.94 508.52,582.34 508.28,582.1 508.04,582.1 507.92,582.46 507.8,582.82 
	507.8,584.38 507.8,586.54 508.52,586.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="508.76,586.54 508.76,582.58 508.76,582.22 509,582.1 509.12,582.1 509.24,582.46 509.36,586.54 
	508.76,586.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="485,577.9 485.12,576.1 485,575.5 485,575.02 485.24,574.66 485.6,574.3 485.84,574.54 486.2,574.78 
	486.32,575.26 486.32,575.86 486.32,576.34 486.32,576.82 486.32,577.66 486.2,578.02 484.88,578.02 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M486.08,575.2c0-0.23-0.19-0.42-0.42-0.42c-0.23,0-0.42,0.19-0.42,0.42s0.19,0.42,0.42,0.42
	C485.89,575.62,486.08,575.43,486.08,575.2"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="485.72,577.3 485.72,576.46 485.72,576.1 485.96,575.98 486.08,576.22 486.08,576.82 486.08,577.3 
	485.24,577.3 485.24,576.22 485.36,575.98 485.6,576.22 485.72,576.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="485,575.26 484.64,575.26 484.64,574.78 485.12,574.42 485.48,574.18 485.72,574.06 486.08,574.3 
	486.44,574.54 486.56,574.9 486.56,575.26 486.32,575.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="488.72,578.02 488.72,576.22 488.6,575.5 488.72,575.02 488.96,574.66 489.32,574.42 489.56,574.54 
	489.8,574.9 489.92,575.26 489.92,575.86 489.92,576.34 489.92,576.94 489.92,577.66 489.92,578.14 488.6,578.14 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M489.68,575.2c0-0.23-0.19-0.42-0.42-0.42c-0.23,0-0.42,0.19-0.42,0.42s0.19,0.42,0.42,0.42
	C489.49,575.62,489.68,575.43,489.68,575.2"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="489.32,577.42 489.32,576.46 489.32,576.22 489.44,576.1 489.56,575.98 489.68,576.34 489.8,576.82 
	489.68,577.42 488.84,577.42 488.84,576.34 488.96,575.98 489.08,575.98 489.32,576.22 489.32,576.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="488.6,575.38 488.24,575.38 488.36,574.78 488.72,574.42 489.2,574.18 489.44,574.06 489.68,574.3 
	490.04,574.66 490.16,574.9 490.16,575.38 489.92,575.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="482.96,582.1 482.96,581.98 482.96,581.26 483.44,580.54 483.92,580.06 484.4,579.94 484.76,580.06 
	485.36,580.54 485.72,580.9 485.96,581.26 486.08,581.74 486.08,581.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="483.2,589.3 483.08,583.9 483.2,583.42 483.32,583.18 483.56,583.18 483.68,583.06 483.92,583.66 
	483.8,585.34 483.8,589.42 483.2,589.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="484.16,589.42 484.04,583.42 484.28,583.06 484.4,582.94 484.64,582.82 484.88,583.18 484.88,584.26 
	484.88,589.42 484.16,589.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="485.24,589.3 485.12,583.9 485.24,583.42 485.24,583.3 485.6,583.3 485.6,583.18 485.84,583.78 
	485.84,585.46 485.84,589.54 485.12,589.42 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M485.12,580.6c0-0.3-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54c0,0.3,0.24,0.54,0.54,0.54
	C484.88,581.14,485.12,580.9,485.12,580.6"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M485.96,582.16c0-0.3-0.24-0.54-0.54-0.54s-0.54,0.24-0.54,0.54c0,0.3,0.24,0.54,0.54,0.54
	S485.96,582.46,485.96,582.16"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M484.4,582.04c0-0.36-0.3-0.66-0.66-0.66s-0.66,0.3-0.66,0.66c0,0.36,0.3,0.66,0.66,0.66
	S484.4,582.4,484.4,582.04"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="482.96,582.1 482.24,581.98 482.36,581.02 482.72,580.42 483.32,579.7 484.4,579.34 485.6,579.7 
	486.2,580.3 486.56,581.38 486.68,581.98 486.08,581.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="488.84,581.98 488.84,581.86 488.96,581.14 489.32,580.42 489.92,580.06 490.4,579.82 490.76,579.94 
	491.36,580.42 491.72,580.9 491.96,581.26 492.08,581.74 492.08,581.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="489.2,589.18 489.08,583.78 489.2,583.3 489.2,583.18 489.56,583.06 489.8,583.66 489.8,585.22 
	489.8,589.42 489.08,589.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="490.16,589.3 490.04,583.42 490.16,582.94 490.28,582.82 490.64,582.82 490.88,583.18 490.88,584.14 
	490.88,589.3 490.16,589.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="491.12,589.3 491.12,583.9 491.12,583.42 491.24,583.3 491.6,583.18 491.84,583.66 491.84,585.34 
	491.84,589.42 491.12,589.42 "/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M491,580.6c0-0.3-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54c0,0.3,0.24,0.54,0.54,0.54
	C490.76,581.14,491,580.9,491,580.6"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M491.84,582.04c0-0.3-0.24-0.54-0.54-0.54s-0.54,0.24-0.54,0.54c0,0.3,0.24,0.54,0.54,0.54
	S491.84,582.34,491.84,582.04"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M490.4,581.92c0-0.36-0.3-0.66-0.66-0.66s-0.66,0.3-0.66,0.66c0,0.36,0.3,0.66,0.66,0.66
	S490.4,582.28,490.4,581.92"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="488.96,581.98 488.24,581.98 488.36,581.02 488.72,580.42 489.32,579.7 490.4,579.22 491.48,579.58 
	492.08,580.18 492.56,581.38 492.56,581.86 491.96,581.86 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="523.16" y1="591.58" x2="523.52" y2="586.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="525.44" y1="587.38" x2="525.68" y2="593.62"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="525.68" y1="587.62" x2="526.04" y2="593.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="525.44,593.02 525.32,587.74 524.84,587.74 524.6,587.74 524,588.1 523.88,588.22 523.64,588.58 
	523.52,588.7 523.52,594.94 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="524.36" y1="587.98" x2="524.48" y2="594.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="478.76,595.9 458.24,595.3 458.36,593.38 458.6,593.14 458.6,584.98 458.6,584.62 458.6,584.02 
	458.48,583.78 459.56,583.9 459.32,584.26 459.32,584.74 459.44,585.1 467.36,584.98 467.36,584.62 467.72,584.62 467.84,584.38 
	467.96,584.38 468.08,593.74 468.08,595.06 467.96,595.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="467.96,590.14 467.24,590.14 467.36,591.1 468.08,591.1 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="467.36" y1="590.98" x2="458.6" y2="590.98"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="459.32,590.98 459.32,590.14 458.6,590.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="469.52,595.3 469.52,593.74 469.4,593.86 469.4,593.5 469.64,593.26 469.52,590.98 469.52,590.74 
	469.28,590.26 470.36,589.54 472.28,586.78 474.92,590.14 475.28,590.5 475.16,593.5 475.4,593.74 475.28,595.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="476.48,595.3 476.48,594.7 476.72,594.7 476.84,591.7 477.08,591.46 477.32,591.46 477.56,591.46 
	477.92,591.46 478.16,591.58 478.28,594.7 478.28,595.42 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="476.72" y1="594.7" x2="478.16" y2="594.7"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="476.36" y1="594.82" x2="478.28" y2="594.82"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="476.36" y1="595.06" x2="478.28" y2="595.06"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="476.48,594.7 476.48,593.74 476.72,593.62 476.48,593.38 476.36,580.3 475.76,580.3 475.28,579.94 
	475.28,593.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="475.28,587.62 475.88,586.06 476.48,587.5 475.16,587.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="479.6,582.82 478.28,582.7 478.28,594.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="478.76,596.26 478.88,594.7 479,594.22 478.88,592.54 479.24,591.94 479.24,591.46 479,591.46 
	479.48,590.74 479.72,583.18 479.48,582.94 481.04,580.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="498.8,579.22 498.8,590.86 498.56,591.58 498.8,593.38 498.56,593.62 498.56,595.3 500.48,595.3 
	500.48,593.38 500.12,593.14 500.24,591.34 500.12,590.86 500,579.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="504.8,579.1 504.8,591.1 504.56,591.7 504.8,593.62 504.56,593.74 504.44,595.54 506.48,595.42 
	506.48,593.5 506.12,593.26 506.24,591.46 506.12,590.98 506,579.1 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="494" y1="579.22" x2="511.64" y2="578.98"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="494.12" y1="577.78" x2="513.08" y2="577.78"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="510.68,578.98 511.04,591.94 510.8,591.94 510.92,595.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="512,595.3 512,595.06 516.68,595.18 516.68,595.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="515.96,595.06 515.96,594.94 512.6,594.82 512.6,595.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="513.2,594.82 513.08,594.7 515.48,594.7 515.48,594.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="513.2,594.7 513.2,590.98 513.2,590.62 513.32,590.38 513.68,590.02 513.92,589.78 514.28,589.66 
	514.4,589.66 515.12,590.26 515.24,590.74 515.24,591.34 515.24,592.18 515.24,594.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="513.2,591.7 513.68,591.46 513.92,591.34 514.64,591.46 515.12,591.58 515.24,591.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="511.76,595.42 511.76,593.62 512.12,593.26 512,591.1 511.76,590.74 511.88,590.5 514.28,586.9 
	516.92,590.38 516.8,591.1 516.68,591.22 516.68,593.38 516.8,593.74 516.8,593.98 516.8,594.94 516.92,595.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="513.8,587.5 513.44,587.5 513.44,584.38 513.68,583.9 513.92,583.66 514.64,583.54 515,584.26 
	515.12,584.62 515.12,585.34 515.12,586.06 515.12,587.5 514.76,587.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="515.12,584.74 515.48,584.74 515.24,583.42 514.88,583.18 514.16,583.06 513.8,583.18 513.44,583.54 
	513.2,584.02 513.2,584.62 513.2,584.74 513.44,584.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="479.12,595.9 480.92,596.98 493.16,596.86 495.08,596.86 496.28,595.18 498.8,595.42 504.68,595.54 
	506.84,595.42 511.28,595.42 517.88,595.3 518.96,595.06 519.08,592.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="519.08,595.06 520.64,596.02 522.56,595.9 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="521.12" y1="593.74" x2="520.64" y2="596.38"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="699.44,688.66 702.8,671.02 699.68,668.5 695.72,685.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="699.68,668.26 705.2,663.94 702.8,671.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="702.56,670.66 724.16,671.62 723.32,664.18 705.68,663.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="724.16,671.38 724.28,672.94 745.16,672.58 743.12,665.74 723.2,665.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="743.36,666.58 761.12,665.74 763.88,672.82 745.28,672.94 745.04,672.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="761.24,665.62 758.12,664.3 740.12,664.42 743.24,665.74 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="740.72" y1="664.54" x2="723.56" y2="664.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="763.76" y1="672.7" x2="782.96" y2="672.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="782.96,671.86 780.8,664.54 761.12,665.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="780.68,664.42 777.2,663.46 758.24,664.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="782.96,671.86 805.28,671.02 809,668.38 800.72,663.82 781.28,663.7 777.32,662.86 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="781.64" y1="663.7" x2="782.96" y2="671.98"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="804.8" y1="670.78" x2="800.84" y2="686.38"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="808.64,668.5 804.44,683.98 800.48,686.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="705.68,664.06 704,630.22 699.56,630.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="701.12" y1="629.14" x2="703.88" y2="630.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="701.36,628.78 699.32,630.58 695.12,636.58 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="699.68" y1="629.98" x2="699.68" y2="668.38"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="704" y1="629.98" x2="706.16" y2="628.18"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="701.48" y1="629.02" x2="705.56" y2="628.3"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="704.12,630.46 802.16,630.82 803.48,627.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="802.04,630.34 804.92,630.94 806.6,631.3 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="801.32" y1="663.94" x2="801.32" y2="630.58"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="795.92,631.18 793.76,636.94 801.92,634.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="794.6,636.34 710.72,633.22 710.72,636.1 708.08,636.34 708.08,633.82 703.52,633.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="708.32,636.58 706.16,650.5 705.2,650.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="710.12,635.14 708.68,652.3 705.68,650.5 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="708.56" y1="652.06" x2="716.48" y2="651.82"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="719.72,651.82 772.52,650.62 775.76,641.14 781.28,641.26 781.28,647.26 781.16,647.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="781.28,647.02 775.16,659.14 772.52,649.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="782.12,641.74 796.4,641.5 797.36,646.66 781.28,647.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="797.6,646.54 792.8,657.58 775.16,658.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="794" y1="655.06" x2="794.24" y2="663.82"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="793.76" y1="636.94" x2="792.2" y2="641.5"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="762.56,634.9 763.76,638.5 767.96,638.38 767,635.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="764.12,638.38 760.76,653.26 760.16,650.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="767.96,637.9 762.8,652.78 760.76,652.66 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="760.04" y1="650.62" x2="762.8" y2="635.38"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="724.64" y1="673.3" x2="721.88" y2="688.42"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="697.04,679.78 694.64,680.5 694.52,676.54 696.44,675.94 697.04,680.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="745.28,672.94 743.84,673.18 740.24,687.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="723.2,688.42 724.16,684.58 727.04,684.46 726.2,687.82 725.96,688.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="790.28,663.46 787.16,660.58 781.64,661.3 784.52,663.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="763.88,673.18 759.2,687.22 740.12,687.1 726.44,686.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="759.44,685.9 778.52,685.42 782.84,671.86 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="777.8" y1="685.3" x2="800.84" y2="685.9"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="801.2" y1="663.94" x2="804.44" y2="670.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="726.2" y1="688.18" x2="699.44" y2="688.42"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="721.28,661.66 719.24,663.34 716.36,662.98 714.56,661.42 710.84,660.22 710.36,658.66 712.16,657.22 
	711.44,655.3 711.44,653.62 714.8,653.02 717.44,651.22 719.36,651.34 720.68,653.26 722.12,653.98 722.72,655.78 722.72,658.78 
	723.08,660.7 721.76,661.66 721.16,662.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="722.36,661.18 723.08,662.26 725.36,663.22 727.4,662.74 729.2,662.38 731.24,663.82 732.68,663.94 
	735.2,662.26 736.16,661.06 734.36,659.26 732.44,658.18 733.04,655.42 731.84,653.38 728.72,652.66 724.52,653.38 723.68,655.66 
	722.84,656.5 722.72,656.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="744.32,661.66 742.76,663.1 741.8,663.58 740.72,662.02 741.32,660.1 741.08,658.18 740.48,656.14 
	740.84,653.86 742.04,652.42 744.32,651.82 746.36,652.78 748.4,654.7 748.76,656.5 748.16,659.86 747.92,661.42 747.08,662.98 
	746,663.58 745.04,662.62 744.2,662.26 743.96,662.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="701.96,537.7 704.84,521.62 707,517.9 710.6,519.34 708.68,524.62 704.48,522.22 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="708.8" y1="524.26" x2="705.92" y2="540.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="707.96,523.9 736.04,525.22 735.32,517.42 711.8,516.58 710.6,519.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="712.4" y1="516.58" x2="706.64" y2="518.38"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="713.12,516.94 712.04,485.02 704,486.7 701.48,504.22 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="704.36" y1="486.58" x2="704.96" y2="521.38"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="712.04,484.78 720.32,487.54 720.32,516.22 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="712.64" y1="497.14" x2="704.6" y2="500.14"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="712.64" y1="497.02" x2="720.2" y2="502.06"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="712.28" y1="484.18" x2="705.92" y2="482.02"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="704.6" y1="486.7" x2="706.28" y2="482.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="720.56,487.78 726.56,488.5 726.32,481.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="725.48,488.5 750.08,488.02 748.52,481.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="743.24,488.02 744.08,490.54 740,491.02 739.16,488.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="740.6,491.26 740.48,507.7 719.48,506.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="744.32,490.18 743.24,507.82 740,507.46 737.84,502.06 739.4,487.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="748.76,487.18 764.36,487.42 764.6,484.42 762.68,481.66 763.88,486.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="764.72,484.18 767.12,484.18 765.8,482.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="766.64,483.94 766.64,487.54 805.16,487.42 815.96,480.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="805.76,487.54 805.52,500.14 810.92,495.22 817.52,497.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="806.24,495.7 807.32,516.22 815,510.46 820.88,515.14 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="815" y1="510.46" x2="811.28" y2="495.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="807.08,515.38 807.08,523.9 815.12,522.82 821.12,519.34 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="817.52" y1="522.1" x2="818.36" y2="540.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="808.76,524.26 795.56,523.54 794.12,516.34 807.56,516.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="793.88,516.46 791.12,515.38 782.84,515.5 783.44,516.7 794.36,516.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="796.16,523.42 775.4,523.54 773.72,516.94 783.44,516.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="773.72,516.58 753.2,517.18 755.72,525.58 775.88,524.74 774.8,522.34 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="754.04" y1="517.78" x2="735.2" y2="518.02"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="736.04,525.34 756.56,525.1 754.16,540.58 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="736.28" y1="524.5" x2="734.96" y2="540.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="732.56,540.1 733.04,536.86 731.12,537.34 731.12,540.58 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="776.24" y1="524.26" x2="772.4" y2="540.7"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="794.72" y1="523.42" x2="791.72" y2="539.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="805.88,486.58 800.48,507.58 792.08,507.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="793.4,494.74 778.16,493.66 780.8,500.74 794.48,501.58 792.8,494.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="794.96,501.82 793.52,516.7 779.6,516.46 781.04,500.26 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="778.16" y1="493.18" x2="774.32" y2="498.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="773.72,516.94 774.8,507.58 772.4,501.94 757.64,501.82 759.8,506.5 759.2,515.26 759.32,516.94 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="774.2" y1="518.86" x2="774.32" y2="518.86"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="755.12,517.18 754.28,517.06 754.64,504.34 757.88,501.1 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="742.88" y1="507.58" x2="755.96" y2="507.82"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="774.56" y1="498.82" x2="774.32" y2="506.86"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="623.24,627.7 623.12,627.7 623.12,627.94 623.36,627.94 623.6,628.06 623.6,628.18 623.6,628.42 
	623.36,628.66 623.12,628.78 622.88,628.9 622.52,629.02 621.92,629.02 621.56,629.02 621.56,629.14 621.92,629.26 622.76,629.26 
	622.52,629.62 621.56,629.62 621.56,629.74 622.4,629.74 622.76,629.86 622.88,630.22 622.88,630.82 622.76,631.06 622.16,631.06 
	621.68,631.3 621.56,631.54 622.76,631.42 622.88,631.42 621.92,631.66 621.44,631.78 621.44,632.02 622.4,631.9 623.12,631.66 
	623.48,631.66 623.12,632.02 622.4,632.5 622.28,632.86 622.76,632.86 623.24,632.5 623.96,632.26 624.2,631.54 624.2,630.82 
	624.2,630.34 624.08,629.98 623.96,629.62 624.08,629.02 624.32,628.66 624.44,628.18 624.56,628.18 624.8,627.94 624.8,627.82 
	624.68,627.82 624.44,627.82 624.32,627.82 623.96,627.7 623.84,627.58 623.48,627.46 623.24,627.46 623.12,627.46 623.24,627.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="622.16,629.02 622.16,628.42 620.48,628.42 620.48,632.62 621.92,632.5 621.92,631.9 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="621.92" y1="631.18" x2="622.04" y2="629.74"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="619.04,497.14 618.92,497.26 618.8,497.26 618.68,497.5 618.68,497.62 618.8,497.62 618.8,497.74 
	618.8,497.86 618.92,497.86 619.04,497.86 619.16,497.74 619.16,497.62 619.28,497.5 619.16,497.5 619.16,497.38 619.04,497.26 
	619.04,497.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.8,497.86 618.8,497.98 618.8,498.1 618.68,498.1 618.56,498.1 618.44,498.1 618.44,500.74 
	619.76,500.74 619.52,497.98 619.16,497.98 619.16,497.86 619.16,497.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="587.36,526.3 587,525.34 591.8,525.22 592.04,526.42 587.24,526.42 587.24,525.82 587.12,525.94 
	586.76,526.3 586.64,526.66 586.76,526.9 586.88,526.66 586.88,526.54 587.12,526.18 587.24,526.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="591.92,525.94 591.68,526.3 591.56,526.78 591.68,527.14 591.92,527.14 591.8,526.9 591.68,526.66 
	591.8,526.3 591.92,525.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="592.04,526.42 591.92,527.26 586.88,527.02 587.24,526.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="587.24,527.02 587.12,527.38 587.12,527.74 587.24,527.86 587.24,527.74 587.24,527.5 587.24,527.26 
	587.36,527.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="591.68,527.26 591.56,527.62 591.68,527.86 591.92,527.98 591.8,527.86 591.8,527.62 591.8,527.38 
	591.8,527.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.32,546.7 623.48,546.22 623.36,545.5 618.32,545.86 618.32,546.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.32,545.38 623.24,545.14 623.24,544.42 618.2,544.78 618.2,545.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.56,544.66 618.56,544.54 618.8,544.54 618.92,544.54 618.92,544.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.56,545.38 618.56,545.74 618.68,545.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.68,546.58 618.68,546.94 618.56,547.18 618.8,547.18 618.8,546.7 618.92,546.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.92,545.38 618.92,545.74 619.04,545.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.32,545.86 618.08,546.46 618.2,546.46 618.32,546.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="622.16,544.42 622.16,544.3 622.4,544.3 622.4,544.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="622.04,545.14 622.04,545.5 622.16,545.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="622.4,545.14 622.4,545.38 622.52,545.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="623.36,546.34 623.24,546.82 623,546.82 623.12,546.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="646.04,566.14 646.64,566.14 647.12,566.5 647.96,566.86 648.56,567.58 648.92,567.58 649.16,567.1 
	648.68,566.5 647.72,565.9 646.64,565.66 646.28,565.66 646.16,566.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="646.16,566.14 646.04,566.38 646.04,566.62 646.16,566.5 646.16,566.26 646.16,566.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="648.56,567.58 648.44,567.94 648.44,568.06 648.56,567.94 648.56,567.7 648.56,567.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="649.04,566.98 649.16,566.38 648.92,566.14 648.2,565.78 647.72,565.54 646.76,565.3 646.52,565.3 
	646.4,565.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="650.96,569.5 651.56,569.14 651.92,569.62 653.12,571.18 652.64,571.54 650.96,569.38 650.84,569.98 
	650.84,570.22 650.96,570.1 650.96,569.86 651.08,569.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="652.76,571.54 652.64,572.02 652.52,572.02 652.4,571.78 652.52,571.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="651.56,569.26 651.68,568.78 653.12,570.22 653.24,570.58 653.36,570.82 653.12,571.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="653.48,595.06 652.76,597.46 653,597.82 653.84,597.7 654.08,596.74 654.2,595.66 654.2,594.94 
	653.24,595.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="651.32,600.46 650.12,601.42 648.56,602.14 647.84,602.38 648.08,602.98 648.44,602.98 652.04,601.18 
	651.32,600.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="629.36,606.34 629.24,605.74 631.16,605.74 632,605.74 632,606.22 632,606.34 630.8,606.34 
	629.24,606.46 629.48,606.58 632.24,606.58 632,606.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="632.72,606.22 632.84,605.86 636.2,605.86 636.08,606.34 632.6,606.22 632.96,606.58 636.2,606.58 
	636.08,606.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="636.2,605.86 636.2,606.1 636.2,606.22 636.08,606.22 636.08,606.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="609.8,606.58 612.68,606.58 612.56,606.22 609.8,606.22 609.8,606.58 609.92,606.7 612.8,606.7 
	612.68,606.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="605.48,606.1 605.48,606.58 608.6,606.58 608.6,606.22 605.48,606.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="605.48,606.58 605.6,606.82 608.84,606.82 608.6,606.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="614.72,493.06 623.72,492.94 623.6,493.18 614.72,493.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="604.52,645.46 603.56,645.58 603.32,646.06 603.68,646.9 602.72,647.98 604.28,648.1 604.04,648.82 
	603.8,649.78 605.48,649.66 605.48,651.1 606.32,650.98 606.68,651.7 606.56,652.54 607.64,652.3 607.64,653.86 608.48,653.5 
	608.6,654.22 609.44,653.86 610.64,654.58 611.24,653.5 612.2,654.1 612.68,653.62 612.92,653.02 613.76,653.62 614,651.46 
	611.72,652.42 611.48,651.58 610.4,652.3 609.8,651.34 608.48,651.94 607.88,650.62 606.8,650.62 606.92,649.42 605.84,649.18 
	605.96,647.74 604.76,647.62 605.72,646.66 604.4,645.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="592.76,660.7 591.2,660.94 591.08,659.86 591.44,658.18 592.4,656.86 592.64,655.42 593,654.1 
	593.72,652.54 595.16,650.62 596,649.54 597.32,649.54 596.36,650.38 595.52,650.86 594.32,652.66 593.84,653.74 593.6,656.14 
	593.36,658.66 593.12,660.46 592.88,660.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="645.92,650.5 646.52,649.54 647.96,649.66 648.8,650.14 649.16,651.46 650.12,651.58 650.72,652.66 
	650.72,653.86 651.8,654.46 651.8,656.26 651.56,657.1 651.8,658.42 650.84,658.66 650.12,658.18 650.36,656.38 649.76,654.94 
	648.92,653.26 647.96,651.58 646.52,651.1 645.92,650.74 645.8,650.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="631.4,653.98 631.28,651.82 631.88,652.78 632.84,653.02 633.2,651.82 634.16,653.02 634.64,651.94 
	635.6,652.18 636.2,651.22 637.4,651.1 637.16,649.9 638.48,649.78 638.24,649.3 638.36,648.94 639.08,647.98 638.6,647.62 
	639.08,646.18 637.88,645.82 639.44,644.86 638.84,644.26 640.52,644.74 639.8,646.3 640.88,647.02 640.28,647.38 641.36,648.82 
	640.4,649.18 640.28,649.9 639.08,650.26 638.84,651.1 638.24,650.98 638.36,652.3 637.28,651.94 637.16,653.02 636.44,652.66 
	636.2,653.98 635.36,653.26 635.12,654.34 634.28,653.5 633.56,654.58 633.2,653.86 632.6,654.58 632.12,653.62 631.52,654.82 
	631.4,653.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="703.4,453.82 706.04,440.26 710.84,444.1 707.96,458.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="710.84,444.1 714.32,436.9 713.48,436.18 705.92,440.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="710.6,443.98 732.32,444.46 731.36,436.9 714.44,437.26 719.24,433.3 802.04,432.94 813.32,436.18 
	819.8,441.58 811.04,455.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="813.08,435.58 807.56,402.34 813.8,398.62 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="807.56" y1="402.7" x2="722.96" y2="405.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="715.88,405.58 708.32,405.82 707.72,404.02 702.8,402.1 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="707" y1="440.5" x2="705.2" y2="402.46"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="713.48" y1="434.98" x2="710.6" y2="405.82"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="719.12,432.82 718.52,429.22 712.16,426.34 705.44,428.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="718.28,428.86 719.12,428.5 716.6,415.54 711.8,412.3 706.04,415.54 704.24,426.34 703.52,452.86 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="704.24" y1="427.3" x2="703.16" y2="401.74"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="731.6,436.9 789.44,437.14 790.88,443.62 731.84,443.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="752,443.86 751.4,436.78 747.56,433.06 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="731" y1="437.26" x2="728.96" y2="432.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="791,443.02 791.6,435.34 786.08,432.82 789.08,436.54 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="791.6" y1="435.82" x2="813.56" y2="436.18"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="819.92,440.14 813.32,397.9 722.72,401.02 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="715.16" y1="401.26" x2="702.44" y2="401.74"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="807.56,402.46 800.84,410.02 755.48,411.22 751.04,409.42 749.36,409.42 748.16,410.62 746.6,412.54 
	746,413.5 743.48,404.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="746.36,413.62 746.36,416.5 754.88,415.42 754.28,410.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="755.24,416.02 750.8,430.9 741.44,430.78 745.64,414.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="742.04,404.98 743.36,408.82 742.04,412.9 731.24,412.3 729.44,410.26 727.4,412.9 723.32,412.18 
	722.72,400.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="723.56,411.94 723.56,428.26 725.84,428.02 728,413.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="726.8,426.94 728.12,430.78 732.92,430.42 734.48,417.1 732.92,415.78 730.52,418.3 728.72,429.58 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="729.92" y1="418.06" x2="727.16" y2="411.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="733.16,427.54 741.44,425.38 741.56,430.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="752.24,426.94 777.32,428.26 776.96,426.58 781.4,426.1 781.04,428.62 783.92,428.5 787.52,426.58 
	791,426.58 794.48,432.82 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="710.84" y1="405.22" x2="715.04" y2="401.74"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="715.16,401.14 717.68,415.06 716.6,415.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="800,409.42 803.24,421.42 810.44,418.9 817.04,420.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="803.12,421.18 798.8,422.38 801.92,432.94 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="800" y1="410.5" x2="789.92" y2="426.58"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="541.28,464.14 538.76,453.22 537.8,451.42 538.16,449.02 538.76,448.3 540.92,448.06 542.6,448.9 
	544.04,450.22 546.44,463.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="539.48,448.66 536,441.94 533.96,445.18 528.68,441.82 484.76,442.18 482.96,451.42 482.6,454.3 
	538.88,454.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="517.28,453.94 517.52,444.94 521.24,440.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="503.84,441.82 499.52,445.54 500.24,455.98 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="482.24" y1="454.78" x2="487.28" y2="465.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="500.24" y1="454.78" x2="503.24" y2="465.58"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="517.16" y1="453.58" x2="522.68" y2="466.54"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="536.6,441.34 537.2,419.26 532.4,422.62 528.68,441.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="536.72,420.22 542.6,422.74 544.04,451.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="542.12" y1="422.38" x2="543.68" y2="337.3"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="533.84,420.94 534.8,393.82 539.36,390.46 542.48,392.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="533.24,393.7 535.76,367.18 539.96,362.86 543.8,364.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="536.36,367.9 536,354.94 529.52,359.26 519.68,356.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="528.92,358.9 525.92,353.62 520.04,356.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="526.52,353.62 538.76,341.26 540.32,362.98 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="544.64" y1="337.54" x2="547.04" y2="349.3"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="543.08" y1="365.02" x2="545.6" y2="379.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="542.24" y1="391.66" x2="547.04" y2="404.26"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="543.2" y1="421.66" x2="548.12" y2="437.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="534.68,380.38 533.6,375.34 464,444.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="484.16,445.78 464.36,445.78 462.2,453.34 482.24,453.34 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="463.76" y1="454.3" x2="469.04" y2="465.34"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="461.24,453.7 441.32,453.34 442.64,444.82 447.68,442.66 467.84,442.66 465.56,445.78 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="443.24" y1="452.74" x2="449.6" y2="467.14"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="441.44,452.38 413.48,452.98 411.56,450.58 417.68,447.22 439.52,436.18 444.8,439.54 447.56,443.5 
	"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="412.88" y1="451.18" x2="422" y2="468.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="437.96,435.1 456.32,415.3 465.8,416.98 467.36,423.7 471.92,436.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="457.52,415.78 471.92,400.18 475.04,395.86 480.44,399.82 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="476" y1="395.62" x2="465.2" y2="416.98"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="480.56" y1="399.34" x2="485.36" y2="422.02"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="528.56" y1="359.74" x2="530.12" y2="377.98"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="529.28" y1="358.78" x2="482.48" y2="403.9"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="501.68,386.5 500.84,383.14 495.08,378.58 492.56,380.98 493.52,384.1 502.04,385.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="495.92,378.7 502.4,372.46 507.56,375.58 508.52,379.54 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="506" y1="373.66" x2="523.4" y2="357.58"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="633.32,835.06 634.76,824.5 643.52,818.38 639.8,827.38 634.76,824.02 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="639.68" y1="826.66" x2="636.92" y2="838.66"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="639.2,826.66 662.36,827.02 661.88,819.22 662.12,812.98 649.28,814.54 643.4,818.5 641.6,776.02 
	634.76,778.18 632.36,783.58 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="635.24" y1="777.94" x2="635.72" y2="824.5"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="650.48,814.3 648.32,777.46 641.6,776.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="649.04,803.38 642.32,799.9 635.12,803.38 632.72,809.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="662,813.34 671.72,814.78 682.16,819.82 687.08,828.1 689.48,824.86 682.4,819.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="686.6,827.98 661.88,826.78 660.56,838.06 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="687.08" y1="827.62" x2="683.36" y2="836.86"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="681.68,818.86 677.72,718.54 684.08,717.94 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="677.96" y1="717.7" x2="678.08" y2="711.7"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="677.96,718.3 636.8,716.38 635,713.38 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="636.68" y1="716.26" x2="631.4" y2="716.74"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="637.88,716.38 644,725.5 673.16,724.3 677.72,718.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="673.76" y1="724.3" x2="672.68" y2="814.06"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="648.8" y1="776.5" x2="644.72" y2="726.1"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="647.6,762.94 633.56,762.1 632,765.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="632.96,762.1 633.32,756.1 641.6,750.94 646.76,756.1 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="641.36" y1="750.1" x2="638.12" y2="716.38"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="635.96" y1="761.5" x2="636.2" y2="777.46"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="646.76" y1="740.98" x2="656.6" y2="740.5"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="661.76" y1="740.38" x2="673.28" y2="739.9"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="660.68" y1="724.3" x2="659.96" y2="739.06"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="660.68,725.26 659.36,714.7 653.12,712.3 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="660.92,758.02 658.52,758.5 654.2,755.62 654.2,754.3 656.12,752.62 655.4,751.66 653.6,750.1 
	654.32,747.1 657.68,744.1 656.72,742.66 656.48,738.7 658.76,738.1 662.12,740.62 665.96,742.06 667.52,741.82 669.32,745.42 
	667.28,749.5 667.88,753.1 666.08,756.1 663.08,756.1 662.96,758.02 661.76,759.1 660.56,758.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="658.76,790.78 657.68,791.62 653.36,793.42 653.12,791.02 651.56,789.82 651.92,786.22 655.76,785.38 
	656.36,784.18 656.96,780.82 659.72,779.62 661.16,782.38 663.56,783.22 665.72,784.06 666.92,787.78 666.92,790.18 664.52,791.98 
	663.08,790.18 660.08,790.42 657.92,791.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="659.12,780.22 654.56,778.42 654.8,773.98 656.12,771.46 653.96,768.58 657.32,762.7 657.92,759.22 
	661.28,759.22 663.08,762.82 665.72,763.42 666.92,767.26 667.16,772.18 665.72,776.26 662.72,776.38 661.76,778.66 658.88,779.98 
	"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="620.96,837.46 621.32,826.9 617.24,820.06 625.16,823.9 624.92,835.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="618.32,819.7 610.88,816.1 585.8,815.62 577.76,820.06 572.36,823.9 576.2,827.98 578.36,819.34 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="576.56" y1="827.5" x2="575.96" y2="836.38"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="572.96" y1="824.14" x2="571.52" y2="834.22"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="577.04" y1="827.38" x2="621.08" y2="827.98"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="599.36,836.02 598.16,828.34 599.72,822.34 598.16,819.46 599.72,816.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="599.36" y1="821.26" x2="578.6" y2="820.06"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="598.76,821.5 618.92,819.94 616.88,720.34 618.44,713.26 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="616.88" y1="720.34" x2="623.48" y2="718.9"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="615.92,720.94 611.84,725.62 612.2,815.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="611.48,725.62 580.76,724.54 585.68,815.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="580.76,724.18 575.96,720.94 573.2,714.94 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="575.96" y1="720.7" x2="568.76" y2="720.1"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="575.96" y1="720.58" x2="578.84" y2="818.86"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="576.44" y1="728.38" x2="569.36" y2="731.26"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="575.6" y1="728.26" x2="580.76" y2="733.3"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="577.16" y1="748.54" x2="569.72" y2="754.42"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="576.8" y1="749.38" x2="582.44" y2="752.14"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="576.8" y1="766.06" x2="570.56" y2="774.1"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="576.8" y1="766.06" x2="583.4" y2="769.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="578.6" y1="803.14" x2="571.52" y2="807.34"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="578.24" y1="803.74" x2="585.44" y2="806.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="612.08,742.3 586.4,741.1 580.64,724.18 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="586.28" y1="740.5" x2="586.4" y2="815.98"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="599,771.94 604.64,768.94 604.4,777.34 598.28,779.5 598.88,771.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="604.04,777.1 604.04,782.02 599,782.02 598.64,779.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="603.68,782.74 598.16,784.78 598.16,793.42 603.44,790.9 603.32,797.38 597.32,797.5 598.04,792.94 
	"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="603.08" y1="791.14" x2="603.68" y2="782.26"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="600.92,740.74 597.32,724.54 595.76,718.42 597.32,714.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="576.92,719.74 595.76,718.3 617.12,720.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="605.12,804.94 603.56,801.7 600.32,801.82 598.16,803.26 597.44,803.38 593.48,802.3 591.56,803.86 
	588.92,805.9 588.68,807.46 589.52,811.3 590.48,813.46 591.92,814.42 594.68,814.54 597.8,814.54 599.84,814.42 603.44,815.02 
	605.24,814.18 606.32,813.34 607.4,812.38 606.44,809.02 605.84,808.42 605.84,807.58 605.12,806.14 605.36,805.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="609.92,794.02 608.96,791.74 607.16,791.74 605.48,792.22 605.24,794.5 605.12,796.06 604.16,797.14 
	603.8,797.38 604.28,799.54 606.56,800.98 608,800.38 609.8,796.78 610.4,794.86 610.04,793.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="595.52,764.02 596.6,763.66 599,765.22 599.72,766.54 602.24,765.46 603.32,762.22 603.56,758.5 
	600.44,752.98 597.32,752.02 596.12,753.7 595.4,756.46 593.72,758.62 592.64,760.3 593,763.54 593.72,764.5 596,764.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="545.12,692.5 541.04,677.14 545,675.7 548.72,690.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="541.52,677.26 538.28,670.3 545.24,675.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="538.4,671.14 533.6,665.02 528.68,664.9 528.08,668.02 538.4,670.9 521.72,671.62 522.56,668.62 
	528.32,667.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="534.2,665.26 534.68,661.78 539,659.26 546.68,661.9 544.64,675.34 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="546.44" y1="661.78" x2="547.76" y2="664.78"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="546.44,661.66 547.16,633.22 547.28,628.66 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="544.16" y1="629.86" x2="543.56" y2="625.54"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="544.52,630.22 537.68,638.02 535.52,638.74 535.64,649.06 533.24,648.82 532.88,660.7 534.92,661.06 
	"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="533" y1="660.34" x2="532.88" y2="665.62"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="544.52,630.34 505.64,629.86 503.12,626.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="500.72,629.14 505.4,629.5 514.76,638.5 535.28,638.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="505.76,630.1 505.4,645.46 511.28,649.42 512.48,669.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="505.16,645.46 499.04,649.06 500.84,669.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="499.52,648.1 500.72,637.9 506.12,630.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="499.88,645.46 472.88,645.1 472.76,638.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="499.04,648.22 472.76,647.86 473.36,644.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="500,657.46 475.52,657.94 472.76,647.38 470.6,661.18 464.24,656.38 458.72,660.34 460.04,671.5 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="470.96" y1="660.58" x2="471.44" y2="670.78"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="459.32,660.34 463.64,645.22 465.8,641.38 472.64,638.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="466.28,640.9 467.36,638.5 467.48,636.22 467.24,634.54 467.24,634.18 467.96,635.98 469.04,636.58 
	470.72,636.34 471.32,636.1 471.68,638.02 471.56,638.98 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="468.32" y1="635.74" x2="471.68" y2="629.86"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="470.12" y1="626.5" x2="467.48" y2="634.54"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="467.12,634.9 429.8,635.74 425.6,628.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="423.68,632.02 430.04,635.62 429.56,672.22 424.52,677.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="429.56,672.58 429.2,681.34 424.76,676.9 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="428.72" y1="680.98" x2="428.36" y2="694.42"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="429.92,672.46 467.84,672.7 468.8,670.78 438.92,670.18 429.8,671.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="438.44,669.7 436.04,639.58 430.52,635.74 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="436.88" y1="640.18" x2="465.32" y2="640.54"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="436.52,639.46 443.24,653.98 462.32,651.94 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="443.72" y1="653.62" x2="443.48" y2="670.42"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="468.92" y1="670.78" x2="521.12" y2="669.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="467.6,672.58 467.12,681.1 428.96,680.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="446.96,681.1 448.04,672.58 453.56,670.06 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="446.84" y1="681.22" x2="452.48" y2="694.66"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="472.4" y1="694.3" x2="467" y2="680.38"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="467.12,680.02 505.64,679.18 505.64,672.22 508.64,669.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="521.96,671.62 521.72,679.06 525.44,692.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="535.64,689.26 535.04,691.06 543.56,690.34 543.44,688.9 535.64,689.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="540.2,677.38 521.48,679.18 505.52,679.42 509.12,693.46 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="623.96" y1="601.9" x2="623.96" y2="569.86"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="614.12,475.66 614.36,479.02 623.12,478.54 623,475.3 614.12,475.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="614.12,476.86 623.12,476.62 623,476.98 614.12,477.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="616.04,476.98 616.04,477.82 619.28,477.7 619.28,477.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="616.16,476.74 616.16,476.14 622.28,476.02 622.4,476.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="617.36,493.66 617.36,494.26 621.44,494.14 621.32,493.42 617.36,493.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="615.08,493.54 617.24,493.42 617.24,493.9 615.08,493.9 615.08,493.42 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="598.28,514.06 598.04,506.98 598.16,506.98 598.28,506.98 598.52,506.98 598.52,506.74 598.4,506.74 
	598.28,506.74 598.16,506.74 597.92,506.86 597.92,506.98 598.16,514.06 598.4,513.94 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="640.28,513.94 639.92,506.86 640.16,506.74 640.28,506.74 640.4,506.86 640.4,506.62 640.28,506.62 
	640.04,506.62 639.92,506.74 639.8,506.86 640.16,513.82 640.28,513.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="684.2,579.46 683.36,579.46 683.36,585.7 684.2,585.7 684.2,579.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="685.4,579.34 684.56,579.34 684.56,585.7 685.4,585.7 685.4,579.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="686.6,579.46 685.76,579.46 685.76,585.7 686.6,585.7 686.6,579.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="687.92,579.46 686.96,579.46 686.96,585.82 687.92,585.82 687.92,579.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="689.12,579.46 688.28,579.46 688.28,585.82 689.12,585.82 689.12,579.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="690.32,579.46 689.48,579.46 689.48,585.82 690.32,585.82 690.32,579.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="691.64,579.46 690.8,579.46 690.8,585.82 691.64,585.82 691.64,579.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.48,577.9 665.24,591.46 664.04,591.58 662.84,590.98 662.48,590.5 662.36,589.66 662,588.7 
	661.64,587.5 661.64,587.02 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.48,577.78 664.04,577.66 662.84,577.66 662.12,578.02 661.88,578.38 661.76,578.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="600.2,657.58 599.96,650.5 600.08,650.5 600.2,650.5 600.44,650.5 600.44,650.26 600.32,650.26 
	600.2,650.26 600.08,650.26 599.84,650.38 599.84,650.5 600.08,657.58 600.32,657.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="643.64,657.1 643.28,650.02 643.52,650.02 643.64,649.9 643.88,650.02 643.76,649.78 643.64,649.78 
	643.4,649.78 643.28,649.9 643.16,650.02 643.52,657.1 643.64,656.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="563.6,473.62 571.76,473.62 571.76,474.7 563.6,474.7 563.6,473.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="563.6,471.7 571.64,471.7 571.64,472.78 563.6,472.78 563.6,471.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="563.6,469.9 571.64,469.9 571.64,470.98 563.6,470.98 563.6,469.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="563.48,468.22 571.52,468.22 571.52,469.3 563.48,469.3 563.48,468.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="536.36,474.58 544.52,474.58 544.52,475.66 536.36,475.66 536.36,474.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="536.24,472.66 544.4,472.66 544.4,473.74 536.24,473.74 536.24,472.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="536.24,470.86 544.4,470.86 544.4,471.94 536.24,471.94 536.24,470.86 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="536.12,469.06 544.28,469.06 544.28,470.14 536.12,470.14 536.12,469.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="700.4,700.18 708.44,700.18 708.44,701.26 700.4,701.26 700.4,700.18 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="700.28,698.38 708.32,698.38 708.32,699.46 700.28,699.46 700.28,698.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="700.28,696.46 708.32,696.46 708.32,697.54 700.28,697.54 700.28,696.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="700.16,694.78 708.2,694.78 708.2,695.86 700.16,695.86 700.16,694.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.72,707.62 673.76,707.62 673.76,708.7 665.72,708.7 665.72,707.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.6,705.7 673.64,705.7 673.64,706.78 665.6,706.78 665.6,705.7 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.6,703.9 673.64,703.9 673.64,704.98 665.6,704.98 665.6,703.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="665.48,702.1 673.52,702.1 673.52,703.18 665.48,703.18 665.48,702.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="565.16,706.06 573.2,706.06 573.2,707.14 565.16,707.14 565.16,706.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="565.04,704.26 573.08,704.26 573.08,705.34 565.04,705.34 565.04,704.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="565.04,702.34 573.08,702.34 573.08,703.54 565.04,703.54 565.04,702.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="564.92,700.66 572.96,700.66 572.96,701.74 564.92,701.74 564.92,700.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="530.84,704.5 538.88,704.5 538.88,705.58 530.84,705.58 530.84,704.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="530.72,702.58 538.76,702.58 538.76,703.66 530.72,703.66 530.72,702.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="530.72,700.78 538.76,700.78 538.76,701.86 530.72,701.86 530.72,700.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="530.6,699.1 538.64,699.1 538.64,700.18 530.6,700.18 530.6,699.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="530.84,710.38 538.88,710.38 538.88,711.46 530.84,711.46 530.84,710.38 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="530.84,708.58 538.88,708.58 538.88,709.66 530.84,709.66 530.84,708.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="530.72,706.9 538.76,706.9 538.76,707.98 530.72,707.98 530.72,706.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="565.28,710.5 573.32,710.5 573.32,711.58 565.28,711.58 565.28,710.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="565.16,708.82 573.2,708.82 573.2,709.9 565.16,709.9 565.16,708.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="558.92,489.1 558.92,480.94 560,480.94 560,489.1 558.92,489.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="557,489.22 557,481.06 558.08,481.06 558.08,489.22 557,489.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="555.2,489.22 555.2,481.06 556.28,481.06 556.28,489.22 555.2,489.22 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="553.52,489.34 553.52,481.18 554.6,481.18 554.6,489.34 553.52,489.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="689.36,493.54 689.36,485.5 690.44,485.5 690.44,493.54 689.36,493.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="687.44,493.66 687.44,485.62 688.52,485.62 688.52,493.66 687.44,493.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="685.64,493.66 685.64,485.62 686.72,485.62 686.72,493.66 685.64,493.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="683.96,493.78 683.96,485.74 685.04,485.74 685.04,493.78 683.96,493.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="689.96,688.66 689.96,680.62 691.04,680.62 691.04,688.66 689.96,688.66 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="688.04,688.78 688.04,680.74 689.12,680.74 689.12,688.78 688.04,688.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="686.24,688.78 686.24,680.74 687.32,680.74 687.32,688.78 686.24,688.78 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="684.56,688.9 684.56,680.86 685.64,680.86 685.64,688.9 684.56,688.9 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="692,716.5 692,708.46 693.08,708.46 693.08,716.5 692,716.5 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="690.2,716.62 690.2,708.58 691.28,708.58 691.28,716.62 690.2,716.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="688.28,716.62 688.28,708.58 689.36,708.58 689.36,716.62 688.28,716.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="686.6,716.74 686.6,708.7 687.68,708.7 687.68,716.74 686.6,716.74 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="415.88,725.98 416.6,718.3 418.88,714.82 431.12,712.54 454.52,711.82 486.68,709.42 516.56,712.18 
	533.36,712.06 538.88,712.06 542.48,712.66 544.16,715.42 549.08,741.1 549.32,772.78 549.32,824.02 549.32,835.54 548.36,836.98 
	542.36,841.54 541.52,839.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="568.28,842.26 682.04,843.58 689.84,839.38 691.76,831.7 691.28,821.98 686,714.58 679.64,710.02 
	671.48,709.78 655.76,710.26 615.8,712.66 572.36,712.18 564.68,712.18 561.44,715.78 561.92,725.02 563.72,806.26 563.24,818.38 
	563.96,827.26 564.92,835.54 566.36,841.3 568.04,842.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="551.48,489.34 551.48,481.3 552.56,481.3 552.56,489.34 551.48,489.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="549.56,489.34 549.56,481.3 550.64,481.3 550.64,489.34 549.56,489.34 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="547.88,489.46 547.88,481.42 548.96,481.42 548.96,489.46 547.88,489.46 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="708.44,467.98 716.48,467.98 716.48,469.06 708.44,469.06 708.44,467.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="708.32,466.06 716.36,466.06 716.36,467.26 708.32,467.26 708.32,466.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="708.32,464.26 716.36,464.26 716.36,465.34 708.32,465.34 708.32,464.26 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="708.2,462.58 716.24,462.58 716.24,463.66 708.2,463.66 708.2,462.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="708.68,473.62 716.72,473.62 716.72,474.7 708.68,474.7 708.68,473.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="708.68,471.82 716.72,471.82 716.72,472.9 708.68,472.9 708.68,471.82 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="708.56,470.14 716.6,470.14 716.6,471.22 708.56,471.22 708.56,470.14 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="605.24,606.1 605.24,606.94 608.96,606.94 608.96,606.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="609.44,606.1 609.44,606.94 613.16,606.94 613.16,606.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="629.24,605.98 629.24,606.7 632.48,606.7 632.48,606.1 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="632.6,606.1 632.6,606.7 636.32,606.82 636.32,606.1 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="648.08" y1="602.98" x2="651.68" y2="600.94"/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="647.84,602.38 647.72,602.74 647.84,602.74 647.84,602.62 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="648.08,602.98 647.96,603.34 648.08,603.22 648.08,603.1 648.2,602.98 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="651.56,601.42 651.44,601.66 651.44,601.78 651.32,601.78 651.32,601.54 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="653,597.7 653.6,596.5 653.72,595.06 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="652.76,597.46 652.64,598.06 652.88,597.58 "/>
<polyline style={{strokeWidth:`${zoomLevelFactor}px`}} points="653.36,597.82 653.24,598.18 653.12,598.42 653,598.3 653.12,597.94 653.12,597.82 "/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="653.24" y1="595.06" x2="652.88" y2="595.54"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="652.88" y1="595.54" x2="653.12" y2="595.54"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="653.12" y1="595.54" x2="653.12" y2="595.42"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="653.12" y1="595.42" x2="653.24" y2="595.3"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="653.24" y1="595.3" x2="653.36" y2="595.18"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="653.36" y1="595.18" x2="653.36" y2="595.18"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="653.36" y1="595.18" x2="653.24" y2="595.06"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M616.64,592.96c0-0.43-0.35-0.78-0.78-0.78s-0.78,0.35-0.78,0.78s0.35,0.78,0.78,0.78
	S616.64,593.39,616.64,592.96"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M618.08,593.44c0-0.3-0.24-0.54-0.54-0.54s-0.54,0.24-0.54,0.54c0,0.3,0.24,0.54,0.54,0.54
	S618.08,593.74,618.08,593.44"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M616.16,590.68c0-0.3-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54c0,0.3,0.24,0.54,0.54,0.54
	C615.92,591.22,616.16,590.98,616.16,590.68"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M614.24,594.88c0-0.36-0.3-0.66-0.66-0.66c-0.36,0-0.66,0.3-0.66,0.66s0.3,0.66,0.66,0.66
	C613.94,595.54,614.24,595.24,614.24,594.88"/>
<path style={{strokeWidth:`${zoomLevelFactor}px`}} d="M615.32,595.6c0-0.3-0.24-0.54-0.54-0.54c-0.3,0-0.54,0.24-0.54,0.54c0,0.3,0.24,0.54,0.54,0.54
	C615.08,596.14,615.32,595.9,615.32,595.6"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="113.96" y1="619.78" x2="106.64" y2="637.54"/>
<line style={{strokeWidth:`${zoomLevelFactor}px`}} x1="538.76" y1="659.62" x2="543.92" y2="631.42"/>
</svg>
</div>
)
}

export default Kart