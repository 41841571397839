import React, {useEffect, useState, useRef} from "react";

import './languageSwitcher.styles.scss'
const LanguageSwitcher=({settings, language, setLanguage})=>{

    if(settings.showEnglish == false)return <></>
return(
    <div className="language-switch-wrapper">
        <div
        onClick={()=>setLanguage('NO')}
        className={`language-option ${language == 'NO' ? 'language-active' : ''}`}>
            NO
        </div>
        <div
        onClick={()=>setLanguage('EN')}
        className={`language-option ${language == 'EN' ? 'language-active' : ''}`}>
            EN
        </div>
    </div>
)
}
export default LanguageSwitcher