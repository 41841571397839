import React, {useEffect, useState, useRef} from "react";
import sanityClient from "../client.js";
import {PortableText} from '@portabletext/react'

import {BiArrowBack as Arrow} from 'react-icons/bi'

import './accordion.styles.scss';
import Ikon from './graphics/Ikon.component'

const Accordion = ({title, children, kat, extraAccordionHeight, show, text,setBigPicture}) =>{
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef();
    const [extraHeight, setExtraHeight] = useState(0)
    const [currentShowing, setCurrentShowing] = useState('Fortellinger')

    const [openHeight, setOpenHeight] = useState(false);

    const openHandler=(toggle)=>{
        if(!openHeight){
        // console.log(contentRef.current.scrollHeight)
        setOpenHeight(contentRef.current.scrollHeight)
        setExtraHeight(contentRef.current.scrollHeight)
        }
        setIsOpen(toggle)
    }

    useEffect(()=>{
        // console.log(openHeight)
        // console.log(extraAccordionHeight)
        setExtraHeight(openHeight + extraAccordionHeight)
    },[extraAccordionHeight])

    useEffect(()=>{
        // console.log(show, currentShowing)
        if (show !== undefined && show !== currentShowing){
            // console.log('switch')
            setOpenHeight(false)
            openHandler(false)
            setCurrentShowing(show)
        }
    },[show])

    return (
        <div className='accordion'>
            <div className={`header-wrapper ${isOpen ? 'accordion-open' : ''}`} onClick={() => openHandler(!isOpen)}>
                <div className='header'>
                    <Ikon kategori={kat} />
                    <div className="accordion-title">
                        <div className="accordion-title-label">
                        {title}
                        </div>
                        <div className="accordion-text">
                            {/* {console.log(text)} */}
                        <PortableText
                        value={text}
                        />
                            </div>
                    </div>
                    
                </div>
            </div>
            <div
             ref={contentRef}
             style={
                isOpen
                  ? { 
                      height: extraHeight + 30 + 'px',
                      opacity: 1
                     }
                  : { 
                      marginTop: "0px",
                      height: "0px",
                      opacity: 0
                    }
              }
             className={`content-wrapper ${isOpen ? 'open' : ''}`}>
                {children}
            </div>
        </div>
    )
}

export default Accordion