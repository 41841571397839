import React, {useEffect, useState, useRef,createRef} from "react";
import sanityClient from "../client.js";
import imageUrlBuilder from '@sanity/image-url'
import {PortableText} from '@portabletext/react'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useMediaQuery } from 'react-responsive'

import {getFile,getFileAsset, buildFilePath,buildFileUrl} from '@sanity/asset-utils'

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { Navigation, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {
    useParams, useLocation, useHistory,useNavigate
  } from "react-router-dom";

import { BiX } from 'react-icons/bi';
import { AiOutlineMenu } from 'react-icons/ai';

import BigPicture from "../components/bigpicture.component.jsx";
import Kart from '../components/kart.component'
import Ikon from '../components/graphics/Ikon.component'
import Menu from '../components/menu.component'
import LanguageSwitcher from '../components/languageSwitcher.component'
import Fortelling from '../components/fortelling.component'
import Event from '../components/event.component'
import ZoomKnapp from '../components/zoomKnapp.component'

import Archive from './archive.page'
import Musikken from './musikken.page'

import './frontpage.styles.scss';
import styled from "styled-components";


const Frontpage=()=>{

    const isNarrow = useMediaQuery({ query: '(max-width: 1100px)' })
    const [contentExpanded, setContentExpanded] = useState(false);
    const [programExpanded, setProgramExpanded] = useState(false)
    const [pageContent, setPageContent] = useState(null);
    const [toggleMusicContentState, toggleMusicContent] = useState(false)
    const [bigPicture, setBigPicture] = useState(null)

    const [loc, setLoc] = useState('')

    const [menuHidden, setMenuHidden] = useState(false)
    const [menuOpen, toggleMenuOpen] = useState(false)

    const [mapPosition, setMapPosition] = useState({x:-2700,y:-2900})
    const [zoomLevel, setZoomLevel] = useState(1);

    const [deltaStart, setDeltaStart]=useState(null)
    const [delta, setDelta]=useState({x:0,y:0})

    const [pageUrl, setPageUrl] = useState(null)
    const [pageState, setPageState] = useState('Forside');
    const [language, setLanguage] = useState('NO')
    const navigate = useNavigate();

    const [fortellingOpen, setFortellingOpen] = useState(1)

    const [windowSize, setWindowSize] = useState(null)

    const [hasDragged, setHasDragged] = useState(false)
    const [dragPosStart, setDragPosStart] = useState(null)

    const transformRef = useRef();
    const [mapHasInit,setMapHasInit] = useState(false)

    const location = useLocation()
    // const history = useHistory()

    const builder = imageUrlBuilder(sanityClient)

        function urlFor(source) {
        return builder.image(source)
        }

    useEffect(()=>{
        let fetchContentQuery = `{'innstillinger': *[_id == 'innstillinger']{...}[0], 'content': *[_type == 'midlertidig']{...}[0], 'fortellinger': *[_type == 'fortelling']{..., bilder[]{'image':*[_id == ^._ref]{bildefil, title}[0]}},'events': *[_type == 'event']{...} | order(tidspunkt asc), 'blogroll': *[_id == 'blogroll']{'artiklerRefArray': artiklerRefArray[]{..., 'artikkel':*[_type=='artikkel' && _id == ^._ref]{publiseringsDato, ...}[0]} | order(artikkel.publiseringsDato desc) | order(artikkel._createdAt desc) }[0], 'musikken':{'OmMusikken':*[_id == 'omMusikken']{...}, 'Orgel':*[_id == 'orgel']{..., "avsnitt":orgelAvsnitt[]{...}},'Bakgrunnsstoff':*[_id == 'bakgrunnsstoff']{..., "avsnitt":orgelAvsnitt[]{...}},'Noteark':*[_id == 'noteark']{..., "avsnitt":orgelAvsnitt[]{...}},'Lydsnutter':*[_id == 'lydsnutter']{..., "avsnitt":orgelAvsnitt[]{...}},'LydsnutterAnonym':*[_id == 'lydsnutterAnonym']{..., "avsnitt":orgelAvsnitt[]{...}},'Musikere':*[_id == 'musikere']{..., "avsnitt":orgelAvsnitt[]{...}}}}`
        
        sanityClient
        .fetch(fetchContentQuery)
        .then(data => {setPageContent(data)})
        .catch(console.error)

        setWindowSize({x:window.innerWidth, y:window.innerHeight})

        if(isNarrow){
            setHasDragged(true)
        }
    },[])

    const setFortellingOpenHandler=(fortelling)=>{
        // find a way to first define transformRef, before initiating 
        if (fortelling.id == fortellingOpen){ // if closing the current, return state to 1
            setFortellingOpen(1)
        }else{
            setFortellingOpen(fortelling.id);
            setZoomLevel(1)

            let yDelta =  ((window.innerHeight - (500)) / 2) + (550);
 
            if (isNarrow){
                let centeredPos = {x: ((fortelling.pos.x * -0.65) + (window.innerWidth / 2)) - 20, y: (fortelling.pos.y * -0.65) + yDelta - 50}
                transformRef.current.setTransform(centeredPos.x, centeredPos.y, 0.65, 300, 'easeOut');
            }else{
                let centeredPos = {x: ((fortelling.pos.x * -1) + (window.innerWidth / 2)), y: (fortelling.pos.y * -1) + yDelta}
                transformRef.current.setTransform(centeredPos.x, centeredPos.y, 1, 300, 'easeOut');
            }
        }
    }

    const zoomHandler = (int)=>{
        setZoomLevel(zoomLevel + int)
    }

    const pageStateHandler =(event)=>{
        switch (event){
            case 'Organotopia':
                setContentExpanded(true)
                //setPageState('Forside')
                //setMapPosition({x:-2540,y:-2850})
                break;
            case 'Kartet':
                setPageState('Kartet');
                navigate('/')
                setContentExpanded(false)
                setProgramExpanded(false)
                if(transformRef.current !== null) {
                    const coordinates = isNarrow ? {x:-3665, y: -3676} : {x:-3250, y: -3400}
                    transformRef.current.setTransform(coordinates.x, coordinates.y, 1, 300, 'easeOut');
                }

                // console.log(transformRef)
                // transformRef.current.setTransform(-2000, -2000, 1, 300, 'easeOut');
                //setMapPosition({x:-3230,y:-3320})
                break;
        }
    }



    useEffect(() => {

        if(mapHasInit == false)return

        let loc = location.pathname.split('/')

            switch (loc[1]){
                case '':
                    setPageState('Forside')
                    //setMapPosition({x:-2540,y:-2850})
                    break;
                // case 'Fortellinger':
                //     setPageState('Fortellinger')
                //     //setMapPosition({x:-3230,y:-3320})
                //     break;
                case 'arkivet':
                    setPageState('Arkivet')
                    setContentExpanded(false)
                    setProgramExpanded(false)
                    break;
                case 'musikken':
                    // setZoomLevel(0.8)
                    setPageState('Musikken')
                    setContentExpanded(false)
                    setProgramExpanded(false)
                    break;
                case 'fortelling':
                    console.log('fortelling')
                    
                    setContentExpanded(false)
                    setProgramExpanded(false)
                    let fortellingSlug = loc[2];
                    let fortellingSlugNew;
                    // console.log(fortellingSlug)
                    if (fortellingSlug.includes('%C3%A5') || fortellingSlug.includes('%C3%B8') || fortellingSlug.includes('%C3%A6')){
                        // console.log('yes')
                    fortellingSlugNew = fortellingSlug.replace('%C3%A5', 'å').replace('%C3%B8', 'ø').replace('%C3%A6', 'æ')
                    // console.log(fortellingSlugNew)
                    } else{
                        fortellingSlugNew = fortellingSlug
                    }
                    // må gjøre om rare bokstaver fra url
                    // console.log(fortellingSlugNew)

                    if (fortellingSlugNew !== ''){
                        let fetchContentQuery = `*[url.current == '${fortellingSlugNew}']{_id, location}[0]`
        
                        sanityClient
                        .fetch(fetchContentQuery)
                        .then(data => {
                            // console.log(data)
                            let y = ((59.930594 - data.location.lat) * 870000)
                            let x = ((10.751211 - data.location.lng) * -436000)
                            const location={y:y, x:x}
                            // console.log(transformRef.current)
                            setFortellingOpenHandler({id:data._id,pos:location})
                        })
                        .catch(console.error)
                    }
                    else{
                        console.log('no fortelling')
                    }
                    break;
            }

            setLoc(loc)
        // })
    }, [location, mapHasInit])

    const onPanningStartHandler = (e)=>{
        // console.log(e);
        if (!hasDragged){setHasDragged(true)}
        setDragPosStart({x:e.state.positionX, y:e.state.positionY})
    }

    const onPanningStopHandler = (e)=>{
        //console.log(e)
        if (dragPosStart.x - e.state.positionX > -5 && dragPosStart.x - e.state.positionX < 5 && dragPosStart.y - e.state.positionY > -5 && dragPosStart.y - e.state.positionY < 5){
            //console.log('click')
            setFortellingOpen(1)
            window.history.replaceState(null, '', "/")
        }
    }

    const initMap = (e)=>{
        // console.log(pageState)
        setMapHasInit(true)
 
        if (pageState == 'Forside'){
            const coordinates = isNarrow ? {x:-2077, y: -2270} : {x:-2013, y: -2290}
            e.setTransform(coordinates.x, coordinates.y, 0.8, 300, 'easeOut');
        }else if(pageState == 'Kartet'){
            const coordinates = isNarrow ? {x:-3665, y: -3676} : {x:-3250, y: -3400}
            e.setTransform(coordinates.x, coordinates.y, 1, 300, 'easeOut');
        }
        
        // e.setTransform(-2470, -2890, 1, 300, 'easeOut');
    }

    const setZoomHandler =(e)=>{
        //console.log(e);
        setZoomLevel(e.state.scale)
    }

    const returnTime =(date)=>{
        const time = new Date(date).toLocaleTimeString('no-NO', {timeZone:'Europe/Oslo', timeStyle: 'short', hour12: false });
        return time;
    }

    const myPortableTextComponents = {
        marks:{
            em: ({children}) => {
                return (
                  <em>
                    {children}
                  </em>
                )
              },
        },
        types: {
            image: ({value}) => {
                console.log(value)
                return(<div className="avsnitt-image-wrapper">
                  <img src={urlFor(value.asset).width(1200).url()} onClick={()=>{setBigPicture(value.asset)}}/>
                  <div className="avsnitt-image-caption">{language == 'NO' ? value.caption : value.captionEng}</div>
                  </div>)},
            slideshow: ({value}) => {
                return <Swiper
                autoHeight={true}
                spaceBetween={20}
                className="avsnitt-slideshow-wrapper"
                modules={[Navigation, Pagination]}
                navigation
                pagination={{ clickable: true }}
                loop={true}
                >
                {value.slideshowArray.map((slide,index)=>{
                  return <SwiperSlide key={index}>
                      <div className='avsnitt-swiper-image'>
                          <img src={urlFor(slide.asset).width(1200).url()} onClick={()=>{setBigPicture(slide.asset)}}/>
                          <div className="avsnitt-swiper-image-caption"> {language == 'NO' ? slide.bildetekst : slide.bildetekstEng}</div>
                      </div>
                  </SwiperSlide>    
                })}
                </Swiper>
              },
            audioFile: ({value}) =>{
              // console.log(value)
              if ('audioFile' in value){
                  let file = getFile(value.audioFile.asset, sanityClient.config())
                  return <div className="lydsnutt-wrapper">
                        <AudioPlayer
                            className="lydsnutt"
                            src={file.asset.url}
                            onPlay={e => onPlayAudio(file.asset.url)}
                            // other props here
                        />
                  </div>
              }else{
                  return <></>
              }
               
            },
            videoEmbed: ({value}) =>{
                  // console.log(value.videoEmbed);
                  let video;
                  if (value.videoEmbed.includes("vimeo")){
                      const code = value.videoEmbed.split('/');
                      // console.log(code)
                      video = {__html: `<iframe src="https://player.vimeo.com/video/${code[code.length - 1]}" width="640" height="400" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`}
                  }else if (value.videoEmbed.includes("youtube")){
                      const code = value.videoEmbed.split('v=');
                      // console.log(code)
                      video = {__html:  `<iframe width="560" height="315" src="https://www.youtube.com/embed/${code[code.length - 1]}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}
                  } else{
                      return <></>
                  }
                  return <div className="avsnitt-video-wrapper" dangerouslySetInnerHTML={video} >
                      {/* <div className="avsnitt-video" dangerouslySetInnerHTML={video} /> */}
                  </div>
                      {/* <div dangerouslySetInnerHTML={createMarkup()} />; */}
                  //     {video}
                  // </div>
              },
          },
    }

    //BLOGROLL

    const containerRef = useRef();
    const aboutSectionRef = useRef();
    const programmeSectionRef = useRef();
    const scrollRefs = useRef([]);
    const menuScrollRef = useRef([]);

    const returnArtikkelDate = (item)=>{
        console.log(item.artikkel)
        let date;
        if('publiseringsDato' in item.artikkel){
            date = item.artikkel.publiseringsDato
        }else{
            date = item.artikkel._createdAt
        }
        let newDate = new Date(date)
        return newDate.getDate() + '.' + (newDate.getMonth() + 1);
    }

    const returnReadmoreArticles = (lang) =>{

        let array = [...pageContent.blogroll.artiklerRefArray];
        let length = 2
        if(array.length < 3){
            length = 2
        }

        return array.slice(0,length).map((item, index) =>{
            return (
                <span
                    key={'art_'+index}
                    className="intro-readmore-title"
                    onClick={()=>openArticleHandler(index)}
                >
                    <span className="intro-readmore-title-label">{lang == 'NO' ? item.artikkel.tittel : item.artikkel.tittelEng}</span> <span className="intro-readmore-title-date">{returnArtikkelDate(item)}</span>
                </span>
            )
        })
    }

    useEffect(()=>{
        if(pageContent == null) return
        if('blogroll' in pageContent){
            scrollRefs.current = [...Array(pageContent.blogroll.artiklerRefArray.length).keys()].map(
                (_, i) => scrollRefs.current[i] ?? createRef()
            );
        }
    },[pageContent])

    const scrollSmoothHandler = (index) => {
        if (index == 'about'){
            aboutSectionRef.current.scrollIntoView({ behavior: "smooth" });
        }else if (index == 'programme'){
            programmeSectionRef.current.scrollIntoView({ behavior: "smooth" });
        }else{
            scrollRefs.current[index].current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const openArticleHandler = (index)=>{
        console.log(index)
        if (index == 0){
            setContentExpanded(true)
        } else{
            setContentExpanded(index)
        }
        
        // scrollSmoothHandler(index)
    }

    useEffect(()=>{
        
        if (contentExpanded === 1){
            console.log('one')
            scrollSmoothHandler(1)
        }else if (contentExpanded === 2){
            console.log('two')
            scrollSmoothHandler(2)
        }else if (contentExpanded === true){
            console.log('zero')
            scrollSmoothHandler(0)
        }
    },[contentExpanded])

    const menuScrollHandler = () => {
        menuScrollRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const onPlayAudio = (src) =>{
        const allAudio = Array.from(
            document.querySelectorAll('audio')
          );

          for(let i = 0; allAudio.length > i; i++){
            if(allAudio[i].currentSrc != src){
                allAudio[i].pause()
            }
          }
    }

    //   useEffect(()=>{
    //     scrollSmoothHandler(currentIndex)
    //   },[currentIndex])

    const ProgrammeSection = ()=>{
        return (<>
        <div className="expanded-text-avsnitt-tittel">
                    {language =='NO' ? 'Program 17.september kl 12-24' : 'Programme September 17th, 12:00-24:00'}    
                </div>
                <div className="expanded-text-avsnitt-content">
                    <div className="expanded-programme">
                        {pageContent.events.map(event =>{
                            // console.log(event)
                            return <div className="event-item hovedevent">
                                <div className="event-item-title">
                                    {returnTime(event.tidspunkt)} - {language == 'NO' ? event.title : event.titleEng}
                                </div>
                                {'undertittel' in event ?
                                <div className="event-item-subtitle">
                                    {language == 'NO' ? event.undertittel : event.undertittelEng}
                                </div>
                                :''}
                                <div className="event-item-text">
                                <PortableText
                                    value={language == 'NO' ? event.tekst : event.tekstEng}
                                    components={myPortableTextComponents}
                                />
                                </div>
                                {'underevent' in event ? 
                                <div className="event-item underevent">
                                <div className="event-item-title">
                                    {returnTime(event.underevent[0].tidspunkt)} - {language == 'NO' ? event.underevent[0].title : 'titleEng' in event.underevent[0] ? event.underevent[0].titleEng : event.underevent[0].title}
                                </div>
                                {'undertittel' in event ?
                                <div className="event-item-subtitle">
                                    {language == 'NO' ? event.underevent[0].undertittel : event.underevent[0].undertittelEng}
                                </div>
                                :''}
                                <div className="event-item-text">
                                <PortableText
                                    value={language == 'NO' ? event.underevent[0].tekst : event.underevent[0].tekstEng}
                                    components={myPortableTextComponents}
                                />
                                </div>
                                </div>
                                :''
                                }
                            </div>
                        })}
                    </div>
                </div>
                </>)
    }

    const AboutSection = ()=>{
        return (<>
            <div className="expanded-text-avsnitt-tittel">
                    ORGANOTOPIA
                </div>
                <div className="expanded-text-avsnitt-content">
                {language == 'NO' ? 
                    <PortableText
                    value={pageContent.content.expandedText}
                    components={myPortableTextComponents}
                    />
                    :
                    <PortableText
                    value={pageContent.content.expandedTextEng}
                    components={myPortableTextComponents}
                    />
                }
                </div>
                <div className="expanded-text-avsnitt-content expanded-text-wrapper-credits">
                    <div className="expanded-text-avsnitt-content-subtitle">
                    {language == 'NO' ? 'Kreditering' : 'Credits'}
                    </div>
                    {language == 'NO' ? 
                    <PortableText
                    value={pageContent.content.creditText}
                    components={myPortableTextComponents}
                    />
                    :
                    <PortableText
                    value={pageContent.content.creditTextEng}
                    components={myPortableTextComponents}
                    />
                    }
                </div>
                {'kunstnerText' in pageContent.content ?
                <div className="expanded-text-avsnitt-content">
                    <div className="expanded-text-avsnitt-content-subtitle">
                    {language == 'NO' ? 'Kunstere' : 'Artists'}
                    </div>
                    {language == 'NO' ? 
                    <PortableText
                    value={pageContent.content.kunstnerText}
                    components={myPortableTextComponents}
                    />
                    :
                    <PortableText
                    value={pageContent.content.kunstnerTextEng}
                    components={myPortableTextComponents}
                    />
                    }
                </div>
                :''}
                <div className="expanded-text-avsnitt-content logo-section">
                        <div className="logo">
                            <a href='http://www.ultima.no'>
                                <img src={process.env.PUBLIC_URL + '/assets/ultima2.png'}/>
                            </a>
                        </div>
                        <div className="oat-logo">
                            <a href='https://oslotriennale.no/'>
                                <img src={process.env.PUBLIC_URL + '/assets/OAT.jpg'}/>
                            </a>
                        </div>
                    </div>
                <div className="expanded-text-avsnitt-content expanded-text-wrapper-personvern">
                    <div className="expanded-text-avsnitt-content-subtitle">
                    {language == 'NO' ? 'Personvern' : 'Privacy policy'}
                    </div>
                    {language == 'NO' ? 
                    <PortableText
                    value={pageContent.content.personvernsText}
                    components={myPortableTextComponents}
                    />
                    :
                    <PortableText
                    value={pageContent.content.personvernsTextEng}
                    components={myPortableTextComponents}
                    />
                    }
                </div>
                </>
        )
    }

    console.log(pageContent)
    if (!pageContent) return <></>
    return (
        <>
            {bigPicture && <BigPicture img={bigPicture} setBigPicture={setBigPicture}/> }
            {/* {contentExpanded || menuHidden ? '' :  */}
            <Menu zoomHandler={zoomHandler} menuOpen={menuOpen} toggleMenuOpen={toggleMenuOpen} pageStateHandler={pageStateHandler} toggleMusicContent={toggleMusicContent} />
            {/* } */}

            {contentExpanded ?
            <div className="expanded-text-wrapper">
                <div className="close-button" onClick={()=>{setContentExpanded(false)}}><BiX/></div>
                <div className="menu-button" onClick={()=>{menuScrollHandler()}}><AiOutlineMenu/></div>
                <div className="button-background"></div>

                <div className="language-wrapper">
                    <LanguageSwitcher settings={pageContent.innstillinger} language={language} setLanguage={setLanguage} />
                </div>

            <div className="expanded-text-menu" ref={menuScrollRef}>

                {pageContent.blogroll.artiklerRefArray.map((avsnitt, index)=>{
                    return <div key={'a' + avsnitt._key} className="meny-item" onClick={()=>scrollSmoothHandler(index)}>{language == 'NO' ? avsnitt.artikkel.tittel : 'tittelEng' in avsnitt.artikkel ? avsnitt.artikkel.tittelEng : avsnitt.artikkel.tittel}</div>
                })}
                <div className="meny-item" onClick={()=>scrollSmoothHandler('about')}>{language == 'NO' ? 'Om Organotopia' : 'About Organotopia'}</div>
                <div className="meny-item" onClick={()=>scrollSmoothHandler('programme')}>{language == 'NO' ? 'Program 17.09.2022' : 'Programme 17.09.2022'}</div>
            </div>
            
            <div className="expanded-text-content" ref={containerRef}>
                {pageContent.blogroll.artiklerRefArray.map((avsnitt,index)=>{
                    if (language == 'NO'){
                        return <div key={index} className="expanded-text-avsnitt" ref={scrollRefs.current[index]}>
                        <div className="expanded-text-avsnitt-tittel">
                            {avsnitt.artikkel.tittel}
                        </div>
                        <div className="expanded-text-avsnitt-content">
                            
                            <PortableText
                                value={avsnitt.artikkel.innhold}
                                components={myPortableTextComponents}
                            />
                
                        </div>
                    </div>
                    } else if('innholdEngelsk' in avsnitt.artikkel){
                        return <div key={index} className="expanded-text-avsnitt" ref={scrollRefs.current[index]}>
                        <div className="expanded-text-avsnitt-tittel">
                        {'tittelEng' in avsnitt.artikkel ? avsnitt.artikkel.tittelEng : avsnitt.artikkel.tittel}
                        </div>
                        <div className="expanded-text-avsnitt-content">
               
                            <PortableText
                            value={avsnitt.artikkel.innholdEngelsk}
                            components={myPortableTextComponents}
                            />
                            
                        </div>
                    </div>
                    }else{
                        return <div key={index} className="expanded-text-avsnitt" ref={scrollRefs.current[index]}>  <div className="musikk-artikkel-avsnitt-tittel">
                        {'tittelEng' in avsnitt ? avsnitt.artikkel.tittelEng : avsnitt.artikkel.tittel}
                        </div>
                        <div className="expanded-text-avsnitt-content">
               
                            English translation coming soon.
                            
                        </div></div>
                    }
                    
                })}
                <div className='expanded-text-avsnitt expanded-about' ref={aboutSectionRef}>
                    <AboutSection/>
                </div>
                <div className='expanded-text-avsnitt expanded-programme' ref={programmeSectionRef}>
                    <ProgrammeSection/>
                </div>
            </div>

            </div>
            :
            ''}

            {/* {programExpanded ?
            <div className="expanded-text-wrapper program-wrapper">
                <div className="close-button" onClick={()=>{setProgramExpanded(false)}}><BiX/></div>
                <div className="language-wrapper">
                    <LanguageSwitcher settings={pageContent.innstillinger} language={language} setLanguage={setLanguage} />
                </div>
                
                
            </div>
            :
            ''} */}

            {pageState == 'Arkivet' ? <Archive content={pageContent.fortellinger}/> :''}
            {pageState == 'Musikken' ? <Musikken menuOpen={menuOpen} settings={pageContent.innstillinger} language={language} setLanguage={setLanguage} zoomLevel={zoomLevel} setMenuHidden={setMenuHidden} content={pageContent.musikken} hasDragged={hasDragged} setHasDragged={setHasDragged} setLoc={setLoc} loc={loc}/> :''}
            {/* <div className="zoomlevel">{zoomLevel}</div> */}
            {pageState != 'Arkivet' && pageState != 'Musikken' ?
            <div className="frontpage">
                    {!isNarrow && 
                        <div className={`corner-flower-wrapper ${hasDragged ? 'hidden' : ''}`} onClick={()=>setHasDragged(!hasDragged)}>
                            <div className="corner-flower-text">
                            {hasDragged ? <>?</> : <>Dra i kartet<br/>og klikk på<br/>figurene for å<br/>utforske historier</>}
                            </div>
                            <div className="corner-flower-background">
                                <svg version="1.1" id="Layer_1"x="0px" y="0px" viewBox="0 0 1000 1000">
                                <line className="fl" x1="325.52" y1="904.62" x2="325.52" y2="904.62"/>
                                <path className="fl" d="M640.37,77.96c5.53-36.21-18.4-70.3-53.97-76.86c-35.56-6.56-69.33,16.69-76.75,52.55
                                    c-7.92-33.25-39.09-55.26-72.6-51.28c-33.51,3.98-62.78,34.17-62.9,68.37c-17.61-29.55-54.35-40.9-85.23-26.34
                                    c-30.88,14.56-47.28,52.54-36.32,85.22c-25.66-23.3-64.6-22.97-89.86,0.78c-25.26,23.74-30.43,64.37-9.44,92.06
                                    c-31.29-13.42-67.53-0.86-84.16,29.17c-16.63,30.03-8.67,69.9,18.94,89.97c-35-5.17-67.9,18.25-74.97,53.38
                                    c-7.07,35.13,12.75,68.56,46.95,77.71c-36.52,3.98-62.99,37.14-59.21,74.18c3.78,37.03,36.99,62.82,73.54,59.14
                                    c-25.26,6.85-44.24,28.02-48.55,54.16c-4.31,26.14,6.83,52.42,28.52,67.23s49.9,15.41,72.18,1.54
                                    c-20.95,14.83-29.65,43.62-25.63,69.2c4.02,25.58,22.1,46.58,46.56,54.08c24.46,7.5,48.89-0.44,66.22-19.47
                                    c-17.34,31.64-7.03,71.51,23.4,90.45c30.43,18.94,70.25,9.29,90.24-20.7c-8.26,24.17-2.35,50.99,15.26,69.31
                                    c17.61,18.32,43.91,24.99,67.96,17.25c24.05-7.74,38.93-27.18,42.88-52.44c2.37,37,33.69,65.17,70.22,63.13s64.62-33.51,62.97-70.55
                                    c17.78,24.47,53.14,33.24,81.14,22.41c28.01-10.83,45.45-39.27,42.63-69.52c24.45,20.95,67.15,16.48,92.04-3.93
                                    c24.88-20.41,30.26-54.6,15.15-83.2c19.78,14.29,45.66,16.55,67.56,5.9c21.9-10.65,36.36-32.53,37.74-57.11
                                    c1.38-24.58-10.53-47.98-31.1-61.09c26.72,11.17,59.36,0.98,80.6-18.89s29.16-50.43,20.31-78.32s-32.87-48.03-61.55-51.62
                                    c27.02-1.97,50.81-21.36,62.11-46.32c11.3-24.95,8.29-54.16-7.85-76.21c-16.14-22.05-42.85-33.43-69.69-29.7
                                    c24.92-10.5,41.39-34.65,44.39-61.84c3-27.19-8.8-53.89-30.81-69.72c-22.02-15.83-47.4-13.32-71.73-1.46
                                    c30.4-26.36,34.72-72.39,9.76-104.09c-24.95-31.69-70.24-38.67-102.34-14.47c8.18-32.56-8.47-66.26-39.08-79.14
                                    C695.27,38.01,657.32,49.1,640.37,77.96z"/>
                                </svg>
                            </div>
                        </div>
                    }
                        <TransformWrapper
                            ref={transformRef}
                            onInit={(e)=>initMap(e)}
                            onPanningStart={(e)=>onPanningStartHandler(e)}
                            onPanningStop={(e)=>onPanningStopHandler(e)}
                            // onZoom={(e)=>setZoomHandler(e)}
                            onZoomStop={(e)=>setZoomHandler(e)}
                            maxScale={5}
                            minScale={0.2}
                            limitToBounds={false}
                            panning={{excluded:['swiper-button-next', 'swiper-button-prev','swiper-pagination-bullet']}}
                            wheel={{step:0.05, excluded:['fortelling-content-wrapper','event-wrapper','event-content-wrapper','swiper-text-title','swiper-text-subtitle', 'swiper-text','swiper','p']}}
                            pinch={{step:10, excluded:['fortelling-content-wrapper','event-wrapper','event-content-wrapper','swiper-text-title','swiper-text-subtitle', 'swiper-text','swiper','p']}}
                            >
                                <TransformComponent>
                                    <div className="map-transform">
                                        <div className="musikk-ikon-wrapper" onClick={()=>{navigate('/musikken');toggleMenuOpen(false)}}>
                                            <Ikon kategori={'musikk-ikon'}/>
                                        </div>
                                        <div className="fortellinger-wrapper">
                                            {pageContent.fortellinger.map(fortelling=>{
                                                return <Fortelling key={fortelling._id} data={fortelling} fortellingOpen={fortellingOpen} zoomLevel={zoomLevel} setFortellingOpenHandler={setFortellingOpenHandler} setBigPicture={setBigPicture} />
                                            })}
                                            {pageContent.innstillinger.showEventsOnMap == true ? 
                                            <>
                                                {pageContent.events.map(event=>{
                                                    return <Event key={event._id} data={event} language={language} fortellingOpen={fortellingOpen} zoomLevel={zoomLevel} setFortellingOpenHandler={setFortellingOpenHandler} setBigPicture={setBigPicture} />
                                                })}
                                            </>
                                            :
                                            ''
                                            }
                                        </div>
                                        <div className="intro-wrapper">
                                            <div className="intro-wrapper-title">
                                                ORGANOTOPIA
                                            </div>
                                            <div className="language-wrapper">
                                                <LanguageSwitcher settings={pageContent.innstillinger} language={language} setLanguage={setLanguage} />
                                            </div>
                                            <div className="intro-wrapper-text">
                                                {language == 'NO' ? 
                                                <>
                                                {'introTextFirst' in pageContent.content ?
                                                    <div className="intro-p">
                                                        <PortableText
                                                        value={pageContent.content.introTextFirst}
                                                        components={myPortableTextComponents}
                                                        />
                                                    </div>
                                                :''}
                                                <div className="split-p">
                                                <PortableText
                                                value={pageContent.content.introTextSecond}
                                                components={myPortableTextComponents}
                                                />
                                                </div>
                                                <PortableText
                                                value={pageContent.content.introTextThird}
                                                components={myPortableTextComponents}
                                                />
                                                </>
                                                :
                                                <>
                                                {'introTextFirstEng' in pageContent.content ?
                                                    <div className="intro-p">
                                                        <PortableText
                                                        value={pageContent.content.introTextFirstEng}
                                                        components={myPortableTextComponents}
                                                        />
                                                    </div>
                                                :''}
                                                <div className="split-p">
                                                <PortableText
                                                value={pageContent.content.introTextSecondEng}
                                                components={myPortableTextComponents}
                                                />
                                                </div>
                                                <PortableText
                                                value={pageContent.content.introTextThirdEng}
                                                components={myPortableTextComponents}
                                                />
                                                </>
                                                }
                                            </div>
                                            {language == 'NO' ? 
                                            <div className="intro-readmore-wrapper">
                                                <div className="intro-readmore-title-wrapper">
                                                    Siste artikler: 
                                                    <div className="intro-readmore-articles">
                                                        {returnReadmoreArticles('NO')}
                                                    </div>
                                                </div>
                                                <strong style={{float:'left'}} className="intro-readmore-button" onClick={()=>{setContentExpanded(true)}}>
                                                    Les mer!
                                                </strong>
                                            </div>
                                            :
                                            <div className="intro-readmore-wrapper">
                                            <div className="intro-readmore-title-wrapper">
                                                Latest articles: 
                                                <div className="intro-readmore-articles">
                                                    {returnReadmoreArticles('EN')}
                                                </div>
                                            </div>
                                            <strong style={{float:'left'}} className="intro-readmore-button" onClick={()=>{setContentExpanded(true)}}>
                                                Read more!
                                            </strong>
                                        </div>
                                            }
                                                {/* {pageContent.innstillinger.showEvents == true ? 
                                                    <strong className="intro-readmore-button" onClick={()=>{setProgramExpanded(true)}}>
                                                        {language =='NO' ? 'Dagsprogram 17. september' : 'Day programme September 17th'}
                                                    </strong>
                                                    :''
                                                } */}
                                        </div>
                                        <div className="kart-wrapper">
                                            <Kart zoomLevel={zoomLevel}/>
                                        </div> 
                                    </div>
                                </TransformComponent>
                            </TransformWrapper>
        
        </div>
        :''}
        </>
    )

}

export default Frontpage