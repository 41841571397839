import './App.scss';
import Frontpage from './pages/frontpage.page'

function App() {

  return (
    <div className="App">
      <Frontpage />
    </div>
  );
}

export default App;
