import React, {useEffect, useState, useRef} from "react";
import { useMediaQuery } from 'react-responsive'
import { BiX } from 'react-icons/bi';
import {AiOutlineMenu} from 'react-icons/ai'
import { useNavigate } from "react-router-dom";

import {RiInstagramLine} from 'react-icons/ri';


import './menu.styles.scss'
import { BiZoomIn } from 'react-icons/bi';
import { BiZoomOut } from 'react-icons/bi';

const Organotopia =()=>{
    return <svg version="1.1" x="0px" y="0px" viewBox="0 0 134.18 49.49" >
	<path className="meny-sky" d="M59.27,0.53c0.1,2.28,1.44,4.32,3.5,5.31c2.05,0.99,4.48,0.77,6.33-0.56c0.76-2.98,3.32-5.11,6.39-5.27
	c3.07-0.16,5.89,1.71,6.94,4.6c1.55-1.39,3.62-2.12,5.69-1.97c2.07,0.16,3.99,1.16,5.3,2.76c0.81,1.67,2.61,2.68,4.47,2.65
	c1.86-0.03,3.53-1.13,4.29-2.82s0.45-3.72-0.76-5.13c5.12,0.15,9.67,3.35,11.68,8.06c2.23-2.81,6.05-3.8,9.36-2.42
	c3.31,1.38,5.32,4.74,4.9,8.3c4.38,1.62,5.47,3.09,5.84,6.13c0.95,7.8-5.78,9.3-5.78,9.3c5.25,4.5,1.13,11.96-4.38,11.99
	c-0.71,3.05-3.47,5.18-6.6,5.1c-3.13-0.08-5.72-2.3-6.27-5.38c0.34,2.81-1.4,5.47-4.12,6.27c-2.72,0.8-5.61-0.53-6.84-3.08
	c-1.83,1.76-4.34,2.63-6.87,2.36c-2.53-0.26-4.8-1.57-6.22-3.67c-2.8,3.75-7.21,5.92-11.89,5.91c-0.1-2.28-1.44-4.32-3.5-5.31
	c-2.06-0.99-4.43-0.79-6.28,0.55c-0.76,2.98-3.37,5.12-6.43,5.28c-3.07,0.16-5.91-1.76-6.96-4.65c-1.55,1.39-3.59,2.08-5.66,1.92
	c-2.07-0.16-4.07-1.13-5.39-2.73c-0.81-1.67-2.52-2.72-4.38-2.69c-1.86,0.03-3.53,1.13-4.29,2.82s-0.45,3.72,0.76,5.13
	c-5.12-0.15-9.67-3.25-11.68-7.96c-2.23,2.81-6.05,3.8-9.36,2.42s-5.32-4.85-4.9-8.42C1.59,34.25,0.01,31.06,0,27.28
	c-0.01-6.04,6.1-7.38,6.1-7.38c-2.42-0.55-4.29-2.45-4.82-4.88c-0.53-2.42,0.39-4.93,2.36-6.44s4.63-1.75,6.83-0.6l0.01-0.04
	c0.71-3.05,3.47-5.18,6.6-5.1c3.13,0.08,5.78,2.35,6.32,5.44l0.04,0.04c-0.34-2.81,1.4-5.47,4.12-6.27s5.62,0.5,6.85,3.06L34.4,5.03
	c1.83-1.76,4.34-2.63,6.87-2.36c2.53,0.26,4.8,1.62,6.23,3.72L47.4,6.44C50.19,2.7,54.6,0.5,59.27,0.5"/>
    </svg>
}

const Musikken =()=>{
    return <svg version="1.1" x="0px" y="0px" viewBox="0 0 129.6 42.24" >

<polygon className="meny-sky" points="9.36,22.08 7.56,22.08 4.92,21.84 2.64,21 1.8,20.04 0.84,19.2 0.72,18.36 0.36,17.28 0,15.6 0,14.52 0.36,13.2 
	0.6,12.36 1.2,11.64 1.68,11.16 2.76,10.32 3.36,9.72 4.32,9.24 5.4,8.76 6.6,8.52 8.76,8.64 10.32,8.76 11.16,8.88 12.12,9.6 
	13.32,9.96 15,10.2 16.32,10.32 17.16,9.96 18.72,9.36 19.44,8.64 19.8,7.8 19.8,6.12 20.04,4.32 20.28,3 21.24,1.32 21.96,0.72 
	22.8,0.24 25.08,0.24 26.28,0.24 27.84,0.48 29.88,1.44 30.36,1.92 30.84,3 31.08,3.96 31.56,5.4 32.16,6.72 33,7.8 34.56,8.76 
	35.76,8.88 37.44,8.16 38.64,7.44 39.72,6.24 40.2,5.04 41.16,3.24 42.12,2.28 43.32,1.32 44.16,0.96 45.36,1.2 46.92,1.68 
	48.24,2.16 49.08,3.36 49.68,4.32 50.64,6.12 51,4.56 51.84,3.48 53.16,2.16 54.96,1.08 56.52,0.48 59.04,0.24 61.44,0.24 
	64.56,1.8 65.16,2.52 65.4,3 65.88,4.08 66.6,6.24 66.72,6.6 67.44,4.92 68.04,3.36 69.12,1.8 70.68,0.72 72.24,0.12 73.92,0 
	75.48,0 77.16,0 79.44,0 81.24,0.36 83.88,1.2 85.44,1.92 86.76,2.76 87.72,3.84 88.44,6 88.68,7.56 89.04,6.6 89.64,4.44 
	91.08,2.76 93.36,1.32 94.56,0.84 96.84,0.72 98.64,0.84 100.2,1.2 102.48,2.28 103.68,3 104.28,3.6 105.72,6.48 106.08,7.08 
	106.68,8.64 106.68,6.84 108.12,4.92 109.32,3.24 111,1.8 113.28,0.84 116.28,0.6 119.28,0.84 121.32,1.32 123.72,4.44 123.84,6.72 
	123.6,9 122.4,10.44 121.56,11.88 124.2,11.52 126.36,12.72 128.04,14.04 128.76,15.24 129.36,16.44 129.6,18.12 129.6,19.92 
	129.36,21.84 128.28,24.72 126.48,26.64 124.56,27.48 121.56,27.6 119.76,27.48 121.56,29.28 121.8,30.36 123.24,32.4 123.96,36.24 
	123.48,37.8 123,39.36 121.56,40.56 120,41.16 118.44,41.76 117.12,42.12 114.6,42.24 112.2,41.52 110.52,40.92 109.56,39.48 
	109.2,37.68 108.6,36 108.48,36.36 108.12,38.16 107.52,39.96 106.8,40.56 105,41.88 103.56,42.12 101.4,42.12 99.96,41.16 
	98.88,39.6 98.28,38.4 97.56,36.96 97.32,36.12 96.48,35.28 94.68,35.16 92.64,35.4 90.72,36.6 90.12,38.04 89.64,39.72 
	89.28,41.04 87.96,42.12 85.2,41.88 82.8,41.28 81,40.2 79.8,38.52 78.84,35.76 78.48,35.4 77.76,37.08 76.8,39.12 74.52,40.56 
	71.76,41.04 68.76,41.28 66.36,41.16 65.4,40.8 63.48,40.08 62.16,38.88 61.56,37.56 61.32,36.48 61.08,34.56 61.08,34.8 
	60.96,36.36 60.48,38.28 59.88,39.48 59.04,40.2 56.76,40.92 53.64,41.04 51.48,40.56 50.16,39.72 49.68,38.28 48.84,35.16 
	48.12,36.96 47.16,38.88 45.48,40.32 42.6,40.8 41.04,41.04 38.16,41.28 35.64,41.04 33.96,40.56 32.28,40.2 31.08,39.6 
	29.88,38.88 29.28,37.92 28.92,36.96 28.68,36 28.44,35.4 27.84,36.6 26.76,37.8 24.6,39 23.16,39.72 21.12,40.68 19.32,41.28 
	17.04,41.52 16.2,41.52 14.4,41.28 12.84,41.04 11.64,40.08 10.8,39.48 10.32,38.16 10.08,36.84 10.32,35.28 10.68,34.08 
	11.16,33.36 11.88,32.4 12.12,31.8 10.92,32.16 9.12,32.04 7.32,31.68 6,31.32 4.92,30.6 4.32,29.52 4.08,28.2 4.32,26.52 
	5.52,24.96 6.72,23.76 7.92,23.04 "/>
    </svg>
} 

const Arkivet =()=>{
    return <svg version="1.1" x="0px" y="0px" viewBox="0 0 111.48 41.16" >
<polygon className="meny-sky" points="6.12,17.76 5.52,17.52 4.56,16.56 4.2,15.24 4.2,13.68 4.56,11.52 4.8,10.2 5.04,9.24 5.52,7.92 6.36,6.72 
	7.44,5.76 8.76,4.92 10.08,4.32 12.36,3.84 14.04,4.08 14.52,4.08 15.96,4.08 16.8,4.56 17.64,5.16 18.36,5.88 19.32,6.6 
	20.04,7.68 20.28,8.4 20.28,6.72 21.12,5.16 22.08,4.08 23.16,3.24 24.36,2.52 25.56,1.92 26.52,1.56 28.08,1.2 30.12,0.96 
	31.56,0.84 33.36,1.08 34.92,1.68 36.12,2.28 36.84,2.88 37.8,3.48 38.64,5.04 39.12,6.72 39.48,4.68 40.44,3.48 41.64,2.28 
	43.32,1.2 45.12,0.6 46.8,0.6 49.44,1.44 51,2.16 51.72,2.64 52.8,3.48 53.64,4.56 54.36,6.24 55.2,7.44 56.28,8.52 57.6,8.88 
	58.8,8.88 60.6,8.88 62.76,8.04 63.24,7.56 63.72,6.84 64.2,5.52 64.44,4.68 64.8,3.48 65.28,2.4 65.88,1.08 66.36,0.6 67.2,0.24 
	69.72,0 71.16,0 72.36,0.6 73.56,1.56 74.64,2.88 75.12,3.84 75.24,6 75.6,3.12 76.44,1.92 77.52,1.08 79.44,0.36 82.32,0 84.48,0 
	86.76,0.24 88.68,1.2 89.64,2.28 90.36,3.12 91.08,5.28 91.32,6.6 91.32,4.44 91.56,2.88 92.64,1.56 93.96,0.84 95.52,0.6 
	97.68,0.6 100.32,1.08 102.6,1.8 103.8,2.64 104.76,4.8 105.24,6.72 104.88,9 103.8,10.92 102.12,12.6 103.92,12.24 105.96,12.12 
	108.72,12.24 110.04,12.6 111.12,13.08 111.48,13.92 111.48,15.12 111.36,16.44 111,17.76 110.16,18.96 109.68,19.44 108.48,20.4 
	106.56,21.36 106.32,21.48 108.12,21.6 108.84,22.08 109.68,22.56 110.4,23.76 110.88,24.72 111,26.16 110.88,27.84 110.16,30 
	109.92,30.84 109.56,31.56 108.72,32.16 107.4,32.52 106.08,32.76 104.88,32.76 102.96,32.64 102.24,32.52 102.48,33.72 
	102.36,34.8 102.48,36.6 102,37.92 101.28,39 99.72,39.84 97.32,40.32 95.16,40.2 93.12,39.72 91.8,39 90.24,38.28 88.56,36.48 
	87.36,35.04 86.4,33.84 85.32,33.36 83.52,33.48 82.32,34.44 82.2,36.12 82.2,37.8 81.96,39.24 81.36,39.84 80.64,40.44 78.96,40.8 
	77.88,41.04 76.68,41.04 74.88,41.04 72.6,41.16 70.56,40.32 69.72,39 68.76,37.32 68.16,35.76 67.92,35.16 67.8,36.6 67.32,37.44 
	66.24,39 65.4,39.72 63.12,40.44 61.68,40.8 59.64,41.04 57.24,41.04 55.8,40.2 54.96,39.12 54.36,38.04 53.88,36.72 53.52,35.88 
	53.28,34.2 53.16,33.36 52.92,34.56 52.56,35.76 52.08,37.32 51.36,38.52 50.28,39.48 48.96,40.08 47.28,40.2 45.48,39.96 44.04,39 
	41.76,38.04 40.56,36.12 40.08,34.2 39.36,32.88 37.92,31.92 36,30.96 34.2,30.84 31.68,31.32 31.2,32.64 31.56,35.16 31.8,36.12 
	31.8,37.32 31.2,38.64 30.24,39.72 27.6,40.68 25.8,40.68 23.88,40.68 22.44,39.36 21.6,38.4 20.88,36.84 19.68,34.32 19.2,33.12 
	19.2,32.64 18.72,33.48 17.64,34.92 15.6,36.36 12.6,37.08 10.92,37.2 8.52,36.96 6.84,36.24 5.4,34.8 4.44,33.36 3.96,32.28 
	3.72,31.08 3.96,29.52 4.68,28.44 5.64,27.48 4.08,28.08 2.28,28.08 1.08,27.72 0.36,26.16 0.12,24.72 0,23.28 0,21.48 0.36,20.16 
	1.2,19.08 2.16,18.24 3.36,17.52 4.68,17.04 "/>
    </svg>
} 

const Kartet =()=>{
    return <svg version="1.1" x="0px" y="0px" viewBox="0 0 114.12 38.52" >
<polygon className="meny-sky" points="10.08,15.96 8.4,15.48 7.44,13.32 7.44,10.92 7.32,9.84 7.32,7.08 7.8,5.88 8.52,4.44 9.96,2.88 10.56,2.4 
	11.52,1.92 12.48,1.2 13.44,0.72 14.28,0.36 14.88,0.24 15.48,0.12 16.44,0 18.96,0.24 20.04,0.48 21.12,0.96 22.32,1.56 
	23.16,2.16 23.04,2.16 22.68,2.52 22.44,3 22.32,3.48 22.2,4.08 22.08,4.8 22.2,5.4 22.56,6.24 23.04,6.72 24.48,7.44 25.8,7.8 
	27.24,8.16 28.92,7.68 29.76,6.96 30.24,6.36 30.84,5.4 31.2,4.68 31.68,3.96 32.4,3.24 33.12,2.28 34.2,1.56 34.68,1.2 36.12,0.72 
	37.56,0.36 39.72,0.48 41.16,0.72 42.48,1.08 43.2,1.44 43.92,1.92 44.76,2.4 45.36,3.24 45.84,3.84 46.2,4.68 46.8,5.76 46.8,5.4 
	47.52,4.08 48.12,3.24 49.2,2.64 51.36,1.68 52.2,1.2 53.52,0.6 54.96,0.36 55.92,0.36 57.24,0.24 59.04,0.24 60.36,0.24 61.8,0.48 
	62.76,1.08 64.32,2.28 64.8,3.24 65.04,4.8 65.52,6.12 66.72,6.96 68.28,7.2 69.72,6.84 71.04,6.24 71.52,5.4 71.64,4.44 71.4,3.6 
	70.92,3 70.92,2.4 71.16,2.04 71.64,1.56 72.12,1.32 72.96,1.2 74.88,1.44 76.32,1.8 78.12,2.28 79.2,2.52 79.92,2.76 80.76,3.24 
	81.6,3.84 82.08,4.44 82.8,6.96 82.68,4.92 83.64,3.96 84.48,2.88 85.68,2.04 86.88,1.56 87.72,1.32 89.88,1.32 91.32,1.32 
	92.64,1.68 93.72,2.28 94.68,2.76 95.76,3.24 96.48,3.72 97.08,4.32 97.56,5.04 97.8,4.32 98.52,3.24 99.12,2.64 99.72,2.04 
	100.8,1.56 102.96,1.32 103.92,1.32 105.12,1.32 106.32,2.04 107.64,2.64 108.6,3.48 110.04,6.12 110.76,7.08 111,7.92 111.12,8.88 
	111.12,9.84 110.76,11.04 110.64,11.64 109.92,12.6 109.44,13.32 109.08,14.04 109.68,15.12 110.4,15.36 111.48,15.48 112.44,15.24 
	113.16,14.4 113.52,13.8 113.64,13.32 114,15 114.12,15.96 114.12,17.88 114,19.32 113.64,21.24 113.04,23.04 112.44,24 
	111.72,24.72 110.04,26.28 109.2,26.4 108.24,27 107.76,27.6 107.4,28.56 107.16,29.28 106.8,32.16 106.68,33.24 106.68,35.76 
	106.2,36.96 105.12,38.04 103.32,38.4 101.28,38.52 99.12,38.04 97.08,37.56 95.64,36.84 94.2,36.12 93.36,35.16 92.76,34.08 
	92.52,33.48 92.64,33.6 91.8,35.04 90,36.36 88.2,37.08 85.44,37.8 82.08,38.4 80.4,38.16 77.76,37.68 76.44,37.08 75.36,36.12 
	74.76,35.28 74.28,34.2 73.68,32.64 73.44,31.56 73.44,31.44 73.08,32.28 72.6,33.96 71.52,35.52 69.72,37.08 67.8,37.56 
	64.92,37.68 61.68,36.84 59.76,36.48 57,34.92 56.16,33.72 55.68,32.4 55.44,30.96 54.24,31.44 53.52,32.04 53.28,32.76 
	53.04,33.72 53.04,34.68 53.4,35.64 53.88,36.48 54.84,37.2 53.16,37.44 52.2,37.44 50.4,37.44 47.52,37.56 46.68,37.44 
	44.88,36.96 43.92,36.36 42.84,35.64 42.12,34.8 41.28,33.6 40.44,31.92 39.96,30.72 39.36,29.16 39.24,29.04 38.88,30.12 
	38.16,31.8 37.68,32.88 37.32,33.96 36.6,35.52 35.28,36.84 33.36,37.92 30.96,38.04 28.68,38.04 26.52,37.2 25.08,36.12 
	24.36,34.92 23.88,33 23.4,31.56 23.16,30.24 22.68,30.84 21.96,32.64 21.12,34.56 20.28,35.76 18.36,36.84 15.96,37.44 
	14.28,37.56 12.12,37.44 10.2,36.96 8.28,35.76 6.96,34.08 6.36,31.92 6.36,30.48 6.96,28.92 7.32,28.56 4.44,28.92 2.88,28.68 
	1.56,27.96 0.6,26.64 0,25.08 0,22.56 0.24,21 0.72,19.44 1.32,18.36 2.88,17.4 4.44,16.8 6.72,16.2 "/>
    </svg>
} 

const Menu=({pageStateHandler, zoomHandler, menuOpen, toggleMenuOpen, toggleMusicContent})=>{

	const isNarrow = useMediaQuery({ query: '(max-width: 1100px)' })
	const [flowerHidden, setFlowerHidden] = useState(true);

	const navigate = useNavigate();

return(<>
{isNarrow ? 
	<div className={`menu-wrapper-mobile ${menuOpen? 'menu-open' : 'menu-closed'}`}>
		<div className="menu-content">
			<div className="menu-item" onClick={()=>{pageStateHandler('Organotopia');toggleMenuOpen(false)}}>
				<div className='menu-label'>Organotopia</div>
				<Organotopia/>
			</div>
			<div className="menu-item" onClick={()=>{toggleMenuOpen(false);window.location.href = '/musikken'}}>
				<div className='menu-label'>Musikken</div>
				<Musikken/>
			</div>
			<div className="menu-item" onClick={()=>{pageStateHandler('Kartet');toggleMenuOpen(false)}}>
				<div className='menu-label'>Kartet</div>
				<Kartet/>
			</div>
			<div className="menu-item" onClick={()=>{navigate('/arkivet');toggleMenuOpen(false)}}>
				<div className='menu-label'>Arkiv</div>
				<Arkivet/>
			</div>
			{menuOpen && 
			<div className={`menu-corner-flower-wrapper ${flowerHidden ? 'menu-hidden' : ''}`} onClick={()=>setFlowerHidden(!flowerHidden)}>
				<div className="menu-corner-flower-text">
				{flowerHidden ? <>?</> : <>Dra i kartet<br/>og klikk på<br/>figurene for å<br/>utforske historier</>}
				</div>
				<div className="menu-corner-flower-background">
					<svg version="1.1" id="Layer_1"x="0px" y="0px" viewBox="0 0 1000 1000">
					<line className="fl" x1="325.52" y1="904.62" x2="325.52" y2="904.62"/>
					<path className="fl" d="M640.37,77.96c5.53-36.21-18.4-70.3-53.97-76.86c-35.56-6.56-69.33,16.69-76.75,52.55
						c-7.92-33.25-39.09-55.26-72.6-51.28c-33.51,3.98-62.78,34.17-62.9,68.37c-17.61-29.55-54.35-40.9-85.23-26.34
						c-30.88,14.56-47.28,52.54-36.32,85.22c-25.66-23.3-64.6-22.97-89.86,0.78c-25.26,23.74-30.43,64.37-9.44,92.06
						c-31.29-13.42-67.53-0.86-84.16,29.17c-16.63,30.03-8.67,69.9,18.94,89.97c-35-5.17-67.9,18.25-74.97,53.38
						c-7.07,35.13,12.75,68.56,46.95,77.71c-36.52,3.98-62.99,37.14-59.21,74.18c3.78,37.03,36.99,62.82,73.54,59.14
						c-25.26,6.85-44.24,28.02-48.55,54.16c-4.31,26.14,6.83,52.42,28.52,67.23s49.9,15.41,72.18,1.54
						c-20.95,14.83-29.65,43.62-25.63,69.2c4.02,25.58,22.1,46.58,46.56,54.08c24.46,7.5,48.89-0.44,66.22-19.47
						c-17.34,31.64-7.03,71.51,23.4,90.45c30.43,18.94,70.25,9.29,90.24-20.7c-8.26,24.17-2.35,50.99,15.26,69.31
						c17.61,18.32,43.91,24.99,67.96,17.25c24.05-7.74,38.93-27.18,42.88-52.44c2.37,37,33.69,65.17,70.22,63.13s64.62-33.51,62.97-70.55
						c17.78,24.47,53.14,33.24,81.14,22.41c28.01-10.83,45.45-39.27,42.63-69.52c24.45,20.95,67.15,16.48,92.04-3.93
						c24.88-20.41,30.26-54.6,15.15-83.2c19.78,14.29,45.66,16.55,67.56,5.9c21.9-10.65,36.36-32.53,37.74-57.11
						c1.38-24.58-10.53-47.98-31.1-61.09c26.72,11.17,59.36,0.98,80.6-18.89s29.16-50.43,20.31-78.32s-32.87-48.03-61.55-51.62
						c27.02-1.97,50.81-21.36,62.11-46.32c11.3-24.95,8.29-54.16-7.85-76.21c-16.14-22.05-42.85-33.43-69.69-29.7
						c24.92-10.5,41.39-34.65,44.39-61.84c3-27.19-8.8-53.89-30.81-69.72c-22.02-15.83-47.4-13.32-71.73-1.46
						c30.4-26.36,34.72-72.39,9.76-104.09c-24.95-31.69-70.24-38.67-102.34-14.47c8.18-32.56-8.47-66.26-39.08-79.14
						C695.27,38.01,657.32,49.1,640.37,77.96z"/>
					</svg>
				</div>
			</div>
			}
			<div className={`menu-instagram-button-mobile ${!menuOpen && 'ig-button-hidden'}`}>
				<a href='https://www.instagram.com/organotopia/'>
					<div className="instagram-button">
						<RiInstagramLine/>
					</div>
				</a>
			</div>
			<div className="menu-toggle-button-wrapper" onClick={()=>{toggleMenuOpen(!menuOpen);setFlowerHidden(true)}}>
				{menuOpen ? <BiX /> : <AiOutlineMenu />}
			</div>
		</div>
	</div>
:
<div className="menu-wrapper">
    <strong onClick={()=>pageStateHandler('Organotopia')} style={{width:'155px'}}>
        <div className='menu-label'>Organotopia</div>
        <Organotopia/>
    </strong>
    <strong onClick={()=>{window.location.href = '/musikken'}}>
        <div className='menu-label'>Musikken</div>
        <Musikken/>
    </strong>
    <strong onClick={()=>pageStateHandler('Kartet')}>
        <div className='menu-label'>Kartet</div>
        <Kartet/>
    </strong>
    <strong onClick={()=>navigate('/arkivet')}>
        <div className='menu-label'>Arkivet</div>
        <Arkivet/>
    </strong>
	<a href='https://www.instagram.com/organotopia/'>
		<div className="instagram-button">
			<RiInstagramLine/>
		</div>
	</a>
    {/* <div className="zoom-knapp-wrapper">
        <strong onClick={()=>zoomHandler(0.2)}>
            <div className='zoom-knapp'><BiZoomIn/></div>
        </strong>
        <strong onClick={()=>zoomHandler(-0.2)}>
            <div className='zoom-knapp'><BiZoomOut/></div>
        </strong>
    </div> */}
</div>
}

</>)
}
export default Menu