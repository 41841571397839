import React from "react";

import './graphics.styles.scss'

const KirkeIkon =({kategori})=>{

return(
<div>
    {kategori == 'Orgel'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 52.41 51.32">
<path d="M29.23,4.03l3.53-2.02l-5.04,6.05v5.17c5.54,0.8,9.91,5.92,10.58,11.46h5.54l6.55-5.04l-2.02,3.02l-1.01,1.51l5.04,1.51
	l-5.04,2.02l3.02,4.54l-6.55-5.04H38.3c-0.67,5.67-5.21,9.83-10.88,10.5v5.54l5.04,6.05l-3.53-2.02l-1.51-0.5l-1.51,4.54l-1.51-4.54
	l-1.51,0.5l-3.53,2.02l5.04-6.05v-5.54c-5.54-0.71-9.87-5-10.67-10.5H8.57l-6.55,5.04l3.02-4.54L0,25.7l5.04-1.51l-1.01-1.51
	l-2.02-3.02l6.55,5.04h5.21c0.71-5.63,5.04-10.83,10.71-11.51l0.21-5.12l-5.04-6.05l3.53,2.02l1.51,0.5L26.2,0l1.51,4.54L29.23,4.03
	z"/>
        </svg>
    :''}
        {kategori == 'Bakgrunnsstoff'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 48.12 44.8">
<path d="M41.9,0l-0.45,25.72c-1.11,2.35-1.07,5.08,0.17,7.36c1.24,2.32,3.91,3.91,6.5,4.25l-6.64,7.47l-8.23-8.64
	c-5.32,4.87-13.48,4.87-18.8,0L6.64,44.8L0,37.33c2.59-0.35,4.42-1.94,5.67-4.25c1.24-2.28,1.28-5.01,0.17-7.36L6.22,0
	c8.71,1.21,15.59,7.36,17.63,15.9C25.89,7.36,33.18,1.21,41.9,0z"/>
        </svg>
    :''}
        {kategori == 'Musikere'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 48.12 44.8">
<polyline points="23.86,34.28 35.94,39.73 35.94,44.8 41,39.73 46.07,44.8 46.07,37.79 30.49,26.88 36.33,24.54 42.17,13.63 
	47.24,21.82 47.24,1.17 42.17,9.74 30.49,0 30.49,13.63 23.86,6.62 17.24,13.63 17.24,0 5.55,9.74 0.88,1.17 0.88,21.82 5.55,13.63 
	11.79,24.54 17.24,26.88 1.66,37.79 1.66,44.8 6.72,39.73 11.79,44.8 11.79,39.73 23.86,34.28 "/>
        </svg>
    :''}
        {kategori == 'Lydsnutter'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 36.28 44.8">
<polyline points="8.15,22.58 5.55,21.84 2.22,19.99 1.11,17.03 1.11,14.81 2.22,9.26 5.55,5.92 5.18,5.18 4.44,5.55 2.96,5.55 
	1.85,4.81 1.48,4.07 1.48,2.59 3.7,0.74 5.55,0 7.78,0.37 8.15,1.48 8.15,2.59 8.15,3.7 7.03,4.07 7.4,5.18 10,4.07 13.7,4.07 
	16.29,5.18 18.14,6.29 19.62,5.18 22.21,4.07 25.92,4.07 28.51,5.18 28.88,4.07 27.77,3.7 27.77,2.59 27.77,1.48 28.14,0.37 
	30.36,0 32.21,0.74 34.43,2.59 34.43,4.07 34.06,4.81 32.95,5.55 31.47,5.55 30.73,5.18 30.36,5.92 33.69,9.26 34.8,14.81 
	34.8,17.03 33.69,19.99 30.36,21.84 27.77,22.58 30.36,22.95 34.06,24.81 35.54,27.4 35.54,29.99 34.8,35.17 31.84,38.88 
	32.21,39.62 32.95,39.25 34.43,39.25 35.54,39.62 35.91,40.73 36.28,42.21 34.06,44.06 32.58,44.8 30.36,44.8 29.62,43.69 
	29.62,42.58 29.62,41.47 30.73,41.1 29.99,39.99 27.4,41.1 24.07,41.47 21.1,40.73 19.25,39.62 18.14,36.65 16.66,39.62 
	14.81,40.73 11.85,41.47 8.52,41.1 5.92,39.99 5.55,41.1 6.29,41.47 6.29,42.58 6.29,43.69 5.55,44.8 3.33,44.8 1.85,44.06 0,42.21 
	0,40.73 0.37,39.62 1.48,39.25 2.96,39.25 3.7,39.62 4.07,38.88 1.11,35.17 0.37,29.99 0.37,27.4 1.85,24.81 5.55,22.95 8.15,22.58 
	"/>
        </svg>
    :''}
        {kategori == 'Noteark'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 46.29 44.8">
<path d="M23.14,39.56c4.3,0.07,8.37,1.98,11.18,5.24l-5.77-13.56c2.53-1.19,4.35-3.51,4.9-6.25l3.82,2.43v3.12l1.39-2.43l2.08,1.73
	v2.78l1.39-2.08l3.47,2.08l-2.08-3.12l2.78-2.43l-3.82,1.39l-2.78-1.73l3.47-2.08L39,25.68l-5.55-3.12L39,19.43l4.16,1.04
	l-3.47-2.08L42.47,17l3.82,1.04l-2.78-2.08l2.08-3.47l-3.47,2.08l-1.39-2.08v2.78L38.65,17l-1.39-2.43v3.12l-4.51,2.43
	c-0.91-2.28-2.67-4.41-4.95-5.3L34.83,0c-2.93,3.4-7.21,5.32-11.69,5.24C18.66,5.32,14.38,3.4,11.45,0l7.04,14.83
	c-2.29,0.89-4.04,3.02-4.95,5.3L9.02,17.7v-3.12L7.63,17l-2.08-1.73v-2.78l-1.39,2.08l-3.47-2.08l2.08,3.47L0,18.04L3.82,17
	l2.78,1.39l-3.47,2.08l4.16-1.04l5.55,3.12l-5.55,3.12l-4.16-1.04l3.47,2.08l-2.78,1.73L0,27.06l2.78,2.43l-2.08,3.12l3.47-2.08
	l1.39,2.08v-2.78l2.08-1.73l1.39,2.43v-3.12l3.82-2.43c0.55,2.74,2.37,5.06,4.9,6.25L11.96,44.8
	C14.77,41.54,18.84,39.63,23.14,39.56z"/>
        </svg>
    :''}
</div>
)
}

export default KirkeIkon
