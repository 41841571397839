import React from "react";

import './graphics.styles.scss'

const Ikon =({kategori})=>{

return(
<div className="arkiv-ikon">
    {kategori == 'kat1'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 73.37 76.65">
<path className="fødeIkon" d="M9.94,46.78c-8.83-0.49-9.41-2.43-9.9-5.82c-0.69-4.85,6.79-6.11,6.79-6.11s-4.76-0.29-4.46-5.14
	c0.35-5.91,8.25-5.92,8.25-5.92s-6.21-0.78-5.63-5.82s5.82-6.11,5.82-6.11S6.16,10.58,8,7.96s7.96-3.2,7.96-3.2
	C16.06,3.4,15.57,0.2,20.43,0s3.59,5.73,3.59,5.73c0.87,0,5.73,0.68,6.02,3.59c0.29,2.91-6.6,4.37-6.6,4.37
	c4.56,0.58,6.6,1.84,6.6,5.14s-8.15,4.17-8.15,4.17c4.66-0.19,8.15-0.1,8.15,2.23s-3.79,4.46-3.79,4.46s12.24-3.45,20.87,0
	c0,0-3.79-2.14-3.79-4.46s3.49-2.43,8.15-2.23c0,0-8.15-0.87-8.15-4.17s2.04-4.56,6.6-5.14c0,0-6.89-1.46-6.6-4.37
	c0.29-2.91,5.14-3.59,6.02-3.59c0,0-1.26-5.92,3.59-5.73s4.37,3.4,4.46,4.76c0,0,6.11,0.58,7.96,3.2c1.84,2.62-2.81,3.88-2.81,3.88
	s5.24,1.07,5.82,6.11c0.58,5.05-5.63,5.82-5.63,5.82s7.89,0.01,8.25,5.92c0.29,4.85-4.46,5.14-4.46,5.14s7.49,1.27,6.79,6.11
	c-0.49,3.4-1.07,5.34-9.9,5.82c0,0-0.12,29.87-25.47,29.87S9.94,46.78,9.94,46.78z"/>
        </svg>
    :''}
        {kategori == 'kat2'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 89.89 89.56">
<path className="byggeIkon" d="M83.38,47.87c-6.95-2.58-7.51-3.14-7.51-3.14s0.56-0.56,7.51-3.14c6.95-2.58,6.5-6.95,6.5-6.95
	s0.22-8.41-7.62-7.85c-7.85,0.56-6.87,8.39-6.87,8.72c0,0.34-3.76,0.8-4.85,2.88C69.17,41.03,68,40.48,68,40.48v-8.12H57.32V21.89
	H49.2c0,0-0.55-1.18,2.08-2.55c2.08-1.08,2.54-4.85,2.88-4.85c0.34,0,8.16,0.98,8.72-6.87c0.56-7.85-7.85-7.62-7.85-7.62
	s-4.37-0.45-6.95,6.5c-2.58,6.95-3.14,7.51-3.14,7.51s-0.56-0.56-3.14-7.51c-2.58-6.95-6.95-6.5-6.95-6.5s-8.41-0.22-7.85,7.62
	c0.56,7.85,8.39,6.87,8.72,6.87c0.34,0,0.8,3.76,2.88,4.85c2.63,1.38,2.08,2.55,2.08,2.55h-8.12v10.46H21.89v8.12
	c0,0-1.18,0.55-2.55-2.08c-1.08-2.08-4.85-2.54-4.85-2.88c0-0.34,0.98-8.16-6.87-8.72c-7.85-0.56-7.62,7.85-7.62,7.85
	s-0.45,4.37,6.5,6.95c6.95,2.58,7.51,3.14,7.51,3.14s-0.56,0.56-7.51,3.14c-6.95,2.58-6.5,6.95-6.5,6.95s-0.22,8.41,7.62,7.85
	c7.85-0.56,6.87-8.39,6.87-8.72c0-0.34,3.76-0.8,4.85-2.88c1.37-2.63,2.55-2.08,2.55-2.08v8.12h10.68V68h8.16
	c-0.02,0.44-0.31,1.28-2.12,2.23c-2.08,1.08-2.54,4.85-2.88,4.85c-0.34,0-8.16-0.98-8.72,6.87c-0.56,7.85,7.85,7.62,7.85,7.62
	s4.37,0.45,6.95-6.5c2.58-6.95,3.14-7.51,3.14-7.51s0.56,0.56,3.14,7.51c2.58,6.95,6.95,6.5,6.95,6.5s8.41,0.22,7.85-7.62
	c-0.56-7.85-8.39-6.87-8.72-6.87c-0.34,0-0.8-3.76-2.88-4.85c-1.81-0.94-2.11-1.79-2.12-2.23h8.16V57.1H68v-8.12
	c0,0,1.18-0.55,2.55,2.08c1.08,2.08,4.85,2.54,4.85,2.88c0,0.34-0.98,8.16,6.87,8.72c7.85,0.56,7.62-7.85,7.62-7.85
	S90.33,50.44,83.38,47.87z"/>
        </svg>
    :''}
        {kategori == 'kat3'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 99.13 99.13">
<path className="eieIkon" d="M28,36.23c0,0-9.47-6.98-17.54-7.14s-10.24,2.48-10.4,4.35c-0.16,1.86,1.71,4.81,6.05,5.74
	c0,0-6.38,0.31-6.06,4.35s6.68,4.35,6.68,4.35s-7.18-0.31-6.69,3.88s5.14,4.5,7.16,4.66s-7.69,0.76-7.18,5.43
	c0.41,3.77,3.66,4.47,7.8,3.88C11.59,65.19,24.48,62,28,59.2c0,0,2.74,6.27,10.3,11.93c0,0-6.98,9.47-7.14,17.54
	c-0.16,8.07,2.48,10.24,4.35,10.4c1.86,0.16,4.81-1.71,5.74-6.05c0,0,0.31,6.38,4.35,6.06s4.35-6.68,4.35-6.68s-0.31,7.18,3.88,6.69
	c4.19-0.49,4.5-5.14,4.66-7.16c0.16-2.02,0.76,7.69,5.43,7.18c3.77-0.41,4.47-3.66,3.88-7.8c-0.54-3.77-3.72-16.65-6.52-20.18
	c0,0,9.09-5.11,9.86-12.37c0,0,9.47,6.98,17.54,7.14c8.07,0.16,10.24-2.48,10.4-4.35s-1.71-4.81-6.05-5.74c0,0,6.38-0.31,6.06-4.35
	s-6.68-4.35-6.68-4.35s7.18,0.31,6.69-3.88c-0.49-4.19-5.14-4.5-7.16-4.66c-2.02-0.16,7.69-0.76,7.18-5.43
	c-0.41-3.77-3.66-4.47-7.8-3.88c-3.77,0.54-16.65,3.72-20.18,6.52c0,0-3.43-6.05-10.3-7.79c0,0,6.98-9.47,7.14-17.54
	c0.16-8.07-2.48-10.24-4.35-10.4c-1.86-0.16-4.81,1.71-5.74,6.05c0,0-0.31-6.38-4.35-6.06s-4.35,6.68-4.35,6.68s0.31-7.18-3.88-6.69
	s-4.5,5.14-4.66,7.16s-0.76-7.69-5.43-7.18c-3.77,0.41-4.47,3.66-3.88,7.8c0.54,3.77,3.72,16.65,6.52,20.18
	C37.86,28,31.48,31.98,28,36.23z"/>
        </svg>
    :''}
        {kategori == 'kat4'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 76.49 98.43">
<path className="traskeIkon" d="M73.93,82.22c-2.35-4.6-8.03-6.42-8.03-6.42c4.49-0.11,10.08,3,10.08,3c0-11.45-9.87-13.7-9.87-13.7
	c6.96-0.21,9.87,2.25,9.87,2.25c-0.51-2.83-1.28-8.54-4.88-11.88c-3-2.78-7.56-2.36-9.01-2.14v-22.7l-0.08-19.48
	c1.66,0,4.23-0.91,4.23-4.31s-5.31-3.07-5.31-3.07S61.33,0,58.63,0c-2.7,0-2.52,3.6-2.52,3.6c-2.88,0-5.04,0.26-5.04,2.88
	c0,3.24,5.04,3.24,5.04,3.24c0,16.04-6.13,20.9-6.13,20.9H34.81l-6.88-5.98c0,0-1.68-1.8-4.58-1.8c-2.9,0-5.89,2.39-5.89,7.78v22.9
	h-1.12c0,0-11.67,0.3-14.66-2.69c0,0-2.73,8.73,10.15,12.65c0,0-5.94,1.55-9.64-2.14c0,0-0.39-0.07-0.32,3.01
	c0.07,3.29,2.12,8.43,10.18,11c0,0-5.6,1.9-9.18-2.02c0,0-2.02,2.02-0.11,6.05c1.9,4.03,6.5,6.61,10.64,7.73
	c0,0-8.96,0.78-12.88-2.58c0,0-1.57,2.8,0.78,7.06s6.56,6.83,15.04,6.83h10.03V75.27h25.31l-0.97,2.14
	c0.64,1.61,4.39,3.85,4.39,3.85c-6.96,2.89-6.1,7.81-6.1,7.81c4.07-2.25,8.13-2.03,13.91-2.03c9.74,0,13.59,3.42,13.59,3.42
	C76.17,86.93,76.28,86.82,73.93,82.22z"/>
        </svg>
    :''}
        {kategori == 'kat5'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 74.44 82.49">
<path className="lekeIkon" d="M74.44,82.49V71.33c0,0-24.47-1.38-29.98-18.26c0,0,6.55,7.93,12.41,4.14c5.86-3.79,1.58-10.57,0.89-11.6
	c2.68,0.3,9.1-0.46,9.79-5.63s-2.76-8.27-6.2-8.62c4.65-1.94,6.3-4,5.86-7.58c-0.67-5.48-5.51-7.24-9.3-6.2
	c3.14-5.04,3.1-9.59-1.72-12.06c-4.81-2.47-7.93,1.72-9.3,3.45C46.52,5.62,45.49,0,38.6,0S31.7,7.24,31.7,7.24s-5.17-5.17-10.34-3.1
	s-4.21,11.17-4.21,11.17s-8.44-1.46-10.2,3.81c-1.44,4.33,0.26,8.41,5.77,10.82c0,0-7.78,4.06-6.52,10.73
	c0.75,4.01,5.7,6.99,12.06,5.51c0,0-4.82,6.55-0.34,9.99c4.48,3.45,12.41-2.76,12.41-2.76S21.71,71.33,0,71.33v11.15
	c0,0,28.5-1.81,37.22-21.33C37.22,61.16,47.08,80.86,74.44,82.49z"/>
        </svg>
    :''}
	{kategori == 'musikk-ikon' &&
		<svg  x="0px" y="0px" viewBox="0 0 166.49 119.98" >
		<path className="musikkIkon" d="M83.26,94.16l0.37,4.08h0v0.75h0l0.2,0.51H83.6l1.5,4.87l0,0l2.25,4.12h0.43l2.23,2.86h0l0.75,0.37l0,0l3,3h0
	l0.38,0.37l3.75,2.25h0l0.75,0.38h0l4.12,1.5h0.38l4.5,0.75h5.25l3.75-0.75h1.13l3.75-1.5h0l0.75-0.37h0l3.75-2.25l0,0l0.37-0.37h0
	l3-3l0,0l0.75-0.37h0l2.23-2.86h0.36l2.25-4.12l0,0l0.35-1.52l0.06-0.11h-0.03l0,0h0.03l1.12-3.75h0v-0.75h0l0.38-4.12v0v-0.37v0
	v-0.37h0l0.75,0.37h0l1.88,2.25h0.37l1.87,1.87h0l1.87,1.87l-0.75,1.12l0,0l-1.5,3h0l-0.75,1.12h0l-0.37,0.75l0,0l-0.37,0.75h0
	l-1.12,1.88h0v3.75v0l0.37,1.12h0l1.88,2.25h0l2.25,1.12h2.62l0.75-1.12h0l1.5-2.25h0v-1.12v0l0.75-3.75h0l0.37-1.87l0.75,3.37h0
	l0.37,2.25h0l0,0l0,0h0l0.37,0.75h0l0.37,1.5h0h0l0,0v0h0l4.87,2.25h3.75l1.5-2.25h0v-1.5h0v-0.75h0v-2.25h0l-3.75-3.37h0
	l-1.12-0.75l-0.75-0.75h0l-1.87-1.12h4.87l3.37-3h0l1.12-1.12h0l1.5-1.88h0v-1.87h0l-1.12-1.87h0l-0.75-1.5h-1.5l-4.5-0.37l-3,0.37
	h-0.37l-3,1.5h0l-2.62,1.87H147l-1.87-2.25h0l-0.75-0.38h0L144,92.61h0l-2.25-1.87l0,0l4.5-0.38v0l4.5-0.37h0l4.12-1.12h0l3.75-1.12
	h0l3-1.12h0l2.25-1.88l0,0l1.87-1.5h0l0,0l0,0h0l0.75-1.87h0v-1.87h0l-0.75-1.87h0l-1.88-1.88v0l-2.25-1.5l0,0l-3-1.5h0l-3.75-1.12
	h0l-4.12-0.75v0l-4.5-0.38l0,0l-4.5-0.37h4.5l4.5-0.75h0l4.12-0.75h0l3.75-1.12l0,0l3-1.5v0l2.25-1.5h0l1.88-1.88h0l0.75-1.87h0
	v-1.88l0,0l-0.75-1.5h0l-1.88-1.88v0l-2.25-1.5l0,0l-3-1.5h0l-3.75-1.12h0l-4.12-0.75h0l-4.5-0.75h0h-4.5l0,0l4.5-0.38v0l4.5-0.37h0
	l4.12-0.75h0l3.75-1.12l0,0l3-1.5v0l2.25-1.5h0l1.88-1.88h0l0.75-1.87h0v-1.88h0l-0.75-1.88l0,0l-1.88-1.5h0l-2.25-1.87h0l-3-1.12h0
	l-3.75-1.12l0,0L150.75,30v0l-4.5-0.38l0,0l-4.5-0.38l2.25-1.87h0l0.37-0.75h0L147,24h0.75l2.62,1.87h0l3,1.12l0,0l0.37,0.37l0,0
	l3,0.38l4.5-0.38l0,0l1.5-0.37l0,0l0.75-1.12h0l1.12-1.87h0v0l0,0h0v-1.87h0l-1.5-1.87l0,0l-1.12-1.12h0l-3.37-3h-4.87l1.87-1.12h0
	l0.75-0.75h0l1.12-0.75h0l3.75-3.37h0v0l0,0h0V7.87h0V7.12v0v-1.5h0l-1.5-2.25H156l-4.87,2.25h0l-0.38,1.5l0,0l-0.37,0.75h0l0,0l0,0
	h0L150,10.12l0,0l-0.75,3.38h0v0.75h0l-0.37-2.62v0l-0.75-3.75h0V6.75h0l-1.5-2.25h0l-0.75-1.12h-2.62L141,4.5h0l0,0l0,0h0
	l-1.87,2.25h0l0,0l0,0h0l-0.37,1.12h0v3.75v0l1.5,2.62h0l0.38,0.75h0l0.75,1.12l1.5,3h0l0.75,1.12h0l-1.87,1.87l0,0L139.87,24h-0.38
	l-2.62,2.62h0v-0.75h0l-0.38-4.12V21l0,0l-1.12-3.75h0L135,16.5l0,0l-1.88-3.75h0L132.75,12h0l0,0l0,0h0l-2.62-3.37l0,0l-0.75-0.38
	h0l-3-3l0,0L126,4.87h0l-3.75-2.25l0,0l-0.75-0.37l0,0l-3.75-1.5h-1.13L112.88,0h0l-2.62,0L108,0l-0.37,0h0l-4.5,0.75h-0.38
	l-4.12,1.5l0,0l-0.75,0.37l0,0l-3.75,2.25h0l0,0l0,0h0l-0.37,0.37l0,0l-3,3h0L90,8.62l0,0L87.38,12h0v0l0,0h0v0.75h0l-2.25,3.75l0,0
	v0.75h0L83.63,21l0,0v0.75l-0.35,4.01l-0.03,0.11l-0.03-0.11l-0.35-4.01V21l0,0l-1.5-3.75h0V16.5l0,0l-2.25-3.75h0V12h0l0,0v0h0
	l-2.62-3.37l0,0l-0.75-0.38h0l-3-3l0,0l-0.37-0.37h0l0,0l0,0h0l-3.75-2.25l0,0l-0.75-0.37l0,0l-4.12-1.5h-0.38L58.87,0h0h-0.37
	h-2.25h-2.62h0l-3.75,0.75h-1.13l-3.75,1.5l0,0l-0.75,0.37l0,0l-3.75,2.25h0l-0.37,0.37l0,0l-3,3h0l-0.75,0.38l0,0L33.75,12h0l0,0
	l0,0h0l-0.37,0.75h0L31.5,16.5l0,0l-0.37,0.75h0L30,21l0,0v0.75l-0.38,4.12h0v0.75h0L27,24h-0.38l-1.87-1.87l0,0l-1.87-1.87h0
	l0.75-1.12h0l1.5-3L25.87,15h0l0.38-0.75h0l1.5-2.62v0V7.87h0l-0.37-1.12h0l0,0l0,0h0L25.5,4.5h0l0,0l0,0h0l-2.25-1.12h-2.62
	L19.87,4.5h0l-1.5,2.25h0v1.12h0l-0.75,3.75v0l-0.37,2.62h0V13.5h0l-0.75-3.38l0,0l-0.37-2.25h0l0,0l0,0h0l-0.37-0.75l0,0l-0.38-1.5
	h0L10.5,3.37H6.75l-1.5,2.25h0v1.5v0v0.75h0v2.25h0l0,0v0h0L9,13.5h0l1.12,0.75h0L10.87,15h0l1.87,1.12H7.87l-3.37,3h0l-1.12,1.12
	l0,0l-1.5,1.87h0V24h0l0,0v0h0L3,25.87h0L3.75,27l0,0l1.5,0.37l0,0l4.5,0.38l3-0.38l0,0L13.12,27l0,0l3-1.12h0L18.75,24h0.75
	l2.62,2.62h0l0.37,0.75h0l2.25,1.87l-4.5,0.38l0,0L15.75,30v0l-4.12,1.12l0,0l-3.75,1.12h0l-3,1.12h0l-2.25,1.87h0l-1.88,1.5l0,0
	L0,38.62h0v1.88h0l0.75,1.87h0l1.88,1.88h0l2.25,1.5v0l3,1.5l0,0l3.75,1.12h0l4.12,0.75h0l4.5,0.37v0l4.5,0.38l0,0h-4.5h0l-4.5,0.75
	h0l-4.12,0.75h0l-3.75,1.12h0l-3,1.5l0,0l-2.25,1.5v0l-1.88,1.88h0L0,58.87l0,0v1.88h0l0.75,1.87h0l1.88,1.88h0l2.25,1.5v0l3,1.5
	l0,0l3.75,1.12h0l4.12,0.75h0l4.5,0.75h4.5l-4.5,0.37l0,0l-4.5,0.38v0l-4.12,0.75h0l-3.75,1.12h0l-3,1.5l0,0l-2.25,1.5v0l-1.88,1.88
	h0L0,79.49h0v1.87h0l0.75,1.87h0l0,0l0,0h0l1.87,1.5l0,0l2.25,1.88h0l3,1.12h0l3.75,1.12h0l4.12,1.12h0l4.5,0.37v0l4.5,0.38l0,0
	l-2.25,1.87h0l-0.37,0.75h0l-0.75,0.38h0l-1.87,2.25h-0.75l-2.62-1.87h0l-3-1.5h-0.37l-3-0.37l-4.5,0.37h-1.5L3,94.11h0l-1.12,1.87
	h0v1.87h0l1.5,1.88h0l1.12,1.12h0l3.37,3h4.87l-1.87,1.12h0l-0.75,0.75L9,106.48h0l-3.75,3.37h0v2.25h0v0.75h0v1.5h0l1.5,2.25h3.75
	l4.87-2.25h0v0l0,0h0h0l0.37-1.5h0l0.37-0.75h0l0,0l0,0h0l0.37-2.25h0l0.75-3.37l0.37,1.87h0l0.75,3.75v0v1.12h0l1.5,2.25h0
	l0.75,1.12h2.62l2.25-1.12h0l1.88-2.25h0l0.37-1.12v0v-3.75h0l-1.12-1.88h0l-0.37-0.75l0,0l-0.37-0.75h0l-0.75-1.12h0l-1.5-3l0,0
	l-0.75-1.12l1.87-1.87h0l1.87-1.87H27l1.88-2.25h0l0.75-0.37h0v0.37v0v0.37v0L30,98.24h0v0.75h0l1.12,3.75h0.03l0,0h-0.03l0.06,0.11
	l0.35,1.52l0,0l2.25,4.12h0.36l2.23,2.86h0l0.75,0.37l0,0l3,3h0l0.37,0.37l0,0l3.75,2.25h0l0.75,0.37h0l3.75,1.5h1.13l3.75,0.75
	h5.25l4.5-0.75h0.38l4.12-1.5h0l0.75-0.38h0l3.75-2.25l0.38-0.37h0l3-3l0,0l0.75-0.37h0l2.23-2.86h0.43l2.25-4.12l0,0l1.5-4.87
	h-0.24l0.2-0.51h0v-0.75h0l0.37-4.08H83.26z M149.25,106.48L149.25,106.48L149.25,106.48l0-0.75h0V106.48z"/>
		</svg>
	}
</div>
)
}

export default Ikon
