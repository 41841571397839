import React, {useEffect, useState, useRef} from "react";
import sanityClient from "../client.js";
import {PortableText} from '@portabletext/react'
import Draggable from 'react-draggable';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom";

import reactable from 'reactablejs'

import { BiX } from 'react-icons/bi';

import BigPicture from "../components/bigpicture.component.jsx";
import KirkeKart from '../components/kirkekart.component'
import MusikkArtikkel from '../components/musikkArtikkel.component.jsx'
import MusikkIkonGruppe from '../components/musikkIkonGruppe.component.jsx'
import MusikkRamme from '../components/graphics/MusikkRamme.component'
import LanguageSwitcher from '../components/languageSwitcher.component'


import './musikken.styles.scss';

const Musikken=({menuOpen, settings, language, setLanguage, content, hasDragged, setHasDragged, loc, setLoc,})=>{
    // console.log(settings, language)

    const [contentExpanded, setContentExpanded] = useState(false);
    const isNarrow = useMediaQuery({ query: '(max-width: 1100px)'})
    const [zoomLevel, setZoomLevel] = useState(0.5);
    const [dragPosStart, setDragPosStart] = useState(null)
    const [bigPicture, setBigPicture] = useState(null)
    const [localMusicState, setLocalMusicState] = useState(false)

    const navigate = useNavigate();

    const transformRef = useRef();

    useEffect(()=>{
        // console.log(loc)

        function returnUrlCategory(kat){
            switch (kat){
                case 'Lyd': return 'Lydsnutter'
                break;
                case 'Stemmer': return 'Bakgrunnsstoff'
                break;
                case 'Komposisjon': return 'Noteark'
                break;
                case 'Demo': return 'LydsnutterAnonym'
                break;
                default: return kat
            }
        }

        if(loc.length > 2 ){
            // console.log(loc)
            if(loc[2] !== ''){
            let location = loc[2];
            let locationCap = location[0].toUpperCase() + location.substring(1);
            
            setContentExpanded({current:0,kat:returnUrlCategory(locationCap)})
            }
        }
        // else if(loc.length == 2 && loc[1] == 'musikken'){
        //     // console.log(loc)
        //     // setContentExpanded(false)
        // }
    },[loc])

    useEffect(()=>{
        // console.log(contentExpanded)

        function returnUrlCategory(kat){
            switch (kat){
                case 'Lydsnutter': return 'Lyd'
                break;
                case 'Bakgrunnsstoff': return 'Stemmer'
                break;
                case 'Noteark': return 'Komposisjon'
                break;
                case 'LydsnutterAnonym': return 'Demo'
                break; 
                default: return kat
            }
        }

        if (contentExpanded){
            // console.log('expanded')
            window.history.replaceState(null, contentExpanded.kat, "/musikken/" + returnUrlCategory(contentExpanded.kat))
        } else{
            // console.log('musikken')
            // console.log(contentExpanded)
            // console.log(loc)
            // const history = useLocation();
            // history.push("/musikken");
            navigate("/musikken");
        }
    },[contentExpanded])

    const onPanningStartHandler = (e)=>{
        //console.log(e)
        if (!hasDragged){setHasDragged(true)}
        setDragPosStart({x:e.state.positionX, y:e.state.positionY})
    }

    const onPanningStopHandler = (e)=>{
        //console.log(e)
        if (dragPosStart.x - e.state.positionX > -5 && dragPosStart.x - e.state.positionX < 5 && dragPosStart.y - e.state.positionY > -5 && dragPosStart.y - e.state.positionY < 5){
            // console.log('click')
            // setFortellingOpen(1)
        }
    }

    const onZoomHandler = (e)=>{
        // console.log(e)
        if (e.state.scale < 0.3 && isNarrow){
            // e.preventDefault;
            console.log('max');
            const coordinates = {x:-650, y: -870}
            e.setTransform(coordinates.x, coordinates.y, 0.3, 300, 'easeOut')
        }
    }

    const initMap = (e)=>{
        const coordinates = isNarrow ? {x:-330, y: -370} : {x:-560, y: -1210}
        const zoomLevel = isNarrow ? 0.5 : 1
        e.setTransform(coordinates.x, coordinates.y, zoomLevel, 300, 'easeOut');
        // e.setTransform(-2470, -2890, 1, 300, 'easeOut'); 
        //transform: translate3d(-2627px, -2736px, 0px) scale(0.5);
    }

    const setZoomHandler =(e)=>{
        //console.log(e);
        setZoomLevel(e.state.scale)
    }
// console.log(content.OmMusikken[0].introText)
    if (!content) return <></>
    return (
        <div className="musikken-page">
            {bigPicture && <BigPicture img={bigPicture} setBigPicture={setBigPicture}/> }
            {contentExpanded ?<>
            <MusikkArtikkel setContentExpanded={setContentExpanded} language={language} currentIndex={contentExpanded.current} artikkelContent={content[contentExpanded.kat][0]} setBigPicture={setBigPicture} />
            <MusikkRamme kategori={contentExpanded.kat}/></>
            :
            ''}
            
            <div className={`musikken-language-switcher`}>
                {!isNarrow ? <LanguageSwitcher settings={settings} language={language} setLanguage={setLanguage} /> : !menuOpen ? <LanguageSwitcher settings={settings} language={language} setLanguage={setLanguage} /> : ''}
            </div>

                {!isNarrow && !contentExpanded &&
                    <div className={`corner-flower-wrapper ${hasDragged ? 'hidden' : ''}`} onClick={()=>setHasDragged(!hasDragged)}>
                        <div className="corner-flower-text">
                        {hasDragged ? <>?</> : language == 'NO' ? <PortableText value={content.OmMusikken[0].introText}/> : <PortableText value={content.OmMusikken[0].introTextEngelsk}/>}
                        </div>
                        <div className="corner-flower-background">
                            <svg version="1.1" id="Layer_1"x="0px" y="0px" viewBox="0 0 1000 1000">
                            <line className="fl" x1="325.52" y1="904.62" x2="325.52" y2="904.62"/>
                            <path className="fl" d="M640.37,77.96c5.53-36.21-18.4-70.3-53.97-76.86c-35.56-6.56-69.33,16.69-76.75,52.55
                                c-7.92-33.25-39.09-55.26-72.6-51.28c-33.51,3.98-62.78,34.17-62.9,68.37c-17.61-29.55-54.35-40.9-85.23-26.34
                                c-30.88,14.56-47.28,52.54-36.32,85.22c-25.66-23.3-64.6-22.97-89.86,0.78c-25.26,23.74-30.43,64.37-9.44,92.06
                                c-31.29-13.42-67.53-0.86-84.16,29.17c-16.63,30.03-8.67,69.9,18.94,89.97c-35-5.17-67.9,18.25-74.97,53.38
                                c-7.07,35.13,12.75,68.56,46.95,77.71c-36.52,3.98-62.99,37.14-59.21,74.18c3.78,37.03,36.99,62.82,73.54,59.14
                                c-25.26,6.85-44.24,28.02-48.55,54.16c-4.31,26.14,6.83,52.42,28.52,67.23s49.9,15.41,72.18,1.54
                                c-20.95,14.83-29.65,43.62-25.63,69.2c4.02,25.58,22.1,46.58,46.56,54.08c24.46,7.5,48.89-0.44,66.22-19.47
                                c-17.34,31.64-7.03,71.51,23.4,90.45c30.43,18.94,70.25,9.29,90.24-20.7c-8.26,24.17-2.35,50.99,15.26,69.31
                                c17.61,18.32,43.91,24.99,67.96,17.25c24.05-7.74,38.93-27.18,42.88-52.44c2.37,37,33.69,65.17,70.22,63.13s64.62-33.51,62.97-70.55
                                c17.78,24.47,53.14,33.24,81.14,22.41c28.01-10.83,45.45-39.27,42.63-69.52c24.45,20.95,67.15,16.48,92.04-3.93
                                c24.88-20.41,30.26-54.6,15.15-83.2c19.78,14.29,45.66,16.55,67.56,5.9c21.9-10.65,36.36-32.53,37.74-57.11
                                c1.38-24.58-10.53-47.98-31.1-61.09c26.72,11.17,59.36,0.98,80.6-18.89s29.16-50.43,20.31-78.32s-32.87-48.03-61.55-51.62
                                c27.02-1.97,50.81-21.36,62.11-46.32c11.3-24.95,8.29-54.16-7.85-76.21c-16.14-22.05-42.85-33.43-69.69-29.7
                                c24.92-10.5,41.39-34.65,44.39-61.84c3-27.19-8.8-53.89-30.81-69.72c-22.02-15.83-47.4-13.32-71.73-1.46
                                c30.4-26.36,34.72-72.39,9.76-104.09c-24.95-31.69-70.24-38.67-102.34-14.47c8.18-32.56-8.47-66.26-39.08-79.14
                                C695.27,38.01,657.32,49.1,640.37,77.96z"/>
                            </svg>
                        </div>
                    </div>
                }    

            <TransformWrapper
                ref={transformRef}
                onInit={(e)=>initMap(e)}
                // initialScale={0.2}
                onPanningStart={(e)=>onPanningStartHandler(e)}
                onPanningStop={(e)=>onPanningStopHandler(e)}
                // onZoom={(e)=>onZoomHandler(e)}
                // onZoomStop={(e)=>setZoomHandler(e)}
                maxScale={5}
                minScale={0.5}
                limitToBounds={false}
                // centerZoomedOut={true}
                // panning={{excluded:['swiper-button-next', 'swiper-button-prev','swiper-pagination-bullet']}}
                wheel={{step:0.2}}
                pinch={{step:1}}
                
                >
                    <TransformComponent>

                        <div className="map-transform">

                            {/* <MusikkIkonGruppe pos={{x:635, y:1370}} kat={'Lydsnutter'} label={'Lyd'} setContentExpanded={setContentExpanded} language={language} content={content.Lydsnutter[0]}/> */}

                            <MusikkIkonGruppe pos={{x:850, y:1605}} kat={'Bakgrunnsstoff'} label={'Stemmer'} setContentExpanded={setContentExpanded} language={language} content={content.Bakgrunnsstoff[0]}/>

                            <MusikkIkonGruppe pos={{x:1010, y:1215}} kat={'Musikere'} label={'Musikere'} setContentExpanded={setContentExpanded} language={language} content={content.Musikere[0]}/>

                            <MusikkIkonGruppe pos={{x:1140, y:1505}} kat={'Noteark'} label={'Komposisjon'} setContentExpanded={setContentExpanded} language={language} content={content.Noteark[0]}/> 

                            <MusikkIkonGruppe pos={{x:1460, y:1440}} kat={'Orgel'} label={'Orgel'} setContentExpanded={setContentExpanded} language={language} content={content.Orgel[0]}/>

                            {/* <div className="kirke-intro-wrapper">
                                <div className="kirke-intro-wrapper-title">
                                    MUSIKKEN
                                </div>
                                <div className="kirke-intro-wrapper-text">
                                    <PortableText
                                    value={content.OmMusikken[0].introText}
                                    />
                                </div>
                            </div> */}

                            <div className="kart-wrapper">
                                <KirkeKart zoomLevel={zoomLevel}/>
                            </div>
                        </div>
                    </TransformComponent>
                </TransformWrapper>
        {/* <div className="map-scale" style={{transform:`scale(${zoomLevel})`}}>
            <Draggable
            onStart={(e)=>startDragHandler(e)}
            onStop={(e)=>stopDragHandler(e)}
            bounds={{top: calculateTopMapMargin(), left: calculateLeftMapMargin(), right: -200, bottom: -500}}
            position={mapPosition}>
                <div className="map-kirke">
                 
                        <MusikkIkonGruppe pos={{x:1170, y:3055}} kat={'Lydsnutter'} setContentExpanded={setContentExpanded} content={content.Lydsnutter[0]}/>

                        <MusikkIkonGruppe pos={{x:1740, y:2905}} kat={'Bakgrunnsstoff'} setContentExpanded={setContentExpanded} content={content.Bakgrunnsstoff[0]}/>

                        <MusikkIkonGruppe pos={{x:2360, y:3185}} kat={'Musikere'} setContentExpanded={setContentExpanded} content={content.Musikere[0]}/>

                        <MusikkIkonGruppe pos={{x:2025, y:2305}} kat={'Noteark'} setContentExpanded={setContentExpanded} content={content.Noteark[0]}/> 

                        <MusikkIkonGruppe pos={{x:2995, y:2925}} kat={'Orgel'} setContentExpanded={setContentExpanded} content={content.Orgel[0]}/>

                        <div className="kirke-intro-wrapper">
                            <div className="kirke-intro-wrapper-title">
                                MUSIKKEN
                            </div>
                            <div className="kirke-intro-wrapper-text">
                                <PortableText
                                value={introText}
                                />
                            </div>
                        </div>

                        
                        <div className="kirkekart-wrapper">
                            <KirkeKart zoomLevel={zoomLevel}/>
                        </div>
                    </div>
            </Draggable>
            </div> */}
        </div>
    )

}

export default Musikken