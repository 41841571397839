import React, {useEffect, useState, useRef} from "react";
import sanityClient from "../client.js";
import imageUrlBuilder from '@sanity/image-url'
import {PortableText} from '@portabletext/react'
import { useMediaQuery } from 'react-responsive'

import Accordion from "../components/accordion.component.jsx";
import AccordionFortelling from "../components/accordionFortelling.component.jsx";

import BigPicture from "../components/bigpicture.component.jsx";

// import { BiX } from 'react-icons/bi';

import './archive.styles.scss';

const builder = imageUrlBuilder(sanityClient)


function urlFor(source) {
  return builder.image(source)
}

const Archive=({content})=>{
    //TODO: reset høyde når man bytter mellom bilde og tekst-accordion
    //TODO: gjøre så man ikke kan sortere alfabetisk på tegninger?

    //after closed,  make the new accordion open again by itself, store the current category in state and use it to determine if accordion should be open or not

    const [pageContent, setPageContent] = useState(null)
    const [show, setShow] = useState('Fortellinger');
    const [sortingBy, setSortingBy] = useState('Kronologisk');

    const isNarrow = useMediaQuery({ query: '(max-width: 1100px)' })

    const [extraAccordionHeight,setExtraAccordionHeight] = useState(0);

    const [fortellingShowing, setFortellingShowing] = useState(null);

    const [bigPicture, setBigPicture] = useState(null)

    useEffect(() =>{ 
        let fetchContentQuery = `{'arkivTekster': *[_type == 'arkiv']{arkivTekst,eieTekst,byggeTekst,lekeTekst,traskeTekst,fodeTekst}[0]}`
        sanityClient
        .fetch(fetchContentQuery)
        .then(data => {setPageContent(data)})
        .catch(console.error)
        // console.log({x:window.innerWidth, y:window.innerHeight})
    },[]);

    // const eieText = 'Historier om det å føle seg fremmed og hjemme. Om eierskap, tilhørighet og konflikt.'
    // const byggeText = 'Historier om de fysiske tingene vi lever i. Om vedlikehold, park, strukturer og bygninger.'
    // const lekeText = 'Historier om det som er så forunderlig. Om fantasi, dyr, spenning, klatring og underholdning.'
    // const fødeText = 'Historier om det mest åpenbare og det som åpenbarer seg selv. Om natur, mat, familie, åndelighet, vær og relasjoner.'
    // const traskeText = 'Historier om det man går til eller går forbi. Om venner, prating, gater, dansing og transport.'

    const setShowHandler =(input)=>{
        // console.log(show)
        // console.log(input)
        setShow(input)
        setFortellingShowing(null)
        if (input == 'Tegninger'){setSortingBy('Kronologisk')}
        setExtraAccordionHeight(0)
    }
// console.log(pageContent)
    if (!content || !pageContent) return <></>
    return (
        <div className={`archive-page ${bigPicture && 'bigpicture-showing'}`}>
        {bigPicture && <BigPicture img={bigPicture} setBigPicture={setBigPicture}/> }

            <div className="top">
                <div className="top-title">
                    Arkivet
                </div>
                { !isNarrow && 
                    <div className="top-menu">
                        <div className={`top-menu-button ${show == 'Fortellinger' ? 'button-selected' : ''}`} onClick={()=>setShowHandler('Fortellinger')}>
                            Vis fortellinger
                        </div>
                        <div className={`top-menu-button ${show == 'Tegninger' ? 'button-selected' : ''}`} onClick={()=>setShowHandler('Tegninger')}>
                            Vis tegninger
                        </div>
                        {/* <div className="top-menu-sort-wrapper">
                            <div className={`top-menu-sort-button ${sortingBy == 'Kronologisk' ? 'sort-button-selected' : ''}`} onClick={()=>setSortingBy('Kronologisk')}>
                                <div className="top-menu-sort-button-circle"></div>
                                <div className="top-menu-sort-button-label">Kronologisk</div>
                            </div>
                            <div className={`top-menu-sort-button ${sortingBy == 'Alfabetisk' ? 'sort-button-selected' : ''} ${show == 'Tegninger' ? 'button-disabled' : ''}`} onClick={()=>setSortingBy('Alfabetisk')}>
                                <div className="top-menu-sort-button-circle"></div>
                                <div className="top-menu-sort-button-label">Alfabetisk</div>
                            </div>
                        </div> */}
                    </div>
                }
            </div>
            <div className="top-description">
                     <PortableText
                        value={pageContent.arkivTekster.arkivTekst}
                    />
            </div>
            {isNarrow && 
                <div className="mobile-top-menu">
                    <div className={`mobile-top-menu-button ${show == 'Fortellinger' ? 'button-selected' : ''}`} onClick={()=>setShowHandler('Fortellinger')}>
                        Vis fortellinger
                    </div>
                    <div className={`mobile-top-menu-button ${show == 'Tegninger' ? 'button-selected' : ''}`} onClick={()=>setShowHandler('Tegninger')}>
                        Vis tegninger
                    </div>
                </div>
            }
            {show == 'Fortellinger' ?
                <div className="accordions-wrapper">
                <Accordion title={'Føde'} kat='kat1' text={pageContent.arkivTekster.fodeTekst} show={show} extraAccordionHeight={extraAccordionHeight}>
                    {sortingBy == 'Kronologisk' ? content.filter(fortelling =>{
                        return fortelling.kategori == 'kat1'
                    }).sort(function(a, b) {
                        return (a._createdAt > b._createdAt) ? -1 : ((a._createdAt < b._createdAt) ? 1 : 0);
                    }).map(fortelling =>{
                        //console.log(fortelling)
                        return <AccordionFortelling key={fortelling._id} fortellingShowing={fortellingShowing} setFortellingShowing={setFortellingShowing} fortelling={fortelling} setExtraAccordionHeight={setExtraAccordionHeight} setBigPicture={setBigPicture} />
                    })
                    :
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat1'
                    }).sort(function(a, b) {
                        return (a.title < b.title) ? -1 : ((a.title > b.title) ? 1 : 0);
                    }).map(fortelling =>{
                        //console.log(fortelling)
                        return <AccordionFortelling key={fortelling._id} fortellingShowing={fortellingShowing} setFortellingShowing={setFortellingShowing} fortelling={fortelling} setExtraAccordionHeight={setExtraAccordionHeight} setBigPicture={setBigPicture} />
                    })
                    }
                </Accordion>
                <Accordion title={'Bygge'} kat='kat2' text={pageContent.arkivTekster.byggeTekst} show={show} extraAccordionHeight={extraAccordionHeight}>
                    {sortingBy == 'Kronologisk' ? content.filter(fortelling =>{
                        return fortelling.kategori == 'kat2'
                    }).sort(function(a, b) {
                        return (a._createdAt > b._createdAt) ? -1 : ((a._createdAt < b._createdAt) ? 1 : 0);
                    }).map(fortelling =>{
                        //console.log(fortelling)
                        return <AccordionFortelling key={fortelling._id} fortellingShowing={fortellingShowing} setFortellingShowing={setFortellingShowing} fortelling={fortelling} setExtraAccordionHeight={setExtraAccordionHeight} setBigPicture={setBigPicture} />
                    })
                    :
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat2'
                    }).sort(function(a, b) {
                        return (a.title < b.title) ? -1 : ((a.title > b.title) ? 1 : 0);
                    }).map(fortelling =>{
                        //console.log(fortelling)
                        return <AccordionFortelling key={fortelling._id} fortellingShowing={fortellingShowing} setFortellingShowing={setFortellingShowing} fortelling={fortelling} setExtraAccordionHeight={setExtraAccordionHeight} setBigPicture={setBigPicture} />
                    })
                    }
                </Accordion>

                <Accordion title={'Eie'} kat='kat3' text={pageContent.arkivTekster.eieTekst} show={show} extraAccordionHeight={extraAccordionHeight}>
                    {sortingBy == 'Kronologisk' ? content.filter(fortelling =>{
                        return fortelling.kategori == 'kat3'
                    }).sort(function(a, b) {
                        return (a._createdAt > b._createdAt) ? -1 : ((a._createdAt < b._createdAt) ? 1 : 0);
                    }).map(fortelling =>{
                        //console.log(fortelling)
                        return <AccordionFortelling key={fortelling._id} fortellingShowing={fortellingShowing} setFortellingShowing={setFortellingShowing} fortelling={fortelling} setExtraAccordionHeight={setExtraAccordionHeight} setBigPicture={setBigPicture} />
                    })
                    :
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat3'
                    }).sort(function(a, b) {
                        return (a.title < b.title) ? -1 : ((a.title > b.title) ? 1 : 0);
                    }).map(fortelling =>{
                        //console.log(fortelling)
                        return <AccordionFortelling key={fortelling._id} fortellingShowing={fortellingShowing} setFortellingShowing={setFortellingShowing} fortelling={fortelling} setExtraAccordionHeight={setExtraAccordionHeight} setBigPicture={setBigPicture} />
                    })
                    }
                </Accordion>
                <Accordion title={'Traske'} kat='kat4' text={pageContent.arkivTekster.traskeTekst} show={show} extraAccordionHeight={extraAccordionHeight}>
                    {sortingBy == 'Kronologisk' ? content.filter(fortelling =>{
                        return fortelling.kategori == 'kat4'
                    }).sort(function(a, b) {
                        return (a._createdAt > b._createdAt) ? -1 : ((a._createdAt < b._createdAt) ? 1 : 0);
                    }).map(fortelling =>{
                        //console.log(fortelling)
                        return <AccordionFortelling key={fortelling._id} fortellingShowing={fortellingShowing} setFortellingShowing={setFortellingShowing} fortelling={fortelling} setExtraAccordionHeight={setExtraAccordionHeight} setBigPicture={setBigPicture} />
                    })
                    :
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat4'
                    }).sort(function(a, b) {
                        return (a.title < b.title) ? -1 : ((a.title > b.title) ? 1 : 0);
                    }).map(fortelling =>{
                        //console.log(fortelling)
                        return <AccordionFortelling key={fortelling._id} fortellingShowing={fortellingShowing} setFortellingShowing={setFortellingShowing} fortelling={fortelling} setExtraAccordionHeight={setExtraAccordionHeight} setBigPicture={setBigPicture} />
                    })
                    }
                </Accordion>
                <Accordion title={'Leke'} kat='kat5' text={pageContent.arkivTekster.lekeTekst} show={show} extraAccordionHeight={extraAccordionHeight}>
                    {sortingBy == 'Kronologisk' ? content.filter(fortelling =>{
                        return fortelling.kategori == 'kat5'
                    }).sort(function(a, b) {
                        return (a._createdAt > b._createdAt) ? -1 : ((a._createdAt < b._createdAt) ? 1 : 0);
                    }).map(fortelling =>{
                        //console.log(fortelling)
                        return <AccordionFortelling key={fortelling._id} fortellingShowing={fortellingShowing} setFortellingShowing={setFortellingShowing} fortelling={fortelling} setExtraAccordionHeight={setExtraAccordionHeight} setBigPicture={setBigPicture} />
                    })
                    :
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat5'
                    }).sort(function(a, b) {
                        return (a.title < b.title) ? -1 : ((a.title > b.title) ? 1 : 0);
                    }).map(fortelling =>{
                        //console.log(fortelling)
                        return <AccordionFortelling key={fortelling._id} fortellingShowing={fortellingShowing} setFortellingShowing={setFortellingShowing} fortelling={fortelling} setExtraAccordionHeight={setExtraAccordionHeight} setBigPicture={setBigPicture} />
                    })
                    }
                </Accordion>
                </div>
            :
            <div className="accordions-wrapper tegninger-wrapper">
                <Accordion title={'Føde'} kat='kat1' text={pageContent.arkivTekster.fodeTekst} show={show} extraAccordionHeight={extraAccordionHeight}>
                    {sortingBy == 'Kronologisk' ? 
                    
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat1'
                    }).sort(function(a, b) {
                            return (a._createdAt > b._createdAt) ? -1 : ((a._createdAt < b._createdAt) ? 1 : 0);
                        }).reduce(function(pV, cV, cI){
                        if(cV.bilder !== undefined){
                            pV.push(...[...cV.bilder]);
                        }
                        return pV;
                      }, []).filter((value, index, array) => {
                            const _value = JSON.stringify(value);
                            return index === array.findIndex(obj => {
                              return JSON.stringify(obj) === _value;
                            });
                        }).map((item, index)=>{
                           //console.log(item);
                          return <div key={index} className="accordion-bilde-wrapper">
                              <div className="accordion-bilde"><img src={urlFor(item.image.bildefil).width(500).url()} onClick={()=>{setBigPicture(item.image.bildefil)}}/>
                              </div>
                              <div className="accordion-bilde-caption">
                              {item.image.title}
                            </div>
                            </div>
                      })
                    :
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat1'
                    }).sort(function(a, b) {
                            return (a.title > b.title) ? -1 : ((a.title < b.title) ? 1 : 0);
                        }).reduce(function(pV, cV, cI){
                        if(cV.bilder !== undefined){
                            pV.push(...[...cV.bilder]);
                        }
                        return pV;
                      }, []).filter((value, index, array) => {
                            const _value = JSON.stringify(value);
                            return index === array.findIndex(obj => {
                              return JSON.stringify(obj) === _value;
                            });
                        }).map((item, index)=>{
                           //console.log(item);
                          return <div key={index} className="accordion-bilde-wrapper">
                              <div className="accordion-bilde"><img src={urlFor(item.image.bildefil).width(500).url()} onClick={()=>{setBigPicture(item.image.bildefil)}}/>
                              </div>
                              <div className="accordion-bilde-caption">
                              {item.image.title}
                            
                            </div>
                            </div>
                      })
                    }
                </Accordion>
                <Accordion title={'Bygge'} kat='kat2' text={pageContent.arkivTekster.byggeTekst} show={show} extraAccordionHeight={extraAccordionHeight}>
                    {sortingBy == 'Kronologisk' ? 
                    
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat2'
                    }).sort(function(a, b) {
                            return (a._createdAt > b._createdAt) ? -1 : ((a._createdAt < b._createdAt) ? 1 : 0);
                        }).reduce(function(pV, cV, cI){
                        if(cV.bilder !== undefined){
                            pV.push(...[...cV.bilder]);
                        }
                        return pV;
                      }, []).filter((value, index, array) => {
                          //console.log(array)
                            const _value = JSON.stringify(value);
                            return index === array.findIndex(obj => {
                              return JSON.stringify(obj) === _value;
                            });
                        }).map((item, index, array)=>{
                           console.log(array);
                          return <div key={index} className="accordion-bilde-wrapper">
                              <div className="accordion-bilde"><img src={urlFor(item.image.bildefil).width(500).url()} onClick={()=>{setBigPicture(item.image.bildefil)}}/>
                              </div>
                              <div className="accordion-bilde-caption">
                              {item.image.title}
                            </div>
                            </div>
                      })
                    :
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat2'
                    }).sort(function(a, b) {
                            return (a.title > b.title) ? -1 : ((a.title < b.title) ? 1 : 0);
                        }).reduce(function(pV, cV, cI){
                        if(cV.bilder !== undefined){
                            pV.push(...[...cV.bilder]);
                        }
                        return pV;
                      }, []).filter((value, index, array) => {
                            const _value = JSON.stringify(value);
                            return index === array.findIndex(obj => {
                              return JSON.stringify(obj) === _value;
                            });
                        }).map((item, index)=>{
                          //console.log(item);
                          return <div key={index} className="accordion-bilde-wrapper">
                              <div className="accordion-bilde"><img src={urlFor(item.image.bildefil).width(500).url()} onClick={()=>{setBigPicture(item.image.bildefil)}}/>
                              </div>
                              <div className="accordion-bilde-caption">
                              {item.image.title}
                            </div>
                            </div>
                      })
                    }
                </Accordion>
                <Accordion title={'Eie'} kat='kat3' text={pageContent.arkivTekster.eieTekst} show={show} extraAccordionHeight={extraAccordionHeight}>
                    {sortingBy == 'Kronologisk' ? 
                    
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat3'
                    }).sort(function(a, b) {
                            return (a._createdAt > b._createdAt) ? -1 : ((a._createdAt < b._createdAt) ? 1 : 0);
                        }).reduce(function(pV, cV, cI){
                        if(cV.bilder !== undefined){
                            pV.push(...[...cV.bilder]);
                        }
                        return pV;
                      }, []).filter((value, index, array) => {
                            const _value = JSON.stringify(value);
                            return index === array.findIndex(obj => {
                              return JSON.stringify(obj) === _value;
                            });
                        }).map((item, index)=>{
                          //console.log(item);
                          return <div key={index} className="accordion-bilde-wrapper">
                              <div className="accordion-bilde"><img src={urlFor(item.image.bildefil).width(500).url()} onClick={()=>{setBigPicture(item.image.bildefil)}}/>
                              </div>
                              <div className="accordion-bilde-caption">
                              {item.image.title}
                            </div>
                            </div>
                      })
                    :
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat3'
                    }).sort(function(a, b) {
                            return (a.title > b.title) ? -1 : ((a.title < b.title) ? 1 : 0);
                        }).reduce(function(pV, cV, cI){
                        if(cV.bilder !== undefined){
                            pV.push(...[...cV.bilder]);
                        }
                        return pV;
                      }, []).filter((value, index, array) => {
                            const _value = JSON.stringify(value);
                            return index === array.findIndex(obj => {
                              return JSON.stringify(obj) === _value;
                            });
                        }).map((item, index)=>{
                          //console.log(item);
                          return <div key={index} className="accordion-bilde-wrapper">
                              <div className="accordion-bilde"><img src={urlFor(item.image.bildefil).width(500).url()} onClick={()=>{setBigPicture(item.image.bildefil)}}/>
                              </div>
                              <div className="accordion-bilde-caption">
                              {item.image.title}
                            </div>
                            </div>
                      })
                    }
                </Accordion>
                <Accordion title={'Traske'} kat='kat4' text={pageContent.arkivTekster.traskeTekst} show={show} extraAccordionHeight={extraAccordionHeight}>
                    {sortingBy == 'Kronologisk' ? 
                    
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat4'
                    }).sort(function(a, b) {
                            return (a._createdAt > b._createdAt) ? -1 : ((a._createdAt < b._createdAt) ? 1 : 0);
                        }).reduce(function(pV, cV, cI){
                        if(cV.bilder !== undefined){
                            pV.push(...[...cV.bilder]);
                        }
                        return pV;
                      }, []).filter((value, index, array) => {
                            const _value = JSON.stringify(value);
                            return index === array.findIndex(obj => {
                              return JSON.stringify(obj) === _value;
                            });
                        }).map((item, index)=>{
                          //console.log(item);
                          return <div key={index} className="accordion-bilde-wrapper">
                              <div className="accordion-bilde"><img src={urlFor(item.image.bildefil).width(500).url()} onClick={()=>{setBigPicture(item.image.bildefil)}}/>
                              </div>
                              <div className="accordion-bilde-caption">
                              {item.image.title}
                            </div>
                            </div>
                      })
                    :
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat4'
                    }).sort(function(a, b) {
                            return (a.title > b.title) ? -1 : ((a.title < b.title) ? 1 : 0);
                        }).reduce(function(pV, cV, cI){
                        if(cV.bilder !== undefined){
                            pV.push(...[...cV.bilder]);
                        }
                        return pV;
                      }, []).filter((value, index, array) => {
                            const _value = JSON.stringify(value);
                            return index === array.findIndex(obj => {
                              return JSON.stringify(obj) === _value;
                            });
                        }).map((item, index)=>{
                          //console.log(item);
                          return <div key={index} className="accordion-bilde-wrapper">
                              <div className="accordion-bilde"><img src={urlFor(item.image.bildefil).width(500).url()} onClick={()=>{setBigPicture(item.image.bildefil)}}/>
                              </div>
                              <div className="accordion-bilde-caption">
                              {item.image.title}
                            </div>
                            </div>
                      })
                    }
                </Accordion>
                <Accordion title={'Leke'} kat='kat5' text={pageContent.arkivTekster.lekeTekst} show={show} extraAccordionHeight={extraAccordionHeight}>
                    {sortingBy == 'Kronologisk' ? 
                    
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat5'
                    }).sort(function(a, b) {
                            return (a._createdAt > b._createdAt) ? -1 : ((a._createdAt < b._createdAt) ? 1 : 0);
                        }).reduce(function(pV, cV, cI){
                        if(cV.bilder !== undefined){
                            pV.push(...[...cV.bilder]);
                        }
                        return pV;
                      }, []).filter((value, index, array) => {
                            const _value = JSON.stringify(value);
                            return index === array.findIndex(obj => {
                              return JSON.stringify(obj) === _value;
                            });
                        }).map((item, index)=>{
                           //console.log(item);
                          return <div key={index} className="accordion-bilde-wrapper">
                              <div className="accordion-bilde"><img src={urlFor(item.image.bildefil).width(500).url()} onClick={()=>{setBigPicture(item.image.bildefil)}}/>
                              </div>
                              <div className="accordion-bilde-caption">
                              {item.image.title}
                            </div>
                            </div>
                      })
                    :
                    content.filter(fortelling =>{
                        return fortelling.kategori == 'kat5'
                    }).sort(function(a, b) {
                            return (a.title > b.title) ? -1 : ((a.title < b.title) ? 1 : 0);
                        }).reduce(function(pV, cV, cI){
                        if(cV.bilder !== undefined){
                            pV.push(...[...cV.bilder]);
                        }
                        return pV;
                      }, []).filter((value, index, array) => {
                            const _value = JSON.stringify(value);
                            return index === array.findIndex(obj => {
                              return JSON.stringify(obj) === _value;
                            });
                        }).map((item, index)=>{
                           //console.log(item);
                          return <div key={index} className="accordion-bilde-wrapper">
                              <div className="accordion-bilde"><img src={urlFor(item.image.bildefil).width(500).url()} onClick={()=>{setBigPicture(item.image.bildefil)}}/>
                              </div>
                              <div className="accordion-bilde-caption">
                              {item.image.title}
                            </div>
                            </div>
                      })
                    }
                </Accordion>
            </div>
            }
        </div>
    )

}

export default Archive