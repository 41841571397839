import React, {useEffect, useState, useRef} from "react";

import sanityClient from "../client.js";
import imageUrlBuilder from '@sanity/image-url'
import {PortableText} from '@portabletext/react'

import {BiArrowBack as Arrow} from 'react-icons/bi'
import BigPicture from "../components/bigpicture.component.jsx";

import './accordionFortelling.styles.scss';

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}


const AccordionFortelling = ({fortelling, fortellingShowing, setFortellingShowing, setExtraAccordionHeight, text, setBigPicture}) =>{

    //const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef();
    // const [bigPicture, setBigPicture] = useState(null)

    const openFortellingHandler = ()=>{
        //setIsOpen(!isOpen);
        if(fortellingShowing == fortelling._id){
            setFortellingShowing(null)
            setExtraAccordionHeight(0)
        } else{
            setFortellingShowing(fortelling._id)
            setExtraAccordionHeight(contentRef.current.scrollHeight)
        }
    }

    const returnDate=(ISOdate)=>{
        const d = new Date(ISOdate);
        let month;
        let day;
        //2022-02-11T14:24:37Z
        // var arr = "2012-07-01 00:00:00.0".split("-");
        var months = [ "Januar", "Februar", "Mars", "April", "Mai", "Juni",
            "Juli", "August", "September", "Oktober", "November", "Desember" ];
        // var month_index =  parseInt(arr[1],10) - 1;

        return d.getDay() + '. ' + months[d.getMonth()];
    }

    return (
        <div className='accordion-fortelling'>
            {/* {bigPicture && <BigPicture img={bigPicture} setBigPicture={setBigPicture}/> } */}
            <div className={`header-wrapper ${fortellingShowing == fortelling._id ? 'accordion-open' : ''}`} onClick={() => openFortellingHandler()}>
                <div className='accordion-fortelling-header'>
                    <div className="accordion-fortelling-dato"></div>
                    <div className="accordion-title">{fortelling.title}</div>
                    <div className="accordion-les-mer">{fortellingShowing == fortelling._id ? 'Lukk' : 'Les mer'}</div>
                </div>
            </div>
            <div
             
             ref={contentRef}
             style={
                fortellingShowing == fortelling._id
                  ? { 
                      height: contentRef.current.scrollHeight,
                      opacity: 1
                     }
                  : { 
                      marginTop: "0px",
                      height: "0px",
                      opacity: 0
                    }
              }
             className={`content-wrapper ${fortellingShowing == fortelling._id ? 'open' : ''}`}>
                 <div className="accordion-fortelling-tekst">
                    <PortableText
                        value={fortelling.tekst}
                    />
                </div>
                <div className="accordion-fortelling-bilder">
                    {fortelling.bilder ? fortelling.bilder.map((bilde,index) =>{
                        return <div key={index} className='accordion-fortelling-bilde'>
                                <img src={urlFor(bilde.image.bildefil).width(500).url()} onClick={()=>{setBigPicture(bilde.image.bildefil)}}/>
                            </div>
                    }) : ""}
                </div>
            </div>
        </div>
    )
}

export default AccordionFortelling