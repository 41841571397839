import React, {useEffect, useState, useRef} from "react";
import sanityClient from "../client.js";
import {PortableText} from '@portabletext/react'

import { BiX } from 'react-icons/bi';

import './musikkIkonGruppe.styles.scss';
import KirkeIkon from './graphics/KirkeIkon.component'

const MusikkIkonGruppe=({pos,kat,label,content,language, setContentExpanded})=>{
    // console.log(content)

    const [utvalgte, setUtvalgte] = useState(null)

    const positions=[
        {x:-63,y:40},
        {x:-52,y:-47},
        {x:35,y:-70},
        {x:98,y:-10},
        {x:9,y:101},
    ]

    const returnLabel =(label)=>{
        // console.log(label)
        if (language == 'EN'){
            switch(label){
                case 'Komposisjon':
                    return 'composition'
                    break;
                case 'Lyd':
                    return 'sound'
                    break;
                case 'Stemmer':
                    return 'voices'
                    break;
                case 'Orgel':
                    return 'organ'
                    break;
                case 'Musikere':
                    return 'musicians'
                    break;
            }
        }else{
            return label
        }
    }

    const setUtvalgteIkoner = () =>{
        let max = content.orgelAvsnitt.length > 5 ? 5 : content.orgelAvsnitt.length;     
        var arr = [];
        while(arr.length < max){
            var r = Math.floor(Math.random() * max);
            if(arr.indexOf(r) === -1) arr.push(r);
        }
        //console.log(arr)
        let utvalgte = arr.map(int =>{
            // console.log(int)
            return content.orgelAvsnitt[int]
        })
        // console.log(utvalgte)
        setUtvalgte(utvalgte);
    }

    useEffect(()=>{
        if(content && !utvalgte){
            // console.log('setter utvalgte')
            setUtvalgteIkoner()
        }
    },[content])

    //console.log(content)
 if(!utvalgte) return<></>
    return(
        <div className={`musikk-ikon-gruppe-wrapper ${kat}-ikon`} style={{transform:`translate(${pos.x}px, ${pos.y}px)`}}>

            <div className="musikk-ikon-kategori" onClick={()=>setContentExpanded({current:0,kat:kat})}>
               <KirkeIkon kategori={kat}/>
            </div>
            {utvalgte.map((utvalgt, index)=>{
                // let rand = Math.floor( Math.random() * 10 );
                // let pos = {x:positions[index].x+rand, y:positions[index].y+rand}
                //console.log(utvalgt)
                return <div key={utvalgt._key} className={`musikk-ikon-utvalgt ikon-${index}`} onClick={()=>setContentExpanded({current:index, kat:kat})} style={{transform:`translate(${positions[index].x}px, ${positions[index].y}px)`}}> <KirkeIkon kategori={kat} /> </div>
            })} 
            <div className="musikk-ikon-label" onClick={()=>setContentExpanded({current:0,kat:kat})}>
                {returnLabel(label)}
            </div>
        </div>
    )
}

export default MusikkIkonGruppe