import React from "react";

import './graphics.styles.scss'

const MusikkRamme =({kategori})=>{
	console.log(kategori)
return(
<div className="musikk-ramme">
	{kategori == 'Orgel'?<>
		<svg className="orgel-ramme-detalj-top-left-1" version="1.1" x="0px" y="0px" viewBox="0 0 32.17 50.27">
			<polygon points="32.17,18.1 8.04,0 14.08,12.06 18.1,18.1 0,24.13 18.1,30.16 8.04,50.27 32.17,30.16 "/>
		</svg>
		<svg className="orgel-ramme-detalj-top-left-2" version="1.1" x="0px" y="0px" viewBox="0 0 32.17 50.27">
			<polygon points="32.17,18.1 8.04,0 14.08,12.06 18.1,18.1 0,24.13 18.1,30.16 8.04,50.27 32.17,30.16 "/>
		</svg>
		<svg className="orgel-ramme-detalj-top-right-1" version="1.1" x="0px" y="0px" viewBox="0 0 32.17 50.27">
			<polygon points="32.17,18.1 8.04,0 14.08,12.06 18.1,18.1 0,24.13 18.1,30.16 8.04,50.27 32.17,30.16 "/>
		</svg>
		<svg className="orgel-ramme-detalj-top-right-2" version="1.1" x="0px" y="0px" viewBox="0 0 32.17 50.27">
			<polygon points="32.17,18.1 8.04,0 14.08,12.06 18.1,18.1 0,24.13 18.1,30.16 8.04,50.27 32.17,30.16 "/>
		</svg>

		<svg className="orgel-ramme-detalj-bottom-left-1" version="1.1" x="0px" y="0px" viewBox="0 0 32.17 50.27">
			<polygon points="32.17,18.1 8.04,0 14.08,12.06 18.1,18.1 0,24.13 18.1,30.16 8.04,50.27 32.17,30.16 "/>
		</svg>
		<svg className="orgel-ramme-detalj-bottom-left-2" version="1.1" x="0px" y="0px" viewBox="0 0 32.17 50.27">
			<polygon points="32.17,18.1 8.04,0 14.08,12.06 18.1,18.1 0,24.13 18.1,30.16 8.04,50.27 32.17,30.16 "/>
		</svg>
		<svg className="orgel-ramme-detalj-bottom-right-1" version="1.1" x="0px" y="0px" viewBox="0 0 32.17 50.27">
			<polygon points="32.17,18.1 8.04,0 14.08,12.06 18.1,18.1 0,24.13 18.1,30.16 8.04,50.27 32.17,30.16 "/>
		</svg>
		<svg className="orgel-ramme-detalj-bottom-right-2" version="1.1" x="0px" y="0px" viewBox="0 0 32.17 50.27">
			<polygon points="32.17,18.1 8.04,0 14.08,12.06 18.1,18.1 0,24.13 18.1,30.16 8.04,50.27 32.17,30.16 "/>
		</svg>
		</>

    :''}
	{/* {kategori == 'lydsnutter'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 50.27 32.17">
			<polygon  points="18.1,0 0,24.13 12.06,18.1 18.1,14.08 24.13,32.17 30.16,14.08 50.27,24.13 30.16,0 "/>
		</svg>
    :''} */}
        {kategori == 'Musikere'?<>
        <svg className="musiker-ramme-detalj-top-middle" version="1.1" x="0px" y="0px" viewBox="0 0 90.48 32.17">
			<polygon  points="26.14,32.17 0,14.08 0,0 10.05,10.05 20.11,0 20.11,10.05 46.25,22.12 70.38,10.05 70.38,0 
				80.43,10.05 90.48,0 90.48,14.08 64.34,32.17 "/>
		</svg>
		<svg className="musiker-ramme-detalj-bottom-left" version="1.1" x="0px" y="0px" viewBox="0 0 68.37 36.19">
			<polyline  points="15.64,0 33.74,0 51.84,0 61.89,16.09 67.92,4.02 67.92,36.19 61.89,22.12 43.79,36.19 43.79,16.09 
				33.74,28.15 23.68,16.09 23.68,36.19 7.6,22.12 -0.44,36.19 -0.44,4.02 7.6,16.09 15.64,0 "/>
        </svg>
		<svg className="musiker-ramme-detalj-bottom-right" version="1.1" x="0px" y="0px" viewBox="0 0 68.37 36.19">
			<polyline  points="15.64,0 33.74,0 51.84,0 61.89,16.09 67.92,4.02 67.92,36.19 61.89,22.12 43.79,36.19 43.79,16.09 
				33.74,28.15 23.68,16.09 23.68,36.19 7.6,22.12 -0.44,36.19 -0.44,4.02 7.6,16.09 15.64,0 "/>
        </svg>
		</>
    :''}
        {kategori == 'Noteark'?<>
        <svg className="noteark-ramme-detalj-top-left" version="1.1" x="0px" y="0px" viewBox="0 0 75 34.04">
			<path d="M32.47,22.12l-2.01-6.03h-6.03l4.02-4.02l-2.01-2.01h-6.03l4.02-2.01l-4.02-6.03l6.03,4.02L30.46,0l-2.01,8.04
			l2.01,4.02l4.02-6.03l-2.01,6.03l5.43,9.83l4.62-9.83l-2.01-6.03l4.02,6.03l2.01-4.02L44.54,0l4.02,6.03l6.03-4.02l-4.02,6.03
			l4.02,2.01h-4.02l-4.02,2.01l4.02,4.02h-4.02l-4.02,6.03c3.57,1.24,6.55,3.77,8.34,7.09L75,15.14c-5.27,4.86-8.21,11.74-8.09,18.9
			H8.09C8.21,26.87,5.27,19.99,0,15.14l24.13,14.08C25.92,25.89,28.9,23.35,32.47,22.12"/>	
		</svg>
			<svg className="noteark-ramme-detalj-top-right" version="1.1" x="0px" y="0px" viewBox="0 0 75 34.04">
			<path d="M32.47,22.12l-2.01-6.03h-6.03l4.02-4.02l-2.01-2.01h-6.03l4.02-2.01l-4.02-6.03l6.03,4.02L30.46,0l-2.01,8.04
			l2.01,4.02l4.02-6.03l-2.01,6.03l5.43,9.83l4.62-9.83l-2.01-6.03l4.02,6.03l2.01-4.02L44.54,0l4.02,6.03l6.03-4.02l-4.02,6.03
			l4.02,2.01h-4.02l-4.02,2.01l4.02,4.02h-4.02l-4.02,6.03c3.57,1.24,6.55,3.77,8.34,7.09L75,15.14c-5.27,4.86-8.21,11.74-8.09,18.9
			H8.09C8.21,26.87,5.27,19.99,0,15.14l24.13,14.08C25.92,25.89,28.9,23.35,32.47,22.12"/>	
		</svg>
		<svg className="noteark-ramme-detalj-bottom-left" version="1.1" x="0px" y="0px" viewBox="0 0 75 34.04">
			<path d="M32.47,22.12l-2.01-6.03h-6.03l4.02-4.02l-2.01-2.01h-6.03l4.02-2.01l-4.02-6.03l6.03,4.02L30.46,0l-2.01,8.04
			l2.01,4.02l4.02-6.03l-2.01,6.03l5.43,9.83l4.62-9.83l-2.01-6.03l4.02,6.03l2.01-4.02L44.54,0l4.02,6.03l6.03-4.02l-4.02,6.03
			l4.02,2.01h-4.02l-4.02,2.01l4.02,4.02h-4.02l-4.02,6.03c3.57,1.24,6.55,3.77,8.34,7.09L75,15.14c-5.27,4.86-8.21,11.74-8.09,18.9
			H8.09C8.21,26.87,5.27,19.99,0,15.14l24.13,14.08C25.92,25.89,28.9,23.35,32.47,22.12"/>	
		</svg>
			<svg className="noteark-ramme-detalj-bottom-right" version="1.1" x="0px" y="0px" viewBox="0 0 75 34.04">
			<path d="M32.47,22.12l-2.01-6.03h-6.03l4.02-4.02l-2.01-2.01h-6.03l4.02-2.01l-4.02-6.03l6.03,4.02L30.46,0l-2.01,8.04
			l2.01,4.02l4.02-6.03l-2.01,6.03l5.43,9.83l4.62-9.83l-2.01-6.03l4.02,6.03l2.01-4.02L44.54,0l4.02,6.03l6.03-4.02l-4.02,6.03
			l4.02,2.01h-4.02l-4.02,2.01l4.02,4.02h-4.02l-4.02,6.03c3.57,1.24,6.55,3.77,8.34,7.09L75,15.14c-5.27,4.86-8.21,11.74-8.09,18.9
			H8.09C8.21,26.87,5.27,19.99,0,15.14l24.13,14.08C25.92,25.89,28.9,23.35,32.47,22.12"/>	
		</svg>
		</>
    :''}
        {kategori == 'Bakgrunnsstoff'? <>
        <svg className="bakgrunnsstoff-ramme-detalj-top-left" version="1.1" x="0px" y="0px" viewBox="0 0 74.29 35.74">
			<path  d="M35.91,35.54C19.05,37.49,2.27,25.37,0,8.54h38.2c3.2,1.54,6.95,1.46,10.07-0.24c3.13-1.69,5.25-4.78,5.7-8.31
	l14.11,9.4L53.75,21.24c3.46,8.37,11.48,13.95,20.53,14.28L35.91,35.54z"/>
        </svg>
		<svg className="bakgrunnsstoff-ramme-detalj-top-right" version="1.1" x="0px" y="0px" viewBox="0 0 74.29 35.74">
			<path  d="M35.91,35.54C19.05,37.49,2.27,25.37,0,8.54h38.2c3.2,1.54,6.95,1.46,10.07-0.24c3.13-1.69,5.25-4.78,5.7-8.31
	l14.11,9.4L53.75,21.24c3.46,8.37,11.48,13.95,20.53,14.28L35.91,35.54z"/>
        </svg>
		<svg className="bakgrunnsstoff-ramme-detalj-bottom-left" version="1.1" x="0px" y="0px" viewBox="0 0 74.29 35.74">
			<path  d="M35.91,35.54C19.05,37.49,2.27,25.37,0,8.54h38.2c3.2,1.54,6.95,1.46,10.07-0.24c3.13-1.69,5.25-4.78,5.7-8.31
	l14.11,9.4L53.75,21.24c3.46,8.37,11.48,13.95,20.53,14.28L35.91,35.54z"/>
        </svg>
		<svg className="bakgrunnsstoff-ramme-detalj-bottom-right" version="1.1" x="0px" y="0px" viewBox="0 0 74.29 35.74">
			<path  d="M35.91,35.54C19.05,37.49,2.27,25.37,0,8.54h38.2c3.2,1.54,6.95,1.46,10.07-0.24c3.13-1.69,5.25-4.78,5.7-8.31
	l14.11,9.4L53.75,21.24c3.46,8.37,11.48,13.95,20.53,14.28L35.91,35.54z"/>
        </svg>
		</>
    :''}
        {kategori == 'Lydsnutter' || kategori == 'Demo' ?<>
        <svg className="lydsnutter-ramme-detalj-top-left" version="1.1" x="0px" y="0px" viewBox="0 0 84.45 34.18">
			<polygon points="72.39,0 76.41,4.02 76.41,8.04 76.41,16.09 74.4,20.11 78.42,20.11 80.43,20.11 82.44,20.11 
				84.45,20.11 84.45,24.13 84.45,28.15 80.43,32.17 78.42,32.17 76.41,30.16 74.4,28.15 74.4,26.14 74.4,24.13 66.36,30.16 
				58.31,32.17 52.28,32.17 48.26,30.16 44.24,24.13 42.23,18.1 42.23,24.13 38.2,30.16 34.18,34.18 28.15,34.18 18.1,32.17 
				12.06,26.14 10.05,28.15 12.06,32.17 10.05,34.18 8.04,34.18 6.03,34.18 2.01,30.16 0,28.15 0,24.13 2.01,22.12 4.02,22.12 
				6.03,22.12 8.04,24.13 10.05,24.13 8.04,18.1 6.03,12.06 8.04,6.03 10.05,2.01 16.09,0 "/>
        </svg>
		<svg className="lydsnutter-ramme-detalj-top-middle" version="1.1" x="0px" y="0px" viewBox="0 0 84.45 34.18">
			<polygon points="72.39,0 76.41,4.02 76.41,8.04 76.41,16.09 74.4,20.11 78.42,20.11 80.43,20.11 82.44,20.11 
				84.45,20.11 84.45,24.13 84.45,28.15 80.43,32.17 78.42,32.17 76.41,30.16 74.4,28.15 74.4,26.14 74.4,24.13 66.36,30.16 
				58.31,32.17 52.28,32.17 48.26,30.16 44.24,24.13 42.23,18.1 42.23,24.13 38.2,30.16 34.18,34.18 28.15,34.18 18.1,32.17 
				12.06,26.14 10.05,28.15 12.06,32.17 10.05,34.18 8.04,34.18 6.03,34.18 2.01,30.16 0,28.15 0,24.13 2.01,22.12 4.02,22.12 
				6.03,22.12 8.04,24.13 10.05,24.13 8.04,18.1 6.03,12.06 8.04,6.03 10.05,2.01 16.09,0 "/>
		</svg>
		<svg className="lydsnutter-ramme-detalj-top-right" version="1.1" x="0px" y="0px" viewBox="0 0 84.45 34.18">
			<polygon points="72.39,0 76.41,4.02 76.41,8.04 76.41,16.09 74.4,20.11 78.42,20.11 80.43,20.11 82.44,20.11 
				84.45,20.11 84.45,24.13 84.45,28.15 80.43,32.17 78.42,32.17 76.41,30.16 74.4,28.15 74.4,26.14 74.4,24.13 66.36,30.16 
				58.31,32.17 52.28,32.17 48.26,30.16 44.24,24.13 42.23,18.1 42.23,24.13 38.2,30.16 34.18,34.18 28.15,34.18 18.1,32.17 
				12.06,26.14 10.05,28.15 12.06,32.17 10.05,34.18 8.04,34.18 6.03,34.18 2.01,30.16 0,28.15 0,24.13 2.01,22.12 4.02,22.12 
				6.03,22.12 8.04,24.13 10.05,24.13 8.04,18.1 6.03,12.06 8.04,6.03 10.05,2.01 16.09,0 "/>
		</svg>
		<svg className="lydsnutter-ramme-detalj-bottom-left" version="1.1" x="0px" y="0px" viewBox="0 0 84.45 34.18">
			<polygon points="72.39,0 76.41,4.02 76.41,8.04 76.41,16.09 74.4,20.11 78.42,20.11 80.43,20.11 82.44,20.11 
				84.45,20.11 84.45,24.13 84.45,28.15 80.43,32.17 78.42,32.17 76.41,30.16 74.4,28.15 74.4,26.14 74.4,24.13 66.36,30.16 
				58.31,32.17 52.28,32.17 48.26,30.16 44.24,24.13 42.23,18.1 42.23,24.13 38.2,30.16 34.18,34.18 28.15,34.18 18.1,32.17 
				12.06,26.14 10.05,28.15 12.06,32.17 10.05,34.18 8.04,34.18 6.03,34.18 2.01,30.16 0,28.15 0,24.13 2.01,22.12 4.02,22.12 
				6.03,22.12 8.04,24.13 10.05,24.13 8.04,18.1 6.03,12.06 8.04,6.03 10.05,2.01 16.09,0 "/>
        </svg>
		<svg className="lydsnutter-ramme-detalj-bottom-middle" version="1.1" x="0px" y="0px" viewBox="0 0 84.45 34.18">
			<polygon points="72.39,0 76.41,4.02 76.41,8.04 76.41,16.09 74.4,20.11 78.42,20.11 80.43,20.11 82.44,20.11 
				84.45,20.11 84.45,24.13 84.45,28.15 80.43,32.17 78.42,32.17 76.41,30.16 74.4,28.15 74.4,26.14 74.4,24.13 66.36,30.16 
				58.31,32.17 52.28,32.17 48.26,30.16 44.24,24.13 42.23,18.1 42.23,24.13 38.2,30.16 34.18,34.18 28.15,34.18 18.1,32.17 
				12.06,26.14 10.05,28.15 12.06,32.17 10.05,34.18 8.04,34.18 6.03,34.18 2.01,30.16 0,28.15 0,24.13 2.01,22.12 4.02,22.12 
				6.03,22.12 8.04,24.13 10.05,24.13 8.04,18.1 6.03,12.06 8.04,6.03 10.05,2.01 16.09,0 "/>
		</svg>
		<svg className="lydsnutter-ramme-detalj-bottom-right" version="1.1" x="0px" y="0px" viewBox="0 0 84.45 34.18">
			<polygon points="72.39,0 76.41,4.02 76.41,8.04 76.41,16.09 74.4,20.11 78.42,20.11 80.43,20.11 82.44,20.11 
				84.45,20.11 84.45,24.13 84.45,28.15 80.43,32.17 78.42,32.17 76.41,30.16 74.4,28.15 74.4,26.14 74.4,24.13 66.36,30.16 
				58.31,32.17 52.28,32.17 48.26,30.16 44.24,24.13 42.23,18.1 42.23,24.13 38.2,30.16 34.18,34.18 28.15,34.18 18.1,32.17 
				12.06,26.14 10.05,28.15 12.06,32.17 10.05,34.18 8.04,34.18 6.03,34.18 2.01,30.16 0,28.15 0,24.13 2.01,22.12 4.02,22.12 
				6.03,22.12 8.04,24.13 10.05,24.13 8.04,18.1 6.03,12.06 8.04,6.03 10.05,2.01 16.09,0 "/>
		</svg>
			
			</>
    :''}
        {/* {kategori == 'bakgrunnsstoff'?
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 68.37 36.19">
			<polyline  points="15.64,0 33.74,0 51.84,0 61.89,16.09 67.92,4.02 67.92,36.19 61.89,22.12 43.79,36.19 43.79,16.09 
				33.74,28.15 23.68,16.09 23.68,36.19 7.6,22.12 -0.44,36.19 -0.44,4.02 7.6,16.09 15.64,0 "/>
        </svg>
    :''} */}

</div>
)
}

export default MusikkRamme